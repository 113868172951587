
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import SideBar from '../../page/dashboard'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "react-router-dom";
import { checkPermission } from '../../utils/permission_utils.js';
import { useLocation } from 'react-router-dom';
import * as CrmService from '../../services/crm_service';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import province from '../../assets/datas/provinces.json'
import banks from '../../assets/datas/banks.json'
const moment = require("moment-timezone")
moment.tz.setDefault("Asia/Bangkok")
export default function App() {
    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
    // backdrop
    const [rows_data, setRowsData] = React.useState(true);
    const [edit_mode, setEdit] = React.useState(false)
    const [user_data, setUserData] = React.useState({})
    const [user_data1, setUserData1] = React.useState({})
    const [user_data2, setUserData2] = React.useState({})
    const [user_data3, setUserData3] = React.useState({})
    const [user_data4, setUserData4] = React.useState({})
    const [user_data5, setUserData5] = React.useState({})
    const [query_phone, setQueryPhone] = React.useState('')

    const [consent, setConsent] = React.useState({})
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const contact_id = queryParams.get('contact_id');
    const user_id = queryParams.get('user_id');
    const navigate = useNavigate();
    // const has_permission = checkPermission(location.pathname, decoded);
    const has_permission = true;
    React.useEffect(() => {
        if (!has_permission) {
            window.location.href = '/404';
        }
        fetchData()
    }, [])
    const fetchData = () => {
        let obj = {
            "user_id": user_id,
            "contact_id": Number(contact_id)
        }
        CrmService.getFormDetail(token, obj)
            .then((res) => {
                setUserData({
                    contact_id: res.data.data.user_data[0].contact_id,
                    user_id: res.data.data.user_data[0].user_id,
                })
                setQueryPhone(res.data.data.user_data[0].user_detail.formData1.phone)
                setUserData1(res.data.data.user_data[0].user_detail.formData1)
                setUserData2(res.data.data.user_data[0].user_detail.formData2)
                setUserData3(res.data.data.user_data[0].user_detail.formData3)
                setUserData4(res.data.data.user_data[0].user_detail.formData4)
                setUserData5(res.data.data.user_data[0].user_detail.formData5)
                setConsent(res.data.data.user_data[0].user_detail.consent)
            })
    }
    const handleEdit = (value) => {
        setEdit(true)
    }
    const handleSaveForm = (value) => {
        setEdit(false)
        let obj = {
            user_data: user_data,
            user_detail: {
                formData1: user_data1,
                formData2: user_data2,
                formData3: user_data3,
                formData4: user_data4,
                formData5: user_data5,
                consent: consent,
            },
            query_phone:query_phone
        }
        confirmAlert({
            title: 'ยืนยันที่จะบันทึกการแก้ไข?',
            message: 'คุณแน่ใจหรือที่จะอัพเดทข้อมูล',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        CrmService.EditForm(token, obj)
                            .then((res) => {
                                window.alert('บันทึกสำเร็จ')
                                navigate(`/crm/customer/edit?contact_id=${contact_id}&user_id=${user_id}&phone=${user_data1.phone}`)
                                window.location.reload();
                            })
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        // console.log("no")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
    }
    const convertDate = () => {
        return moment(new Date(user_data1?.birthYear + '-' + user_data1?.birthMonth + '-' + user_data1?.birthDate)).format('YYYY-MM-DD')
    }
    const handleChangeBirthdate = (date) => {

        let birthDate = moment(new Date(date)).format('DD')
        let birthMonth = moment(new Date(date)).format('MM')
        let birthYear = moment(new Date(date)).format('YYYY')

        setUserData1((prevData) => ({
            ...prevData,
            birthDate: birthDate,
            birthMonth: birthMonth,
            birthYear: birthYear,
        }));
    }
    const handleChange = (e, form) => {
        const { name, value } = e.target;
        if (form === 1) {
            setUserData1((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else if (form === 2) {
            setUserData2((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else if (form === 3) {
            setUserData3((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else if (form === 4) {
            setUserData4((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else if (form === 5) {
            setUserData5((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };
    return (
        <>
            {has_permission && (
                <div className='customer-list' style={{ marginRight: '1rem', marginLeft: '1rem' }}>
                    <Paper elevation={5} style={{ paddingBottom: '10px', padding: '20px' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Button variant="contained" color="success" style={{ marginRight: '5px', float: 'right', marginBottom: '10px' }} onClick={() => handleSaveForm()}><SaveIcon />บันทึก</Button>
                                <Button variant="contained" style={{ marginRight: '10px', float: 'right' }} onClick={() => handleEdit()}><EditIcon />แก้ไขข้อมูล</Button>
                            </Grid>
                        </Grid>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '7px' }}>ข้อมูลทั่วไป</Typography>

                            <Grid container spacing={0}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>คำนำหน้าชื่อ</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl >
                                        {user_data1?.prefix &&
                                            <Select
                                                style={{ width: "100px" }}
                                                size="small"
                                                disabled={!edit_mode}
                                                name="prefix"
                                                value={ user_data1?.prefix??""}
                                                onChange={(e) => handleChange(e, 1)}                                    >
                                                <MenuItem value="นาย">นาย</MenuItem>
                                                <MenuItem value="นางสาว">นางสาว</MenuItem>
                                                <MenuItem value="นาง">นาง</MenuItem>
                                            </Select>

                                        }
                                        {user_data1?.gender &&
                                            <Select
                                                style={{ width: "100px" }}
                                                size="small"
                                                disabled={!edit_mode}
                                                name="gender"
                                                value={user_data1?.gender??"" }
                                                onChange={(e) => handleChange(e, 1)}                                    >
                                                <MenuItem value="นาย">นาย</MenuItem>
                                                <MenuItem value="นางสาว">นางสาว</MenuItem>
                                                <MenuItem value="นาง">นาง</MenuItem>
                                            </Select>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ชื่อ</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="firstName" variant="outlined" name="firstName" value={user_data1?.firstName} disabled={!edit_mode} onChange={(e) => handleChange(e, 1)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>นามสกุล</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="lastName" variant="outlined" name="lastName" value={user_data1?.lastName} disabled={!edit_mode} onChange={(e) => handleChange(e, 1)} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันเดือนปีเกิด</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <MobileDatePicker inputFormat="YYYY-MM-DD" value={convertDate()} onChange={(e) => handleChangeBirthdate(e)} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เลขบัตรประชาชน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="identity" variant="outlined" name="identity" value={user_data1?.identity} disabled={true} onChange={(e) => handleChange(e, 1)} />
                                </Grid>

                                <Grid item xs={1.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ประวัติการศึกษาสูงสุด</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl >




                                        <Select
                                            style={{ width: "200px" }}
                                            size="small"
                                            disabled={!edit_mode}
                                            name="educational"
                                            value={user_data1?.educational ?? ""}
                                            onChange={(e) => handleChange(e, 1)}  >
                                            {user_data1?.educational === "มป" ?
                                                <MenuItem value={"มป"} >มัธยมศึกษาตอนปลาย/ปวช.</MenuItem>
                                                : <MenuItem value={"ปวช"} >มัธยมศึกษาตอนปลาย/ปวช.</MenuItem>
                                            }
                                            <MenuItem value="ปวส">อนุปริญญา/ปวส.</MenuItem>
                                            <MenuItem value="ปต">ปริญญาตรี</MenuItem>
                                            <MenuItem value="ปท">ปริญญาโท ขึ้นไป</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เบอร์มือถือ</Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="phone" variant="outlined" name="phone" value={user_data1?.phone} disabled={!edit_mode} onChange={(e) => handleChange(e, 1)} />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ID Line</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="line" variant="outlined" name="line" value={user_data1?.line} disabled={!edit_mode} onChange={(e) => handleChange(e, 1)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>Facebook</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="facebook" variant="outlined" name="facebook" value={user_data1?.facebook} disabled={!edit_mode} onChange={(e) => handleChange(e, 1)} />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อีเมล</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="email" variant="outlined" name="email" value={user_data1?.email} disabled={!edit_mode} onChange={(e) => handleChange(e, 1)} />
                                </Grid>
                            </Grid>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px', marginTop: '5px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '7px' }}>ข้อมูลคู่สมรส</Typography>

                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>สถานภาพ</Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <FormControl >
                                        <Select
                                            style={{ width: "100px" }}
                                            size="small"
                                            disabled={!edit_mode}
                                            name="status"
                                            value={user_data4?.status ?? ""}
                                            onChange={(e) => handleChange(e, 4)}                                    >
                                            <MenuItem value="โสด">โสด</MenuItem>
                                            <MenuItem value="สมรส">สมรส</MenuItem>
                                            <MenuItem value="หย่าร้าง">หย่าร้าง</MenuItem>
                                            <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ชื่อ</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseName" variant="outlined" name="spouseName" value={user_data4?.spouseName} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>นามสกุล</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseSurname" variant="outlined" name="spouseSurname" value={user_data4?.spouseSurname} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>นามสกุลเดิม</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseOldSurname" variant="outlined" name="spouseOldSurname" value={user_data4?.spouseOldSurname} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เบอร์มือถือ</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spousePhone" variant="outlined" name="spousePhone" value={user_data4?.spousePhone} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ที่อยู่คู่สมรส</Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseAddress" variant="outlined" name="spouseAddress" value={user_data4?.spouseAddress} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ตำบล/แขวง</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseDistrict" variant="outlined" name="spouseDistrict" value={user_data4?.spouseDistrict} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อำเภอ/เขต</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseCounty" variant="outlined" name="spouseCounty" value={user_data4?.spouseCounty} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จังหวัด</Typography>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="spouseProvince"
                                        value={user_data4?.spouseProvince ?? ""}
                                        onChange={(e) => handleChange(e, 4)}                                    >
                                        {
                                            province.map((province) => {
                                                return <MenuItem value={province.province_form}>{province.province_form}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รหัสไปรษณีย์</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseZipcode" variant="outlined" name="spouseZipcode" value={user_data4?.spouseZipcode} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ชื่อบริษัท/โรงงาน</Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseComp" variant="outlined" name="spouseComp" value={user_data4?.spouseComp} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เบอร์โทรที่ทำงาน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseCompPhone" variant="outlined" name="spouseCompPhone" value={user_data4?.spouseCompPhone} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รายได้ต่อเดือน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseIncome" variant="outlined" name="spouseIncome" value={user_data4?.spouseIncome} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                            </Grid>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px', marginTop: '5px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '7px' }}>ข้อมูลบุคคลที่สามารถติดตามทวงหนี้ได้</Typography>

                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ชื่อ</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="collectName" variant="outlined" name="collectName" value={user_data4?.collectName} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>นามสกุล</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="collectSurname" variant="outlined" name="collectSurname" value={user_data4?.collectSurname} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เบอร์มือถือ</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="collectPhone" variant="outlined" name="collectPhone" value={user_data4?.collectPhone} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ความสัมพันธ์</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="collectRelation" variant="outlined" name="collectRelation" value={user_data4?.collectRelation} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>

                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ชื่อที่ทำงาน</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="collectComp" variant="outlined" name="collectComp" value={user_data4?.collectComp} disabled={!edit_mode} onChange={(e) => handleChange(e, 4)} />
                                </Grid>
                            </Grid>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px', marginTop: '5px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '7px' }}>ข้อมูลที่อยู่</Typography>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ข้อมูลตามบัตรประชาชน</Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="address" variant="outlined" name="address" value={user_data1?.address} disabled={!edit_mode} onChange={(e) => handleChange(e, 1)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ตำบล/แขวง</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="district" variant="outlined" name="district" value={user_data1?.district} disabled={!edit_mode} onChange={(e) => handleChange(e, 1)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อำเภอ/เขต</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="county" variant="outlined" name="county" value={user_data1?.county} disabled={!edit_mode} onChange={(e) => handleChange(e, 1)} />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จังหวัด</Typography>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="province"
                                        value={user_data1?.province ?? ""}
                                        onChange={(e) => handleChange(e, 1)}                                    >
                                        {
                                            province.map((province) => {
                                                return <MenuItem value={province.province_form}>{province.province_form}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รหัสไปรษณีย์</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="zipcode" variant="outlined" name="zipcode" value={user_data1?.zipcode} disabled={!edit_mode} onChange={(e) => handleChange(e, 1)} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ที่อยู่ปัจจุบัน</Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="currentAddress" variant="outlined" name="currentAddress" value={user_data2?.currentAddress} disabled={!edit_mode} onChange={(e) => handleChange(e, 2)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ตำบล/แขวง</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="currentDistrict" variant="outlined" name="currentDistrict" value={user_data2?.currentDistrict} disabled={!edit_mode} onChange={(e) => handleChange(e, 2)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อำเภอ/เขต</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="currentCounty" variant="outlined" name="currentCounty" value={user_data2?.currentCounty} disabled={!edit_mode} onChange={(e) => handleChange(e, 2)} />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จังหวัด</Typography>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="currentProvince"
                                        value={user_data2?.currentProvince ?? ""}
                                        onChange={(e) => handleChange(e, 2)}                                    >
                                        {
                                            province.map((province) => {
                                                return <MenuItem value={province.province_form}>{province.province_form}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รหัสไปรษณีย์</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="currentZipcode" variant="outlined" name="currentZipcode" value={user_data2?.currentZipcode} disabled={!edit_mode} onChange={(e) => handleChange(e, 2)} />
                                </Grid>
                            </Grid>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px', marginTop: '5px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '7px' }}>ข้อมูลที่อยู่ที่ทำงาน</Typography>

                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ประเภทอาชีพ</Typography>
                                </Grid>
                                <Grid item xs={3} >
                                    <FormControl >
                                        <Select
                                            style={{ width: "200px" }}
                                            size="small"
                                            disabled={!edit_mode}
                                            name="career"
                                            value={user_data3?.career ?? ""}
                                            onChange={(e) => handleChange(e, 3)}                                    >
                                            <MenuItem value="พนักงานบริษัท (ประจำสำนักงาน)">พนักงานบริษัท (ประจำสำนักงาน)</MenuItem>
                                            <MenuItem value="ข้าราชการ,พนักงานรัฐวิสาหกิจ">ข้าราชการ,พนักงานรัฐวิสาหกิจ</MenuItem>
                                            <MenuItem value="พนักงานผลิต/พนักงานโรงงาน">พนักงานผลิต/พนักงานโรงงาน</MenuItem>
                                            <MenuItem value="พนักงานขับรถ">พนักงานขับรถ</MenuItem>
                                            <MenuItem value="นักงานบริการ เช่น ร้านอาหาร,โรงแรม">พนักงานบริการ เช่น ร้านอาหาร,โรงแรม</MenuItem>
                                            <MenuItem value="พนักงานขาย">พนักงานขาย</MenuItem>
                                            <MenuItem value="เจ้าของกิจการ/หุ้นส่วนกิจการพนักงานขนส่งอาหาร เช่น Grab, Line man">เจ้าของกิจการ/หุ้นส่วนกิจการพนักงานขนส่งอาหาร เช่น Grab, Line man</MenuItem>
                                            <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ระบุอาชีพ (อื่นๆ)</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="elseCareer" variant="outlined" name="elseCareer" value={user_data3?.elseCareer} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ตำแหน่ง</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="compPosition" variant="outlined" name="compPosition" value={user_data3?.compPosition} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>

                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รายได้ (ประจำ)</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="mainIncome" variant="outlined" name="mainIncome" value={user_data3?.mainIncome} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รายได้ (พิเศษ)</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="extraIncome" variant="outlined" name="extraIncome" value={user_data3?.extraIncome} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>แหล่งที่มาของรายได้</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="incomeSource" variant="outlined" name="incomeSource" value={user_data3?.incomeSource} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ประเภทกิจการ</Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="compType" variant="outlined" name="compType" value={user_data3?.compType} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ชื่อกิจการ</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="compName" variant="outlined" name="compName" value={user_data3?.compName} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เบอร์โทรที่ทำงาน</Typography>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="compPhone" variant="outlined" name="compPhone" value={user_data3?.compPhone} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อีเมลที่ทำงาน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem', width: '350px' }} size="small" id="compEmail" variant="outlined" name="compEmail" value={user_data3?.compEmail} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ข้อมูลที่อยู่ที่ทำงาน</Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="compAddress" variant="outlined" name="compAddress" value={user_data3?.compAddress} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ตำบล/แขวง</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="compDistrict" variant="outlined" name="compDistrict" value={user_data3?.compDistrict} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อำเภอ/เขต</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="compCounty" variant="outlined" name="compCounty" value={user_data3?.compCounty} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จังหวัด</Typography>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="compProvince"
                                        value={user_data3?.compProvince ?? ""}
                                        onChange={(e) => handleChange(e, 3)}                                    >
                                        {
                                            province.map((province) => {
                                                return <MenuItem value={province.province_form}>{province.province_form}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รหัสไปรษณีย์</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="compZipcode" variant="outlined" name="compZipcode" value={user_data3?.compZipcode} disabled={!edit_mode} onChange={(e) => handleChange(e, 3)} />
                                </Grid>
                            </Grid>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px', marginTop: '5px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '7px' }}>ข้อมูลการขอสินเชื่อ</Typography>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ภาระหนี้รวมทั้งหมด</Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="career" variant="outlined" name="totalDebt" value={user_data5?.totalDebt} disabled={!edit_mode} onChange={(e) => handleChange(e, 5)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ผ่อนชำระต่อเดือน</Typography>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="payAble" variant="outlined" name="payAble" value={user_data5?.payAble} disabled={!edit_mode} onChange={(e) => handleChange(e, 5)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จำนวนเงินที่ขอกู้</Typography>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="amountReq" variant="outlined" name="amountReq" value={user_data5?.amountReq} disabled={!edit_mode} onChange={(e) => handleChange(e, 5)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ยอดที่ต้องการผ่อนชำระต่อเดือน</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="mainIncome" variant="outlined" name="monthlyInstallments" value={user_data5?.monthlyInstallments} disabled={!edit_mode} onChange={(e) => handleChange(e, 5)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วัตถุประสงค์</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="extraIncome" variant="outlined" name="objective" value={user_data5?.objective} disabled={!edit_mode} onChange={(e) => handleChange(e, 5)} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ธนาคาร</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="bank"
                                        value={user_data5?.bank ?? ""}
                                        onChange={(e) => handleChange(e, 5)}                                    >
                                        {
                                            Array.from(banks.banks, (item, index) => {
                                                return <MenuItem key={index} value={item}>{item}</MenuItem>

                                            })
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>เลขบัญชี</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="account" variant="outlined" name="account" value={user_data5?.account} disabled={!edit_mode} onChange={(e) => handleChange(e, 5)} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Paper>
                </div>
            )}
        </>
    )

}