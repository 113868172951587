
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import jwt_decode from "jwt-decode";
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as CrmService from '../../services/crm_service';
// components
import { useNavigate } from "react-router-dom";
import { checkPermission } from '../../utils/permission_utils.js';
import { useLocation } from 'react-router-dom';
export default function App({citizen_id}) {
    let token = localStorage.getItem('token')
    let decoded
    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [open, setOpen] = React.useState(false);
    const [rows_data, setRowsData] = React.useState([]);
    const [detail, setDetail] = React.useState();
    const [filters, setFilters] = React.useState([])
    const [phone, setPhone] = React.useState('')

    const navigate = useNavigate();
    const location = useLocation();
    // const has_permission = checkPermission(location.pathname, decoded);

    React.useEffect(() => {
        // if (!has_permission) {
        //     window.location.href = '/404';
        // }
        fetchData()

    }, [filters])

    const fetchData = () => {
        let object = {
            "pagination": {
                "page": 1,
                "rowsPerPage": 10000,
                "sortBy": "",
                "descending": true
            },
            "filters": filters
        }
        CrmService.crmGetUserListByCitizenID(token, citizen_id)
            .then((res) => {
                let row = res.data.data.list
                let detail = res.data.data.detail
                setDetail(detail)
                setRowsData(row)
                let data = []
                row.map((item, index) => (
                    data.push(
                        {
                            "id": index + 1,
                            ...item
                        }
                    )
                ));
                setRowsData(data);
            })
    }
          
    // columns
    const columns = [
        {
            field: '_id',
            headerName: 'id',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'register_date',
            headerName: 'วันที่สมัคร Digital App',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'brand',
            headerName: 'Brand',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'MarketingChannel',
            headerName: 'ช่องทางการตลาด',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'line_id',
            headerName: 'Line ID',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'line_name',
            headerName: 'ชื่อ LINE',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'register_status',
            headerName: 'สถานะ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'contract_no',
            headerName: 'เลขที่สัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'consent_date',
            headerName: 'วันที่รับเอกสารconsent',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'note_all_contact',
            headerName: 'note',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'form_detail',
            headerName: 'ใบสมัคร/ไฟล์สำหรับสมัคร',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>

                        <IconButton id='form_detail' onClick={(event) => {
                            routeChangeFormDetail(event, cellValues)
                        }} ><SearchIcon color="primary" /></IconButton>
                    </>
                );
            }
        },
        {
            field: 'revcard',
            headerName: 'การ์ดลูกหนี้',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton id={"revcard"}  onClick={(event) => {
                            routeChangeRevCard(event, cellValues)
                        }} ><SearchIcon color="primary" /></IconButton>
                    </>
                );
            }

        },
        {
            field: 'detail',
            headerName: 'รายละเอียดเพิ่มเติม',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton id={"detail"}  onClick={(event) => {
                            routeChangeDetail(event, cellValues);
                        }} ><SearchIcon color="primary" /></IconButton>
                    </>
                );
            }
        },

    ];

    // function
    const routeChangeDetail = (e, rowdata) => {
        const parameterData = { user_id:rowdata.row.user_id,contract_no:rowdata.row.contract_no};
        const queryParams = new URLSearchParams(parameterData).toString();
        window.open('/crm/customer/detail?' + queryParams, '_blank');
    }
    const routeChangeRevCard = (e, rowdata) => {
        const parameterData = { Policy_no:rowdata.row.contract_no};
        const queryParams = new URLSearchParams(parameterData).toString();
        window.open('/crm/customer/revcard_detail?' + queryParams, '_blank');
    }
    const routeChangeFormDetail = (e,rowdata) => {
        const parameterData = { contact_id:rowdata.row.contact_id,user_id :rowdata.row.user_id,contract_no:rowdata.row.contract_no};
        const queryParams = new URLSearchParams(parameterData).toString();
        window.open('/crm/customer/form_detail?' + queryParams, '_blank');
    };
    return (
        <>

            {/* {has_permission && ( */}
            <div className='customer-list' style={{ marginRight: '2rem', marginLeft: '2rem' }}>
                {
                    false ?
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        : ""
                }
                        <div style={{ padding: '10px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={1.5}><TextField id="outlined-basic" label="ชื่อ" variant="outlined" value={detail?.name} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth disabled={true} /></Grid>
                                <Grid item xs={2}><TextField id="outlined-basic" label="นามสกุล" variant="outlined" value={detail?.lastname} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth disabled={true} /></Grid>
                                <Grid item xs={2}><TextField id="outlined-basic" label="เลขบัตรประจำตัวประชาชน" variant="outlined" value={detail?.citizen_id} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth disabled={true} /></Grid>
                                <Grid item xs={2}><TextField id="outlined-basic" label="วัน/เดือน/ปี เกิด" variant="outlined" value={detail?.birthdate} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth disabled={true} /></Grid>
                                <Grid item xs={2}><TextField id="outlined-basic" label="เบอร์โทรศัพท์มือถือ" variant="outlined" value={detail?.phone_number} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth disabled={true} /></Grid>
                            </Grid>
                        </div>
                        <Typography variant='h5' style={{ margin: '20px'}}>ประวัติการสมัครสินเชื่อ</Typography>
                        <h2></h2>
                        <Paper elevation={3} >
                    <Box
                        sx={{
                            height: 630,
                            width: '100%',
                            // bgcolor:'black'
                        }}
                    >
                        <DataGrid
                            rows={rows_data}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            sx={{
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    textOverflow: "clip",
                                    whiteSpace: "break-spaces",
                                    lineHeight: 1.5,
                                },
                            }}

                        />
                    </Box>

                </Paper>
            </div>

            {/* )} */}

        </>

    )
}
