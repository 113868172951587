
  export const converBank = (bankCode) => {
    let bankName
    switch (bankCode) {
        case '002':
          bankName = "ธนาคารกรุงเทพ (BBL)"
          break;
        case '004':
          bankName = 'ธนาคารกสิกรไทย (KBANK)'
          break;
        case '006':
          bankName = 'ธนาคารกรุงไทย (KTB)'
          break;
        case '014':
          bankName = 'ธนาคารไทยพาณิชย์ (SCB)'
          break;
        case '025':
          bankName = 'ธนาคารกรุงศรีอยุธยา (BAY)'
          break;
        default:
          bankName = 'NO'
      }
      return bankName
};