import * as React from 'react';
import './../sum.css'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import SideBar from './../../page/dashboard'
import * as userHttpServices from '../../../src/services/services';
import TableActivity from '../../components/table/table_activity';
import TableRegisDD from '../../components/table/table_crm_regis_direct_debit';
import TablePayDD from '../../components/table/table_crm_pay_direct_debit';
import TableTokenDD from '../../components/table/table_crm_token_direct_debit';

import TableRevcard from '../../components/table/table_revcard';
import { useLocation } from 'react-router-dom';
export default function App() {
    const [data, setData] = React.useState()
    let token = localStorage.getItem('token_org')
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const contract_no = queryParams.get('contract_no');
    const user_id = queryParams.get('user_id');

    const handleCloseTab = () => {
        window.close();
    };
    return (
        <div className='contact-management' style={{ margin: '2rem' }}>
            <SideBar />
            <Button variant="contained" style={{ marginTop: '3rem' }} onClick={() => handleCloseTab()}><ArrowBackIcon></ArrowBackIcon>ย้อนกลับ</Button>
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: 'bold' }}>รายละเอียดเพิ่มเติม</Typography>
                </Grid>
            </Grid>
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>ประวัติการสมัคร Direct Debit</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>ตารางประวัติการสมัคร Direct Debit ของลูกค้า</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableRegisDD />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>ประวัติการชำระผ่าน Direct Debit</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>ตารางประวัติการชำระ Direct Debit ของลูกค้า</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TablePayDD />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>รายการToken ตัดเงินของลูกค้า</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>ตารางTokenสำหรับตัดเงินของลูกค้า</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableTokenDD />
                    </AccordionDetails>
                </Accordion>

            </div>
        </div >
    )
}