import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import jwt_decode from "jwt-decode";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import SideBar from '../dashboard.js';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { confirmAlert } from 'react-confirm-alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as DirectCredit from '../../services/direct_credit.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';



export default function App() {
    let token = localStorage.getItem('token')
    let token_org = localStorage.getItem('token_org')

    let decode;
    if (token === null) {
        window.location.href = '/login';
    } else {
        decode = jwt_decode(token);
    }
    const [open, setOpen] = useState(true);
    const [rows_tran, setRowsTran] = useState([]);
    const [selected_row, setSelectedRow] = useState([]);
    const [from_date, setFromDate] = useState(null);
    const [end_date, setEndDate] = useState(null)
    const location = useLocation();
    const has_permis = checkPermission(location.pathname, decode);

    useEffect(() => {
        if (!has_permis) {
            window.location.href = '/404';
        }
        loadAwaitList();
    }, [])


    const formatNumberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const loadAwaitList = () => {
        setOpen(true);
        let object = {
            start_date: from_date,
            end_date: end_date
        }
        DirectCredit.getCreditOperationList(object, token_org)
            .then((res) => {
                
                const list_data = res.data.data;
                const transfer = [];
                console.log(res);
                list_data.map((item, index) => {
                    console.log(item);
                    let status = "ไม่รับสาย"
                    if(item.status === 0){
                        status = "รอแอด Line"
                    }
                    transfer.push(
                        {
                            "id": index + 1,
                            "fullname": item.name,
                            "id_number": item.id_number,
                            "contact_id": item.contact_id,
                            "status": status,
                            "approve_date":item.approve_date === null ?'': item.approve_date.split("T")[0]
                        }
                    )
                });
                setRowsTran(transfer);
                setOpen(false);
            })
    }

    const handleSelectedChange = (newSelection) => {
        //console.log(newSelection.selectionModel);
        setSelectedRow(newSelection);
    };

    const handleSearchButton = () => {

        if (!from_date || !end_date || end_date < from_date) {
            alert("วันที่ผิดโปรดเลือกวันที่ใหม่อีกครั้ง");
            return;
        }
        else {

            loadAwaitList();
        }
    }

    const handleSendButton = () => {
        const selectedData = selected_row.map((selectedIndex) =>
            rows_tran.find((row) => row.id === selectedIndex).contact_id
        );
        let object = {
            "list_data": selectedData,
            "status": 1,
            "user": {
                user_id: decode.user_id
            }
        }
        confirmAlert({
            title: 'ยืนยันที่จะอัพเดทสถานะ?',
            message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        DirectCredit.edit_status(object, token_org)
                            .then((res) => {
                                window.alert('อัพเดทสถานะสำเร็จ')
                                window.location.reload();
                            })
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("no")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })

    }
    const handleClearButton = () => {
        setEndDate(null);
        setFromDate(null);
        loadAwaitList();
    }
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'No.',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1

        },
        {
            field: 'approve_date',
            headerName: 'วันที่อนุมัติ',
            width: 150,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'fullname',
            headerName: 'ชื่อลูกค้า',
            width: 500,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'id_number',
            headerName: 'เลขบัตรประชาชน',
            width: 250,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'status',
            headerName: 'สถานะ',
            width: 200,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'detail',
            headerName: 'Detail',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Button variant="contained" color="primary"  onClick={(e) => routeChangeEdit(params)}>
                    Detail
                </Button>
            ),
        },
    ]
    const routeChangeEdit = (rowdata) => {
       const parameterData = { contact_id: rowdata.row.contact_id};
       const queryParams = new URLSearchParams(parameterData).toString();
        window.open('/direct_credit/operator/detail?' + queryParams, '_blank');
    };
    return (
        <>
            {has_permis && (
                <div className='customer-list' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: '' }}>Credit Operation</Typography>
                        </Grid>
                    </Grid>
                    <Paper elevation={3}>
                        <Grid container spacing={2} style={{ padding: '1rem' }}>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="วันที่เริ่มต้น"
                                        inputFormat="YYYY-MM-DD"
                                        value={from_date}
                                        onChange={(date) => setFromDate(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />
                                </LocalizationProvider>

                            </Grid>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="ถึงวันที่"
                                        inputFormat='YYYY-MM-DD'
                                        value={end_date}
                                        onChange={(date) => setEndDate(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                        minDate={from_date}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' size='small'
                                    onClick={() => handleSearchButton()}
                                >
                                    ค้นหา
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" size='small'
                                    onClick={() => handleClearButton()}
                                >
                                    เคลียร์
                                </Button>
                            </Grid>

                        </Grid>
                        {open && ( // Render loading indicator when open is true
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        )}
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                            }}
                        >
                            <DataGrid
                                rows={rows_tran}
                                columns={columns}
                                checkboxSelection
                                disableRowSelectionOnClick
                                selectionModel={selected_row}
                                onSelectionModelChange={handleSelectedChange}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                </div>
            )}
        </>
    )
}