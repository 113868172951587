import * as React from 'react';
import './../sum.css'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import SideBar from './../../page/dashboard'
import * as userHttpServices from '../../../src/services/services';
import TableActivity from '../../components/table/table_activity';
import TableRevcard from '../../components/table/table_revcard';
import { useParams } from "react-router-dom";
import Card from '@mui/material/Card';
import { useLocation } from 'react-router-dom';
function calculateAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}
function financial(n) {
    if (n == 0) {
        return "0"
    } else if (n === null || n === undefined || isNaN(n)) {
        return "0"
    } else {
        return parseFloat(n).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
}
function cleanText(text) {
    if (text === null || text === undefined || text === "") {
        return "-"
    } else {
        return text.replace("null", "")
    }
}
function cleanDate(date) {
    if (date === null || date === undefined || date === "") {
        return "-"
    } else {
        return date.split(' ')[0]
    }
}
export default function App() {
    const [data, setData] = React.useState()
    let token = localStorage.getItem('token_org')
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const Policy_no = queryParams.get('Policy_no');
    let filter = {
        "pagination": {
            "page": 1,
            "rowsPerPage": 10000,
            "sortBy": "",
            "descending": true
        },
        "filters": [
            {
                "operator": "and",
                "attribute": "Policy_no",
                "search": Policy_no
            }
        ]
    }
    React.useEffect(() => {
        console.log("Policy_no",Policy_no)
        if (Policy_no !== '-'&& Policy_no !== ''){
            userHttpServices.getManagement(token, filter)
            .then((res) => {
                userHttpServices.getContactDetail(token, res.data.data[0].id)
                    .then((res) => {
                        console.log(res.data.data);
                        setData(res.data.data)
                    })
            })
        }
    }, [])
    const handleCloseTab = () => {
        window.close();
    };
    return (

        <div className='contact-management' style={{ margin: '2rem' }}>
            <SideBar />
            <Button variant="contained" style={{ marginTop: '3rem' }} onClick={() => handleCloseTab()}><ArrowBackIcon></ArrowBackIcon>ย้อนกลับ</Button>

            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: 'bold' }}>การ์ดลูกหนี้ของสัญญาเลขที่ : {data?.Policy_no}</Typography>
                </Grid>
            </Grid>

            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>ข้อมูลทั่วไป</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'}>
                            <Card>
                                <Stack direction="row" style={{ marginTop: '1rem' }} >
                                    {/* moment(value, 'DD/MM/YYYY') */}
                                    <div style={{ width: '15%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="รหัสลูกค้า" variant="outlined" value={cleanText(data?.Customer[0]?.CustCode)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '15%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="กลุ่มลูกค้า" variant="outlined" value={cleanText(data?.Customer[0]?.CustGroup)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="ชั้นลูกค้า" variant="outlined" value={cleanText(data?.Customer[0]?.CustCode)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    {/* <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="วันเกิด" variant="outlined" value={data?.Customer[0]?.BirthDate?.split(" ")[0] || ''} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div> */}
                                    <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="วันเกิด" variant="outlined" value={cleanDate(data?.Customer[0]?.BirthDate)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '15%' }}><TextField id="outlined-basic" fullWidth label="อายุ" variant="outlined" value={calculateAge(data?.Customer[0]?.BirthDate) || ''} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                </Stack>
                                <Stack direction="row" style={{ marginTop: '1rem' }}>
                                    <div style={{ width: '15%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="คำนำหน้าชื่อ" variant="outlined" value={cleanText(data?.Customer[0]?.T_PreName)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '35%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="ชื่อ" variant="outlined" value={cleanText(data?.Customer[0]?.T_Name)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '50%' }}><TextField id="outlined-basic" fullWidth label="นามสกุล" variant="outlined" value={cleanText(data?.Customer[0]?.T_Sname)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                </Stack>
                                <Stack direction="row" style={{ marginTop: '1rem' }}>
                                    <div style={{ width: '15%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="Title" variant="outlined" value={cleanText(data?.Customer[0]?.E_PreName)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '35%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="Name" variant="outlined" value={cleanText(data?.Customer[0]?.E_Name)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '50%' }}><TextField id="outlined-basic" fullWidth label="Surname" variant="outlined" value={cleanText(data?.Customer[0]?.E_Sname)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                </Stack>
                                <Stack direction="row" style={{ marginTop: '1rem' }}>
                                    <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="เลขบัตรประชาชน" value={cleanText(data?.Customer[0]?.IDCNO)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="Passport" value={cleanText(data?.Customer[0]?.PassportNo)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '10%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="สัญชาติ" value={cleanText(data?.Customer[0]?.Nation)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="วุติการศึกษา" value={cleanText(data?.Customer[0]?.Education)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '15%' }}><TextField id="outlined-basic" fullWidth label="อาชีพ" variant="outlined" value={cleanText(data?.Customer[0]?.OCC)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>

                                </Stack>
                                <Stack direction="row" style={{ marginTop: '1rem' }}>
                                    <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="ภาวะการทำงาน" variant="outlined" value={cleanText(data?.Customer[0]?.BusinessType)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="ประเภทธุรกิจ" variant="outlined" value={cleanText(data?.Customer[0]?.CompanyType)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '60%' }}><TextField id="outlined-basic" fullWidth label="ชื่อที่ทำงาน" variant="outlined" value={cleanText(data?.Customer[0]?.CompanyName)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                </Stack>
                                <Stack direction="row" style={{ marginTop: '1rem' }}>
                                    <div style={{ width: '100%' }}><TextField id="outlined-basic" fullWidth label="ที่อยู่ที่ทำงาน" variant="outlined" value={cleanText(data?.Customer[0]?.Comp_Address1 + " " + data?.Customer[0]?.Comp_Address2 + " " + data?.Customer[0]?.Comp_TB + " " + data?.Customer[0]?.Comp_DT + " " + data?.Customer[0]?.Comp_PV + " " + data?.Customer[0]?.Comp_ZIP)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                </Stack>
                                <Stack direction="row" style={{ marginTop: '1rem' }}>
                                    <div style={{ width: '22.5%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="โทรศัพท์ที่ทำงาน" variant="outlined" value={cleanText(data?.Customer[0]?.COMP_HP)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '22.5%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="โทรศัพท์มือถือ" variant="outlined" value={cleanText(data?.Customer[0]?.C_MP)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '22.5%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="Line ID" variant="outlined" value={cleanText(data?.Customer[0]?.LineID)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '32.5%' }}><TextField id="outlined-basic" fullWidth label="Email" variant="outlined" value={cleanText(data?.Customer[0]?.C_Email)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                </Stack>
                                <Stack direction="row" style={{ marginTop: '1rem' }}>
                                    <div style={{ width: '30%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="แหล่งรายได้เพิ่มเติม" variant="outlined" value={financial(data?.Customer[0]?.SpecialIncome)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '15%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="อายุงาน" variant="outlined" value={cleanText(data?.Customer[0]?.JobYear)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '30%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="แหล่งที่มารายได้พิเศษ" variant="outlined" value={cleanText(data?.Customer[0]?.SpecialIncomeSource)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '25%' }}><TextField id="outlined-basic" fullWidth label="Credit Limit" variant="outlined" value={financial(data?.Customer[0]?.CreditLimit)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                </Stack>
                                <Stack direction="row" style={{ marginTop: '1rem' }}>
                                    <div style={{ width: '30%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="รายได้ (บาท/เดือน)" variant="outlined" value={financial(data?.Customer[0]?.JobIncome)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '30%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="รายได้พิเศษ (บาท/เดือน)" variant="outlined" value={financial(data?.Customer[0]?.SpecialIncome)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '40%' }}><TextField id="outlined-basic" fullWidth label="รายได้รวม (บาท/เดือน)" variant="outlined" value={financial(parseFloat(data?.Customer[0]?.JobIncome) + parseFloat(data?.Customer[0]?.SpecialIncome))} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                </Stack>
                                <Stack direction="row" style={{ marginTop: '1rem' }}>
                                    <div style={{ width: '30%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="หนี้ NCB (บาท/เดือน)" variant="outlined" value={financial(data?.Customer[0]?.LoanNCB)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '30%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="หนี้อื่นๆ (บาท/เดือน)" variant="outlined" value={financial(data?.Customer[0]?.LoanOther)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                    <div style={{ width: '40%' }}><TextField id="outlined-basic" fullWidth label="รวมหนี้ (บาท/เดือน)" variant="outlined" value={financial(parseFloat(data?.Customer[0]?.LoanNCB) + parseFloat(data?.Customer[0]?.LoanOther))} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                </Stack>
                            </Card>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>การ์ดลูกหนี้</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'}>
                            <Stack direction="row">
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="วันที่ทำสัญญา" variant="outlined" value={cleanDate(data?.Policy_date)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="วันสิ้นสุดสัญญา" variant="outlined" value={cleanDate(data?.End_date)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="เลขที่สัญญา" variant="outlined" value={cleanText(data?.Policy_no)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="เลขอ้างอิง" variant="outlined" value={cleanText(data?.Ref_no)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%' }}><TextField id="outlined-basic" fullWidth label="วงเงินกู้" variant="outlined" value={financial(data?.Amount)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                            </Stack>
                            <Stack direction="row" style={{ marginTop: '1rem' }}>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="อัตราดอกเบี้ย (% ต่อปี)" variant="outlined" value={data?.Interest_rate || ''} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="จำนวนงวด (งวดเดือน)" variant="outlined" value={data?.pay_no || ''} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="ค่าปรับ (% ต่อปี)" variant="outlined" value={financial(data?.plup_rate)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="ค่าทวงถาม (บาท)" variant="outlined" value={financial(data?.WFtie)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%' }}><TextField id="outlined-basic" fullWidth label="ค่างวด (บาท)" variant="outlined" value={financial(data?.PPP) || ''} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                            </Stack>

                        </Typography>
                        {/* {console.log(data)} */}
                        <div style={{ paddingTop: '2rem' }}>
                            {/* {console.log('data',data)}
                            {data === undefined ? "" : <ChildComponent childToParent={{ data }} />} */}
                            {data === undefined ? "" : <TableRevcard data={data.Revcard} />}
                            {/* <ChildComponent data={data}/> */}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>Collector Activity</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {data === undefined ? "" : <TableActivity data={data.Activity} type={'crm'} />}
                    </AccordionDetails>
                </Accordion>
            </div>
        </div >
    )
}