
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import SideBar from '../../page/dashboard'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
// import backdrop
import { confirmAlert } from 'react-confirm-alert';
// components
import Filter from '../filter_2.js';
import { useNavigate } from "react-router-dom";
import { checkPermission } from '../../utils/permission_utils.js';
import { useLocation } from 'react-router-dom';
import * as CrmService from '../../services/crm_service';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import { Label } from '@material-ui/icons';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import bank_name from '../../assets/datas/bank.json'
import province from '../../assets/datas/provinces.json'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
const moment = require("moment-timezone")
moment.tz.setDefault("Asia/Bangkok")
export default function App() {
    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [res_data, setResData] = React.useState(true);
    const [edit_mode, setEdit] = React.useState(false)
    const [note, setNote] = React.useState([])
    const [rows, setRow] = React.useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const phone = queryParams.get('phone');
    const contact_id = queryParams.get('contact_id');
    // const has_permission = checkPermission(location.pathname, decoded);
    const has_permission = true;
    const [filters, setFilters] = React.useState([])
    React.useEffect(() => {
        if (!has_permission) {
            window.location.href = '/404';
        }
        fetchData()
    }, [filters])
    const fetchData = () => {

        let object = {
            "email": `${phone}@mscw.com`
        }
        CrmService.crmGetScoreing(token, object)
            .then((res) => {
                let data = res.data.data
                // const search_date = new Date(Number(data?.search_date));
                // const date_getsalary = new Date(Number(data?.date_getsalary));
                let obj = {
                    search_date: data?.search_date ?? new Date(),
                    province_form: data?.province_form ?? '',
                    marketing_channel: data?.marketing_channel ?? '',
                    email: data?.email ?? '',
                    company: data?.company ?? '',
                    gen_der: data?.gen_der ?? '',
                    is_bangkok: data?.is_bangkok ?? '',
                    bankrupt: data?.bankrupt ?? '',
                    residence: data?.residence ?? '',
                    spouse_income: data?.spouse_income ?? 0,
                    help_the_family: data?.help_the_family ?? 0,
                    number_of_children: data?.number_of_children ?? 0,
                    employer_type: data?.employer_type ?? '',
                    type_of_business: data?.type_of_business ?? '',
                    employee_strength: data?.employee_strength ?? '',
                    describe_job_duties: data?.describe_job_duties ?? '',
                    part: data?.part ?? '',
                    job_description: data?.job_description ?? '',
                    work_age: data?.work_age ?? '',
                    con_work_age: data?.con_work_age ?? '',
                    base_salary: data?.base_salary ?? 0,
                    amount_getsalary:data?.amount_getsalary ?? '0',
                    date_getsalary_1: data?.date_getsalary_1 ??  new Date(),
                    date_getsalary_2: data?.date_getsalary_2 ??  new Date(),
                    date_getsalary_3: data?.date_getsalary_3 ??  new Date(),
                    date_getsalary_4: data?.date_getsalary_4 ??  new Date(),
                    commission_1: data?.commission_1 ?? 0,
                    commission_2: data?.commission_2 ?? 0,
                    commission_3: data?.commission_3 ?? 0,
                    extra_income: data?.extra_income ?? 0,
                    expense_item: data?.expense_item ?? 0,
                    house_rent: data?.house_rent ?? 0,
                    personal_expenses: data?.personal_expenses ?? 0,
                    city: data?.city ?? '',
                    pay_all_debts: data?.pay_all_debts ?? 0,
                    informal_debt: data?.informal_debt ?? 0,
                    total_informal_debt: data?.total_informal_debt ?? 0,
                    pico_old_installment: data?.pico_old_installment ?? 0,
                    ability_to_pay: data?.ability_to_pay ?? '',
                    old_customer_history: data?.old_customer_history ?? '',
                    old_customer_history: data?.old_customer_history ?? '',
                    original_limit: data?.original_limit ?? '',
                    pico_debt_balance: data?.pico_debt_balance ?? 0,
                    original_installments: data?.original_installments ?? 0,
                    installment_remaining: data?.installment_remaining ?? 0,
                    case_maker: data?.case_maker ?? '',
                    loan_purpose: data?.loan_purpose ?? '',
                    requested_limit: data?.requested_limit ?? 0,
                    bank_name: data?.bank_name ?? '',
                    accountno: data?.accountno ?? '',
                    accountname: data?.accountname ?? '',
                    accounttype: data?.accounttype ?? '',
                    offer_limit: data?.offer_limit ?? '',
                    installment_offered: data?.installment_offered ?? 0,
                    less_offer: data?.less_offer ?? '',
                    dept_in_stm: data?.dept_in_stm ?? 0,
                    estimate_installment_amount: data?.estimate_installment_amount ?? '',
                    id_number: data?.id_number ?? '',
                    contact_id:contact_id
                }
                setResData(obj)
            })
    }
    const handleEdit = (value) => {
        setEdit(true)
    }
    const handleSave = (value) => {
        setEdit(false)
        console.log(res_data)
        confirmAlert({
            title: 'ยืนยันที่จะบันทึกการแก้ไข',
            message: 'คุณแน่ใจหรือที่จะอัพเดทข้อมูล',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        CrmService.updateScoreing(res_data)
                            .then((res) => {
                                if (res.data.success) {
                                    window.alert('บันทึกสำเร็จ')
                                    window.location.reload();
                                }

                            })
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        // console.log("no")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })

    }
    const handleChange = (e) => {
        console.log(e)
        const { name, value } = e.target;
        setResData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleChangeSearchDate = (value) => {
        setResData((prevData) => ({
            ...prevData,
            search_date: value,
        }));
    }

    const handleChangeDateSalary = (value,name) => {
        console.log(value)
        setResData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    return (
        <>
            {has_permission && (
                <div className='customer-list' style={{ marginRight: '1rem', marginLeft: '1rem' }}>
                    <Paper elevation={5} style={{ paddingBottom: '10px', padding: '20px' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Button variant="contained" color="success" style={{ marginRight: '5px', float: 'right', marginBottom: '10px' }} onClick={() => handleSave()}><SaveIcon />บันทึก</Button>
                                <Button variant="contained" style={{ marginRight: '10px', float: 'right' }} onClick={() => handleEdit()}><EditIcon />แก้ไขข้อมูล</Button>
                            </Grid>
                        </Grid>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '7px', marginBottom: '10px' }}>New Scoring Version</Typography>

                            <Grid container spacing={0}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันที่สืบค้น</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <MobileDatePicker inputFormat="YYYY-MM-DD"
                                            name="search_date"
                                            // value={moment.unix(res_data?.search_date.slice(0, -3) ?? "").format("YYYY/MM/DD")}
                                            value={res_data?.search_date}
                                            onChange={handleChangeSearchDate} disabled={!edit_mode} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จังหวัด</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="province_form"
                                        value={res_data?.province_form ?? ""}
                                        onChange={handleChange}
                                    >
                                        {
                                            province.map((province) => {
                                                return <MenuItem value={province.province_form}>{province.province_form}</MenuItem>
                                            })
                                        }
                                    </Select>                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ช่องทางการตลาด</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    { }
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "300px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="marketing_channel"
                                        value={res_data?.marketing_channel ?? ""}
                                        onChange={handleChange}
                                        type='text'
                                    >
                                        <MenuItem value="Facebook">Facebook</MenuItem>
                                        <MenuItem value="Tiktok">Tiktok</MenuItem>
                                        <MenuItem value="Google">Google</MenuItem>
                                        <MenuItem value="Youtube">Youtube</MenuItem>
                                        <MenuItem value="ทบทวน">ทบทวน</MenuItem>
                                        <MenuItem value="MOU">MOU</MenuItem>
                                        <MenuItem value="ช่องทางอื่นๆ">ช่องทางอื่นๆ</MenuItem>
                                        <MenuItem value="ลูกค้าเก่าปิดบัญชี">ลูกค้าเก่าปิดบัญชี</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>Email</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="birthdate" variant="outlined" name="email"
                                        value={res_data?.email}
                                        onChange={handleChange} disabled={true} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ประเภทลูกค้า</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="company"
                                        value={res_data?.company ?? ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="A">A</MenuItem>
                                        <MenuItem value="B">B</MenuItem>
                                        <MenuItem value="C">C</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เพศ</Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="gen_der"
                                        value={res_data?.gen_der ?? ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="ชาย">ชาย</MenuItem>
                                        <MenuItem value="หญิง">หญิง</MenuItem>
                                    </Select>                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={12}>
                                    <FormLabel id="demo-radio-buttons-group-label">ท่านมีทะเบียนบ้าน กทม. หรือพักอาศัย หรือทำงานอยู่ใน กทม.(อย่างใดอย่างหนึ่งในกทม.) หรือไม่</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="is_bangkok"
                                        value={res_data?.is_bangkok ?? ""}
                                        onChange={handleChange}

                                    >
                                        <FormControlLabel value="1" control={<Radio disabled={!edit_mode} />} label="ใช่" />
                                        <FormControlLabel value="0" control={<Radio disabled={!edit_mode} />} label="ไม่ใช่" />
                                    </RadioGroup>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บุคคลล้มละลาย</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="bankrupt"
                                        value={res_data?.bankrupt ?? ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'ใช่'}>ใช่</MenuItem>
                                        <MenuItem value={'ไม่ใช่'}>ไม่ใช่</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px', marginTop: '5px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '7px' }}>ข้อมูลลูกค้า</Typography>

                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>สถานที่อยู่อาศัย</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="residence"
                                        value={res_data?.residence ?? ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'เจ้าของบ้านปลอดภาระ'}>เจ้าของบ้านปลอดภาระ</MenuItem>
                                        <MenuItem value={'เช่าอาศัย'}>เช่าอาศัย</MenuItem>
                                        <MenuItem value={'อาศัยคนอื่น / พักฟรี'}>อาศัยคนอื่น / พักฟรี</MenuItem>
                                        <MenuItem value={'ที่อยู่อาศัย ติดภาระหนี้'}>ที่อยู่อาศัย ติดภาระหนี้</MenuItem>
                                        <MenuItem value={'เจ้าบ้านปลอดภาระ'}>เจ้าบ้านปลอดภาระ</MenuItem>
                                        <MenuItem value={'สวัสดิการ'}>สวัสดิการ</MenuItem>
                                        <MenuItem value={'อื่นๆ'}>อื่นๆ</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รายได้คู่สมรส</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouse_income" variant="outlined" name="spouse_income"
                                        value={res_data?.spouse_income}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ช่วยเหลือครอบครัว</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="collectPhone" variant="outlined" name="help_the_family"
                                        value={res_data?.help_the_family}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จำนวนบุตร</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="number_of_children" variant="outlined" type='number' name="number_of_children"
                                        value={res_data?.number_of_children}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ประเภทนายจ้าง</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="employer_type"
                                        value={res_data?.employer_type ?? ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'บริษัทมหาชน'}>บริษัทมหาชน</MenuItem>
                                        <MenuItem value={'รัฐวิสาหกิจ, ราชการ'}>รัฐวิสาหกิจ, ราชการ</MenuItem>
                                        <MenuItem value={'จำกัด < 2 ปี'}>{"จำกัด < 2 ปี"}</MenuItem>
                                        <MenuItem value={'จำกัด 2-5 ปี'}>จำกัด 2-5 ปี</MenuItem>
                                        <MenuItem value={'จำกัด 6-10 ปี'}>จำกัด 6-10 ปี</MenuItem>
                                        <MenuItem value={'จำกัด มากกว่า 10 ปี'}>จำกัด มากกว่า 10 ปี</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ประเภทธุรกิจ</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="type_of_business" variant="outlined" name="type_of_business"
                                        value={res_data?.type_of_business}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ความแข็งแรงของนายจ้าง</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        name="employee_strength"
                                        value={res_data?.employee_strength ?? ""}
                                        disabled={!edit_mode}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'มหาชน / ราชการ'}>มหาชน / ราชการ</MenuItem>
                                        <MenuItem value={'ห้างหุ้นส่วนจำกัด / จำกัด ทุนจดทะเบียน < 5 ล้านบาท'}>{"ห้างหุ้นส่วนจำกัด / จำกัด ทุนจดทะเบียน < 5 ล้านบาท"}</MenuItem>
                                        <MenuItem value={'จำกัด ทุนจดทะเบียน  5 - 10 ล้านบาท'}>จำกัด ทุนจดทะเบียน  5 - 10 ล้านบาท</MenuItem>
                                        <MenuItem value={'จำกัด ทุนจดทะเบียน  > 10 ล้านบาท'}>{"จำกัด ทุนจดทะเบียน  > 10 ล้านบาท"}</MenuItem>

                                    </Select>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บรรยายหน้าที่งาน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="describe_job_duties" variant="outlined" name="describe_job_duties"
                                        value={res_data?.describe_job_duties}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ฝ่าย</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="part" variant="outlined" name="part"
                                        value={res_data?.part}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ลักษณะงาน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        name="job_description"
                                        value={res_data?.job_description ?? ""}
                                        onChange={handleChange}
                                        disabled={!edit_mode}
                                    >
                                        <MenuItem value={'กลุ่มผู้จัดการฝ่ายขึ้นไป หรือ ผู้มีทักษะเฉพาะต่อองค์กร'}>กลุ่มผู้จัดการฝ่ายขึ้นไป หรือ ผู้มีทักษะเฉพาะต่อองค์กร</MenuItem>
                                        <MenuItem value={'หัวหน้าระดับขั้นต้น'}>หัวหน้าระดับขั้นต้น</MenuItem>
                                        <MenuItem value={'ผู้จัดการแผนก'}>ผู้จัดการแผนก</MenuItem>
                                        <MenuItem value={'พนักงานประจำ'}>พนักงานประจำ</MenuItem>
                                        <MenuItem value={'Outsource ติดต่อง่าย'}>Outsource ติดต่อง่าย</MenuItem>
                                        <MenuItem value={'ประทวน'}>ประทวน</MenuItem>
                                        <MenuItem value={'แรงงาน'}>แรงงาน</MenuItem>
                                        <MenuItem value={'PG'}>PG</MenuItem>
                                        <MenuItem value={'พนง. ขาย'}>พนง. ขาย</MenuItem>
                                        <MenuItem value={'Outsource ติดต่อยาก'}>Outsource ติดต่อยาก</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อายุงาน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="work_age" variant="outlined" name="work_age"
                                        value={res_data?.work_age}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>อายุงาน con1.4</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Grid item xs={2}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "200px" }}
                                            size="small"
                                            disabled={!edit_mode}
                                            name="con_work_age"
                                            value={res_data?.con_work_age ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={'มากกว่า 7 ปี'}>มากกว่า 7 ปี</MenuItem>
                                            <MenuItem value={'5 - 6  ปี'}>5 - 6  ปี</MenuItem>
                                            <MenuItem value={'3 - 4 ปี'}>3 - 4 ปี</MenuItem>
                                            <MenuItem value={'น้อยกว่า 3 ปี'}>น้อยกว่า 3 ปี</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เงินเดือนฐาน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="base_salary" variant="outlined" name="base_salary"
                                        value={res_data?.base_salary}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                         
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>รับเงินเดือน (ครั้ง)</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Grid item xs={2}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "200px" }}
                                            size="small"
                                            disabled={!edit_mode}
                                            name="amount_getsalary"
                                            value={res_data?.amount_getsalary ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={1}>1 ครั้ง</MenuItem>
                                            <MenuItem value={2}>2 ครั้ง</MenuItem>
                                            <MenuItem value={3}>3 ครั้ง</MenuItem>
                                            <MenuItem value={4}>4 ครั้ง</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันที่รับเงินเดือน ครั้งที่ 1</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <MobileDatePicker
                                            inputFormat="YYYY-MM-DD"
                                            name="date_getsalary_1"
                                            value={res_data?.date_getsalary_1}
                                            onChange={(e) => handleChangeDateSalary(e,'date_getsalary_1')} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันที่รับเงินเดือน ครั้งที่ 2</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <MobileDatePicker
                                            inputFormat="YYYY-MM-DD"
                                            name="date_getsalary_2"
                                            value={res_data?.date_getsalary_2}
                                            onChange={(e) => handleChangeDateSalary(e,'date_getsalary_2')} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันที่รับเงินเดือน ครั้งที่ 3</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <MobileDatePicker
                                            inputFormat="YYYY-MM-DD"
                                            name="date_getsalary_3"
                                            value={res_data?.date_getsalary_3}
                                            onChange={(e) => handleChangeDateSalary(e,'date_getsalary_3')} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันที่รับเงินเดือน ครั้งที่ 4</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <MobileDatePicker
                                            inputFormat="YYYY-MM-DD"
                                            name="date_getsalary_4"
                                            value={res_data?.date_getsalary_4}
                                            onChange={(e) => handleChangeDateSalary(e,'date_getsalary_4')} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1.5}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ค่าคอม</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="commission_1" variant="outlined" name="commission_1"
                                        value={res_data?.commission_1}
                                        onChange={handleChange} disabled={!edit_mode} />

                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="commission_2" variant="outlined" name="commission_2"
                                        value={res_data?.commission_2}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="commission_3" variant="outlined" name="commission_3"
                                        value={res_data?.commission_3}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>รายได้พิเศษ</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="extra_income" variant="outlined" name="extra_income"
                                        value={res_data?.extra_income}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รายการหัก</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="expense_item" variant="outlined" name="expense_item"
                                        value={res_data?.expense_item}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าเช่าบ้าน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="house_rent" variant="outlined" name="house_rent"
                                        value={res_data?.house_rent}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าใช้จ่ายส่วนตัว</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="personal_expenses" variant="outlined" name="personal_expenses"
                                        value={res_data?.personal_expenses}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ค่าใช้จ่ายส่วนตัวที่ลูกค้าแจ้ง</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="city" variant="outlined" name="city"
                                        value={res_data?.city}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ผ่อนหนี้ทั้งหมด</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="pay_all_debts" variant="outlined" name="pay_all_debts"
                                        value={res_data?.pay_all_debts}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>หนี้นอกระบบต่อเดือน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="informal_debt" variant="outlined" name="informal_debt"
                                        value={res_data?.informal_debt}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>หนี้นอกระบบรวม</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="total_informal_debt" variant="outlined" name="total_informal_debt"
                                        value={res_data?.total_informal_debt}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ยอดผ่อนเก่ากับ PICO</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="pico_old_installment" variant="outlined" name="pico_old_installment"
                                        value={res_data?.pico_old_installment}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ความสามารถในการผ่อน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseDistrict" variant="outlined" name="ability_to_pay"
                                        value={res_data?.ability_to_pay}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ประเภทลูกค้า</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        name="old_customer_history"
                                        value={res_data?.old_customer_history ?? ""}
                                        disabled={!edit_mode}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'ลูกค้าใหม่'}>ลูกค้าใหม่</MenuItem>
                                        <MenuItem value={'ลูกค้า refinance - ได้รับวงเงินกลับไป'}>ลูกค้า refinance -ขยายงวด</MenuItem>
                                        <MenuItem value={'ลูกค้า refinance - ได้รับวงเงินกลับไป'}>ลูกค้า refinance - ได้รับวงเงินกลับไป</MenuItem>
                                        <MenuItem value={'mou'}>mou</MenuItem>

                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วงเงินเดิม</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="original_limit" variant="outlined" name="original_limit"
                                        value={res_data?.original_limit}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ยอดหนี้คงเหลือ Pico</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="pico_debt_balance" variant="outlined" name="pico_debt_balance"
                                        value={res_data?.pico_debt_balance}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จำนวนงวดผ่อนเดิม</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="original_installments" variant="outlined" name="original_installments"
                                        value={res_data?.original_installments}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>งวดผ่อนคงเหลือ</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="installment_remaining" variant="outlined" name="installment_remaining"
                                        value={res_data?.installment_remaining}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ผู้ทำเคสลูกค้า</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="case_maker"
                                        value={res_data?.case_maker?? ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'ปฐมาวดี'}>ปฐมาวดี</MenuItem>
                                        <MenuItem value={'อนงค์นาถ'}>อนงค์นาถ</MenuItem>
                                        <MenuItem value={'รมย์นรินทร์'}>รมย์นรินทร์</MenuItem>
                                        <MenuItem value={'ศิระดา'}>ศิระดา</MenuItem>
                                        <MenuItem value={'อัญชลี'}>อัญชลี</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>วัตถุประสงค์ในการกู้</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="loan_purpose" variant="outlined" name="loan_purpose"
                                        value={res_data?.loan_purpose}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วงเงินที่ขอ</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="requested_limit" variant="outlined" name="requested_limit"
                                        value={res_data?.requested_limit}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>ชื่อธนาคาร</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="bank_name"
                                        value={res_data?.bank_name ?? ""}
                                        onChange={handleChange}
                                    >
                                        {
                                            bank_name.bank.map((bank) => {
                                                return <MenuItem value={bank.bank_name}>{bank.bank_name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เลขบัญชี</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="accountno" variant="outlined" name="accountno"
                                        value={res_data?.accountno}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ชื่อบัญชี</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="accountname" variant="outlined" name="accountname"
                                        value={res_data?.accountname}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ประเภทบัญชี</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="accounttype" variant="outlined" name="accounttype"
                                        value={res_data?.accounttype}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วงเงินเสนอ</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="offer_limit" variant="outlined" name="offer_limit"
                                        value={res_data?.offer_limit}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>งวดผ่อนเสนอ</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="installment_offered" variant="outlined" name="installment_offered"
                                        value={res_data?.installment_offered}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>หักจำนวนงวดเสนอ</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid item xs={2}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: "200px" }}
                                            size="small"
                                            disabled={!edit_mode}
                                            name="less_offer"
                                            value={res_data?.less_offer ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={''}></MenuItem>
                                            <MenuItem value={'1'}>1</MenuItem>
                                            <MenuItem value={'2'}>2</MenuItem>
                                            <MenuItem value={'3'}>3</MenuItem>
                                            <MenuItem value={'0'}>0</MenuItem>
                                        </Select>
                                    </Grid>
                                    {/* <TextField style={{ paddingRight: '1rem' }} size="small" id="spouseCounty" variant="outlined" name="less_offer"
                                        value={res_data?.less_offer}
                                        onChange={handleChange} disabled={!edit_mode} /> */}
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ผ่อนหนี้ในSTM</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="dept_in_stm" variant="outlined" name="dept_in_stm"
                                        value={res_data?.dept_in_stm}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ marginTop: '15px' }}>
                                <Grid item xs={1}>
                                    <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ประมาณยอดผ่อน</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField style={{ paddingRight: '1rem' }} size="small" id="estimate_installment_amount" variant="outlined" name="estimate_installment_amount"
                                        value={res_data?.estimate_installment_amount}
                                        onChange={handleChange} disabled={!edit_mode} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Paper>
                </div>
            )}
        </>
    )

}