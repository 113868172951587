import axios from "axios";
import jwt_decode from "jwt-decode";
// import config from '../config.js'
const config = require('../config.js')

const MSCW_URL = config.MSCW_URL;
const PICO_URL = config.PICO_URL;
// const PROD_API_URL = process.env.PROD_API_URL; 21222123
var token = localStorage.getItem('token')
let ROOT_URL

if (token !== null) {
    const decoded = jwt_decode(token)
    ROOT_URL = decoded.org_id === 2 ? PICO_URL : MSCW_URL;
}
//production
//  const ROOT_URL = "https://api-uat.transfinmational.net";
//  const ROOT_URL = "https://api-mscw.transfinmational.net";
//  const ROOT_URL = "http://localhost:3334";
//  const ROOT_URL2 = config.PROD_API;

export const getCollectorExport = (token, body) => {
    return axios.post(`${ROOT_URL}/hubspot/contacts/export2`, body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}