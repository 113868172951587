import { Grid, Stack, Typography } from "@mui/material";

export default function StatementNoteFooter() {
  return (
    <>
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
          <Grid item xs>
              <Typography variant='h5' style={{ fontWeight: '' }}>หมายเหตุ</Typography>
          </Grid>
      </Grid>

      <Stack spacing={2}>
        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ minWidth: 120, hight: '100%', maxHeight: 48, backgroundColor: '#FFAAAA' }}></div>
          <Typography>วันดังกล่าวมียอดเงินคงเหลือสิ้นวันน้อยกว่า 499 บาท</Typography>
        </div>
        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ minWidth: 120, hight: '100%', maxHeight: 48, backgroundColor: '#FFE59A' }}></div>
          <Typography>วันดังกล่าวมียอดเงินคงเหลือสิ้นวันอยู่ในช่วง 500 – 1,499 บาท</Typography>
        </div>
        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ minWidth: 120, hight: '100%', maxHeight: 48, backgroundColor: '#B5D8A8' }}></div>
          <Typography>วันดังกล่าวมียอดเงินคงเหลือสิ้นวันสูงกว่า 1,500 บาท</Typography>
        </div>

        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ minWidth: 120, hight: '100%', maxHeight: 48, border: 'solid #00bfff' }}></div>
          <Typography>วันดังกล่าวมีจำนวนเงินเข้าบัญชีในช่วง 1,000 – 4,999 บาท</Typography>
        </div>
        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ minWidth: 120, hight: '100%', maxHeight: 48, border: 'solid #ff9800' }}></div>
          <Typography>วันดังกล่าวมีเงินเข้ามากกว่า 5,000 บาทขึ้นไป</Typography>
        </div>

        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ minWidth: 120, hight: '100%', maxHeight: 48, backgroundColor: '#E7AAFD' }}></div>
          <Typography>รายการบัญชีดังกล่าวมีรายการการรับเงินเดือน ภายในช่วงวันนั้นๆ</Typography>
        </div>

        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ minWidth: 120, hight: '100%', maxHeight: 48, backgroundColor: '#C5BFB9' }}></div>
          <Typography>วันดังกล่าวมีรายการรับเงินเดือนเข้าบัญชี</Typography>
        </div>
      </Stack>
    </>
  );
}
