import axios from "axios";

const config = require('../config.js')
const MKT_URL = config.MKT_URL;

export const getMKTDashboardData = (selected_month) => {
    return axios.get(
        `${MKT_URL}/marketing/${selected_month}`, 
        {}
    )
}

export const updateMKTDashboardData = (obj) => {
    return axios.put(
        `${MKT_URL}/marketing`, 
        {'data':obj},
        {}
    )
}