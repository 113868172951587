import * as React from 'react';
import * as tackingHttpServices from '../../services/tacking_service';
import * as crmHttpServices from '../../services/crm_service';

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import { confirmAlert } from 'react-confirm-alert';
import { useConfirm } from "material-ui-confirm";
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Input } from '@mui/material';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../App.css'
import SaveAltIcon from '@mui/icons-material/SaveAlt';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 550,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const columns = [
    { id: 'name', label: 'name', width: '200' },
    { id: 'date_doc', label: 'วันที่รับเอกสาร', width: '10%' },
    { id: 'password', label: 'password', width: '10%' },
    { id: 'status', label: 'สถานะเอกสาร', width: '20%' },
    { id: 'button3', width: '40%' },
    { id: '', width: 200, label: 'Download' },
];

const headCells = [

    {
        id: 'type',
        disablePadding: false,
        width: 250,
        label: 'ประเภทเอกสาร',
    },
    {
        id: 'date_receipt',
        disablePadding: false,
        width: 200,
        label: 'วันที่รับเอกสาร',
    },
    {
        id: '',
        width: 200,
        label: 'เพิ่มเติม',
    },


];
function MyApp() {
    // const [contact_id, setContactId] = React.useState('984');
    // const [user_id, setUserId] = React.useState('1710101191988');
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [rows, setRows] = React.useState([]);
    const [status, setStatus] = React.useState(false)
    const [file_type, setFileType] = React.useState([]);
    const [type, setType] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    let token = localStorage.getItem('token_org')
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const contact_id = queryParams.get('contact_id');
    const user_id = queryParams.get('user_id');
    

    console.log(contact_id)
    console.log(user_id)
    React.useEffect(() => {
        setRows([])
        setStatus(false)
        setSelected([])
        crmHttpServices.crmGetFileList(token, contact_id)
            .then((res) => {
                setStatus(true)
                if (res.data.data != null) {
                    setRows(res.data.data)
                } else {
                    setRows([])
                }
            })
    }, [])
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align='left'
                            padding='normal'
                            width={headCell.width}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };
    const EnhancedTableToolbar = (props) => {
        const { numSelected } = props;
        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.text.primary, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
            </Toolbar>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => n.id);

            let withoutDuplicates = [...new Set(selected.concat(newSelecteds))];

            setSelected(withoutDuplicates);

            return;
        }

        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const getFile = (row) => {
        let obj = {
            user_id: user_id,
            contact_id: contact_id,
            file_type: row.file_type,
            name: row.file_name
        }
        tackingHttpServices.getFile(token, obj)
            .then((res) => {
                var myParam = res.data.data;
                window.open('/preview?param=' + encodeURIComponent(myParam), '_blank');
            })
    }
    const getFileDownload = (row) => {
        let obj = {
            user_id: user_id,
            contact_id: contact_id,
            file_type: row.file_type,
            name: row.file_name
        }
        tackingHttpServices.getFile(token, obj)
            .then((res) => {
                var myParam = res.data.data;
                window.open(myParam);
            })
    }
    const openPopup = (row) => {
        setType(row.file_type)
        let obj = {
            user_id: user_id,
            contact_id: contact_id,
            file_type: row.file_type,
        }
        crmHttpServices.getFileType(token, obj)
            .then((res) => {
                if (res.data.data != null) {
                    setFileType(res.data.data)
                    setOpen(true)
                } else {
                    setFileType([])
                    setOpen(true)
                }
            })
    }
    const dateFomate = (row) => {
        var today = new Date(row.date)
        let date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
        return date
    }
    const nameFomate = (file_type) => {
        switch (file_type) {
            case "id_card_no":
                return 'บัตรประชาชน'
            case "statement":
                return 'statement'
            case "house_regis":
                return 'ทะเบียนบ้าน'
            case "other":
                return 'เอกสารอื่นๆ'
            case "salary":
                return 'สลีปเงินเดือน/หนังสือรับรองรายได้'
            case "book_bank":
                return 'หน้าสมุดบัญชีธนาคาร'
            case "auth":
                return 'รูปถ่ายคู่บัตรประชาชน'
            case "form":
                return 'ฟอร์มสมัคร'
            default:
        }
    }
    return (
        <Box sx={{ width: '100%' }}>
            {
                rows.length === 0 && !status ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ""
            }
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow>
                                            <TableCell >{nameFomate(row.file_type)}</TableCell>
                                            <TableCell >{row.create_date == null || row.status == 0 ? "-" : row.create_date.split("T")[0]}</TableCell>
                                            <TableCell><IconButton onClick={(e) => openPopup(row)}><SearchIcon color="primary" /></IconButton></TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 300, 500,]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {/* รายการเอกสาร */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h2 id="child-modal-title" className='test'>รายการเอกสาร :{nameFomate(type)}</h2>
                    <TableContainer sx={{ maxHeight: 380 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ width: column.width }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(file_type).map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <TableCell >{row.file_name}</TableCell>
                                            <TableCell >{dateFomate(row)}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }} >{row.password == 'undefined' ? "-" : row.password}</TableCell>
                                            <TableCell>
                                                <Select
                                                    value={row.status}
                                                    disabled={true}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    style={{ width: 150 }}
                                                >
                                                    <MenuItem value={1}>รอการตรวจสอบ</MenuItem>
                                                    <MenuItem value={2}>รอการแก้ไข</MenuItem>
                                                    <MenuItem value={3}>ได้รับครบถ้วน</MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell ><IconButton onClick={(e) => getFile(row)}><SearchIcon color="primary" /></IconButton> </TableCell>
                                            <TableCell ><IconButton onClick={(e) => getFileDownload(row)}><SaveAltIcon color="primary" /></IconButton> </TableCell>

                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={file_type.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Modal>
        </Box>
    );
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={1}>

            <MyApp />
        </SnackbarProvider>
    );
}

