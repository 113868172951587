// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Container } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import SideBar from './dashboard.js'
import jwt_decode from "jwt-decode";

export default function NotFound() {
    let token = localStorage.getItem('token')
    let decode;
    if(token === null){
        window.location.href = '/login';
    }else{
        decode = jwt_decode(token);
    }
    
  return (
    <Container>
      <SideBar />
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <ErrorOutline style={{ fontSize: '150px', color: '#f44336' }} />
        <Typography variant="h4" color="textPrimary" gutterBottom>
          Oops! You are not authorized to access this webpage. <br/>
          อ๊ะ!  คุณไม่ได้รับอนุญาตเข้าถึงหน้าเว็บนี้
        </Typography>
      </div>
    </Container>
  );
};


