import * as React from 'react';
import ModalEditUserForm from '../../components/modal/crm_edit_user_form';
import ModalEditUserScoring from '../../components/modal/crm_edit_user_scoring';
import { useEffect } from 'react';
import '../sum.css'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SideBar from '../../page/dashboard'
import jwt_decode from "jwt-decode";
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function App() {
    const location = useLocation();
    const [value, setValue] = React.useState([]);
    const [value1, setValue1] = React.useState(0);
    const queryParams = new URLSearchParams(location.search);
    const contact_id = queryParams.get('contact_id');
    const user_id = queryParams.get('user_id');
    const handleChange = (event, newValue) => {
        setValue1(newValue);
    };
    let token = localStorage.getItem('token')
    let decoded
    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
    // const has_permission = checkPermission(location.pathname, decoded);
    useEffect(() => {
        // if (!has_permission) {
        //     window.location.href = '/404'
        // }
    }, [])
    return (
        <>
            <div className='document_tracking' style={{ margin: '2rem' }}>
                <SideBar />
                <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                    <Grid item xs>
                        <Typography variant='h4' style={{ fontWeight: 'bold' }}>แก้ไขข้อมูลลูกค้า</Typography>
                    </Grid>
                </Grid>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '3.5rem' }}>
                    <Tabs value={value1} onChange={handleChange} >
                        <Tab label="ข้อมูลใบสมัคร" {...a11yProps(0)} />
                        <Tab label="ข้อมูล Scoring" {...a11yProps(0)} />

                    </Tabs>

                </Box>
                <CustomTabPanel value={value1} index={0}>
                    <ModalEditUserForm />
                </CustomTabPanel>
                <CustomTabPanel value={value1} index={1}>
                <ModalEditUserScoring />
                </CustomTabPanel>

            </div>
        </>
    )
}