
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';
import SideBar from '../../page/dashboard'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
// import backdrop
import { confirmAlert } from 'react-confirm-alert';
// components
import { useNavigate } from "react-router-dom";
import { checkPermission } from '../../utils/permission_utils.js';
import { useLocation } from 'react-router-dom';
import * as CrmService from '../../services/crm_service';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import { Label } from '@material-ui/icons';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import bank_name from '../../assets/datas/bank.json'
import province from '../../assets/datas/provinces.json'
import CustomerForm from '../../components/form/customer-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
const moment = require("moment-timezone")
moment.tz.setDefault("Asia/Bangkok")
export default function App() {
    let token = localStorage.getItem('token')
    let decoded

    // if (token === null) {
    //     window.location.href = '/login'
    // } else {
    //     decoded = jwt_decode(token)
    // }
    // backdrop
    const [res_data, setResData] = React.useState(true);
    const [ncb_data, setNCBData] = React.useState(true);
    const [overdue_detail, setOverdueDetail] = React.useState(true);

    const [edit_mode, setEdit] = React.useState(true)
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const user_id = queryParams.get('user_id');
    const contact_id = queryParams.get('contact_id');
    const [filters, setFilters] = React.useState([])
    React.useEffect(() => {
        fetchData()
    }, [filters])
    const fetchData = () => {

        let object = {
            user_id: user_id,
            contact_id: contact_id
        }
        CrmService.crmGetContactEdit(object)
            .then((res) => {
                let data = res.data.data
                const birth_day = new Date(data?.birth_day);
                let note_ncb = ""
                let index = 0
                if(res.data.ncb_data.isOk){
                    for (const item of res.data.ncb_data.MSCW?.value_4?.value_4_7?.value_4_7) {
                        const startDate = moment(item.startDate)
                        const endDate = moment(item.endDate)
                        note_ncb += `บัญชีที่เสียครั้งล่าสุด บัญชีที่ ${index + 1}วันที่เสีย ${startDate.format("YYYY-MM-DD")} วันที่แก้ไข ${endDate.format("YYYY-MM-DD")}จำนวนเดือนที่ปล่อยเสีย${item.period}เดือน\n`
                        index += 1
                    }
                } 
                let obj = {
                    contact_id:contact_id,
                    search_date: data?.search_date ?? new Date(),
                    province_form: data?.province_form ?? '',
                    marketing_channel: data?.marketing_channel ?? '',
                    email: data?.email ?? '',
                    company: data?.company ?? '',
                    gen_der: data?.gen_der ?? '',
                    is_bangkok: data?.is_bangkok ?? '',
                    bankrupt: data?.bankrupt ?? '',
                    residence: data?.residence ?? '',
                    spouse_income: data?.spouse_income ?? 0,
                    help_the_family: data?.help_the_family ?? 0,
                    number_of_children: data?.number_of_children ?? 0,
                    employer_type: data?.employer_type ?? '',
                    type_of_business: data?.type_of_business ?? '',
                    employee_strength: data?.employee_strength ?? '',
                    describe_job_duties: data?.describe_job_duties ?? '',
                    part: data?.part ?? '',
                    job_description: data?.job_description ?? '',
                    work_age: data?.work_age ?? '',
                    con_work_age: data?.con_work_age ?? '',
                    base_salary: data?.base_salary ?? 0,
                    amount_getsalary: data?.amount_getsalary ?? '0',
                    date_getsalary_1: data?.date_getsalary_1 ?? new Date(),
                    date_getsalary_2: data?.date_getsalary_2 ?? new Date(),
                    date_getsalary_3: data?.date_getsalary_3 ?? new Date(),
                    date_getsalary_4: data?.date_getsalary_4 ?? new Date(),
                    commission_1: data?.commission_1 ?? 0,
                    commission_2: data?.commission_2 ?? 0,
                    commission_3: data?.commission_3 ?? 0,
                    sum_commission: data?.sum_commission ?? 0,
                    extra_income: data?.extra_income ?? 0,
                    expense_item: data?.expense_item ?? 0,
                    house_rent: data?.house_rent ?? 0,
                    personal_expenses: data?.personal_expenses ?? 0,
                    city: data?.city ?? '',
                    pay_all_debts: data?.pay_all_debts ?? 0,
                    informal_debt: data?.informal_debt ?? 0,
                    total_informal_debt: data?.total_informal_debt ?? 0,
                    pico_old_installment: data?.pico_old_installment ?? 0,
                    ability_to_pay: data?.ability_to_pay ?? '',
                    old_customer_history: data?.old_customer_history ?? '',
                    original_limit: data?.original_limit ?? '',
                    pico_debt_balance: data?.pico_debt_balance ?? 0,
                    original_installments: data?.original_installments ?? 0,
                    installment_remaining: data?.installment_remaining ?? 0,
                    case_maker: data?.case_maker ?? '',
                    loan_purpose: data?.loan_purpose ?? '',
                    requested_limit: data?.requested_limit ?? 0,
                    bank_name: data?.bank_name ?? '',
                    accountno: data?.accountno ?? '',
                    accountname: data?.accountname ?? '',
                    accounttype: data?.accounttype ?? '',
                    offer_limit: data?.offer_limit ?? '',
                    installment_offered: data?.installment_offered ?? 0,
                    less_offer: data?.less_offer ?? '',
                    dept_in_stm: data?.dept_in_stm ?? 0,
                    estimate_installment_amount: data?.estimate_installment_amount ?? '',
                    firstname: data?.firstname ?? '',
                    lastname: data?.lastname ?? '',
                    educational_bg: data?.educational_bg ?? '',
                    status: data?.status ?? '',
                    phone: data?.phone ?? '',
                    id_number: data?.id_number ?? '',
                    workplace: data?.workplace ?? '',
                    position: data?.position ?? '',
                    commission: data?.commission ?? '',
                    birth_day: birth_day ?? '',
                    note: data?.note ?? '',
                    salary_slip_deducted_detail_1: data?.salary_slip_deducted_detail_1 ?? '',
                    salary_slip_deducted_detail_2: data?.salary_slip_deducted_detail_2 ?? '',
                    salary_slip_deducted_detail_3: data?.salary_slip_deducted_detail_3 ?? '',
                    salary_slip_deducted_amount_1: data?.salary_slip_deducted_amount_1 ?? '',
                    salary_slip_deducted_amount_2: data?.salary_slip_deducted_amount_2 ?? '',
                    salary_slip_deducted_amount_3: data?.salary_slip_deducted_amount_3 ?? '',
                    sum_commission: data?.sum_commission ?? '',
                    total_deducted_slip: data?.total_deducted_slip ?? '',
                    receive_net_money: data?.receive_net_money ?? '',
                    money_left: data?.money_left ?? '',
                    salary: data?.salary ?? '',
                    note_ncb: note_ncb ?? "",
                    hr_phone:data?.hr_phone ?? '',
                    net_personal_expenses:data?.net_personal_expenses ?? ''
                }
                setResData(obj)
                setNCBData(res.data.ncb_data)
                if (data.conclude === 'อนุมัติ') {
                    setEdit(false)
                }
            })
    }
    const handleExport = (value) => {
        navigate('/crm/cm/form', { state: { data: res_data, ncb: ncb_data } })
    }
    const handleSave = (value) => {
        setEdit(false)
        console.log(res_data)
        confirmAlert({
            title: 'ยืนยันที่จะบันทึกการแก้ไข',
            message: 'คุณแน่ใจหรือที่จะอัพเดทข้อมูล',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        CrmService.updateModifyForm(res_data)
                            .then((res) => {
                                if (res.data.success) {
                                    window.alert('บันทึกสำเร็จ')
                                    window.location.reload();
                                }

                            })
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        // console.log("no")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })

    }
    const handleChange = (e) => {
        console.log(e)
        const { name, value } = e.target;
        setResData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleChangeSearchDate = (value) => {
        setResData((prevData) => ({
            ...prevData,
            search_date: value,
        }));
    }
    const handleChangeBirthDate = (value) => {
        setResData((prevData) => ({
            ...prevData,
            birth_day: value,
        }));
    }
    const handleChangeDateSalary = (value, name) => {
        console.log(value)
        setResData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    return (
        <>
            <div className='customer-list' style={{ marginRight: '1rem', marginLeft: '1rem' }}>
                <Paper elevation={5} style={{ paddingBottom: '10px', padding: '20px' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {edit_mode === true ? <Button variant="contained" color="success" style={{ marginRight: '5px', float: 'right', marginBottom: '10px' }} onClick={() => handleSave()}><SaveIcon />บันทึก</Button> : ""
                            }
                            <Button variant="contained" style={{ marginRight: '10px', float: 'right', marginBottom: '10px' }} onClick={() => handleExport()}><GetAppIcon />Export</Button>
                        </Grid>
                    </Grid>
                    <Card variant="outlined" style={{ padding: '10px' }}>

                        <Grid container spacing={0}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันที่สืบค้น</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <MobileDatePicker inputFormat="YYYY-MM-DD"
                                        name="search_date"
                                        // value={moment.unix(res_data?.search_date.slice(0, -3) ?? "").format("YYYY/MM/DD")}
                                        value={res_data?.search_date}
                                        onChange={handleChangeSearchDate} disabled={!edit_mode} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ผู้ทำเคสลูกค้า</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ width: "200px" }}
                                    size="small"
                                    disabled={!edit_mode}
                                    name="case_maker"
                                    value={res_data?.case_maker ?? ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'ปฐมาวดี'}>ปฐมาวดี</MenuItem>
                                    <MenuItem value={'อนงค์นาถ'}>อนงค์นาถ</MenuItem>
                                    <MenuItem value={'รมย์นรินทร์'}>รมย์นรินทร์</MenuItem>
                                    <MenuItem value={'ศิระดา'}>ศิระดา</MenuItem>
                                    <MenuItem value={'มานิตา'}>มานิตา</MenuItem>
                                </Select>
                            </Grid>


                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ลูกหนี้ชื่อ</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="firstname" variant="outlined" type='text' name="firstname"
                                    value={res_data?.firstname}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ลูกหนี้นามสกุล</Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="lastname" variant="outlined" type='text' name="lastname"
                                    value={res_data?.lastname}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อายุ</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="value_1_4_1" variant="outlined" type='text' name="value_1_4_1"
                                    value={ncb_data?.MSCW?.value_1?.value_1_4?.value_1_4_1 ?? ""}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วุฒิ</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ width: "200px" }}
                                    size="small"
                                    disabled={!edit_mode}
                                    name="educational_bg"
                                    value={res_data?.educational_bg ?? ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'ปวช./ มัธยมปลายหรือต่ำกว่า'}>ปวช./ มัธยมปลายหรือต่ำกว่า</MenuItem>
                                    <MenuItem value={'ปวส./ วิชาเฉพาะ'}>ปวส./ วิชาเฉพาะ</MenuItem>
                                    <MenuItem value={'ปริญญาตรี'}>ปริญญาตรี</MenuItem>
                                    <MenuItem value={'สูงกว่าปริญญาตรี'}>สูงกว่าปริญญาตรี</MenuItem>
                                </Select>
                            </Grid>
                            {/* <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันเกิด</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="birth_day" variant="outlined" type='text' name="birth_day"
                                    value={res_data?.birth_day}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid> */}
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันเกิด</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <MobileDatePicker inputFormat="YYYY-MM-DD"
                                        name="birth_day"
                                        // value={moment.unix(res_data?.search_date.slice(0, -3) ?? "").format("YYYY/MM/DD")}
                                        value={res_data?.birth_day}
                                        onChange={handleChangeBirthDate} disabled={!edit_mode} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>สถานะภาพ</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ width: "200px" }}
                                    size="small"
                                    disabled={!edit_mode}
                                    name="status"
                                    value={res_data?.status ?? ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'โสด'}>โสด</MenuItem>
                                    <MenuItem value={'สมรส'}>สมรส</MenuItem>
                                    <MenuItem value={'หย่าร้าง'}>หย่าร้าง</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บุตร</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="number_of_children" variant="outlined" type='text' name="number_of_children"
                                    value={res_data?.number_of_children}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>คน</Typography>
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เบอร์</Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="phone" variant="outlined" type='text' name="phone"
                                    value={res_data?.phone}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เลขบัตรปชช</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="id_number" variant="outlined" type='text' name="id_number"
                                    value={res_data?.id_number}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ที่ทำงาน</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="workplace" variant="outlined" type='text' name="workplace"
                                    value={res_data?.workplace}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ประเภทธุรกิจ</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="type_of_business" variant="outlined" type='text' name="type_of_business"
                                    value={res_data?.type_of_business}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เบอร์ HR</Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="hr_phone" variant="outlined" type='text' name="hr_phone"
                                    value={res_data?.hr_phone}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ตำแหน่ง</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="position" variant="outlined" type='text' name="position"
                                    value={res_data?.position}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บรรยายหน้าที่งาน</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="describe_job_duties" variant="outlined" type='text' name="describe_job_duties"
                                    value={res_data?.describe_job_duties}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อายุงาน</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="work_age" variant="outlined" type='text' name="work_age"
                                    value={res_data?.work_age}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จังหวัด</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ width: "200px" }}
                                    size="small"
                                    disabled={!edit_mode}
                                    name="province_form"
                                    value={res_data?.province_form ?? ""}
                                    onChange={handleChange}
                                >
                                    {
                                        province.map((province) => {
                                            return <MenuItem value={province.province_form}>{province.province_form}</MenuItem>
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ช่องทาง</Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ width: "170px" }}
                                    size="small"
                                    disabled={!edit_mode}
                                    name="marketing_channel"
                                    value={res_data?.marketing_channel ?? ""}
                                    onChange={handleChange}
                                    type='text'
                                >
                                    <MenuItem value="Facebook">Facebook</MenuItem>
                                    <MenuItem value="Tiktok">Tiktok</MenuItem>
                                    <MenuItem value="Google">Google</MenuItem>
                                    <MenuItem value="Youtube">Youtube</MenuItem>
                                    <MenuItem value="ทบทวน">ทบทวน</MenuItem>
                                    <MenuItem value="MOU">MOU</MenuItem>
                                    <MenuItem value="ช่องทางอื่นๆ">ช่องทางอื่นๆ</MenuItem>
                                    <MenuItem value="ลูกค้าเก่าปิดบัญชี">ลูกค้าเก่าปิดบัญชี</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ประเภทลูกค้า</Typography>
                            </Grid>
                            <Grid item xs={2}>
                            <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        name="old_customer_history"
                                        value={res_data?.old_customer_history ?? ""}
                                        disabled={!edit_mode}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'ลูกค้าใหม่'}>ลูกค้าใหม่</MenuItem>
                                        <MenuItem value={'ลูกค้า refinance - ได้รับวงเงินกลับไป'}>ลูกค้า refinance -ขยายงวด</MenuItem>
                                        <MenuItem value={'ลูกค้า refinance - ได้รับวงเงินกลับไป'}>ลูกค้า refinance - ได้รับวงเงินกลับไป</MenuItem>
                                        <MenuItem value={'mou'}>mou</MenuItem>

                                    </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ความแข็งแรงของนายจ้าง</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ width: "200px" }}
                                    size="small"
                                    name="employee_strength"
                                    value={res_data?.employee_strength ?? ""}
                                    disabled={!edit_mode}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'มหาชน / ราชการ'}>มหาชน / ราชการ</MenuItem>
                                    <MenuItem value={'ห้างหุ้นส่วนจำกัด / จำกัด ทุนจดทะเบียน < 5 ล้านบาท'}>{"ห้างหุ้นส่วนจำกัด / จำกัด ทุนจดทะเบียน < 5 ล้านบาท"}</MenuItem>
                                    <MenuItem value={'จำกัด ทุนจดทะเบียน  5 - 10 ล้านบาท'}>จำกัด ทุนจดทะเบียน  5 - 10 ล้านบาท</MenuItem>
                                    <MenuItem value={'จำกัด ทุนจดทะเบียน  > 10 ล้านบาท'}>{"จำกัด ทุนจดทะเบียน  > 10 ล้านบาท"}</MenuItem>

                                </Select>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ลักษณะงาน</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ width: "300px" }}
                                    size="small"
                                    name="job_description"
                                    value={res_data?.job_description ?? ""}
                                    onChange={handleChange}
                                    disabled={!edit_mode}
                                >
                                    <MenuItem value={'กลุ่มผู้จัดการฝ่ายขึ้นไป หรือ ผู้มีทักษะเฉพาะต่อองค์กร'}>กลุ่มผู้จัดการฝ่ายขึ้นไป หรือ ผู้มีทักษะเฉพาะต่อองค์กร</MenuItem>
                                    <MenuItem value={'หัวหน้าระดับขั้นต้น'}>หัวหน้าระดับขั้นต้น</MenuItem>
                                    <MenuItem value={'ผู้จัดการแผนก'}>ผู้จัดการแผนก</MenuItem>
                                    <MenuItem value={'พนักงานประจำ'}>พนักงานประจำ</MenuItem>
                                    <MenuItem value={'Outsource ติดต่อง่าย'}>Outsource ติดต่อง่าย</MenuItem>
                                    <MenuItem value={'ประทวน'}>ประทวน</MenuItem>
                                    <MenuItem value={'แรงงาน'}>แรงงาน</MenuItem>
                                    <MenuItem value={'PG'}>PG</MenuItem>
                                    <MenuItem value={'พนง. ขาย'}>พนง. ขาย</MenuItem>
                                    <MenuItem value={'Outsource ติดต่อยาก'}>Outsource ติดต่อยาก</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รายได้จากเงินเดือน</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="base_salary" variant="outlined" type='text' name="base_salary"
                                    value={res_data?.base_salary}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รายได้พิเศษ</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="extra_income" variant="outlined" type='text' name="extra_income"
                                    value={res_data?.extra_income}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าคอม</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="commission" variant="outlined" type='text' name="commission"
                                    value={res_data?.commission}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าคอม 1</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="commission_1" variant="outlined" type='text' name="commission_1"
                                    value={res_data?.commission_1}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าคอม 2</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="commission_2" variant="outlined" type='text' name="commission_2"
                                    value={res_data?.commission_2}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าคอม 3</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="commission_3" variant="outlined" type='text' name="commission_3"
                                    value={res_data?.commission_3}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รวมสุทธิ</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="sum_commission" variant="outlined" type='text' name="sum_commission"
                                    value={res_data?.sum_commission}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={2}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รายการหักจากสลีปเงินเดือน เงินเดือน</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="salary" variant="outlined" type='text' name="salary"
                                    value={res_data?.salary}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}> หัก</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="salary_slip_deducted_detail_1" variant="outlined" type='text' name="salary_slip_deducted_detail_1"
                                    value={res_data?.salary_slip_deducted_detail_1}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>

                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="salary_slip_deducted_amount_1" variant="outlined" type='text' name="salary_slip_deducted_amount_1"
                                    value={res_data?.salary_slip_deducted_amount_1}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}> บาท</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}> หัก</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="salary_slip_deducted_detail_2" variant="outlined" type='text' name="salary_slip_deducted_detail_2"
                                    value={res_data?.salary_slip_deducted_detail_2}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>

                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="salary_slip_deducted_amount_2" variant="outlined" type='text' name="salary_slip_deducted_amount_2"
                                    value={res_data?.salary_slip_deducted_amount_2}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}> บาท</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}> หัก</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="salary_slip_deducted_detail_3" variant="outlined" type='text' name="salary_slip_deducted_detail_3"
                                    value={res_data?.salary_slip_deducted_detail_3}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>

                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="salary_slip_deducted_amount_3" variant="outlined" type='text' name="salary_slip_deducted_amount_3"
                                    value={res_data?.salary_slip_deducted_amount_3}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}> บาท</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รวมรายการหัก</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="expense_item" variant="outlined" type='text' name="expense_item"
                                    value={res_data?.expense_item}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บาท</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รับเงินสุทธิ</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="receive_net_money" variant="outlined" type='text' name="receive_net_money"
                                    value={res_data?.receive_net_money}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}> บาท</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าใช้จ่ายส่วนตัว</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="personal_expenses" variant="outlined" type='text' name="personal_expenses"
                                    value={res_data?.personal_expenses}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าใช้จ่ายส่วนตัวที่ลูกค้าแจ้ง</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="city" variant="outlined" type='text' name="city"
                                    value={res_data?.city}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บุตร</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="child_expenses" variant="outlined" type='text' name="child_expenses"
                                    value={ncb_data?.MSCW?.child_expenses}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บาท</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าเช่าบ้าน</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="house_rent" variant="outlined" type='text' name="house_rent"
                                    value={res_data?.house_rent}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>รายการหักจากSTM</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="dept_in_stm" variant="outlined" type='text' name="dept_in_stm"
                                    value={res_data?.dept_in_stm}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าใช้จ่ายส่วนตัวสุทธิ</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="net_personal_expenses" variant="outlined" type='text' name="net_personal_expenses"
                                    value={res_data?.net_personal_expenses}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>NCB</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="total_account" variant="outlined" type='text' name="total_account"
                                    value={ncb_data?.credit_card_detail?.total_account}
                                    onChange={handleChange} disabled={true} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บัญชี</Typography>
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เปิด</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="lastname" variant="outlined" type='text' name="lastname"
                                    value={ncb_data?.credit_card_detail?.total_active_account}
                                    onChange={handleChange} disabled={true} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บัญชี</Typography>
                            </Grid>
                            <Grid item xs={1.2}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>คงเหลือที่หมุนเวียนได้</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="amount_can_withdrawn_after_deduction" variant="outlined" type='text' name="amount_can_withdrawn_after_deduction"
                                    value={ncb_data?.credit_card_information?.amount_can_withdrawn_after_deduction}
                                    onChange={handleChange} disabled={true} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บาท</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ค่าใช้จ่าย NCB สุทธิ</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="lastname" variant="outlined" type='text' name="lastname"
                                    value={ncb_data?.other_detail?.installment}
                                    onChange={handleChange} disabled={true} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บาท</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อายุเครดิต</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="credit_age" variant="outlined" type='text' name="credit_age"
                                    value={ncb_data?.credit_card_detail?.credit_age}
                                    onChange={handleChange} disabled={true} />
                            </Grid>
                            <Grid item xs={1.2}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จำนวนเดือนที่ไม่เสีย</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="value_4_2_1" variant="outlined" type='text' name="value_4_2_1"
                                    value={ncb_data?.MSCW?.value_4?.value_4_2?.value_4_2_1}
                                    onChange={handleChange} disabled={true} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={2}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>อายุบัญชีปัจจุบันปกติที่ยาวที่สุด</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="value_4_3_1" variant="outlined" type='text' name="value_4_3_1"
                                    value={ncb_data?.MSCW?.value_4?.value_4_3?.value_4_3_1}
                                    onChange={handleChange} disabled={true} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บัญชี่ที่เสีย NCB</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="bad_account_1to3_installments" variant="outlined" type='text' name="bad_account_1to3_installments"
                                    value={ncb_data?.MSCW?.value_4?.value_4_5?.value_4_5_1}
                                    onChange={handleChange} disabled={true} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เหลือเงินต่อเดือน</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="money_left" variant="outlined" type='text' name="money_left"
                                    value={res_data?.money_left}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บาท</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วงเงินเสนอครั้งนี้</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="offer_limit" variant="outlined" type='text' name="offer_limit"
                                    value={res_data?.offer_limit}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={0.3}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บาท</Typography>
                            </Grid>
                            <Grid item xs={0.1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>/</Typography>
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ผ่อน</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="estimate_installment_amount" variant="outlined" type='text' name="estimate_installment_amount"
                                    value={res_data?.estimate_installment_amount}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={0.3}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>บาท</Typography>
                            </Grid>
                            <Grid item xs={0.1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>/</Typography>
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>งวดผ่อน</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="installment_offered" variant="outlined" type='text' name="installment_offered"
                                    value={res_data?.installment_offered}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={0.3}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>งวด</Typography>
                            </Grid>
                            <Grid item xs={0.1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>/</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จำนวนงวดหัก</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="less_offer" variant="outlined" type='text' name="less_offer"
                                    value={res_data?.less_offer}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>มีการค้าง</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="lastname" variant="outlined" type='text' name="lastname"
                                    value={ncb_data?.MSCW?.value_4?.value_4_4?.value_4_4_1}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>

                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ข้อมูลการค้างชำระ</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField fullWidth multiline rows={3} style={{ paddingRight: '1rem' }} size="small" id="value_4_7" variant="outlined" type='text' name="value_4_7"
                                    value={res_data?.note_ncb}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>หมายเหตุ</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField fullWidth multiline rows={3} style={{ paddingRight: '1rem' }} size="small" id="note" variant="outlined" type='text' name="note"
                                    value={res_data?.note}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วงเงินเดิม</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="original_limit" variant="outlined" type='text' name="original_limit"
                                    value={res_data?.original_limit}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ยอดหนี้คงเหลือ</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="pico_debt_balance" variant="outlined" type='text' name="pico_debt_balance"
                                    value={res_data?.pico_debt_balance}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>จำนวนงวดผ่อนเดิม</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="original_installments" variant="outlined" type='text' name="original_installments"
                                    value={res_data?.original_installments}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>งวดผ่อนคงเหลือ</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="installment_remaining" variant="outlined" type='text' name="installment_remaining"
                                    value={res_data?.installment_remaining}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ยอดผ่อนเก่า</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="pico_old_installment" variant="outlined" type='text' name="pico_old_installment"
                                    value={res_data?.pico_old_installment}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>

                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>หนี้นอกระบบรวม</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="total_informal_debt" variant="outlined" type='text' name="total_informal_debt"
                                    value={res_data?.total_informal_debt}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>หนี้นอกระบบต่อเดือน</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="informal_debt" variant="outlined" type='text' name="informal_debt"
                                    value={res_data?.informal_debt}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={2}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>คู่สมรสช่วยค่าใช้จ่ายครอบครัวเท่าไหร่(ถ้ามี)</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="help_the_family" variant="outlined" type='text' name="help_the_family"
                                    value={res_data?.help_the_family}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>เงินเดือนคู่สมรส</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="spouse_income" variant="outlined" type='text' name="spouse_income"
                                    value={res_data?.spouse_income}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>ความสามารถในการผ่อน</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="ability_to_pay" variant="outlined" type='text' name="ability_to_pay"
                                    value={res_data?.ability_to_pay}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '2px' }}>รับเงินเดือน (ครั้ง)</Typography>
                            </Grid>
                            <Grid item xs={2} >
                                <Grid item xs={2}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ width: "200px" }}
                                        size="small"
                                        disabled={!edit_mode}
                                        name="amount_getsalary"
                                        value={res_data?.amount_getsalary ?? ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={1}>1 ครั้ง</MenuItem>
                                        <MenuItem value={2}>2 ครั้ง</MenuItem>
                                        <MenuItem value={3}>3 ครั้ง</MenuItem>
                                        <MenuItem value={4}>4 ครั้ง</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันที่รับเงินเดือน ครั้งที่ 1</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <MobileDatePicker
                                        inputFormat="YYYY-MM-DD"
                                        name="date_getsalary_1"
                                        value={res_data?.date_getsalary_1}
                                        onChange={(e) => handleChangeDateSalary(e, 'date_getsalary_1')} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันที่รับเงินเดือน ครั้งที่ 2</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <MobileDatePicker
                                        inputFormat="YYYY-MM-DD"
                                        name="date_getsalary_2"
                                        value={res_data?.date_getsalary_2}
                                        onChange={(e) => handleChangeDateSalary(e, 'date_getsalary_2')} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1.5}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันที่รับเงินเดือน ครั้งที่ 3</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <MobileDatePicker
                                        inputFormat="YYYY-MM-DD"
                                        name="date_getsalary_3"
                                        value={res_data?.date_getsalary_3}
                                        onChange={(e) => handleChangeDateSalary(e, 'date_getsalary_3')} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วันที่รับเงินเดือน ครั้งที่ 4</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <MobileDatePicker
                                        inputFormat="YYYY-MM-DD"
                                        name="date_getsalary_4"
                                        value={res_data?.date_getsalary_4}
                                        onChange={(e) => handleChangeDateSalary(e, 'date_getsalary_4')} renderInput={(params) => <TextField {...params} size="small" disabled={!edit_mode} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วัตถุประสงค์</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="loan_purpose" variant="outlined" type='text' name="loan_purpose"
                                    value={res_data?.loan_purpose}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>วงเงินที่ขอ</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="requested_limit" variant="outlined" type='text' name="requested_limit"
                                    value={res_data?.requested_limit}
                                    onChange={handleChange} disabled={!edit_mode} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: '15px' }}>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>สถานะบ้าน</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="home_debt" variant="outlined" type='text' name="home_debt"
                                    value={ncb_data?.home_car_loan_detail?.total_home_loan}
                                    onChange={handleChange} disabled={true} />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ fontWeight: 'bold', marginTop: '7px' }}>สถานะรถ</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField style={{ paddingRight: '1rem' }} size="small" id="lastname" variant="outlined" type='text' name="lastname"
                                    value={ncb_data?.home_car_loan_detail?.total_car_loan}
                                    onChange={handleChange} disabled={true} />
                            </Grid>
                        </Grid>
                    </Card>
                </Paper>
            </div>

        </>
    )
}