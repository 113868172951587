import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { format } from 'date-fns';
import AlertDialog from './alertDialog2';
import { callOCRStatement2 } from '../../services/statement_service';

// columns
const columns = [
  {
    field: 'id',
    headerName: 'id',
    width: 150,
    headerAlign: 'center',
    align: 'center',
    hide: true
  },
  {
    field: 'contact_id',
    headerName: 'Digital app ID',
    width: 150,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'file_name',
    headerName: 'ชื่อไฟล์',
    flex: 1,
    minWidth: 250,
    headerAlign: 'center',
    align: 'center'
  },
  // {
  //   field: 'url',
  //   headerName: 'url',
  //   flex: 1,
  //   minWidth: 500,
  //   headerAlign: 'center',
  //   align: 'center',
  // },
  {
    field: 'bank',
    headerName: 'ธนาคาร',
    width: 200,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => row.bank && row.bank.toUpperCase()
  },
  {
    field: 'date',
    headerName: 'วันที่รับเอกสาร',
    width: 250,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => {
      const date = new Date(row.date);
      const formattedDate = format(date, "dd/MM/yyyy");

      return formattedDate
    }
  },
  {
    field: 'password',
    headerName: 'Password',
    width: 250,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => {
      return row.password === 'undefined' ? '-' : row.password;
    }
  },
  {
    field: "1",
    headerName: "Detail Statement",
    width: 250,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: ({ row }) =>
      <Button onClick={() => window.open('/preview?param=' + encodeURIComponent(row.url), '_blank')}>
        DETAIL
      </Button>,
  },
];

export default function DataTable({ rows, contact_id }) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [rowIndex, setRowIndex] = useState()
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setSelectionModel([])
    setOpen(false);
  }

  const handleSubmit = (bank) => {
    rows[rowIndex].bank = bank
  }

  const handleSelectionModelChange = (newSelectionModel) => {
    const selectIndex = newSelectionModel[newSelectionModel.length - 1]
    const index = rows.findIndex(item => item.id === selectIndex);
    setRowIndex(index)

    if (newSelectionModel.length !== 0) {
      const file_name = rows[index].file_name;
      const file_type = file_name.split('.').pop();

      if (file_type.toLowerCase() !== 'pdf') {
        console.log("The file type is not 'pdf'.");
        alert("The file type is not 'pdf'.");
        return;
      }

      handleOpen();
    }

    setSelectionModel(selectIndex);
  };

  const handleUploadOcr = () => {
    if (selectionModel.length === 0) {
      alert('กรุณาเลือกไฟล์ที่ต้องการอัปโหลด OCR')
      return
    }
    
    const data = rows[rowIndex]

    const body = {
      password: data.password,
      bank: data.bank,
      callback: "https://ocr-api-mscw.tfm.in.th/test/200?id=" + data.file_id,
      contact_id: contact_id,
      files: data.url
    }

    callOCRStatement2(body).then(res => {
      console.log(res);
      window.location.reload();
    }).catch(err => {
      console.log(err);
      alert(err.response.data.status)
    })
  }

  return (
    <>
      <Paper elevation={3}>
        <Grid container padding={2} marginBottom={1}>
          <Grid item xs>
            <Typography variant='h6'>รายการไฟล์ Statement ที่ยังไม่ได้รับการอ่าน OCR</Typography>
          </Grid>

          <Grid item paddingLeft={2}>
            <Button variant="contained" onClick={() => handleUploadOcr()}>upload ocr</Button>
          </Grid>
        </Grid>

        <div style={{ width: '100%' }}>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            sx={{
              "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                display: "none"
              }
            }}
          />
        </div>
      </Paper>

      <AlertDialog
        open={open}
        handleClose={handleClose}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        rows={rows}
        selectionModel={selectionModel}
      />
    </>
  );
}