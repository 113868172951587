import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

// // Import the dotenv package
// const dotenv = require('dotenv');
// const dotenv = require('dotenv').config();

// // Load the environment variables from .env file
// dotenv.config();
// require('dotenv').config({path:__dirname+'/./../../.env'})

// Sentry.init({
//   dsn: "https://df1b660ca69812fc48e93ff2fa620273@sentry.cmdn.space/5",
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracingOrigins: ["*"],
//       // See docs for support of different versions of variation of react router
//       // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         React.useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes
//       ),
//     }),
//     new Sentry.Replay()
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\//],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });


// const theme = createTheme({
//   typography: {
//     fontFamily: [
//       // 'IBM Plex Sans Thai',
//       // 'Prompt',
//     ].join(','),
//   },
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  // <ThemeProvider theme={theme}>
    // <Sentry.ErrorBoundary>
      <App />
    // </Sentry.ErrorBoundary>
  // </ThemeProvider> 
  // </React.StrictMode>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
