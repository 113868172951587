import TableData from '../../components/table/table_crm_customer_detail_list';
import './../sum.css'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SideBar from './../../page/dashboard'
import jwt_decode from "jwt-decode";
import { checkPermission } from '../../utils/permission_utils.js';
import * as React from 'react';
import { useParams ,useLocation} from 'react-router-dom';


export default function App() {
    var token = localStorage.getItem('token')
    var token_org = localStorage.getItem('token_org')
    const { state } = useLocation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const citizen_id = queryParams.get('citizen_id');

    var decoded
    var decoded_org
    if (token === null) {
        window.location.href = '/login'
    } else {

        decoded = jwt_decode(token)
        decoded_org = jwt_decode(token_org)
    }
    // const hasPermission = checkPermission(location.pathname, decoded)
    // React.useEffect(() => {
    //     if (!hasPermission) {
    //         window.location.href = '/404';
    //     }
    // }, [])
    return (
        <>
            {/* {hasPermission && ( */}
                <div className='contact-management' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: 'bold' }}>ข้อมูลทั่วไป</Typography>
                        </Grid>
                    </Grid>

                    <TableData citizen_id={citizen_id}/>
                </div>
            {/* )} */}
        </>
    )
}