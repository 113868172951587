import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

function Filter({ list, handleSetFilters }) {
  const [rows, setRows] = useState(1)
  const [filters, setFilters] = useState([])
  const filter_list = list

  // const handleSearch = () => {
  //   console.log('filters',filters)
  //   // handleSetFilters(filters)
  // }

  const addClassify = () => {
    setRows(rows + 1)
  }

  const handleDelFilter = (index) => {
    if (rows > 1) {
      setRows(rows - 1)
    }

    const updatedFilters = [...filters];
    delete updatedFilters[index]

    const newRows = [];
    Object.keys(updatedFilters).forEach((key, i) => {
      newRows[i] = updatedFilters[key];
    });

    setFilters(newRows);
  }

  const resetClassify = (index) => {
    const updatedFilters = [...filters];
    delete updatedFilters[index]
    let newRows = updatedFilters.filter(Boolean);

    setFilters(newRows);
  }

  const handleChangeClassify = (index) => (event) => {
    const updatedFilters = [...filters];
    const updatedFilter = { ...updatedFilters[index], attribute: event.target.value, operator: 'or' };
    updatedFilters[index] = updatedFilter;

    setFilters(updatedFilters);
  };

  const handleChangeOperator = (index) => (event) => {
    const updatedFilters = [...filters];
    const updatedFilter = { ...updatedFilters[index], operator: event.target.value };
    updatedFilters[index] = updatedFilter;

    setFilters(updatedFilters);
  }

  const handleChange = (index, type) => (event) => {
    const updatedFilters = [...filters];
    const updatedFilter = { ...updatedFilters[index], search: event.target.value, type: type };
    updatedFilters[index] = updatedFilter;

    setFilters(updatedFilters);
  };

  const handleChangeDate = (index, type) => (event) => {
    const updatedFilters = [...filters];
    const updatedFilter = { ...updatedFilters[index], search: event, type: type };
    updatedFilters[index] = updatedFilter;

    setFilters(updatedFilters);
  }

  const filterClassify = (index) => {
    return (
      <>
        <Grid item >
          <FormControl style={{ width: 300 }} >
            <InputLabel id="demo-simple-select-label">ตัวเลือก</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="select-filter"
              value={filters[index]?.attribute || ''}
              label="ตัวเลือก"
              onChange={handleChangeClassify(index)}
            >
              {
                filter_list.map((item, index) => {
                  return <MenuItem key={index} id={item.name} value={item.property}>{item.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>
        {filterMenu(index)}
        {
          rows !== index + 1 &&
          <Grid item>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Operator</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="select-operator"
                value={filters[index]?.operator || 'or'}
                label="Operator"
                onChange={handleChangeOperator(index)}
                style={{ width: 150 }}
              >
                <MenuItem value={'and'}>และ</MenuItem>
                <MenuItem value={'or'}>หรือ</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        }

        {
          filters[index]?.attribute !== undefined ?
            <Grid item>
              <Tooltip title="ล้างตัวกรอง">
                <IconButton onClick={() => resetClassify(index)}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            :
            ''
        }
        <Grid item marginRight={10}>
          <Tooltip title="ลบตัวกรองข้อมูล">
            <IconButton onClick={() => handleDelFilter(index)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </>
    )
  }

  const filterMenu = (index) => {
    if (filters.length !== 0) {
      if (filters[index]) {
        let display_name = filter_list.find(o => o.property === filters[index]?.attribute)

        if (display_name.type === 'input') {
          return (
            <Grid item>
              <TextField
                id={display_name.type+'-'+display_name.property}
                label={display_name.name}
                variant="outlined"
                value={filters[index]?.search || ''}
                onChange={handleChange(index, display_name?.type)}
              />
            </Grid>
          )
        } else if (display_name.type === 'select') {
          return (
            <Grid item>
              <FormControl style={{ width: 200 }} >
                <InputLabel id="demo-simple-select-label">
                  {display_name?.name}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id={display_name.type+'-'+display_name.property}
                  value={filters[index]?.search || ''}
                  label={display_name?.name}
                  onChange={handleChange(index, display_name?.type)}
                >
                  {
                    display_name.option.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
          )
        } else if (display_name.type === 'date') {
          return (
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <MobileDatePicker
                  label="Date filter"
                  inputFormat="YYYY-MM-DD"
                  id={display_name.type+'-'+display_name.property}
                  value={filters[index]?.search || null}
                  onChange={handleChangeDate(index, display_name?.type)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          )
        }
      }
    }
  }

  return (
    <div>
      <Grid>
        {Array.from({ length: rows }, (_, index) => {
          return (
            <Grid container spacing={2} style={{ padding: '1rem' }}>
              {filterClassify(index)}
            </Grid>
          )
        })}

        <Grid container spacing={2} style={{ padding: '1rem' }}>
          <Grid item >
            <Button id='button-add-input' variant="outlined" style={{ color: "#333", borderColor: "#333" }} onClick={() => addClassify()}>เพิ่มช่องการค้นหา</Button>
          </Grid>
          <Grid item >
            <Button variant="contained" id='button-search'  onClick={() => handleSetFilters(filters)}>ค้นหา</Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Filter;