import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import jwt_decode from "jwt-decode";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import * as crm from '../../services/crm_service';
import { format } from 'date-fns';
import { converBank } from '../../utils/bank';
import moment from "moment-timezone";
import { useLocation } from 'react-router-dom';
moment.tz.setDefault("Asia/Bangkok")
export default function App() {
    let token_org = localStorage.getItem('token_org')
    let token = localStorage.getItem('token')

    let decode;
    if (token === null) {
        window.location.href = '/login';
    } else {
        decode = jwt_decode(token);
    }
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const contract_no = queryParams.get('contract_no');
    const user_id = queryParams.get('user_id');
    const [rows_tran, setRowsTran] = useState([]);
    const [selected_row, setSelectedRow] = useState([]);
    useEffect(() => {
        loadAwaitList();
    }, [])
    const loadAwaitList = () => {
        let object = {
            contract_no: contract_no,
            user_id: user_id
        }
        crm.getDDRegisterList(object)
            .then((res) => {
                const list_data = res.data.data;
                const transfer = [];
                list_data.map((item, index) => {
                    transfer.push(
                        {
                            "id": index + 1,
                            "register_date": moment(new Date(item.create_date)).format('YYYY-MM-DD'),
                            "account_no": item.accountUnmask,
                            "bank": converBank(item.bankCode)
                        }
                    )
                });
                setRowsTran(transfer);
            })
    }
    const handleSelectedChange = (newSelection) => {
        setSelectedRow(newSelection);
    };
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'No.',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1

        },
        {
            field: 'register_date',
            headerName: 'วันที่สมัครสำเร็จ',
            width: 400,
            headerAlign: 'center',
            align: 'center'

        },
        {
            field: 'bank',
            headerName: 'ธนาคาร',
            width: 400,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'account_no',
            headerName: 'เลขบัญชี',
            width: 400,
            headerAlign: 'center',
            align: 'center',
        },
    ]
    return (
        <>
            <div className='customer-list' style={{ margin: '2rem' }}>
                <Paper elevation={3}>
                    <Box  sx={{
                            height: 630,
                            width: '100%',
                        }} >
                        <DataGrid
                            rows={rows_tran}
                            columns={columns}
                            disableRowSelectionOnClick
                            selectionModel={selected_row}
                            onSelectionModelChange={handleSelectedChange}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            sx={{
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    textOverflow: "clip",
                                    whiteSpace: "break-spaces",
                                    lineHeight: 1.5,
                                },
                            }}
                        />
                    </Box>
                </Paper>
            </div>

        </>
    )
}