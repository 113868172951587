/* eslint-disable no-unused-vars */

import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MdOpenInNew } from 'react-icons/md';
import dataTest from '../../assets/datas/data.json';
import * as StatementHttpServices from '../../services/statement_service.js';
import { format } from 'date-fns';

export default function App() {
    const [data, setData] = useState('')

    const contactId = {
        contact_id: '77101'
    }

    useEffect(() => {
        StatementHttpServices.calculatStatement(contactId)
            .then((res) => {
                console.log('res', res.data.data.data[0])
                setData(res.data.data.data[0])
            })
    }, [])

    let token = localStorage.getItem('token')
    let decoded

    // useState
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const monthAbbreviations = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    // const today = new Date()
    // const monthNumber = today.getMonth() + 1;
    // const monthString = monthAbbreviations[monthNumber - 1]; // month names array is zero-based

    // var date = today.getDate() + '-' + monthString + '-' + today.getFullYear();

    const getMonthName = (monthNumber) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[monthNumber - 1] || '';
    };

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // style model
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        //   border: '2px solid #000',
        //   boxShadow: 24,
        p: 4,
        borderRadius: 1
    };

    // columns
    const columns = [
        {
            field: 'date',
            headerName:
                <div className='centered-header'>
                    <span>วันที่</span>
                </div>,
            headerClassName: 'table-header',
            width: 150,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                const date = new Date(row.date);
                const formattedDate = format(date, "dd/MM/yyyy");

                return formattedDate
            }
        },
        {
            field: 'balance',
            headerName:
                <div className='centered-header'>
                    <span>เงินคงเหลือ</span>
                </div>,
            width: 150,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (row.balance || 0).toLocaleString()
        },
        {
            field: 'income',
            headerName:
                <div className='centered-header'>
                    <span>เงินเข้า</span>
                </div>,
            width: 150,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (row.income || 0).toLocaleString()
        },
        {
            field: 'note',
            headerName:
                <div className='centered-header'>
                    <span>หมายเหตุ</span>
                </div>,
            width: 150,
            editable: true,
            headerAlign: 'center',
            align: 'center',
        },
    ];
    const AccordionItem = ({ month, data_2 }) => {
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`accordion-summary-${month}`}>
                    <Typography>{getMonthName(month)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MyDataGrid rows={data_2} />
                </AccordionDetails>
            </Accordion>
        );
    };

    // const addIndex = (data_2) => {
    //     console.log('data_2>>>', data_2)
    //     const updatedArray = [...yourArray];

    //     let modifiedArray = updatedArray.map((object, index) => {
    //         console.log(object)
    //         // Add the desired value to each object
    //         // object.id = index // Replace "your value" with the value you want to add
    //         // return object;
    //     });

    //     console.log('modifiedArray', modifiedArray)

    //     // return data_2
    // }

    const MyDataGrid = (rows) => {
        const dataForm = rows.rows

        return (
            <>
                <Box
                    sx={{
                        width: '100%',
                        '& .green': {
                            backgroundColor: '#01ff00',
                            color: '#01ff00',
                        },
                        '& .yellow': {
                            backgroundColor: '#feff00',
                            color: '#feff00',
                        },
                        '& .red': {
                            backgroundColor: '#ff0000',
                            color: '#ff0000',
                        },
                    }}
                >
                    <DataGrid
                        autoHeight
                        rows={dataForm}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        // checkboxSelection
                        disableRowSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1.5,
                            },
                        }}
                        getCellClassName={(params) => {
                            if ((params.field !== 'note') || params.value == null) {
                                return '';
                            }
                            return params.value > 1500 ? 'green' : params.value > 500 ? 'yellow' : 'red';
                        }}
                    />
                </Box>
            </>
        )
    }

    // return
    if (data === '') {
        return (<>
        </>)
    } else {
        return (
            <div className='statement-analysis' style={{ margin: '2rem' }}>
                <SideBar />
                <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                    <Grid item xs>
                        <Typography variant='h4'>รายละเอียด Statement ของ {data.account_name} รอบระหว่างวันท่ี {data.account_period}</Typography>
                    </Grid>

                    <Button variant="outlined" style={{ textTransform: 'none', marginRight: 15 }} onClick={() => handleOpen()}>Report</Button>
                    {/* <Button variant="outlined" style={{ textTransform: 'none' }}>Edit Statement</Button> */}
                </Grid>

                <div>
                    {data.statement_data[0].map((item, index) => (
                        <AccordionItem key={index} {...item} />
                    ))}
                </div>

                {/* model report statement*/}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            รายงาน Statement ส่วนสรุป
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <Box
                                sx={{
                                    height: 370,
                                    width: '100%',
                                    '& .green': {
                                        backgroundColor: '#01ff00',
                                        color: '#1a3e72',
                                    },
                                    '& .yellow': {
                                        backgroundColor: '#feff00',
                                        color: '#1a3e72',
                                    },
                                    '& .red': {
                                        backgroundColor: '#ff0000',
                                        color: '#1a3e72',
                                    },
                                }}
                            >
                                <DataGrid
                                    rows={[]}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    // checkboxSelection
                                    disableRowSelectionOnClick
                                    sx={{
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1.5,
                                        },
                                    }}
                                    getCellClassName={(params) => {
                                        if ((params.field !== 'col4' && params.field !== 'col7' && params.field !== 'col10' && params.field !== 'col13' && params.field !== 'col16') || params.value == null) {
                                            return '';
                                        }
                                        return params.value === 1 ? 'green' : params.value === 2 ? 'yellow' : 'red';
                                    }}
                                />
                            </Box>
                        </Typography>
                    </Box>
                </Modal>
            </div>
        )
    }
}