import axios from "axios";

const config = require('../config.js')
// const UPLOAD_API_URL = 'https://master-api.transfinmational.org'

const UPLOAD_API_URL = config.ROOT_URL
export const uploadfile = (data) => {
    return axios.post(UPLOAD_API_URL + '/upload_file', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
    })
}