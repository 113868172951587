import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';

export default function FormDialog({ open, handleClose, handleOpen }) {
    // const [open, setOpen] = React.useState(false);
    const [bank, setBank] = useState(null);
    const [pdf_file, setPDFfile] = useState([])
    const [password, setPassword] = useState("");
    const fileInput = useRef(null);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open form dialog
            </Button> */}
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>อัปโหลดไฟล์ NCB</DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography gutterBottom>
                        <Typography variant="body1" gutterBottom>
                            ไฟล์ NCB
                        </Typography>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            // value={news_thumbnail}
                            // onChange={event => onFileBodyChange(event)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                backgroundColor: "#FFFFFF",
                            }}
                            type="file"
                            ref={fileInput}
                            inputProps={{ accept: 'application/pdf' }}
                        // disabled={bank === null}
                        />
                    </Typography>

                    <Typography gutterBottom>
                        <Typography variant="body1" gutterBottom>
                            ประเภทเสีย NCB พิเศษ
                        </Typography>
                        <Autocomplete
                            value={'ไม่มี'}
                            onChange={(event, newValue) => {
                                setBank(newValue);
                            }}
                            id="autocomplete-bank"
                            options={['ไม่มี', 'โดนอายัดเงินเดือน', 'บุคคลล้มละลาย']}
                            // getOptionLabel={(option) => option.th_name}
                            // isOptionEqualToValue={(option, value) => option.en_name === value.en_name}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Typography>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={handleClose}>คำนวณ</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
