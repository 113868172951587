import React, {  } from 'react';
import { useParams } from 'react-router-dom';
import SideBar from '../dashboard.js'

// components
import NoNcbStatementSummaryLoan from '../../components/statement-summary/NoNcbStatementSummaryLoan.js';

export default function App() {
    const { contact_id } = useParams();

    return (
        <div className='customer-list' style={{ margin: '2rem' }}>
            <SideBar />
            <div style={{ marginTop: '4rem', marginBottom: '2rem', width: '100%' }}>
                <NoNcbStatementSummaryLoan contact_id={contact_id}  />
            </div>
        </div>
    )
}