import axios from "axios";
import jwt_decode from "jwt-decode";

const config = require('../config.js')

const MSCW_URL = config.MSCW_URL;
const PICO_URL = config.PICO_URL;
const token = localStorage.getItem('token')
let ROOT_URL

if (token !== null) {
    const decoded = jwt_decode(token)
    console.log('decoded', decoded)
    ROOT_URL = decoded.org_id === 1 ? PICO_URL : MSCW_URL;
}

export const getContactID = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/hubspot/contacts/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
