import * as React from 'react';
import TableData from '../../components/table/user_table';
import '../sum.css'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import { useLocation } from 'react-router-dom';
import { ConfirmProvider } from "material-ui-confirm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Link,
    Routes,
    Route,
    useNavigate,
  } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils';
import { useEffect } from 'react';
export default function App() {
    const { state } = useLocation();
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let token = localStorage.getItem('token')
    const navigate = useNavigate();
    let decoded
    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
const hasPermission = checkPermission(location.pathname,decoded);
useEffect(() => {
    if(!hasPermission){
        window.location.href = '/404';
    }
},[])

    return (
        <>
        {hasPermission && (
        <div className='customer_pending' style={{ margin: '2rem' }}>
            <SideBar />
            {/* <Button variant="contained" style={{ marginTop: '3rem' }} onClick={() => navigate(-1)}><ArrowBackIcon></ArrowBackIcon>ย้อนกลับ</Button> */}
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: 'bold' }}>สถานะลูกค้า</Typography>
                </Grid>
            </Grid>
            <ConfirmProvider>
                <TableData />
            </ConfirmProvider>
        </div>
        )}
        </>
    )
}