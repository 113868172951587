import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import jwt_decode from "jwt-decode";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import SideBar from '../dashboard.js';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import Textarea from '@mui/joy/Textarea';
import EditIcon from '@mui/icons-material/Edit';
import { confirmAlert } from 'react-confirm-alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as TackingService from '../../services/tacking_service.js';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const moment = require("moment-timezone")
moment.tz.setDefault("Asia/Bangkok")

export default function App() {
    let token = localStorage.getItem('token')
    let token_org = localStorage.getItem('token_org')

    let decode;
    if (token === null) {
        window.location.href = '/login';
    } else {
        decode = jwt_decode(token);
    }
    const [open, setOpen] = useState(false);
    const [open_popup, setopenPopup] = useState(false);

    const [rows_data, setRowsData] = useState(false);
    const [action, setAction] = useState();
    const [note, setNote] = useState('');
    const [row, setRows] = useState('');
    const [loading, setLoad] = useState(true);

    const location = useLocation();
    // const has_permis = checkPermission(location.pathname, decode);
    const has_permis = true

    useEffect(() => {
        if (!has_permis) {
            window.location.href = '/404';
        } else {
            loadAwaitList()
        }

    }, [])

    const loadAwaitList = () => {
        TackingService.getMarketingtracking()
            .then((res) => {
                const row = [];
                console.log(res);
                res.data.data.map((item, index) => {
                    if(item.status !== "ยกเลิก"){
                        row.push(
                            {
                                "id": index + 1,
                                "fullname": item.Name + ' ' + item.Surname,
                                "date": item.TimeStamp === null ? '' : moment(item.TimeStamp).format('YYYY-MM-DD'),
                                "tracking_date": item.action_date === null ? '-' : item.action_date.split("T")[0],
                                "Brand": item.Band === null ? '-' : item.Band,
                                "PhoneNumber": item.PhoneNumber,
                                "MarketingChannel": item.MarketingChannel,
                                "status": item.status === null ? '-' : item.status,
                                "note": item.note,
                                "customerId": item.CustomerId
                            }
                        )

                    }
                    console.log(row)
                });
                setRows(row)
                setLoad(false)
            })
    }

    const handleEdit = (row, type, str) => {
        let obj = {
            action: str,
            customerId: row.row.customerId
        }
        confirmAlert({
            title: `ยืนยันที่จะ บันทึก?`,
            message: 'คุณแน่ใจหรือที่จะบันทึก',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        TackingService.MarketingtrackingUpdate(obj)
                            .then((res) => {
                                if (res.data.success === true) {
                                    loadAwaitList()
                                    alert('บันทึกสำเร็จ')
                                    setopenPopup(false)
                                } else {
                                    alert('บันทึกไม่สำเร็จ')
                                }
                            })
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("no")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })

    }
    const handleEditNote = (row, type, str) => {
        let obj = {
            note: note,
            customerId: rows_data.row.customerId
        }
        TackingService.MarketingtrackingUpdate(obj)
            .then((res) => {
                if (res.data.success === true) {
                    loadAwaitList()
                    alert('บันทึกสำเร็จ')
                    setopenPopup(false)
                } else {
                    alert('บันทึกไม่สำเร็จ')
                }
            })
    }
    const columns_1 = [
        {
            field: 'id',
            headerName: 'id',
            width: 1,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'ลำดับ',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1

        },
        {
            field: 'date',
            headerName: 'วันที่ผ่าน Prescreen',
            width: 200,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'fullname',
            headerName: 'ชื่อ-นามสกุล',
            width: 200,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'Brand',
            headerName: 'Brand',
            width: 100,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'PhoneNumber',
            headerName: 'เบอร์โทรศัพท์',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'MarketingChannel',
            headerName: 'ช่องทางการตลาด',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'status',
            headerName: 'สถานะการติดตาม',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'tracking_date',
            headerName: 'วันที่ติดตาม',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Action</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={params.value}
                        label="Action"
                    >
                        <MenuItem value='ติดต่อไม่ได้' onClick={() => handleEdit(params, 'option', 'ติดต่อไม่ได้')}>ติดต่อไม่ได้</MenuItem>
                        <MenuItem value='นัดสมัคร' onClick={() => handleEdit(params, 'option', 'นัดสมัคร')}>นัดสมัคร</MenuItem>
                        <MenuItem value='ยกเลิก' onClick={() => handleEdit(params, 'option', 'ยกเลิก')}>ยกเลิก</MenuItem>
                    </Select>
                </FormControl>
            ),
        },
        {
            field: 'note',
            headerName: 'Note',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div>{params.value}</div>
            ),
        },
        {
            field: '',
            headerName: 'Note',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Button variant="text" onClick={(e) => editNote(params)}><EditIcon />{'\u00a0'}</Button>
            ),
        }
    ]
    const style_note = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 300,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const editNote = (row) => {
        setRowsData(row)
        setNote(row.row.note)
        setopenPopup(true)
    }
    return (
        <>
            {loading && ( // Render loading indicator when open is true
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            {has_permis && (


                <div className='customer-list' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: '' }}>ข้อมูลสรุปผู้ที่ผ่านเกณฑ์ Prescreen แต่ยังไม่ได้ทำการสมัคร Digital App</Typography>
                        </Grid>
                    </Grid>
                    <Paper elevation={3} style={{ padding: 50 }}>
                        <Grid container spacing={2} style={{ padding: '1rem' }}>
                            <div style={{ height: 800, width: "100%", marginTop: 20 }}>
                                <DataGrid
                                    rows={row}
                                    columns={columns_1}
                                    // disableColumnMenu
                                    // hideFooterPagination
                                    // hideFooterSelectedRowCount
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 3,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[3]}
                                    sx={{
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1.5,
                                        },
                                    }}
                                />
                            </div>

                        </Grid>
                        {open && ( // Render loading indicator when open is true
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        )}

                    </Paper>
                    <Dialog open={open_popup} onClose={() => setopenPopup(false)} fullWidth
                        maxWidth="md">
                        <DialogTitle>Note</DialogTitle>
                        <DialogContent>
                            <IconButton
                                style={{ position: "absolute", top: "0", right: "0" }}
                                onClick={() => setopenPopup(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Textarea minRows={5} id="outlined-basic" label="หมายเหตุ" variant="outlined" size="small" value={note} onChange={(e) => setNote(e.target.value)} />
                            <br></br>
                            <Button style={{ float: 'right' }} variant="contained" onClick={(e) => handleEditNote(e)}>บันทึก</Button>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </>
    )
}