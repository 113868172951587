import React, { useEffect } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
const moment = require('moment');
const CustomerForm = ({ data, ncb }) => {
    const styles = {
        container: {
            fontFamily: 'Arial, sans-serif',
            margin: '20px auto',
            padding: '20px',
            width: '210mm',
            height: '297mm',
            boxSizing: 'border-box',
            fontSize: '12px',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            flexWrap: 'wrap',
            marginBottom: '10px',
        },
        label: {
            marginRight: '5px',
        },
        header_label: {
            marginRight: '5px',
            textDecoration: 'underline',
        },
        value: {
            borderBottom: '1px solid #000',
            padding: '0 5px',
            marginRight: '10px',
            minWidth: '50px',
            flexGrow: 1,
        },
        labelContainer: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
        },
        wideLabel: {
            marginRight: '5px',
            textDecoration: 'underline',
            flexGrow: 1,
        },
        bold: {
            fontWeight: 'bold',
        },
        noteSection: {
            borderTop: '1px solid #000',
            paddingTop: '5px',
            marginTop: '10px',
        },
        noteContent: {
            borderBottom: '1px solid #000',
            padding: '5px 0',
            marginBottom: '5px',
        },
        marginLabel: {
            marginLeft: '85px'
        }
    };

    const generatePDF = () => {
        const input = document.getElementById('customerForm');
        html2canvas(input, { scale: 4 }).then(canvas => {
            const imgData = canvas.toDataURL('image/jpeg');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('customer_form.pdf');
        });
    };
    const handleGeneratePDF = () => {
        generatePDF()
    }
    if (data.search_date) {
        const date = new Date(data?.search_date);
        var search_date = moment(date).format("YYYY-MM-DD");
    } else {
        var search_date = ''
    }
    if (data.birth_day) {
        const date = new Date(data?.birth_day);
        var birth_day = moment(date).format("YYYY-MM-DD");
    } else {
        var birth_day = ''
    }
    return (
        <div>
            <Button variant="contained" style={{ marginRight: '10px', float: 'right', marginBottom: '10px' }}  
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.button.backgroundColor}
                onClick={handleGeneratePDF}><GetAppIcon />Download</Button>
            <div id="customerForm" style={styles.container}>
                <div style={styles.row}>
                    <span style={styles.header_label}>รายการสำหรับ modify ลูกค้า</span>
                    <span style={styles.label}>วันที่สืบค้น</span>
                    <span style={styles.value}>{search_date}</span>
                    <span style={styles.label}>ชื่อผู้จัดทำเคส</span>
                    <span style={styles.value}>{data.case_maker}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>ลูกหนี้ชื่อ</span>
                    <span style={styles.value}>{data.firstname}{'  '}{data.lastname}</span>
                    <span style={styles.label}>อายุ</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '30px' }}>{ncb?.MSCW?.value_1?.value_1_4?.value_1_4_1}</span>
                        <span>ปี</span>
                    </div>
                    <span style={styles.label}>วุฒิ</span>
                    <span style={styles.value}>{data.educational_bg}</span>
                    <span style={styles.label}>วันเกิด</span>
                    <span style={styles.value}>{birth_day}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>สถานะภาพ</span>
                    <span style={styles.value}>{data.status}</span>
                    <span style={styles.label}>บุตร</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '30px' }}>{data.number_of_children}</span>
                        <span>คน</span>
                    </div>
                    <span style={styles.label}>เบอร์</span>
                    <span style={styles.value}>{data.phone}</span>
                    <span style={styles.label}>เลขบัตรปชช</span>
                    <span style={styles.value}>{data.id_number}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>ที่ทำงาน</span>
                    <span style={styles.value}>{data.workplace}</span>
                    <span style={styles.label}>ประเภทธุรกิจ</span>
                    <span style={styles.value}>{data.type_of_business}</span>
                    <span style={styles.label}>เบอร์ HR</span>
                    <span style={styles.value}>{data.hr_phone}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>ตำแหน่ง</span>
                    <span style={styles.value}>{data.position}</span>
                    <span style={styles.label}>บรรยายหน้าที่งาน</span>
                    <span style={styles.value}>{data.describe_job_duties}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>อายุงาน</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '30px' }}>{data.work_age}</span>
                        <span>ปี</span>
                    </div>
                    <span style={styles.label}>จังหวัด</span>
                    <span style={styles.value}>{data.province_form}</span>
                    <span style={styles.label}>ช่องทาง</span>
                    <span style={styles.value}>{data.marketing_channel}</span>
                    <span style={styles.label}>ประเภทลูกค้า</span>
                    <span style={styles.value}>{data.old_customer_history}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>ความแข็งแรงของนายจ้าง</span>
                    <span style={styles.value}>{data.employee_strength}</span>
                    <span style={styles.label}>ลักษณะงาน</span>
                    <span style={styles.value}>{data.job_description}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>รายได้จากเงินเดือน</span>
                    <span style={styles.label}>เงินเดือน</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '250px' }}>{data.base_salary}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>รายได้พิเศษ</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '250px' }}>{data.extra_income}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>ค่าคอม</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '250px' }}>{data.commission}</span>
                        <span>บาท</span>
                    </div>
                    <span style={styles.label}>ค่าคอม1</span>
                    <span style={styles.value}>{data.commission_1}</span>
                    <span style={styles.label}>ค่าคอม2</span>
                    <span style={styles.value}>{data.commission_2}</span>
                    <span style={styles.label}>ค่าคอม3</span>
                    <span style={styles.value}>{data.commission_3}</span>
                </div>
                <div style={styles.row}>
                    <span style={{ ...styles.label, ...styles.bold }}>รวมสุทธิ</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '150px' }}>{data.sum_commission}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={styles.header_label}>รายการหักเงินจากสลิปเงินเดือน</span>
                    <span style={styles.label}>เงินเดือน</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '150px' }}>{data.salary}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={{ ...styles.label, ...styles.marginLabel }}>หัก</span>
                    <span style={styles.value}>{data.salary_slip_deducted_detail_1}</span>
                    <span style={styles.value}>{data.salary_slip_deducted_amount_1}</span>
                    <span style={styles.label}>บาท</span>
                </div>
                <div style={styles.row}>
                    <span style={{ ...styles.label, ...styles.marginLabel }}>หัก</span>
                    <span style={styles.value}>{data.salary_slip_deducted_detail_2}</span>
                    <span style={styles.value}>{data.salary_slip_deducted_amount_2}</span>
                    <span style={styles.label}>บาท</span>
                </div>
                <div style={styles.row}>
                    <span style={{ ...styles.label, ...styles.marginLabel }}>หัก</span>
                    <span style={styles.value}>{data.salary_slip_deducted_detail_3}</span>
                    <span style={styles.value}>{data.salary_slip_deducted_amount_3}</span>
                    <span style={styles.label}>บาท</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>รวมรายการที่หัก</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '150px' }}>{data.expense_item}</span>
                        <span>บาท</span>
                    </div>
                    <span style={styles.label}>รับเงินสุทธิ</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '150px' }}>{data.receive_net_money}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={styles.header_label}>ค่าใช้จ่ายส่วนตัว</span>
                    <span >1. ค่าใช้จ่ายส่วนตัว</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '100px' }}>{data.personal_expenses}</span>
                        <span>บาท</span>
                    </div>
                    <span style={styles.label}>ค่าใช้จ่ายส่วนตัวที่ลูกค้าแจ้ง</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '100px' }}>{data.city}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={{ ...styles.label, ...styles.marginLabel }}>2. บุตร</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '100px' }}>{ncb?.MSCW?.child_expenses}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={{ ...styles.label, ...styles.marginLabel }}>3. ค่าเช่าบ้าน</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '100px' }}>{data.house_rent}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={{ ...styles.label, ...styles.marginLabel }}>3. รายการหักจากstm</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '100px' }}>{data.dept_in_stm}</span>
                        <span>บาท</span>
                    </div>
                    <span style={styles.label}>(นำมาใส่ในช่องรายการหัก)</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>ค่าใช้จ่ายส่วนตัว สุทธิ</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '150px' }}>{data.net_personal_expenses}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>NCB</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '150px' }}>{ncb?.credit_card_detail?.total_account}</span>
                        <span>บัญชี</span>
                    </div>
                    <span style={styles.label}>เปิด</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '100px' }}>{ncb?.credit_card_detail?.total_active_account}</span>
                        <span>บัญชี</span>
                    </div>
                    <span style={styles.label}>คงเหลือที่หมุนเวียนได้</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '100px' }}>{ncb?.credit_card_information?.amount_can_withdrawn_after_deduction}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>ค่าใช้จ่าย NCB สุทธิ</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '150px' }}>{ncb?.other_detail?.installment}</span>
                        <span>บาท</span>
                    </div>
                    <span style={styles.label}>อายุเครดิต</span>
                    <span style={styles.value}>{ncb?.credit_card_detail?.credit_age}</span>
                    <span style={styles.label}>จำนวนเดือนที่ไม่เสีย</span>
                    <span style={styles.value}>{ncb?.MSCW?.value_4?.value_4_2?.value_4_2_1}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>อายุบัญชีที่ปัจจุบันปกติยาวที่สุด</span>
                    <span style={styles.value}>{ncb?.MSCW?.value_4?.value_4_3?.value_4_3_1}</span>
                    <span style={styles.label}>บัญชีที่เสีย NCB</span>
                    <span style={styles.value}>{ncb?.MSCW?.value_4?.value_4_5?.value_4_5_1}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>เหลือเงินต่อเดือน</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '150px' }}>{data.money_left}</span>
                        <span>บาท</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>วงเงินเสนอครั้งนี้</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '50px' }}>{data.offer_limit}</span>
                        <span>บาท /</span>
                    </div>
                    <span style={styles.label}>ผ่อน</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '50px' }}>{data.estimate_installment_amount}</span>
                        <span>บาท /</span>
                    </div>
                    <span style={styles.label}>งวดผ่อน</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '50px' }}>{data.installment_offered}</span>
                        <span>งวด /</span>
                    </div>
                    <span style={styles.label}>จำนวนงวดหัก</span>
                    <span style={styles.value}>{data.less_offer}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>มีการค้าง</span>
                    <div style={styles.labelContainer}>
                        <span style={{ ...styles.value, minWidth: '150px' }}>{ncb?.MSCW?.value_4?.value_4_4?.value_4_4_1}</span>
                        <span>กี่บัญชี</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>ข้อมูลการค้างชำระ</span>
                    <span style={styles.value}>{data.note_ncb}</span>
                </div>
                <div style={styles.noteSection}>
                    <span style={{ ...styles.label, ...styles.bold }}>หมายเหตุ</span>
                    <div style={styles.noteContent}>{data.note}</div>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>วงเงินเดิม</span>
                    <span style={styles.value}>{data.original_limit}</span>
                    <span style={styles.label}>ยอดหนี้คงเหลือ</span>
                    <span style={styles.value}>{data.pico_debt_balance}</span>
                    <span style={styles.label}>จำนวนงวดผ่อนเดิม</span>
                    <span style={styles.value}>{data.original_installments}</span>
                    <span style={styles.label}>จำนวนงวดคงเหลือ</span>
                    <span style={styles.value}>{data.installment_remaining}</span>
                    <span style={styles.label}>ยอดผ่อนเก่า</span>
                    <span style={styles.value}>{data.pico_old_installment}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>หนี้นอกระบบรวม</span>
                    <span style={styles.value}>{data.total_informal_debt}</span>
                    <span style={styles.label}>หนี้นอกระบบต่อเดือน</span>
                    <span style={styles.value}>{data.informal_debt}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>คู่สมรสช่วยค่าใช้จ่ายครอบครัวเท่าไหร่(ถ้ามี)</span>
                    <span style={styles.value}>{data.help_the_family}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>เงินเดือนคู่สมรส ?</span>
                    <span style={styles.value}>{data.spouse_income}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>ความสามารถในการผ่อน</span>
                    <span style={styles.value}>{data.ability_to_pay}</span>
                </div>
                {/* <div style={styles.row}>
                    <span style={styles.label}>รับเงินเดือน (ครั้ง)</span>
                    <span style={styles.value}>{data.amount_getsalary}</span>
                </div> */}
                <div style={styles.row}>
                    <span style={styles.label}>รับเงินเดือน (ครั้ง)</span>
                    <span style={styles.value}>{data.amount_getsalary}</span>
                    <span style={styles.label}>วันที่รับเงินเดือน ครั้งที่ 1</span>
                    <span style={styles.value}>{data.date_getsalary_1}</span>
                    <span style={styles.label}>วันที่รับเงินเดือน ครั้งที่ 2</span>
                    <span style={styles.value}>{data.date_getsalary_2}</span>
                    <span style={styles.label}>วันที่รับเงินเดือน ครั้งที่ 3</span>
                    <span style={styles.value}>{data.date_getsalary_3}</span>
                    <span style={styles.label}>วันที่รับเงินเดือน ครั้งที่ 4</span>
                    <span style={{ ...styles.value, minWidth: '150px' }}>{data.date_getsalary_4}</span>
                </div>
                {/* <div style={styles.row}>
                    <span style={styles.value}></span>
                </div> */}
                <div style={styles.row}>
                    <span style={styles.label}>วัตถุประสงค์</span>
                    <span style={styles.value}>{data.loan_purpose}</span>
                    <span style={styles.label}>วงเงินที่ขอ</span>
                    <span style={styles.value}>{data.requested_limit}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>สถานะบ้าน</span>
                    <span style={styles.value}>{ncb?.home_car_loan_detail?.total_home_loan}</span>
                    <span style={styles.label}>สถานะรถ</span>
                    <span style={styles.value}>{ncb?.home_car_loan_detail?.total_car_loan}</span>
                </div>
            </div>
        </div>
    );
};

export default CustomerForm;
