/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
// import { checkPermission,getDecodedToken } from '../../utils/permission_utils.js';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { MdOpenInNew } from 'react-icons/md';

import test from '../../assets/datas/data.json';

import * as StatementHttpServices from '../../services/statement_service.js';
// rows
const datatest = test.data[0]
// console.log('datatest', datatest)

// หาเดือน
// const monthsArray = datatest.statement_data[0].total.map(item => item.month);

const monthsArray = []

// console.log('monthsArray', monthsArray)

export default function App() {

    const [data, setData] = useState('')
    const contact = {
        contact_id: '77101'
    }
    const location = useLocation();
    // let decode = getDecodedToken()
    // let hasPermission = checkPermission(location.pathname,decode);

    useEffect(() => {
        // if(!hasPermission){
        //     window.location.href = '/404';
        // }
        StatementHttpServices.calculatStatement(contact)
            .then((res) => {
                console.log('res', res.data.data.data[0])
                const statementData = res.data.data.data[0]



                // setData(res.data.data.data[0])



            })
    }, [])

    let token = localStorage.getItem('token')
    let decoded

    // useState
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // const dataTest = data.data[0]

    const monthAbbreviations = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    const today = new Date()
    const monthNumber = today.getMonth() + 1;
    const monthString = monthAbbreviations[monthNumber - 1]; // month names array is zero-based

    var date = today.getDate() + '-' + monthString + '-' + today.getFullYear();

    const getMonthName = (monthNumber) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[monthNumber - 1] || '';
    };

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // style model
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        //   border: '2px solid #000',
        //   boxShadow: 24,
        p: 4,
        borderRadius: 1
    };

    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'date',
            headerName: 'วันที่',
            width: 100,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: '0',
            headerName:
                <div className='centered-header'>
                    <span>เงินคงเหลือ</span>
                    <span>บัญชีทั้งหมด</span>
                </div>,
            headerClassName: 'table-header',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: '1',
            headerName:
                <div className='centered-header'>
                    <span>เงินเข้า</span>
                    <span>บัญชีทั้งหมด</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '2',
            headerName:
                <div className='centered-header'>
                    <span>หมายเหตุ</span>
                    <span>บัญชีทั้งหมด</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: '3',
            headerName:
                <div className='centered-header'>
                    <span>เงินคงเหลือ</span>
                    <span>บัญชีที่ 1</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '4',
            headerName:
                <div className='centered-header'>
                    <span>เงินเข้า</span>
                    <span>บัญชีที่ 1</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '5',
            headerName:
                <div className='centered-header'>
                    <span>หมายเหตุ</span>
                    <span>บัญชีที่ 1</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '6',
            headerName:
                <div className='centered-header'>
                    <span>เงินคงเหลือ</span>
                    <span>บัญชีที่ 2</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '7',
            headerName:
                <div className='centered-header'>
                    <span>เงินเข้า</span>
                    <span>บัญชีที่ 2</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '8',
            headerName:
                <div className='centered-header'>
                    <span>หมายเหตุ</span>
                    <span>บัญชีที่ 2</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '9',
            headerName:
                <div className='centered-header'>
                    <span>เงินคงเหลือ</span>
                    <span>บัญชีที่ 3</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '10',
            headerName:
                <div className='centered-header'>
                    <span>เงินเข้า</span>
                    <span>บัญชีที่ 3</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '11',
            headerName:
                <div className='centered-header'>
                    <span>หมายเหตุ</span>
                    <span>บัญชีที่ 3</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '12',
            headerName:
                <div className='centered-header'>
                    <span>เงินคงเหลือ</span>
                    <span>บัญชีที่ 4</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '13',
            headerName:
                <div className='centered-header'>
                    <span>เงินเข้า</span>
                    <span>บัญชีที่ 4</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: '14',
            headerName:
                <div className='centered-header'>
                    <span>หมายเหตุ</span>
                    <span>บัญชีที่ 4</span>
                </div>,
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: '',
        //     headerName: 'Action',
        //     width: 60,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderHeader: (params) => (
        //         <MdOpenInNew onClick={() => handleOpen()} fontSize={20} />
        //     ),
        // },

        // {
        // field: '',
        // headerName: '',
        // description: 'This column has a value getter and is not sortable.',
        // sortable: false,
        // type: 'number',
        // width: 160,
        // valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    const AccordionItem = (m) => {
        console.log('>>>',m)
        const month = m.month

        let statementArray = []
        datatest.statement_data.map(value => {
            Object.entries(value).map((value2) => {
                Object.entries(value2[1]).map((value3) => {
                    if (value3[1].month === month) {
                        Object.entries(value3[1].data_2).map((value4) => {
                            statementArray = [...statementArray, value4[1]]
                        })
                    }
                })
            })
        })

        console.log('statementArray>>>',statementArray)

        const result = statementArray.reduce((accumulator, obj) => {
            Object.values(obj).forEach(value => {
                accumulator.push(value);
            });
            return accumulator;
        }, [])

        const values = Object.values(statementArray).map(item => Object.values(item));

        const resultMap = new Map();

        values.forEach(item => {
            const date = item[0];
            const values = item.slice(1);

            if (resultMap.has(date)) {
                const existingValues = resultMap.get(date);
                resultMap.set(date, [...existingValues, ...values]);
            } else {
                resultMap.set(date, values);
            }
        });

        const newArray = Array.from(resultMap.entries());

        const resultArray = newArray.map(([date, values], id) => {
            const flatValues = values.flat();
            return { id, date, ...flatValues };
        });

        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`accordion-summary-${month}`}>
                    <Typography>{getMonthName(month)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MyDataGrid rows={resultArray} />
                </AccordionDetails>
            </Accordion>
        );
    };

    const calculateTableHeight = (rows) => {
        // Assuming rowHeight is a fixed value for each row
        const rowHeight = 60; // Adjust this value based on your styling
        const headerHeight = 100; // Adjust this value based on your styling

        // Calculate the total height based on row count and other elements' height
        const rowCount = rows.length;
        const totalHeight = rowCount * rowHeight + headerHeight;

        return totalHeight;
    };

    const MyDataGrid = (dataRows) => {
        const rows = dataRows.rows


        const keyCount = Object.keys(rows[0]).length;
        const visibleColumns = columns.slice(0, keyCount); // Hide the column

        return (
            <>
                <Box
                    sx={{
                        height: calculateTableHeight(rows),
                        // height: 300,
                        maxHeight: 630,
                        width: '100%',
                        '& .green': {
                            backgroundColor: '#01ff00',
                            color: '#01ff00',
                        },
                        '& .yellow': {
                            backgroundColor: '#feff00',
                            color: '#feff00',
                        },
                        '& .red': {
                            backgroundColor: '#ff0000',
                            color: '#ff0000',
                        },
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={visibleColumns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        // checkboxSelection
                        disableRowSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1.5,
                            },
                        }}
                        getCellClassName={(params) => {
                            if ((params.field !== '2' && params.field !== '5') || params.value == null) {
                                return '';
                            }
                            return params.value > 1500 ? 'green' : params.value > 500 ? 'yellow' : 'red';
                        }}
                    />
                </Box>
            </>
        )
    }

    console.log('data:', data)
    // return
    if (false) {
        return (<>
        </>)
    } else {
    
        return (
            <>
            <div className='statement-analysis' style={{ margin: '2rem' }}>
                <SideBar />
                <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                    <Grid item xs>
                        <Typography variant='h4'>รายละเอียด Statement ของ {data.account_name} รอบระหว่างวันท่ี {data.account_period}</Typography>
                    </Grid>

                    <Button variant="contained" style={{ textTransform: 'none', marginRight: 15 }} onClick={() => handleOpen()} disabled>Report</Button>
                    <Button variant="contained" style={{ textTransform: 'none' }} disabled>Edit Statement</Button>
                </Grid>

                <div>
                    {monthsArray.map((month, index) => (
                        <AccordionItem key={index} month={month} />
                    ))}
                </div>

                {/* model report statement*/}
                {/* <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            รายงาน Statement ส่วนสรุป
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <Box
                                sx={{
                                    height: 370,
                                    width: '100%',
                                    '& .green': {
                                        backgroundColor: '#01ff00',
                                        color: '#1a3e72',
                                    },
                                    '& .yellow': {
                                        backgroundColor: '#feff00',
                                        color: '#1a3e72',
                                    },
                                    '& .red': {
                                        backgroundColor: '#ff0000',
                                        color: '#1a3e72',
                                    },
                                }}
                            >
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    // checkboxSelection
                                    disableRowSelectionOnClick
                                    sx={{
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1.5,
                                        },
                                    }}
                                    getCellClassName={(params) => {
                                        if ((params.field !== 'col4' && params.field !== 'col7' && params.field !== 'col10' && params.field !== 'col13' && params.field !== 'col16') || params.value == null) {
                                            return '';
                                        }
                                        return params.value === 1 ? 'green' : params.value === 2 ? 'yellow' : 'red';
                                    }}
                                />
                            </Box>
                        </Typography>
                    </Box>
                </Modal> */}
            </div>
        </>
        )
    }
}


///