import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Textarea } from '@mui/joy';
import { TextareaAutosize } from '@mui/material';

export default function StatementAdditionalDialog({ handleClose, additional, onChange }) {
  const [additionalState, setAdditionalState] = useState(additional)
  const [note, setNote] = useState(additional?.note)

  const handleSave = useCallback(() => {

    onChange?.({
      ...additionalState,
      note: note,
    })
  }, [additionalState, note, onChange])

  const handleCancel = () => {
    handleClose()
  }

  const isOpen = useMemo(() => {
    return additional !== null && additional !== undefined
  }, [additional])

  useEffect(() => {
    setNote(additional?.note)
  }, [additional])
  useEffect(() => {
    setAdditionalState(additional)
  }, [additional])

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <h2>ข้อมูลหมายเหตุ</h2>
          <TextareaAutosize
            minRows={6}
            cols={60}
            value={note}
            onChange={(e) => setNote(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>SAVE</Button>
          <Button onClick={handleCancel}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
