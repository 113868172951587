/* eslint-disable react-hooks/exhaustive-deps */

import Button from '@mui/material/Button';
import * as React from 'react';
import { useState, useEffect } from 'react';
import SideBar from '../dashboard'
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert';

import Loading from '../../components/loading';
import FormDialog from './formDialog';
import * as ncbHttpServices from '../../services/ncb_services';

function App() {
    const [data, setData] = useState()
    const [table, setTable] = useState()
    const [rows, setRows] = useState([]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        const MAX_UPLOADS = 4; // Define a constant for the maximum number of uploads.
        if (table.length < MAX_UPLOADS) {
            setOpen(true);
        } else {
            const alertMessage = `ขณะนี้สามารถอัปโหลดไฟล์ NCB ได้สูงสุดที่ ${MAX_UPLOADS} ไฟล์`;
            alert(alertMessage);
        }
    }
    const handleClose = () => setOpen(false);

    var { contact_id } = useParams();

    const getToken = () => {
        let token = localStorage.getItem('token_org')
        return token
    }

    useEffect(() => {
        ncbHttpServices.showUser(contact_id, getToken())
            .then((res) => {
                setTable(res.data.table)

                const new_obj = res.data.table.map((item, index) => {
                    const obj = {
                        "id": index,
                        "enquiryDate": item.enquiryDate,
                        "uploadDate": item.uploadDate,
                        "name": item.name,
                        "file": item.file,
                        "order": item.order,
                    }
                    return obj
                })

                setRows(new_obj)
                setData(res.data.data)
            })
    }, [])

    function handleSummarize() {
        window.location.href = "/ncb/summarize/" + contact_id
    }

    function handleDel() {
        confirmAlert({
            title: 'ลบข้อมูล',
            message: 'คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูล NCB ทั้งหมด',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        ncbHttpServices.del(contact_id, getToken())
                            .then(res => {
                                console.log(res)
                                window.location.reload(true);
                            })
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("cancel")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
    }

    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'order',
            headerName: 'ไฟล์ที่',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'enquiryDate',
            headerName: 'วันที่สือค้น',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'uploadDate',
            headerName: 'วันที่อัปโหลด',
            width: 250,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => row.uploadDate.slice(0, 10),
        },
        {
            field: 'name',
            headerName: 'ชื่อ-สกุล',
            flex: 1,
            minWidth: 350,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'file',
            headerName: 'ไฟล์ NCB',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: "col7",
            headerName: "รายละเอียด",
            width: 250,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ row }) =>
                <Button onClick={() => { console.log('>>>>', row); window.open('/ncb/detail_' + row.order + '/' + row.file.split(/[ .]/)[0]) }}>
                    รายละเอียด
                </Button>,
        }
    ];


    if (!data) return <Loading />

    return (
        <>
            <div style={{ margin: '2rem' }}>
                <SideBar />
                <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                    <Grid item xs>
                        <Typography variant='h4' style={{ fontWeight: '' }}>NCB File</Typography>
                    </Grid>
                </Grid>

                <Paper elevation={3} >
                    <Grid container padding={2} marginBottom={1}>
                        <Grid item xs>
                            <Button variant="contained" onClick={() => handleOpen()}>อัปโหลดไฟล์ NCB</Button>
                        </Grid>
                        <Grid item paddingLeft={2}>
                            <Button variant="contained" onClick={() => handleDel()} disabled={!rows.length}>ลบข้อมูล NCB ทั้งหมด</Button>
                        </Grid>
                        <Grid item paddingLeft={2}>
                            <Button variant="contained" onClick={() => handleSummarize()} disabled={!rows.length}>สรุปผล</Button>
                        </Grid>
                    </Grid>
                    <Box>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            sx={{
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    textOverflow: "clip",
                                    whiteSpace: "break-spaces",
                                    lineHeight: 1.5,
                                },
                            }}
                            autoHeight
                        />
                    </Box>
                </Paper>

                <FormDialog open={open} handleClose={handleClose} handleOpen={handleOpen} contact_id={contact_id} table={table} />
            </div >
        </>
    );

}
export default App;
