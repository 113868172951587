import jwt_decode from "jwt-decode";

export const checkPermission = (pathname, decoded) => {
    const menu_list = decoded.menu_list;
    const permission = decoded.permis_list;
    const menu = menu_list.find(menu => menu.menu_uri === pathname);
    
    const permis_value = permission.some(permis => permis.sub_menu_id === menu.menu_id && permis.permis_value === 1);

    return menu.access && permis_value;
};

export const getToken = () => {
    const token = localStorage.getItem('token');
    if (token === null) {
        window.location.href = '/login';
        return null;
    }
    return token;
};

export const getDecodedToken = () => {
    const token = getToken();
    if (token) {
        return jwt_decode(token);
    }
    return null;
};
