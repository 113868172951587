/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import * as directDebitServices from '../../services/direct_debit';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from "material-ui-confirm";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from '@mui/material/Button';
import ModelChangeBank from '../../components/modal/Bank'

const headCells = [
   
    {
        id: 'contract_no',
        disablePadding: false,
        width: 220,
        label: 'เลขที่สัญญา'
    },

    {
        id: 'name',
        disablePadding: false,
        width: 180,
        label: 'ชื่อ-นามสกุล',
    },
    {
        id: 'id_card',
        disablePadding: false,
        width: 180,
        label: 'เลขบัตรประชาชน',
    },
    {

        id: 'phone_number',
        disablePadding: false,
        width: 180,
        label: 'เบอร์โทรศัพท์',
    },
    {
        id: 'action',
        width: 80,
        label: 'Action',
    },
    {
        id: 'date_receipt',
        disablePadding: false,
        width: 200,
        label: 'วันที่แก้ไขล่าสุด',
    },

];
function MyApp() {
    const navigate = useNavigate();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('collection_date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [rows_data, setRowData] = React.useState([]);
    const [assign_date, setAssignDate] = React.useState('');
    const [opened, setOpened] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [status, setStatus] = React.useState(false)
    const [rowsPerFilter, setRowsPerFilter] = React.useState(1)
    const [value, setValue] = React.useState([]);
    const [tag, setTag] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [open_report, setOpenReport] = React.useState(false);
    const [action_report, setActionReport] = React.useState(false);
    const [start_report, setStartReport] = React.useState(new Date());
    const [end_report, setEndReport] = React.useState(new Date());
    const [openModelBank, setOpenModelBank] = useState(false);

    const [open_assign, setOpenAssign] = React.useState(false);
    const [contract, setContarct] = React.useState(null);
    const handleClose = () => setOpen(false);
    const handleCloseReport = () => setOpenReport(false);
    const handleCloseAssign = () => setOpenAssign(false);
    let token = localStorage.getItem('token_org')

    const { enqueueSnackbar } = useSnackbar();

    //filter
    const [operator, setOperater] = React.useState([true, true, true, true, true, true, true])
    const [filter, setFilter] = React.useState(true)
    // console.clear();

    const [name_filter, setNameFilter] = React.useState('');
    const formRef = React.useRef();


    useEffect(() => {
        setRows([])
        setStatus(false)
        setSelected([])
        setTag(false)

        let test = []

        let object = {
            "pagination": {
                "page": 1,
                "rowsPerPage": 10000,
                "sortBy": "",
                "descending": true
            },
            "filters": test
        }

        // console.log('filter', object)

        directDebitServices.getUserRegisDirectDebit()
            .then((res) => {
                setRows([])
                if (res.data.data.length == 0 || res.data.data == 'null') {
                    setRows([])
                } else {
                    setStatus(true)
                    setRows(res.data.data)
                }

            })
    }, [token])

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            // checked={rowCount > 0 && numSelected === rowCount}
                            // onChange={onSelectAllClick}
                            checked={tag}
                            onChange={(_, tag) => {
                                setTag(tag)
                                onSelectAllClick(_, tag)
                            }}

                            inputProps={{
                                'aria-label': 'select all',
                            }}
                        // disabled
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align='left'
                            padding='normal'
                            width={headCell.width}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // console.log(selected)
            const newSelecteds = stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => n.id);

            let withoutDuplicates = [...new Set(selected.concat(newSelecteds))];

            // console.log(withoutDuplicates)
            setSelected(withoutDuplicates);

            return;
        }

        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleChangeOperator = (index) => (e) => {
        let newOperator = []
        newOperator = [...operator]
        newOperator[index] = e.target.value
        setOperater(newOperator)
    }

    const delFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
        if (rowsPerFilter > 1) {
            setRowsPerFilter(rowsPerFilter - 1)
        }
    }

    const resetFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
    }
    const onClickFilterAction = () => {
        if (name_filter != '') {
            var filter_row = rows.filter(function (row) {
                return row.name.includes(name_filter) || row.surname.includes(name_filter);
            });
            setRows(filter_row)
        } else {
            directDebitServices.getUserRegisDirectDebit(token)
                .then((res) => {
                    // console.log(res)
                    if (res.data.data.length == 0 || res.data.data == 'null') {
                        // console.log('test')
                        setRows([])
                    } else {
                        // console.log('test2')
                        setStatus(true)
                        setRows(res.data.data)
                    }

                })
        }
    }
    const resetFilter = (valueIndex) => {
        switch (valueIndex) {
            case 1:
                directDebitServices.getUserRegisDirectDebit(token)
                    .then((res) => {
                        // console.log(res)
                        if (res.data.data.length == 0 || res.data.data == 'null') {
                            // console.log('test')
                            setRows([])
                        } else {
                            // console.log('test2')
                            setStatus(true)
                            setRows(res.data.data)
                        }

                    })
                break;
            default:
        }
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const filterGrid = (index) => {
        return (
            <>
                <Grid item >
                    <FormControl style={{ width: 300 }} >
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value[index] === undefined ? '' : value[index]}
                            label="Filter"
                            onChange={valueX(index)}
                        >
                            {/* <MenuItem value={1}>เลขบัตรประชาชน</MenuItem> */}
                            <MenuItem value={1}>ชื่อ</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {filterActionGrid(index)}

                {
                    rowsPerFilter !== index + 1 ?
                        <Grid item>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                                <Select
                                    style={{ width: 100 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={operator[index]}
                                    label="Operator"
                                    onChange={handleChangeOperator(index)}
                                >
                                    <MenuItem value={true}>และ</MenuItem>
                                    <MenuItem value={false}>หรือ</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        :
                        ''
                }

                {
                    value[index] !== undefined ?
                        <Grid item>
                            <Tooltip title="ล้างตัวกรอง">
                                <IconButton onClick={() => resetFilterAction(index)}>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        :
                        ''
                }
                <Grid item marginRight={10}>
                    <Tooltip title="ลบตัวกรองข้อมูล">
                        <IconButton onClick={() => delFilterAction(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        )
    }
    const filterActionGrid = (index) => {
        if (value[index] == 1) {
            return (
                <>
                    <Grid item>
                        <TextField id="outlined-basic" label="Name" variant="outlined" value={name_filter} onChange={nameFilter} />
                    </Grid>
                </>
            )
        }
    }
    const nameFilter = (event) => {
        setNameFilter(event.target.value)
    }
    const valueX = (index) => (event) => {
        const filtered = value.filter(obj => {
            return obj === event.target.value;
        });
        if (filtered.length === 0) {

            resetFilter(value[index])

            let newValue = []
            newValue = [...value]
            newValue[index] = event.target.value
            setValue(newValue)
        } else {

        }
    };

    const handleOpenModelBank = (row) => {
        setContarct(row.contract_no === null ? '' : row.contract_no)
        setRowData(row)
        setOpenModelBank(true);
    };
    const handleCloseModelBank = () => {
        setOpenModelBank(false);
    };
    return (
        <Box sx={{ width: '100%' }}>

            <Paper sx={{ width: '100%', mb: 2 }}>
                <Grid display={opened ? 'none' : ''}>
                    {
                        rowsPerFilter > 4 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(4)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 3 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(3)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 2 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(2)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 1 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(1)}
                            </Grid>
                            : null
                    }
                    <Grid container spacing={1} style={{ padding: '1rem' }}>
                        {filterGrid(0)}
                        <Grid item >
                            <Button variant="contained" onClick={() => onClickFilterAction()}>ค้นหา</Button>
                        </Grid>
                    </Grid>
                </Grid>

                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table
                        // sx={{ minWidth: 750 }}
                        // aria-labelledby="tableTitle"
                        // size='medium'
                        stickyHeader aria-label="sticky table"
                    >
                        {/* <Table stickyHeader aria-label="sticky table"> */}
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            {/* {console.log(row.status, row.approve_date)} */}
                                            <TableCell>{row.contract_no == null ? "-" : row.contract_no}</TableCell>
                                            <TableCell>{row.name + " " + row.last_name}</TableCell>
                                            <TableCell>{row.id_card}</TableCell>
                                            <TableCell>{row.phone}</TableCell>
                                             <TableCell > <Select
                                                // value={row.action_type}
                                                // onChange={handleChange}
                                                size="small"
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                style={{ width: 150 }}
                                            >
                                                <MenuItem><em>Action</em></MenuItem>
                                                <MenuItem value={0} onClick={() => handleOpenModelBank(row)}>เลือก / เปลี่ยน ธนาคาร</MenuItem>
                                            </Select></TableCell>
                                            <TableCell>{row.update_date == null ? "-" : row.update_date.split("T")[0]}</TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 300, 500,]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <ModelChangeBank
                open={openModelBank}
                close={handleCloseModelBank}
                data={rows_data}
            />

        </Box>
    );
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={1}>
            <MyApp />
        </SnackbarProvider>
    );
}
