/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

import TextField from '@mui/material/TextField';

// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';


// components
// import MyComponent from '/Users/sarawut/bof-digital-app/src/components/filter.js';

export default function App() {
    const [search, setSearch] = useState('');
    const location = useLocation();

    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
    const hasPermission = checkPermission(location.pathname,decoded)
    // backdrop
    const [open, setOpen] = useState(true);
    useEffect(() => {
        if(!hasPermission){
            window.location.href = '/404'
        }
    },[])
    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 250,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'col2',
            headerName: 'เลขที่สัญญา',
            width: 250,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'col3',
            headerName: 'Contract ID',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'col4',
            headerName: 'ชื่อ-สกุล',
            width: 250,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'col5',
            headerName: 'วงเงิน',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'col6',
            headerName: 'ผลการพิจารณา',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            // field: 'col7',
            // headerName: 'File Statement',
            // width: 250,
            // editable: true,
            // headerAlign: 'center',
            // align: 'center',
            // renderCell: ({ row }: Partial<GridRowParams>) =>
            // <Button onClick={() => yourActionFunction(row)}>
            //   Action
            // </Button>,
            field: "col7",
            headerName: "File Statement",
            width: 250,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ row }) =>
                <Button onClick={() => { console.log(row); window.open('/statement/files_list') }}>
                    Statement
                </Button>,
        },

        // {
        //     field: '',
        //     headerName: 'Action',
        //     width: 60,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderHeader: (params) => (
        //         <MdOpenInNew onClick={() => handleOpen()} fontSize={20} />
        //     ),
        // },

        // {
        // field: '',
        // headerName: '',
        // description: 'This column has a value getter and is not sortable.',
        // sortable: false,
        // type: 'number',
        // width: 160,
        // valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    // rows
    const rows = [
        { id: 1, col2: '65-10-03-4295', col3: 'นางสาวณัฐพร เข็มโรจน์', col4: 'สินเชื่อรายย่อยทั่วไป', col5: '10000', col6: '10', col7: '2023-05-12' },
        // { id: 2, col2: '65-10-03-4294', col3: 'นางสาวอรนุช จันทธธรรมโม', col4: 'สินเชื่อรายย่อยทั่วไป', col5: '20000', col6: '9', col7: '2023-05-12' },
        // { id: 3, col2: '65-10-03-4293', col3: 'นายโสภณ จิวะกุล', col4: 'สินเชื่อรายย่อยทั่วไป', col5: '10000', col6: '8', col7: '2023-02-28' },
        // { id: 4, col2: '65-10-03-4292', col3: 'นางสาวฐิตารีย์ จงมูม', col4: 'สินเชื่อรายย่อยทั่วไป', col5: '20000', col6: '9', col7: '2023-02-28' },
        // { id: 5, col2: 999999999, col3: 999999999, col4: 1, col5: 999999999, col6: 999999999, col7: 1, col8: 999999999, col9: 999999999, col10: 3, },
        // { id: 6, col2: 999999999, col3: 999999999, col4: 1, col5: 999999999, col6: 999999999, col7: 1, col8: 999999999, col9: 999999999, col10: 3, },
        // { id: 7, col2: 999999999, col3: 999999999, col4: 1, col5: 999999999, col6: 999999999, col7: 1, col8: 999999999, col9: 999999999, col10: 3, },
        // { id: 8, col2: 999999999, col3: 999999999, col4: 3, col5: 999999999, col6: 999999999, col7: 1, col8: 999999999, col9: 999999999, col10: 3, },
        // { id: 9, col2: 999999999, col3: 999999999, col4: 1, col5: 999999999, col6: 999999999, col7: 1, col8: 999999999, col9: 999999999, col10: 3, },
        // { id: 10, col2: 999999999, col3: 999999999, col4: 1, col5: 999999999, col6: 999999999, col7: 1, col8: 999999999, col9: 999999999, col10: 3, }
    ];

    const calculateTableHeight = (rows) => {
        // Assuming rowHeight is a fixed value for each row
        const rowHeight = 60; // Adjust this value based on your styling
        const headerHeight = 100; // Adjust this value based on your styling

        // Calculate the total height based on row count and other elements' height
        const rowCount = rows.length;
        const totalHeight = rowCount * rowHeight + headerHeight;

        return totalHeight;
    };


    return (
        <>
        {hasPermission && (
        <div className='customer-list' style={{ margin: '2rem' }}>
            <SideBar />
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: '' }}>Statement Customer List</Typography>
                </Grid>
            </Grid>
            {
                false ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ""
            }

            <Paper elevation={3} >
                <Grid container padding={2} marginBottom={1}>
                    <Grid item xs>
                        <Typography variant='h6' >ค้นหาสัญญา</Typography>
                    </Grid>
                    <Grid item>
                        <TextField id="outlined-basic" label="เลขบัตรประชาชน" variant="outlined" size="small" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </Grid>
                    <Grid item paddingLeft={2}>
                        <Button variant="contained">ค้นหา</Button>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        height: calculateTableHeight(rows),
                        maxHeight: 630,
                        width: '100%',
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1.5,
                            },
                        }}

                    />
                </Box>
            </Paper>
        </div>
        )}
        </>
    )
}