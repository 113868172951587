import * as React from 'react';
import { useNavigate } from "react-router-dom";
import * as userHttpServices from '../../services/services';
import * as tackingHttpServices from '../../services/tacking_service';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from "material-ui-confirm";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { CSVLink } from "react-csv";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,

};
const style_report = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const style_assign = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const headCells = [

    {
        id: 'name',
        disablePadding: false,
        width: 180,
        label: 'ชื่อ-นามสกุล',
    },
    {

        id: 'user_id',
        disablePadding: false,
        width: 180,
        label: 'เลขบัตรประชาชน',
    },
    {
        id: 'direct_debit',
        width: 100,
        label: 'Direct Debit',
    },
    {
        id: 'bank',
        width: 200,
        label: 'ธนาคาร',
    },
    {
        id: 'bank_no',
        width: 200,
        label: 'เลขที่บัญชี',
    },
    {
        id: 'upload',
        disablePadding: false,
        width: 200,
        label: 'Upload เอกสาร',
    },
    {
        id: 'sent',
        disablePadding: false,
        width: 200,
        label: 'ส่งเรื่องให้ฝ่ายสินเชื่อ',
    },
    {
        id: 'date',
        width: 180,
        label: 'เชื่อมกับ Hubspot',
    },
    {
        id: 'date',
        width: 180,
        label: 'วันที่',
    }

];
function MyApp() {
    const navigate = useNavigate();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('collection_date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [rows_data, setRowData] = React.useState([]);
    const [assign_date, setAssignDate] = React.useState('');
    const [opened, setOpened] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [status, setStatus] = React.useState(false)
    const [rowsPerFilter, setRowsPerFilter] = React.useState(1)
    const [value, setValue] = React.useState([]);
    const [tag, setTag] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [open_report, setOpenReport] = React.useState(false);
    const [action_report, setActionReport] = React.useState(false);
    const [start_report, setStartReport] = React.useState(new Date());
    const [end_report, setEndReport] = React.useState(new Date());

    const [open_assign, setOpenAssign] = React.useState(false);
    const [contract, setContarct] = React.useState(null);
    const handleClose = () => setOpen(false);
    const handleCloseReport = () => setOpenReport(false);
    const handleCloseAssign = () => setOpenAssign(false);
    let token = localStorage.getItem('token')
    let token_org = localStorage.getItem('token_org')

    const { enqueueSnackbar } = useSnackbar();

    //filter
    const [operator, setOperater] = React.useState([true, true, true, true, true, true, true])
    const [filter, setFilter] = React.useState(true)
    // console.clear();
    const confirm = useConfirm();
    const [date, setDate] = React.useState(new Date())
    const [test2, settest2] = React.useState('');
    const [test3, settest3] = React.useState('');
    const [test4, settest4] = React.useState('');
    const [test5, settest5] = React.useState('');
    const [test6, settest6] = React.useState('');
    const [test7, settest7] = React.useState([]);
    const [name_filter, setNameFilter] = React.useState('');
    const formRef = React.useRef();
    React.useEffect(() => {
        setRows([])
        setStatus(false)
        setSelected([])
        setTag(false)

        let test = []

        let object = {
            "pagination": {
                "page": 1,
                "rowsPerPage": 10000,
                "sortBy": "",
                "descending": true
            },
            "filters": test
        }

        console.log('filter', object)

        tackingHttpServices.getUserStatus(token_org, object)
        .then((res) => {
            // console.log(res)
            if (res.data.data.length == 0 || res.data.data == 'null') {
                setRows([])
            } else {
                setStatus(true)
                setRows(res.data.data)
                let export_data = []
                for (let row of res.data.data) {
                    let direct_debit_bank = 'Yes';
                    let bank = row.bank_name;
                    if(row.token_account == null || row.token_account == undefined){
                        direct_debit_bank = 'No';
                        bank = '-'
                    }
                    export_data.push({
                        name: row.name + " " + row.surname,
                        id_card: row.user_id.length > 13 ? row.id_card : row.user_id,
                        direct_debit: direct_debit_bank,
                        bank: bank,
                        // direct_debit: row.token_account == undefined ? "Yes" : "No",
                        // bank: row.token_account != undefined ? row.bank_name : "-",
                        document_upload: row.status !== 5 ? "Yes" : "No",
                        sent_for_approve: row.status == 2 || row.status == 6 || row.status == 3 ? "Yes" : "No",
                        approve_date: row.approve_date == undefined ? row.create_date.split("T")[0] : row.approve_date.split("T")[0]
                    })
                }
                setData(export_data)
            }

        })
    }, [filter])

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            // checked={rowCount > 0 && numSelected === rowCount}
                            // onChange={onSelectAllClick}
                            checked={tag}
                            onChange={(_, tag) => {
                                setTag(tag)
                                onSelectAllClick(_, tag)
                            }}

                            inputProps={{
                                'aria-label': 'select all',
                            }}
                        // disabled
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align='left'
                            padding='normal'
                            width={headCell.width}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            console.log(selected)
            const newSelecteds = stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => n.id);

            let withoutDuplicates = [...new Set(selected.concat(newSelecteds))];

            // console.log(withoutDuplicates)
            setSelected(withoutDuplicates);

            return;
        }

        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const tabFilter = () => {
        setOpened(current => !current)
    }

    const addFilter = () => {
        if (rowsPerFilter < 5) {
            setRowsPerFilter(rowsPerFilter + 1)
        }
    }

    const handleChangeOperator = (index) => (e) => {
        let newOperator = []
        newOperator = [...operator]
        newOperator[index] = e.target.value
        setOperater(newOperator)
    }

    const delFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
        if (rowsPerFilter > 1) {
            setRowsPerFilter(rowsPerFilter - 1)
        }
    }

    const resetFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
    }
    const onClickFilterAction = () => {
        if (name_filter != '') {
            var filter_row = rows.filter(function (row) {
                return row.name.includes(name_filter) || row.surname.includes(name_filter);
            });
            setRows(filter_row)
    //    console.log(filter_row)
    //         setRows(filter_row)
            let export_data = []
            for (let row of filter_row) {
                export_data.push({
                    name: row.name + " " + row.surname,
                    id_card: row.user_id.length > 13 ? row.id_card : row.user_id,
                    direct_debit: row.token_account == undefined ? "No" : "Yes",
                    bank: row.token_account != undefined ? row.bank_name : "-",
                    document_upload: row.status !== 5 ? "Yes" : "No",
                    sent_for_approve: row.status == 2 || row.status == 6 || row.status == 3 ? "Yes" : "No",
                    approve_date: row.approve_date == undefined ? row.create_date.split("T")[0] : row.approve_date.split("T")[0]
                })
            }
            setData(export_data)
        } else {
            tackingHttpServices.getUserStatus(token_org)
                .then((res) => {
                    console.log(res)
                    if (res.data.data.length == 0 || res.data.data == 'null') {
                        console.log('test')
                        setRows([])
                    } else {
                        console.log('test2')
                        setStatus(true)
                        setRows(res.data.data)
                        let export_data = []
                        for (let row of res.data.data) {
                            export_data.push({
                                name: row.name + " " + row.surname,
                                id_card: row.user_id.length > 13 ? row.id_card : row.user_id,
                                direct_debit: row.token_account == undefined ? "No" : "Yes",
                                bank: row.token_account != undefined ? row.bank_name : "-",
                                document_upload: row.status !== 5 ? "Yes" : "No",
                                sent_for_approve: row.status == 2 || row.status == 6 || row.status == 3 ? "Yes" : "No",
                                approve_date: row.approve_date == undefined ? row.create_date.split("T")[0] : row.approve_date.split("T")[0]
                            })
                        }
                        setData(export_data)
                    }

                })
        }
    }
    const resetFilter = (valueIndex) => {
        switch (valueIndex) {
            case 1:
                tackingHttpServices.getUserStatus(token_org)
                    .then((res) => {
                        console.log(res)
                        if (res.data.data.length == 0 || res.data.data == 'null') {
                            console.log('test')
                            setRows([])
                        } else {
                            console.log('test2')
                            setStatus(true)
                            setRows(res.data.data)
                            let export_data = []
                            for (let row of res.data.data) {
                                export_data.push({
                                    name: row.name + " " + row.surname,
                                    id_card: row.user_id.length > 13 ? row.id_card : row.user_id,
                                    direct_debit: row.token_account == undefined ? "No" : "Yes",
                                    bank: row.token_account != undefined ? row.bank_name : "-",
                                    document_upload: row.status !== 5 ? "Yes" : "No",
                                    sent_for_approve: row.status == 2 || row.status == 6 || row.status == 3 ? "Yes" : "No",
                                    approve_date: row.approve_date == undefined ? row.create_date.split("T")[0] : row.approve_date.split("T")[0]
                                })
                            }
                            setData(export_data)
                        }

                    })
                break;
            default:
        }
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const filterGrid = (index) => {
        return (
            <>
                <Grid item >
                    <FormControl style={{ width: 300 }} >
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value[index] === undefined ? '' : value[index]}
                            label="Filter"
                            onChange={valueX(index)}
                        >
                            {/* <MenuItem value={1}>เลขบัตรประชาชน</MenuItem> */}
                            <MenuItem value={1}>ชื่อ</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {filterActionGrid(index)}

                {
                    rowsPerFilter !== index + 1 ?
                        <Grid item>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                                <Select
                                    style={{ width: 100 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={operator[index]}
                                    label="Operator"
                                    onChange={handleChangeOperator(index)}
                                >
                                    <MenuItem value={true}>และ</MenuItem>
                                    <MenuItem value={false}>หรือ</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        :
                        ''
                }

                {
                    value[index] !== undefined ?
                        <Grid item>
                            <Tooltip title="ล้างตัวกรอง">
                                <IconButton onClick={() => resetFilterAction(index)}>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        :
                        ''
                }
                <Grid item marginRight={10}>
                    <Tooltip title="ลบตัวกรองข้อมูล">
                        <IconButton onClick={() => delFilterAction(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        )
    }
    const filterActionGrid = (index) => {
        if (value[index] == 1) {
            return (
                <>
                    <Grid item>
                        <TextField id="outlined-basic" label="Name" variant="outlined" value={name_filter} onChange={nameFilter} />
                    </Grid>
                </>
            )
        }
    }
    const nameFilter = (event) => {
        setNameFilter(event.target.value)
    }

    const valueX = (index) => (event) => {
        const filtered = value.filter(obj => {
            return obj === event.target.value;
        });


        if (filtered.length === 0) {

            resetFilter(value[index])

            let newValue = []
            newValue = [...value]
            newValue[index] = event.target.value
            setValue(newValue)
        } else {

        }
    };

    const onClickApprove = (row, status) => {

        if (status == 3 && open == false) {
            let obj = {
                user_id: row.user_id,
                contact_id: row.contact_id,
                action_type: row.action_type,
                id: row.id,
                contract_no: row.contract_no
            }
            setContarct(row.contract_no === null ? '' : row.contract_no)
            setRowData(obj)
            setOpen(true)
        } else if (status != 3) {
            console.log(status)
            let obj = {
                user_id: row.user_id,
                contact_id: row.contact_id,
                action_type: row.action_type,
                id: row.id,
                contract_no: '-',
                date_approve: date,
                status: status
            }
            confirmAlert({
                title: 'ยืนยันที่จะบันทึกการอนุมัติ?',
                message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
                buttons: [
                    {
                        label: 'ตกลง',
                        onClick: () => {
                            tackingHttpServices.approveFileContract(token_org, obj)
                                .then((res) => {
                                    console.log(res)
                                    window.alert('บันทึกสำเร็จ')
                                    setRowData([])
                                    window.location.reload();

                                })
                        }
                    },
                    {
                        label: 'ยกเลิก',
                        onClick: () => {
                            console.log("no")
                        }
                    }
                ],
                overlayClassName: "overlay-custom-class-name"
            })
        } else if (status == 3 && open == true) {
            console.log('ma')
            let obj = {
                user_id: rows_data.user_id,
                contact_id: rows_data.contact_id,
                action_type: rows_data.action_type,
                id: rows_data.id,
                contract_no: contract,
                date_approve: date,
                status: 3
            }
            // console.log(obj)
            confirmAlert({
                title: 'ยืนยันที่จะบันทึกการอนุมัติ?',
                message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
                buttons: [
                    {
                        label: 'ตกลง',
                        onClick: () => {
                            tackingHttpServices.approveFileContract(token_org, obj)
                                .then((res) => {
                                    console.log(res)
                                    window.alert('บันทึกสำเร็จ')
                                    setRowData([])
                                    window.location.reload();

                                })
                        }
                    },
                    {
                        label: 'ยกเลิก',
                        onClick: () => {
                            console.log("no")
                        }
                    }
                ],
                overlayClassName: "overlay-custom-class-name"
            })
        }
    }

    const headers = [
        { label: "Name", key: "name" },
        { label: "ID Number", key: "id_card" },
        { label: "Join Direct Debit", key: "direct_debit" },
        { label: "Bank", key: "bank" },
        { label: "Upload Document?", key: "document_upload" },
        { label: "Sent for Approval?", key: "sent_for_approve" },
        { label: "Lasted update", key: "approve_date" }
    ];
    const [data, setData] = React.useState([])
    const csvLink = React.useRef()
    const handleExport = () => {
        console.log(rows);

        csvLink.current.link.click()
    }

    return (
        <Box sx={{ width: '100%' }}>
            {
                rows.length === 0 && !status ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ""
            }
            <Paper sx={{ width: '100%', mb: 2 }}>
                <Grid display={opened ? 'none' : ''}>
                    {
                        rowsPerFilter > 4 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(4)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 3 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(3)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 2 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(2)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 1 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(1)}
                            </Grid>
                            : null
                    }
                    <Grid container spacing={1} style={{ padding: '1rem' }}>
                        {filterGrid(0)}
                        <Grid item >
                            <Button variant="contained" onClick={() => onClickFilterAction()}>ค้นหา</Button>
                        </Grid>
                        <Grid item >
                            <Button variant="contained" onClick={() => handleExport()}>export</Button>
                        </Grid>
                        <CSVLink
                            headers={headers}
                            filename="CustomerExport.csv"
                            data={data}
                            ref={csvLink}
                            target='_blank'
                        />
                    </Grid>
                </Grid>

                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table
                        // sx={{ minWidth: 750 }}
                        // aria-labelledby="tableTitle"
                        // size='medium'
                        stickyHeader aria-label="sticky table"
                    >
                        {/* <Table stickyHeader aria-label="sticky table"> */}
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
  console.log('rows',rows)
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell> 
                                            <TableCell>{row.name + " " + row.surname}</TableCell>
                                            <TableCell>{row.user_id.length > 13 ? row.id_card : row.user_id}</TableCell>
                                            <TableCell ><IconButton >{(row.token_account != null && row.token_account != undefined) ? <CheckIcon color="primary" /> : <ClearIcon color="primary" />}</IconButton></TableCell>
                                            <TableCell >{ (row.token_account != null && row.token_account != undefined) ? row.bank_name : "-"}</TableCell>
                                            <TableCell >{ (row.token_account != null && row.token_account != undefined) ? row.bank_no : "-"}</TableCell>
                                            <TableCell><IconButton >{row.status !== 5 ? <CheckIcon color="primary" /> : <ClearIcon color="primary" />}</IconButton></TableCell>
                                            <TableCell><IconButton >{row.status == 2 || row.status == 6 || row.status == 3 ? <CheckIcon color="primary" /> : <ClearIcon color="primary" />}</IconButton></TableCell>
                                            <TableCell><IconButton >{row.sync_data ? <CheckIcon color="primary" /> : <ClearIcon color="primary" />}</IconButton></TableCell>
                                            <TableCell>{row.approve_date == undefined ? row.create_date.split("T")[0] : row.approve_date.split("T")[0]}</TableCell>
                                            
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 300, 500,]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* {console.log(file_type)} */}
                    <h2 id="app">ยืนยันการผ่านการอนุมัติ</h2>

                    <TextField style={{ marginBottom: '2rem', width: 230 }} id="outlined-basic2" label="เลขที่สัญญา" variant="outlined" size="small" value={contract} onChange={(e) => setContarct(e.target.value)} />
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DesktopDatePicker
                            label="วันที่ผ่านการอนุมัติ"
                            inputFormat="YYYY-MM-DD"
                            value={date}
                            onChange={(date) => setDate(date)}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </LocalizationProvider>
                    <br></br>
                    <Button style={{ float: 'right', marginTop: 10 }} variant="contained" onClick={() => onClickApprove('', 3)}>ยืนยัน</Button>
                </Box>

            </Modal>

        </Box>
    );
}
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}
export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={1}>
            <MyApp />
        </SnackbarProvider>
    );
}

