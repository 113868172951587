import * as React from 'react';
import { useEffect } from 'react';
import TableData from '../../components/table/document_tracking_user.js';
import '../sum.css'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import { useLocation } from 'react-router-dom';
import { ConfirmProvider } from "material-ui-confirm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Link,
    Routes,
    Route,
    useNavigate,
  } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
export default function App() {
    const { state } = useLocation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const contact_id = queryParams.get('contact_id');
    const user_id = queryParams.get('user_id');
    const names = queryParams.get('name');
    const [name, setNeme] = React.useState(names)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let token = localStorage.getItem('token')
    const navigate = useNavigate();
    let decoded
    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
    const handleCloseTab = () => {
        window.close();
      };
      useEffect(() => {
        
     }, [])
 
    return (

        <div className='document_tracking' style={{ margin: '2rem' }}>
            <SideBar />
            <Button variant="contained" style={{ marginTop: '3rem' }} onClick={() => handleCloseTab()}><ArrowBackIcon></ArrowBackIcon>ย้อนกลับ</Button>
            <Grid container style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: 'bold' }}>รายการติดตามเอกสาร : {name}</Typography>
                </Grid>
            </Grid>
            <ConfirmProvider>
                <TableData />
            </ConfirmProvider>
        </div>
        )
    }
