import axios from "axios";
import jwt_decode from "jwt-decode";
const config = require('../config.js')

const FILE_API_URL = config.FILE_API_URL;
const ORC_URL = config.ORC_URL;
const DTA_API = config.DTA_API
const MSCW_URL = config.MSCW_URL;
const PICO_URL = config.PICO_URL;
const token = localStorage.getItem('token')
let ROOT_URL

if (token !== null) {
    const decoded = jwt_decode(token)
    ROOT_URL = decoded.org_id === 1 ? PICO_URL : MSCW_URL;
}
// Statement
export const getStatements = (contact_id) => {
    return axios.get(`${FILE_API_URL}/get_statements/${contact_id}`)
}
export const getProduct = (citizen_id) => {
    return axios.get(`${DTA_API}/user/get_product/${citizen_id}`)
}
export const delStatementOCR = (statement_id) => {
    return axios.get(`${FILE_API_URL}/del_statement/ocr/${statement_id}`)
}

export const delStatementKeyin = (statement_id) => {
    return axios.get(`${FILE_API_URL}/del_statement/keyin/${statement_id}`)
}

// OCR
export const callOCRStatement = (formData) => {
    console.log('formData callOCRStatement', ...formData)
    return axios.post(
        `${ORC_URL}/api/ocr`, formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    )
};

// OCR DGA
export const callOCRStatement2 = (body) => {
    console.log('body of callOCRStatement2', body)
    return axios.post(`${ORC_URL}/api/ocr/uploaded`, body)
};

export const getOcrStatement = (statement_id) => {
    return axios.get(`${FILE_API_URL}/get_ocr_statement/${statement_id}`)
}

export const editOcrStatement = (body) => {
    return axios.post(`${FILE_API_URL}/edit_ocr_statement`, body);
}

export const getOcrAdditional = (statement_id) => {
    return axios.get(`${FILE_API_URL}/get_ocr_additional/${statement_id}`)
}

export const editOcrAdditional = (body) => {
    return axios.post(`${FILE_API_URL}/edit_ocr_additional`, body);
}

// Keyin
export const saveManualStatement = (body) => {
    return axios.post(`${FILE_API_URL}/save_manual_statement`, body);
}

export const getKeyinStatement = (statement_id) => {
    return axios.get(`${FILE_API_URL}/get_keyin_statement/${statement_id}`)
}

export const editKeyinStatement = (body) => {
    return axios.post(`${FILE_API_URL}/edit_keyin_statement`, body);
}

// Else
export const calculatStatement = (contact_id) => {
    return axios.post(`${FILE_API_URL}/cal_statement`, contact_id)
}
export const updateStatement = (data) => {
    return axios.post(`${ROOT_URL}/add_statement`, data)
}
export const dgaStatements = async (body) => {
    try {
        const response = await axios.post(`${FILE_API_URL}/dgaStatements`, body);
        return response.data;
    } catch (error) {
        console.error('Error in dgaStatements:', error);
        throw error;
    }
}