import TableData from '../../components/table/table_contact_collector.js';
import './../sum.css'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SideBar from './../../page/dashboard'
import jwt_decode from "jwt-decode";
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import * as React from 'react';


export default function App() {
    var token = localStorage.getItem('token')
    var token_org = localStorage.getItem('token_org')
    var decoded
    var decoded_org
    if (token === null) {
        window.location.href = '/login'
    } else {

        decoded = jwt_decode(token)
        decoded_org = jwt_decode(token_org)
    }
    const location = useLocation();
    const hasPermission = checkPermission(location.pathname, decoded)
    React.useEffect(() => {
        if (!hasPermission) {
            window.location.href = '/404';
        }
    }, [])
    return (
        <>
            {hasPermission && (
                <div className='contact-management' style={{ margin: '2rem' }}>
                    {console.log('>>>', decoded)}
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: 'bold' }}>รายการทวงหนี้ของ {decoded_org.name}</Typography>
                        </Grid>
                    </Grid>

                    <TableData />
                </div>
            )}
        </>
    )
}