import axios from "axios";
import jwt_decode from "jwt-decode";

const config = require('../config.js')

const MSCW_URL = config.MSCW_URL;
const PICO_URL = config.PICO_URL;
const PROD_API_URL = config.PROD_API_URL;
const token = localStorage.getItem('token')
let ROOT_URL

if (token !== null) {
    const decoded = jwt_decode(token)
    ROOT_URL = decoded.org_id === 1 ? PICO_URL : MSCW_URL;
}
//production
// const ROOT_URL = "https://api-uat.transfinmational.net";
//  const ROOT_URL = "https://api-mscw.transfinmational.net";

//dev
// const ROOT_URL = "http://localhost:3334";
// const ROOT_URL = config.DEV_API;
// const ROOT_URL2 = config.DEV_API;



// export const createData = (value, token, callback) => {
//     let object = JSON.stringify(value)
//     return axios.post(`${ROOT_URL}/user/create/data`, object, {
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer ' + token
//         },
//     })
// };


export const getContactID = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/hubspot/contacts/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const hubspotContact = (object, token, callback) => {
    console.log(token)
    return axios.get(`${ROOT_URL}/hubspot/contact/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const register = (object, token, callback) => {
    return axios.post(`${ROOT_URL}/user/register`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const login = (object, token, callback) => {
    return axios.post(`${ROOT_URL}/user/login`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const getUser = (object, token) => {
    return axios.get(`${ROOT_URL}/user/get_user/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const getScore = () => {
    return axios.get(`${ROOT_URL}/ncb/score/`, {
    })
}


// export const authen = (token, callback) => {
//     return axios.post(`${ROOT_URL}/user/authen`, {
// headers: {
//     'Content-Type': 'application/json',
//     'Authorization': 'Bearer ' + token
// },
//     })
// };




export const UserLogin = () => {
    let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTYsImlhdCI6MTYzNTg3NjgwMCwiZXhwIjoxNjM1OTYzMjAwfQ.bi1sq3xEUz6cGvS4NHCFun1tOXGOx-Fcby2qC2XBEVo'

    return axios.get(`${PROD_API_URL}/users`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const getUsers = (token) => {
    return axios.get(`${PROD_API_URL}/users`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

// management_report
export const getManagement = (token, body) => {
    return axios.post(`${PROD_API_URL}/contacts/management/`, body, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getManagementReport = (token, body) => {
    return axios.post(`${PROD_API_URL}/contacts/management_report`, body, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getCollector = (token, body) => {
    return axios.post(`${PROD_API_URL}/contacts/collector`, body, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const assignCollector = (token, id, AssignUserID) => {
    let body = {
        "PolicyID": id,
        "AssignUserID": AssignUserID,
        "Description": ""
    }
    return axios.post(`${PROD_API_URL}/contacts/assign`, body, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const collectorAction = (token, id, action, ActionNewOnDate) => {
    let body = {
        "ID": id,
        "TypeID": action,
        "Remark": "",
        "ActionNewOnDate": ActionNewOnDate
    }
    return axios.post(`${PROD_API_URL}/contacts/colloterAction`, body, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getActionType = (token) => {
    return axios.get(`${PROD_API_URL}/actionType`, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getUserCollector = (token) => {
    return axios.get(`${PROD_API_URL}/users`, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getContactActivity = (token) => {
    return axios.get(`${PROD_API_URL}/contact/9/activity`, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}


export const getContactDetail = (token, id) => {
    return axios.get(`${PROD_API_URL}/contact/` + id, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}
export const addRemark = (data, token) => {
    return axios.post(`${PROD_API_URL}/contacts/updateAction`, data, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}
