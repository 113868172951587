import axios from "axios";

const config = require('../config.js')

const COMMITEE_URL = config.COMMITEE_URL

export const getCommitteeSummaryList = (callback) => {
    return axios.get(`${COMMITEE_URL}/api/document/summary`, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}