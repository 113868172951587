import * as React from 'react';
import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CreateIcon from '@mui/icons-material/Create';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import * as HttpServices from '../../../src/services/services';
const columns = [
    { id: '', label: 'No', minWidth: 50 },
    { id: 'TransactionDate', label: 'วัน เดือน ปี', minWidth: 200 },
    { id: 'Duration', label: 'Duration', minWidth: 200 },
    { id: 'Collector', label: 'Collector', minWidth: 200 },
    { id: 'วิธีการทวง', label: 'วิธีการทวง', minWidth: 200 },
    { id: 'Action', label: 'Action', minWidth: 200 },
    { id: 'หมายเหตุ', label: 'หมายเหตุ', minWidth: 200 },
    { id: '', width: 20, label: '' },

];

export default function StickyHeadTable({ data ,type}) {
    const rows = data
    const [type_a, setType] = useState('collecter');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState('');
    const [collector_name, setCollectorName] = useState('');
    const [remark, setRemark] = useState('');
    const [type_id, setTypeID] = useState('');
    const [id, setID] = useState('');
    const getToken = () => {
        let token = localStorage.getItem('token_org')
        return token
    }
    // console.log('rows',rows)

    // function financial(n) {
    //     if (n === 0) {
    //         return "-"
    //     } else {
    //         return n?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    //     }
    // }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const onClickIcon = (row) => {
        setOpen(true)
        setCollectorName(row.Name)
        setDate(row.ActionDateTime === null ? '-' : row.ActionDateTime.split(" ")[0])
        setRemark(row.Remark === null ? '-' : row.Remark)
        setID(row.ActionID)
    }
    const handleClose = () => {
        setOpen(!open)
    }
    const onAddRemark = () => {
        setOpen(true)

        let data ={
            ID: id,
            Remark:remark,
        }

        HttpServices.addRemark(data, getToken())
        .then((res) => {
            console.log(res)
            if (res.data.response.status) {
                alert('บันทึกหมายเหตุ สำเร็จ')
                setOpen(!open)
                window.location.reload(true);
            }

        })
        .catch((err) => {
            console.log(err)
            alert('บันทึกหมายเหตุ ไม่สำเร็จ')
        })

    }
    return (
        <div style={{ height: 400, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
   {/* <------------------- dialog remake start--------------->*/}
            <Dialog open={open} onClose={handleClose}  fullWidth maxWidth="sm" >
                <DialogTitle>เพิ่มหมายเหตุ</DialogTitle>
                <DialogContent>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <TextField required fullWidth label="วัน/เดือน/ปี" variant="outlined" value={date}/>
                        </Grid>
                        <Grid item xs>
                            <TextField required fullWidth label="Collecter" variant="outlined" value={collector_name}/>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <br/>
                                <TextField required fullWidth id="outlined-basic" label="หมายเหตุ" value={remark} onChange={(e) => setRemark(e.target.value)}variant="outlined"/>
                            </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={onAddRemark}>บันทึก</Button>
                    <Button  variant="contained" color="error" onClick={handleClose}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>
{/* <------------------- dialog end --------------->*/}

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, textAlign: 'center' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    let index = rows.findIndex(obj => obj.ActionID === row.ActionID)
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.ActionID}>
                                            <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.ActionDateTime === null ? '-' : row.ActionDateTime.split(" ")[0]}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{ }</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.Name}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.ChannelMethod}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.TypeName}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.Remark}</TableCell>
                                            {type ==='crm'? '' : <TableCell><IconButton onClick={() => onClickIcon(row)}><CreateIcon color="primary" /></IconButton></TableCell>}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[100, 300, 500]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
