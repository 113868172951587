import { useCallback, useEffect, useMemo, useState } from "react";
import * as statementHttpServices from '../../services/statement_service.js';
import moment, { months } from "moment-timezone";
import numbro from "numbro";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from "@mui/x-data-grid";
import * as Sentry from "@sentry/react";
import StatementNoteFooter from "./StatementNoteFooter.js";
import { getOcrDateKey } from "../../utils/ocr_edit.js";
import StatementAdditionalDialog from "../../page/statement/StatementAdditionalDialog.js";

const MAX_YELLOW_BALANCE = 1500
const MAX_RED_BALANCE = 499

const MIN_BLUE_DEPOSIT = 1000
const BLUE_DESCRIPTION_KEYS = [
  /จาก\s/g
] 

const DATE_FORMAT_KEY_IN = 'DD/MM/YYYY'
const DATE_FORMAT_USAGE = 'DD-MM-YY'

const DATE_FORMAT_POSSIBLES = [
  'DD/MM/YYYY', // BAY #1st order
  'DD-MM-YY',   // KBANK
  'DD/MM/YY',   // BBL, KTB, SCB, SCB2 #later order
]

const NUMBER_FORMAT = '0,0.00'

const getEndDate = (group) => {
  return moment(group, DATE_FORMAT_USAGE).add(1, 'month').endOf('month')
}

const checkIsGray = (row, fieldIndex) => {
  let isGray = false
  const length = Object.keys(row).filter(k => k.includes('sumIsSalary')).length

  for (let i = 0; i < length; i++) {
    if (i === fieldIndex) {
      continue
    }
    const sumIsSalary = row['sumIsSalary_' + i]
    if (sumIsSalary === true) {
      isGray = true
      break
    }
  }

  return isGray
}

const checkIsPurple = (row, fieldIndex) => {
  let isPurple = row['sumIsSalary_' + fieldIndex] === true
  return isPurple
}

const checkIsBlue = (sumDepositDescription, balance) => {
  let isBlue = false
  sumDepositDescription.split('|').map(d => {
    d.split(',').map(dd => {
      if (BLUE_DESCRIPTION_KEYS.find(reg => reg.test(dd)) && balance >= MIN_BLUE_DEPOSIT) {
        isBlue = true
      }
    })
  })
  return isBlue
}

const cellClassNamePurpleGray = (params) => {
  const fieldIndex = params.field.split('_')?.[1]

  let classNames = ''
  if (checkIsPurple(params.row, fieldIndex)) {
    classNames += ' purple'
  } else if (checkIsGray(params.row, fieldIndex)) {
    classNames += ' gray'
  }

  return classNames + ''
}

const mapKeyInToOcr = (keyinStatements) => {
  return keyinStatements.map((file, i) => {
    return {
      bank: file.data?.[0]?.account_info?.bank?.en_name,
      data: {
        account_info: {},
        account_statement: file.data?.[0]?.account_statement?.[0]?.map((stm, j) => {
          return {
            balance: stm.finalBalance,
            date: moment(stm.date, DATE_FORMAT_KEY_IN).format(DATE_FORMAT_USAGE),
            deposite: numbro(stm.withdraw).subtract(stm.excludedMoney).value(),
            description: stm.note,
            time: '00:00',
          }
        })
      }
    }
  })
}

const mergeStatement = (account_statements) => {

  /* Clean multiple bank formats */
  const cleaned = account_statements.map((a) => {
    if (a.time === '') {
      a.time = '00:00'
    }

    const foundDateFormat = DATE_FORMAT_POSSIBLES.find(format => moment(a.date, format).isValid())
    if (foundDateFormat) {
      a.date = moment(a.date, foundDateFormat).format(DATE_FORMAT_USAGE)
    } else {
      Sentry.captureMessage('mergeStatement DATE_FORMAT_POSSIBLES' + { extra: { date: a.date } });
    }

    return a
  })


  /* Sort by Date and Time */
  const sorted = cleaned.sort((a, b) => {
    const datetimeA = moment(`${a.date} ${a.time}`, 'DD-MM-YY HH:mm')
    const datetimeB = moment(`${b.date} ${b.time}`, 'DD-MM-YY HH:mm')
    return datetimeA.unix() - datetimeB.unix()
  })

  /* Get only last in day */
  const perDate = sorted.reduce((obj, a) => {
    const exist = obj[a.date]
    try {
      const deposit = a.deposite.toString().replaceAll(/,/g, '') || 0
      let sumDeposit = numbro(exist?.sumDeposit || 0).add(deposit).value()
      let sumDepositDescription = (exist?.sumDepositDescription || '') + ((exist?.sumDepositDescription ? ',' : '') + a.description + '=' + deposit)
      let sumIsSalary = (exist?.['sumIsSalary'] ?? false) || a.isSalary === true
      obj[a.date] = a
      obj[a.date]['sumDeposit'] = sumDeposit
      obj[a.date]['sumDepositDescription'] = sumDepositDescription
      obj[a.date]['sumIsSalary'] = sumIsSalary

      return obj
    } catch(e) {
      console.log(27, e, a)
    }
    
  }, {})

  /* Filter only neccessary columns */
  const filtered = Object.values(perDate).map(a => {
    return {
      date: a.date,
      sumDeposit: a.sumDeposit,
      sumDepositDescription: a.sumDepositDescription,
      sumIsSalary: a.sumIsSalary,
      balance: numbro(a.balance.toString().replaceAll(/,/g, '')).value()
    }
  })

  /* Group by month cut date */
  const groupped = filtered.reduce((groups, a) => {
    const currentDate = moment(a.date, DATE_FORMAT_USAGE)
    let cutDate = moment(a.date, DATE_FORMAT_USAGE).set('date', 25)
    if (cutDate.isAfter(currentDate)) {
      cutDate = cutDate.subtract(1, 'months')
    }
    cutDate = cutDate.format(DATE_FORMAT_USAGE)

    if (!groups[cutDate]) {
      groups[cutDate] = {}
    }
    groups[cutDate][a.date] = a
    return groups
  }, {})

  /* Fulfill round dates */
  let outRangeLastRow = { balance: 0, date: '', sumDeposit: 0, sumDepositDescription: '' }
  const fulfilled = Object.entries(groupped).reduce((groups, [group, dates]) => {
    const startDate = moment(group, DATE_FORMAT_USAGE)
    const endDate = getEndDate(group)                 // 31-XX-XX

    let cutDate = moment(endDate).set('date', 25)     // 25-XX-XX

    groups[group] = []
    let lastRow = outRangeLastRow
    for (let i = startDate; i.isBefore(endDate); i = i.add(1, 'day')) {
      const currentDate = i.format(DATE_FORMAT_USAGE)
      
      const isExtendedRange = i.isSameOrAfter(cutDate, 'date')

      const row = isExtendedRange ? filtered.find(f => f.date === currentDate) : dates[currentDate]
      if (row) {
        groups[group].push(row)
        if (!isExtendedRange) {
          outRangeLastRow = row
        }
        lastRow = row
      } else {
        groups[group].push({ balance: lastRow.balance, date: currentDate, sumDeposit: 0, sumDepositDescription: '' })
      }
    }

    return groups
  }, {})

  return fulfilled
}

const mergeTables = (files) => {
  /* Union transactions */
  const summary = {}
  const unions = {}
  files.map((file, i) => {
    const lastFileInRound = file[file.length - 1]
    Object.entries(lastFileInRound).map(([group, rows], j) => {
      if (!summary[group]) {
        summary[group] = {}
      }
      if (!unions[group]) {
        unions[group] = {}
      }

      rows.map((row, k) => {
        const date = row.date
        if (!summary[group][date]) {
          summary[group][date] = {
            date: date,
            sumDeposit: 0,
            sumDepositDescription: '',
            balance: 0,
          }
        }
        if (!unions[group][date]) {
          unions[group][date] = []
        }

        const sum = summary[group][date]
        const newSum = {
          date: sum.date,
          sumDeposit: sum.sumDeposit + row.sumDeposit, // TODO number
          sumDepositDescription: sum.sumDepositDescription + '|' +  row.sumDepositDescription,
          balance: sum.balance + row.balance,
        }
        summary[group][date] = newSum

        unions[group][date].push({ ...row, i })
      })

    })
  })

  /* Merge table */
  const merge = {}
  Object.entries(summary).map(([group, rows], i) => {
    merge[group] = Object.entries(rows).map(([date, row], j) => {
      return [
        row,
        ...unions[group][date]
      ]
    })
  })

  return merge
}

export default function StatementSummary({ contact_id }) {

  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState([])
  const [fileHeaders, setFileHeaders] = useState([])

  useEffect(() => {
    const fetchStatementList = async () => {
        try {
            const response = await statementHttpServices.getStatements(contact_id);
            const arr = response.data.data

            const unionFileStatements = arr.ocrStatements.reduce((acc, statement, index) => {
              const identify = statement?.data?.account_info?.account_number
              if (acc[identify]) {
                console.log(`Index: ${index} ${identify} duplicated`, acc[identify])
                
                const lastFileInRound = statement?.data?.account_statement[statement?.data?.account_statement.length - 1]
                statement.data.account_statement = lastFileInRound

                const unioned = [
                  ...acc[identify].data.account_statement[0],
                  ...lastFileInRound
                ]

                const cleaned = unioned.reduce((acc, row) => {
                  const identity = `${row.date} ${row.time} ${row.title} ${row.withdraw} ${row.deposit} ${row.balance} ${row.description}`
                  acc[identity] = row
                  return acc
                }, {})

                const arrayized = Object.values(cleaned)

                acc[identify].data.account_statement = [arrayized]
              }
              else {
                console.log(`Index: ${index} ${identify} new`)

                const lastFileInRound = statement?.data?.account_statement[statement?.data?.account_statement.length - 1]
                statement.data.account_statement = [lastFileInRound]
                acc[identify] = statement
              }
              return acc
            }, {})
            const ocrStatements = Object.values(unionFileStatements)

            const fileStatements = ocrStatements.map(ocr => ocr?.data?.account_statement)
            const fileHeader = ocrStatements.map(ocr => {
              return {
                bank: ocr?.bank,
                ...ocr?.data?.account_info
              }
            })
            const perFileStatements = fileStatements.map((files, i) => {
              return files.map((page, j) => {
                return mergeStatement(page)
              })
            })


            const mappedKeyInStatement = mapKeyInToOcr(arr.keyinStatements)
            const fileKeyInStatements = mappedKeyInStatement.map(ocr => ([ocr?.data?.account_statement]))
            const fileKeyInHeader = mappedKeyInStatement.map(ocr => {
              return {
                bank: ocr?.bank,
                account_name: 'KEY IN',
                account_number: '',
                ...ocr?.data?.account_info,
              }
            })
            const perFileKeyInStatements = fileKeyInStatements.map((files, i) => {
              return files.map((page, j) => {
                return mergeStatement(page)
              })
            })

            const summary =  mergeTables([...perFileStatements, ...perFileKeyInStatements])

            setFiles(summary)
            setFileHeaders([...fileHeader, ...fileKeyInHeader])

            setIsLoading(false);
        } catch (error) {
            // Handle any errors here
            console.error(error);
        }
    };

    const fetchStatementAdditional = async () => {
      try {
        const response = await statementHttpServices.getOcrAdditional(contact_id);
        const additionals = response.data.data.data

        setAdditionals(additionals)
      } catch (error) {
        // Handle any errors here
        console.error(error);
      }
    }

    // Call the fetchStatementList function
    fetchStatementList();
    fetchStatementAdditional();
  }, [contact_id]); // Empty dependency array to run the effect only once


  const sorted = useMemo(() => {
    const sortedKey = Object.keys(files).sort((a, b) => {
      const datetimeA = moment(a, DATE_FORMAT_USAGE)
      const datetimeB = moment(b, DATE_FORMAT_USAGE)
      return datetimeA.unix() - datetimeB.unix()
    })
    const sorted = sortedKey.reduce((arr, key) => {
      arr[key] = files[key]
      return arr
    }, {})
    return sorted
  }, [files])

  const [additionals, setAdditionals] = useState([])
  const [selectedAdditional, setSelectedAdditional] = useState(null)

  const handleAdditionSelect = useCallback((row) => {
    const key = getOcrDateKey(row)
    const additional = { key, ...additionals?.[key] } ?? { key, note: ''}

    setSelectedAdditional(additional)
    // TODO TEST multiple render due to array dependency
  }, [additionals])

  const handleAdditionDialogClose = useCallback(() => {
    setSelectedAdditional(null)
  }, [])

  const handleAdditionSave = useCallback((additionalData) => {
    const newAdditionals = {
      ...additionals,
      [additionalData.key]: {
        note: additionalData.note
      }
    }

    statementHttpServices.editOcrAdditional({
      contactId: contact_id,
      additional: [{
        ...newAdditionals
      }]
    })
      .then((response) => {
          console.log("Statement note edited successfully:", response);
          // window.history.back();
          // successSaveData(true)
          setAdditionals(newAdditionals)
          setSelectedAdditional(null)
      })
      .catch((error) => {
          console.error("Error editing statement:", error);
          // successSaveData(false)
          setSelectedAdditional(null) // TODO alert if failed
      });
  }, [additionals, contact_id])

  return (
      <div>

        {
          Object.entries(sorted).map(([group, rows], index) => {
            const columnGroupingModel = rows[0]?.reduce((arr, d, i) => {
              const accountIndex = d.i
              const fileHeader = accountIndex === undefined ? undefined : fileHeaders[accountIndex]
              const accountOd = (fileHeader?.account_od ?? 0) > 0 ? `มียอด OD: ${numbro(fileHeader?.account_od).format(NUMBER_FORMAT)}` : ``
              return [
                ...arr,
                {
                  groupId: accountIndex === undefined ? 'รวมทุกบัญชี' : `บัญชี ${accountIndex + 1}: ${fileHeader.bank} ${fileHeader.account_name} ${fileHeader.account_number} ${accountOd}`,
                  children: [
                    { field: 'date_' + i },
                    { field: 'balance_' + i },
                    { field: 'sumDeposit_' + i },
                    { field: 'sumDepositDescription_' + i },
                  ],
                },
              ]
            }, [])

            const columns = rows[0]?.reduce((arr, d, i) => {
              return [
                ...arr, 
                {
                  
                  field: 'date_' + i,
                  headerName: 'วันที่',
                  width: 120,
                  headerAlign: 'center',
                  align: 'left',
                  hide: false,
                  sortable: false,
                  cellClassName: cellClassNamePurpleGray
                },{
                  field: 'balance_' + i,
                  headerName: 'ยอดคงเหลือสิ้นวัน',
                  width: 120,
                  headerAlign: 'center',
                  align: 'right',
                  hide: false,
                  sortable: false,
                  cellClassName: cellClassNamePurpleGray
                },{
                  field: 'sumDeposit_' + i,
                  headerName: 'เงินเข้า',
                  width: 120,
                  headerAlign: 'center',
                  align: 'right',
                  hide: false,
                  sortable: false,
                  cellClassName: cellClassNamePurpleGray
                },{
                  field: 'sumDepositDescription_' + i,
                  headerName: 'ข้อมูลหมายเหตุ',
                  width: i === 0 ? 180 : 120,
                  headerAlign: 'center',
                  align: 'left',
                  hide: false,
                  sortable: false,
                  cellClassName: (params) => {
                    const fieldIndex = params.field.split('_')?.[1]
                    const balance = numbro(params.row['balance_' + fieldIndex].toString().replaceAll(/,/g, '') || 0).value()

                    let classNames = balance > MAX_YELLOW_BALANCE ? 'green' : balance > MAX_RED_BALANCE ? 'yellow' : 'red'
                    if (checkIsBlue(params.row['sumDepositDescription_' + fieldIndex], balance)) {
                      classNames += ' blue'
                    }

                    if (i === 0) {
                      classNames += ' head-note'
                    } else {
                      classNames += ' not-head-note text-transparent'
                    }
  
                    return classNames
                  }
                },
              ]
            }, [])

            const data = rows.map((row, i) => {
              return row.reduce((arr, fileRow, j) => {
                const dateKey = fileRow.date

                arr['date_' + j] = fileRow.date
                arr['balance_' + j] = numbro(fileRow.balance).format(NUMBER_FORMAT)
                arr['sumDeposit_' + j] = numbro(fileRow.sumDeposit).format(NUMBER_FORMAT)
                arr['sumDepositDescription_' + j] = j === 0 ? (additionals?.[dateKey]?.note ?? '') : fileRow.sumDepositDescription
                arr['sumIsSalary_' + j] = fileRow.sumIsSalary === true
                return arr
              }, { id: i })
            })

            const startDate = moment(group, DATE_FORMAT_USAGE)
            const endDate = getEndDate(group)
            const diffDate = endDate.diff(startDate, 'days') + 1
            const endRangeDate = moment(group, DATE_FORMAT_USAGE).add(1, 'months').set('date', 5)
            const diffRangeDate = endRangeDate.diff(startDate, 'days')
            
            
            const summary = rows.reduce((arr, row, i) => {
              row.map((fileRow, j) => {
                if (!arr['sumSumDeposit_' + j]) {
                  arr['sumSumDeposit_' + j] = 0
                }
                if (!arr['averageSumDeposit_' + j]) {
                  arr['averageSumDeposit_' + j] = 0
                }
                if (!arr['averageRangeSumDeposit_' + j]) {
                  arr['averageRangeSumDeposit_' + j] = 0
                }
                if (!arr['averageBalance_' + j]) {
                  arr['averageBalance_' + j] = 0
                }
                if (!arr['green_' + j]) {
                  arr['green_' + j] = 0
                }
                if (!arr['yellow_' + j]) {
                  arr['yellow_' + j] = 0
                }
                if (!arr['red_' + j]) {
                  arr['red_' + j] = 0
                }
                if (!arr['blue_' + j]) {
                  arr['blue_' + j] = 0
                }

                arr['sumSumDeposit_' + j] = numbro(fileRow.sumDeposit).add(arr['sumSumDeposit_' + j]).value()
                arr['averageSumDeposit_' + j] = numbro(fileRow.sumDeposit).divide(diffDate).add(arr['averageSumDeposit_' + j]).value()
                arr['averageRangeSumDeposit_' + j] = numbro(fileRow.sumDeposit).divide(diffRangeDate).add(arr['averageRangeSumDeposit_' + j]).value()
                arr['averageBalance_' + j] = numbro(fileRow.balance).divide(diffDate).add(arr['averageBalance_' + j]).value()

                if(moment(fileRow.date, DATE_FORMAT_USAGE).isAfter(moment(group, DATE_FORMAT_USAGE).endOf('month'))){
                  arr['green_' + j] =  arr['green_' + j] + (fileRow.balance > MAX_YELLOW_BALANCE ? 1 : 0)
                  arr['yellow_' + j] =  arr['yellow_' + j] + (MAX_YELLOW_BALANCE >= fileRow.balance && fileRow.balance > MAX_RED_BALANCE ? 1 : 0)
                  arr['red_' + j] =  arr['red_' + j] + (MAX_RED_BALANCE >= fileRow.balance ? 1 : 0)
                  arr['blue_' + j] =  arr['blue_' + j] + checkIsBlue(fileRow.sumDepositDescription, fileRow.balance)
                }
              })
              return arr
            }, {})
            
            return (
              <Accordion key={index}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                      <Typography>รอบ {group} ({rows[0].length - 1} บัญชี, {rows.length } วัน)</Typography>
                      <Typography>เฉลี่ยคงเหลือ {numbro(summary['averageBalance_0'] ?? 0).format(NUMBER_FORMAT)}</Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {
                          (summary['green_0'] ?? 0) > 0 && (
                            <Typography sx={{ background: '#B5D8A8', p: 0.5 }}>สีเขียว {summary['green_0']}</Typography>
                          )
                        }
                        {
                          (summary['yellow_0'] ?? 0) > 0 && (
                            <Typography sx={{ background: '#FFE59A', p: 0.5 }}>สีเหลือง {summary['yellow_0']}</Typography>
                          )
                        }
                        {
                          (summary['red_0'] ?? 0) > 0 && (
                            <Typography sx={{ background: '#FFAAAA', p: 0.5 }}>สีแดง {summary['red_0']}</Typography>
                          )
                        }
                        {/* {
                          (summary['blue_0'] ?? 0) > 0 && (
                            <Typography sx={{ background: '#00bfff', p: 0.5 }}>สีฟ้า {summary['blue_0']}</Typography>
                          )
                        } */}
                      </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper elevation={3} >
                      <Box>
                          <DataGrid
                            onCellClick={(params) => {
                              if (params.field === 'sumDepositDescription_0') {
                                handleAdditionSelect(params.row)
                              }
                            }}
                            getRowHeight={() => 'auto'}
                            experimentalFeatures={{ columnGrouping: true }}
                            rows={data}
                            columns={columns}
                            columnGroupingModel={columnGroupingModel}
                            autoHeight
                            // pagination={false}
                            disableColumnFilter
                            // disableRowSelectionOnClick
                            // disableSelectionOnClick
                            sx={{
                                '.MuiDataGrid-footerContainer': {
                                  display: 'none'
                                },
                                borderColor: 'primary.light',
                                '& .MuiDataGrid-cell:hover': {
                                  color: 'primary.main',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    textOverflow: "clip",
                                    whiteSpace: "break-spaces",
                                    // lineHeight: 1.5,
                                },
                                '& .red': {
                                  backgroundColor: '#FFAAAA',
                                },
                                '& .yellow': {
                                  backgroundColor: '#FFE59A',
                                },
                                '& .green': {
                                  backgroundColor: '#B5D8A8',
                                },
                                '& .gray': {
                                  backgroundColor: '#C5BFB9',
                                },
                                '& .purple': {
                                  backgroundColor: '#E7AAFD',
                                },
                                '& .blue': {
                                  border: 'solid #00bfff',
                                },
                                '& .text-transparent': {
                                  color: 'transparent',
                                },
                                '& .head-note': {
                                  cursor: 'pointer',
                                  color: 'black',
                                  whiteSpace: 'normal !important',
                                  height: 'fit-content',
                                  minHeight: '32px !important',
                                  padding: '4px 10px'
                                },
                                '& .not-head-note': {
                                  whiteSpace: 'nowrap !important',
                                  minHeight: '32px !important',
                                }
                                
                            }}
                          />
                      </Box>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            )
            
          })
        }

        <StatementNoteFooter />

        <StatementAdditionalDialog
          additional={selectedAdditional}
          onChange={handleAdditionSave}
          handleClose={handleAdditionDialogClose}
      />
      </div>
  );
}
