import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import BayImage from '../../../src/assets/images/icon_bank_bay.png'
import BblImage from '../../../src/assets/images/icon_bank_bbl.png'
import KbankImage from '../../../src/assets/images/icon_bank_kbank.png'
import KtbImage from '../../../src/assets/images/icon_bank_ktb.png'
import ScbImage from '../../../src/assets/images/icon_bank_scb.png'

const banksData = {
  KBANK: {
    name: 'ธนาคารกสิกรไทย (KBANK)',
    imageSrc: KbankImage
  },
  KTB: {
    name: 'ธนาคารกรุงไทย (KTB)',
    imageSrc: KtbImage,
  },
  SCB: {
    name: 'ธนาคารไทยพาณิชย์ (SCB)',
    imageSrc: ScbImage
  },
  BBL: {
    name: 'ธนาคารกรุงเทพ (BBL)',
    imageSrc: BblImage,
  },
  BAY: {
    name: 'ธนาคารกรุงศรีอยุธยา (BAY)',
    imageSrc: BayImage,
  },
};

function BankSelection(props) {
  const { bank, handleChange } = props

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>ธนาคาร</InputLabel>
        <Select
          id="demo-simple-select"
          value={bank}
          label="ธนาคาร"
          onChange={handleChange}
        >
          {Object.keys(banksData).map((bankKey) => (
            <MenuItem key={bankKey} value={bankKey}>
              {banksData[bankKey].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <br />
      {bank && (
        <div style={{ border: '1px solid #c4c4c4', borderRadius: '5px', padding: '10px', display: 'flex', alignItems: 'center' }}>
          <img src={banksData[bank].imageSrc} alt='' width={'50px'} style={{ marginRight: '10px', borderRadius: '10px' }} />
          <span>{banksData[bank].name}</span>
        </div>
      )}
    </>
  );
}

export default function AlertDialog(props) {
  const { open, handleClose, handleCancel, handleSubmit } = props

  const [bank, setBank] = useState('');

  const handleChange = (e) => {
    setBank(e.target.value)
  }

  const handleSave = () => {
    handleSubmit(bank.toLowerCase())
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          เลือกธนาคาร
        </DialogTitle>
        <DialogContent sx={{ width: '500px' }}>
          <DialogContentText id="alert-dialog-description">
            เลือกธนาคารด้านล่างเพื่อใช้ในการทำ OCR
          </DialogContentText>
          <br />
          <BankSelection bank={bank} handleChange={handleChange} />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>ยกเลิก</Button>
          <Button onClick={handleSave} disabled={bank === ''}>
            ต่อไป
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
