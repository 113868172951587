import { useCallback, useEffect, useMemo, useState } from "react";
import * as statementHttpServices from '../../services/statement_service.js';
import moment, { months } from "moment-timezone";
import numbro from "numbro";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Paper, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from "@mui/x-data-grid";
import * as Sentry from "@sentry/react";
import * as ncbHttpServices from '../../services/ncb_services';
import StatementNoteFooter from "./StatementNoteFooter.js";
import StatementAdditionalDialog from "../../page/statement/StatementAdditionalDialog.js";
import { useCalendarState } from "@mui/x-date-pickers/internals";
import { getOcrDateKey } from "../../utils/ocr_edit.js";

const MAX_YELLOW_BALANCE = 1500
const MAX_RED_BALANCE = 499

const MIN_BLUE_DEPOSIT = 1000
const MAX_BLUE_DEPOSIT = 5000
const BLUE_DESCRIPTION_KEYS = [
  /จาก\s/g
]

const MIN_ORANGE_DEPOSIT = 5000

const DATE_FORMAT_KEY_IN = 'DD/MM/YYYY'
const DATE_FORMAT_USAGE = 'DD-MM-YY'

const DATE_FORMAT_POSSIBLES = [
  'DD/MM/YYYY', // BAY #1st order
  'DD-MM-YY',   // KBANK
  'DD/MM/YY',   // BBL, KTB, SCB, SCB2 #later order
]

const NUMBER_FORMAT = '0,0.00'

const CONSIDER_MONTHS = 6

const WEIGHT_MATRIX = [
  [0.19, 0.095, 0],
  [0.126, 0.063, 0],
  [0.095, 0.047, 0],
  [0.095, 0.047, 0],
  [0.063, 0.031, 0],
  [0.063, 0.031, 0],
]

const WEIGHT_ROUND_MATRIX = [5.7, 3.8, 2.85, 2.85, 1.9, 1.9]

const OPTION_O_WEIGHT = 1.33
const OPTION_1_WEIGHT = 1.33

const MAX_SCORE_0 = 999
const MAX_SCORE_1 = 2500
const MAX_SCORE_2 = 5000

const OPTIONS = [
  '< 1,000 บาท',
  '1,000 - 2,500',
  '2,501 - 5,000',
  'มากกว่า 5,000',
]

const findMaxDateStatement = (account_statements) => {
  ////
  /* Clean multiple bank formats */
  const cleaned = account_statements.map((a) => {
    if (a.time === '') {
      a.time = '00:00'
    }

    const foundDateFormat = DATE_FORMAT_POSSIBLES.find(format => moment(a.date, format).isValid())
    if (foundDateFormat) {
      a.date = moment(a.date, foundDateFormat).format(DATE_FORMAT_USAGE)
    } else {
      Sentry.captureMessage('mergeStatement DATE_FORMAT_POSSIBLES' + { extra: { date: a.date } });
    }

    return a
  })

  /* Sort by Date and Time */
  const sorted = cleaned.sort((a, b) => {
    const datetimeA = moment(`${a.date} ${a.time}`, 'DD-MM-YY HH:mm')
    const datetimeB = moment(`${b.date} ${b.time}`, 'DD-MM-YY HH:mm')
    return datetimeA.unix() - datetimeB.unix()
  })

  ////
  return sorted[sorted.length - 1]?.date
}

const getEndDate = (group) => {
  return moment(group, DATE_FORMAT_USAGE).add(1, 'month')
}

const getEndDate255 = (group) => {
  return moment(group, DATE_FORMAT_USAGE).add(1, 'month').set('date', 3)
}

const checkIsGray = (row, fieldIndex) => {
  let isGray = false
  const length = Object.keys(row).filter(k => k.includes('sumIsSalary')).length

  for (let i = 0; i < length; i++) {
    if (i === fieldIndex) {
      continue
    }
    const sumIsSalary = row['sumIsSalary_' + i]
    if (sumIsSalary === true) {
      isGray = true
      break
    }
  }

  return isGray
}

const checkIsPurple = (row, fieldIndex) => {
  let isPurple = row['sumIsSalary_' + fieldIndex] === true
  return isPurple
}

const checkIsBlue = (sumDepositDescription, balance) => {
  let isBlue = false
  if (balance >= MIN_BLUE_DEPOSIT && balance <= MAX_BLUE_DEPOSIT) {
    isBlue = true
  }
  return isBlue
}

const checkIsOrange = (sumDeposit) => {
  let isOrange = false
  if (sumDeposit > MIN_ORANGE_DEPOSIT) {
    isOrange = true
  }
  return isOrange
}

const cellClassNamePurpleGray = (params) => {
  const fieldIndex = params.field.split('_')?.[1]

  let classNames = ''
  if (checkIsPurple(params.row, fieldIndex)) {
    classNames += ' purple'
  } else if (checkIsGray(params.row, fieldIndex)) {
    classNames += ' gray'
  }

  return classNames + ''
}

const mapKeyInToOcr = (keyinStatements) => {
  return keyinStatements.map((file, i) => {
    return {
      bank: file.data?.[0]?.account_info?.bank?.en_name,
      data: {
        account_info: {},
        account_statement: file.data?.[0]?.account_statement?.[0]?.map((stm, j) => {
          return {
            balance: stm.finalBalance,
            date: moment(stm.date, DATE_FORMAT_KEY_IN).format(DATE_FORMAT_USAGE),
            deposite: numbro(stm.withdraw).subtract(stm.excludedMoney).value(),
            description: stm.note,
            time: '00:00',


          }
        })
      }
    }
  })
}

const mergeStatement = (account_statements, cutDateMonth) => {

  /* Clean multiple bank formats */
  const cleaned = account_statements.map((a) => {
    if (a.time === '') {
      a.time = '00:00'
    }

    const foundDateFormat = DATE_FORMAT_POSSIBLES.find(format => moment(a.date, format).isValid())
    if (foundDateFormat) {
      a.date = moment(a.date, foundDateFormat).format(DATE_FORMAT_USAGE)
    } else {
      Sentry.captureMessage('mergeStatement DATE_FORMAT_POSSIBLES' + { extra: { date: a.date } });
    }

    return a
  })

  /* Sort by Date and Time */
  const sorted = cleaned.sort((a, b) => {
    const datetimeA = moment(`${a.date} ${a.time}`, 'DD-MM-YY HH:mm')
    const datetimeB = moment(`${b.date} ${b.time}`, 'DD-MM-YY HH:mm')
    return datetimeA.unix() - datetimeB.unix()
  })

  /* Get only last in day */
  const perDate = sorted.reduce((obj, a) => {
    const exist = obj[a.date]
    try {
      const deposit = a.deposite.toString().replaceAll(/,/g, '') || 0
      let sumDeposit = numbro(exist?.sumDeposit || 0).add(deposit).value()
      let sumDepositDescription = (exist?.sumDepositDescription || '') + ((exist?.sumDepositDescription ? ',' : '') + a.description + '=' + deposit)
      let sumIsSalary = (exist?.['sumIsSalary'] ?? false) || a.isSalary === true
      obj[a.date] = a
      obj[a.date]['sumDeposit'] = sumDeposit
      obj[a.date]['sumDepositDescription'] = sumDepositDescription
      obj[a.date]['sumIsSalary'] = sumIsSalary

      return obj
    } catch (e) {
      console.log(27, e, a)
    }

  }, {})

  /* Filter only neccessary columns */
  const filtered = Object.values(perDate).map(a => {
    return {
      date: a.date,
      sumDeposit: a.sumDeposit,
      sumDepositDescription: a.sumDepositDescription,
      sumIsSalary: a.sumIsSalary,
      balance: numbro(a.balance.toString().replaceAll(/,/g, '')).value()
    }
  })

  /* Group by month cut date cutDateMonth */
  const groupped = filtered.reduce((groups, a) => {
    const currentDate = moment(a.date, DATE_FORMAT_USAGE)
    let cutDate = moment(a.date, DATE_FORMAT_USAGE).set('date', 1)
    if (cutDate.isAfter(currentDate)) {
      cutDate = cutDate.subtract(1, 'months')
    }
    cutDate = cutDate.format(DATE_FORMAT_USAGE)

    if (!groups[cutDate]) {
      groups[cutDate] = {}
    }
    groups[cutDate][a.date] = a
    return groups
  }, {})

  /* Fulfill round dates */
  let lastRow = { balance: 0, date: '', sumDeposit: 0, sumDepositDescription: '' }
  const fulfilled = Object.entries(groupped).reduce((groups, [group, dates]) => {
    const startDate = moment(group, DATE_FORMAT_USAGE)
    const endDate = getEndDate(group)

    groups[group] = []
    // let lastRow = { balance: 0, date: group, sumDeposit: 0, sumDepositDescription: '' }
    for (let i = startDate; i.isBefore(endDate); i = i.add(1, 'day')) {
      const currentDate = i.format(DATE_FORMAT_USAGE)

      const row = dates[currentDate]
      if (row) {
        groups[group].push(row)
        lastRow = row
      } else {
        groups[group].push({ balance: lastRow.balance, date: currentDate, sumDeposit: 0, sumDepositDescription: '[FULFILLED]' })
      }
    }

    return groups
  }, {})

  return fulfilled
}

const mergeTables = (files, lastDateStatement) => {
  /* Union transactions */
  const summary = {}
  const unions = {}
  files.map((file, i) => {
    const lastFileInRound = file[file.length - 1]
    Object.entries(lastFileInRound).map(([group, rows], j) => {

      if (!summary[group]) {
        summary[group] = {}
      }
      if (!unions[group]) {
        unions[group] = {}
      }

      rows.map((row, k) => {
        const date = row.date
        if (!summary[group][date]) {
          summary[group][date] = {
            date: date,
            sumDeposit: 0,
            sumDepositDescription: '',
            balance: 0,
          }
        }
        if (!unions[group][date]) {
          unions[group][date] = []
        }

        const sum = summary[group][date]
        const newSum = {
          date: sum.date,
          sumDeposit: sum.sumDeposit + row.sumDeposit, // TODO number
          sumDepositDescription: sum.sumDepositDescription + '|' + row.sumDepositDescription,
          balance: sum.balance + row.balance,
        }
        summary[group][date] = newSum

        unions[group][date].push({ ...row, i })
      })

    })
  })

  /* Merge table */
  const merge = {}
  Object.entries(summary).map(([group, rows], i) => {
    merge[group] = Object.entries(rows).map(([date, row], j) => {
      return [
        row,
        ...unions[group][date]
      ]
    })
  })

  /* Delete end side fulfilled rows */
  const deleted = {}
  Object.keys(merge).sort((a, b) =>  moment(a, DATE_FORMAT_USAGE).unix() - moment(b, DATE_FORMAT_USAGE).unix()).map((group, i) => {
    const rows = merge[group]
    if (i === Object.keys(merge).length - 1) {
      for (let i = rows.length - 1; i >= 0; i--) {
        const isOverFilled = moment(rows[i][0].date, DATE_FORMAT_USAGE).isAfter(lastDateStatement)
        const isFullfilled = (rows[i][0].sumDepositDescription.match(/\[FULFILLED\]/g) || []).length === rows[i].length - 1
        if (isFullfilled && isOverFilled) {
          rows.splice(i)
        } else {
          break;
        }
      }
    }
    deleted[group] = rows
  })

  return deleted
}

const getOlderDate = (dateStr1, dateStr2) => {
  var date1 = moment(dateStr1, "DD/MM/YYYY");
  var date2 = moment(dateStr2, "DD/MM/YYYY");

  let result = date1
  if (dateStr1.length === 0) {
    result = date2
  } else if (dateStr2.length === 0) {
    result = date1
  } else {
    if (date1.isAfter(date2)) {
      result = date2
    } else if (date1.isBefore(date2)) {
      result = date1
    }
  }

  return result.format()
}

const reNum = (value) => {
  const num = value || 0
  const result = num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  return result
}

const calDiffMonth = (date1, date2) => {
  let enquityDate = new Date(date1)
  let date = new Date(date2)

  let y = enquityDate.getFullYear() - date.getFullYear()
  let m = enquityDate.getMonth() - date.getMonth()

  return ((y * 12) + (m))
}

export default function StatementSummary({ contact_id }) {

  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState([])
  const [fileHeaders, setFileHeaders] = useState([])

  useEffect(() => {
    
    const fetchStatementList = async () => {
      try {
        const response = await statementHttpServices.getStatements(contact_id);
        const arr = response.data.data

        let lastDateStatement = undefined
        const unionFileStatements = arr.ocrStatements.reduce((acc, statement, index) => {
          // Stamp Last Date IN Statements
          let account_period = statement?.data?.account_info?.account_period
          // For Mistaked KTB, update to be string since 16/08/2024
          if (Array.isArray(account_period) && account_period.length > 0) {
            account_period = account_period[0]
          }
          account_period = account_period
            .replace('to', '-')
            .replace('to', 'ถึง')
            .replace('Statement Period ', '')
            .replace('รายการบัญชีระหว่างวันที่ ', '')
            .replace(/ /g, '')

          if (account_period.length < 21) {
            account_period = account_period.split('')
            account_period.splice(-11, 0, '20');
            account_period.splice(-2, 0, '20');
            account_period = account_period.join('');
          }
          account_period = account_period.split('-')
          let start = moment(account_period[0], DATE_FORMAT_KEY_IN)
          let end = moment(account_period[1], DATE_FORMAT_KEY_IN)
          if (start.get('year') > 2500) {
            start = start.subtract(543, 'years')
          }
          if (end.get('year') > 2500) {
            end = end.subtract(543, 'years')
          }
          
          account_period = start.format(DATE_FORMAT_KEY_IN) + '-' + end.format(DATE_FORMAT_KEY_IN)
          
          if (lastDateStatement ===  undefined || end.isAfter(lastDateStatement)) {
            lastDateStatement = end
          }
          // End Stampping

          const identify = statement?.data?.account_info?.account_number
          if (acc[identify]) {
            console.log(`Index: ${index} ${identify} duplicated`)

            const lastFileInRound = statement?.data?.account_statement[statement?.data?.account_statement.length - 1]
            statement.data.account_statement = lastFileInRound

            const unioned = [
              ...acc[identify].data.account_statement[0],
              ...lastFileInRound
            ]

            const cleaned = unioned.reduce((acc, row) => {
              const identity = `${row.date} ${row.time} ${row.title} ${row.withdraw} ${row.deposit} ${row.balance} ${row.description}`
              acc[identity] = row
              return acc
            }, {})

            const arrayized = Object.values(cleaned)

            acc[identify].data.account_statement = [arrayized]
          }
          else {
            console.log(`Index: ${index} ${identify} new`)

            const lastFileInRound = statement?.data?.account_statement[statement?.data?.account_statement.length - 1]
            statement.data.account_statement = [lastFileInRound]
            acc[identify] = statement
          }
          return acc
        }, {})
        const ocrStatements = Object.values(unionFileStatements)

        const fileStatements = ocrStatements.map(ocr => ocr?.data?.account_statement)
        const fileHeader = ocrStatements.map(ocr => {
          return {
            bank: ocr?.bank,
            ...ocr?.data?.account_info,
          }
        })

        const maxDates = fileStatements.map((files, i) => {
          const lastFileInRound = files[files.length - 1]
          const m = findMaxDateStatement(lastFileInRound)
          return m
        }).sort((a, b) => {
          const dateA = moment(a, DATE_FORMAT_USAGE)
          const dateB = moment(b, DATE_FORMAT_USAGE)
          return dateB.unix() - dateA.unix()
        })
        /* cutDateMonth depends on statement, not including key ins */
        const cutDateMonth = moment(maxDates[0], DATE_FORMAT_USAGE).add(1, 'days').format('DD')

        const perFileStatements = fileStatements.map((files, i) => {
          return files.map((page, j) => {
            return mergeStatement(page, cutDateMonth)
          })
        })

        const mappedKeyInStatement = mapKeyInToOcr(arr.keyinStatements)
        const unionFileKeyInStatements = mappedKeyInStatement.reduce((acc, statement, index) => {
          const identify = statement?.bank
          if (acc[identify]) {
            console.log(`Key In Index: ${index} ${identify} duplicated`)

            const lastFileInRound = statement?.data?.account_statement
            statement.data.account_statement = lastFileInRound

            const unioned = [
              ...acc[identify].data.account_statement,
              ...lastFileInRound
            ]

            const cleaned = unioned.reduce((acc, row) => {
              const identity = `${row.date} ${row.time} ${row.withdraw} ${row.deposite} ${row.balance} ${row.description}`
              acc[identity] = row
              return acc
            }, {})

            const arrayized = Object.values(cleaned)

            acc[identify].data.account_statement = arrayized
          }
          else {
            console.log(`Key In Index: ${index} ${identify} new`)

            const lastFileInRound = statement?.data?.account_statement
            statement.data.account_statement = lastFileInRound
            acc[identify] = statement
          }
          return acc
        }, {})
        const keyInStatements = Object.values(unionFileKeyInStatements)
        const fileKeyInStatements = keyInStatements.map(ocr => ([ocr?.data?.account_statement]))
        const fileKeyInHeader = keyInStatements.map(ocr => {
          return {
            bank: ocr?.bank,
            account_name: 'KEY IN',
            account_number: '',
            ...ocr?.data?.account_info,
          }
        })
        const perFileKeyInStatements = fileKeyInStatements.map((files, i) => {
          return files.map((page, j) => {
            return mergeStatement(page, cutDateMonth)
          })
        })
        
        const summary = mergeTables([...perFileStatements, ...perFileKeyInStatements], lastDateStatement)

        setFiles(summary)
        setFileHeaders([...fileHeader, ...fileKeyInHeader])
        const resData = await ncbHttpServices.ncbGetData(contact_id);
        const summaryData = resData.data;

        const open_account = summaryData.calculated_data?.open_account_in_1_year
        const close_account = summaryData.calculated_data?.close_account_in_1_year
        const cutDate = moment(summaryData.enquityDate).format('YYYY-MM-DD')

        let temp_list = []
        for (let i = 1; i <= 12; i++) {
          // open_account_in_1_year
          let creditLimit = []
          let installmentAmount = []
          let amountOwed = []
          let dateAccountOpened = []
          let accountType = []
          let SumCreditLimit = 0
          // lose_account_in_1_year
          let creditLimit2 = []
          let installmentAmount2 = []
          let amountOwed2 = []
          let dateAccountOpened2 = []
          let dateAccountClosed2 = []
          let accountType2 = []
          let SumCreditLimit2 = 0

          open_account && open_account.rows.forEach(element => {
            const date = moment(element.dateAccountOpened).format('YYYY-MM-DD')
            const diffMonth = calDiffMonth(cutDate, date)

            if (diffMonth === i) {
              accountType.push(element.accountType)
              dateAccountOpened.push(moment(element.dateAccountOpened).format('DD/MM/YYYY'))
              creditLimit.push(element.creditLimit)
              installmentAmount.push(element.installmentAmount)
              amountOwed.push(element.amountOwed)
              SumCreditLimit += element.creditLimit
            }
          });

          close_account && close_account.rows.forEach(element => {
            const date = moment(element.dateAccountClosed).format('YYYY-MM-DD')
            const diffMonth = calDiffMonth(cutDate, date)

            if (diffMonth === i) {
              accountType2.push(element.accountType)
              dateAccountClosed2.push(moment(element.dateAccountClosed).format('DD/MM/YYYY'))
              dateAccountOpened2.push(moment(element.dateAccountOpened).format('DD/MM/YYYY'))
              creditLimit2.push(element.creditLimit)
              installmentAmount2.push(element.installmentAmount)
              amountOwed2.push(element.amountOwed)
              SumCreditLimit2 += element.creditLimit

            }
          })

          const rowSpan = Math.max(0, dateAccountOpened.length, dateAccountOpened2.length)

          if (rowSpan !== 0) {
            temp_list.push({
              diffMonth: i,
              accountType: accountType,
              dateAccountOpened: dateAccountOpened,
              creditLimit: creditLimit,
              installmentAmount: installmentAmount,
              amountOwed: amountOwed,

              accountType2: accountType2,
              dateAccountClosed2: dateAccountClosed2,
              dateAccountOpened2: dateAccountOpened2,
              creditLimit2: creditLimit2,
              installmentAmount2: installmentAmount2,
              amountOwed2: amountOwed2,

              rowSpan: Math.max(1, dateAccountOpened.length, dateAccountOpened2.length),
              outstanding_debt: SumCreditLimit - SumCreditLimit2,
            })
          }

        }

        setNCBData(temp_list)

        setIsLoading(false);
      } catch (error) {
        // Handle any errors here
        console.error(error);
      }
    };

    const fetchStatementAdditional = async () => {
      try {
        const response = await statementHttpServices.getOcrAdditional(contact_id);
        const additionals = response.data.data.data

        setAdditionals(additionals)
      } catch (error) {
        // Handle any errors here
        console.error(error);
      }
    }

    // Call the fetchStatementList function
    fetchStatementList();
    fetchStatementAdditional();
  }, [contact_id]); // Empty dependency array to run the effect only once

  const sorted = useMemo(() => {
    const sortedKey = Object.keys(files).sort((a, b) => {
      const datetimeA = moment(a, DATE_FORMAT_USAGE)
      const datetimeB = moment(b, DATE_FORMAT_USAGE)
      return datetimeA.unix() - datetimeB.unix()
    })
    const sorted = sortedKey.reduce((arr, key) => {
      arr[key] = files[key]
      return arr
    }, {})
    return sorted
  }, [files])

  const sorted255 = useMemo(() => {
    const filtered = []
    Object.entries(sorted).map(([group, rows], index) => {
      rows = rows.filter(row => {
        const date = moment(row[0].date, DATE_FORMAT_USAGE).get('date')
        return date >= 25 || date <= 3
      })
      filtered.push(...rows)
    })

    const groupped = filtered.reduce((groups, rows) => {
      const currentDate = moment(rows[0].date, DATE_FORMAT_USAGE)
      let cutDate = moment(rows[0].date, DATE_FORMAT_USAGE).set('date', 25)

      if (cutDate.isAfter(currentDate)) {
        cutDate = cutDate.subtract(1, 'months')
      }
      cutDate = cutDate.format(DATE_FORMAT_USAGE)

      if (!groups[cutDate]) {
        groups[cutDate] = []
      }
      groups[cutDate].push(rows)
      return groups
    }, {})

    const fulfilled = {}
    Object.entries(groupped).map(([group, rows], index) => {
      fulfilled[group] = []
      
      const accounts = []
      rows.map((row, i) => {
        // Union account number
        row.map((fileRow, j) => {
          if (fileRow.i!== undefined && !accounts.includes(fileRow.i)) {
            accounts.push(fileRow.i)
          }
        })

        // Init row
        fulfilled[group][i] = [row[0]]
      })

      // Fulfill
      rows.map((row, i) => {
        accounts.map((a, j) => {
          const fileRow = row.find(fileRow => fileRow.i === a)
          if (fileRow) {
            fulfilled[group][i].push(fileRow)
          }
          else {
            const date = fulfilled[group][i][0].date
            if (i === 0) {
              fulfilled[group][i].push({
                balance: 0,
                date: date,
                i: a,
                sumDeposit: 0,
                sumDepositDescription: '[FULFILLED]'
              })
            }
            else {
              const row = fulfilled[group][i - 1][j + 1]
              const accountIndex = row.i
              const fileHeader = accountIndex === undefined ? undefined : fileHeaders[accountIndex]
              fulfilled[group][i].push(Object.assign({}, row, {
                date: date,
                balance: fileHeader?.account_name === 'KEY IN' ? 0 : row.balance,
                sumDeposit: 0,
                sumDepositDescription: '[FULFILLED]'
              }))
            }
          }
        })
      })
    })

    return fulfilled
  }, [sorted])

  const scoreColumns = useMemo(() => {
    return [
      {
        field: 'sum_score',
        headerName: 'คะแนน',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'month',
        headerName: 'รอบ',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'sum_weight',
        headerName: 'น้ำหนัก',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'color',
        headerName: 'แถบสี',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
        cellClassName: (params) => {
          return params.id % 3 === 0 ? 'green' : params.id % 3 === 1 ? 'yellow' : 'red'
        }
      }, {
        field: 'weight',
        headerName: 'น้ำหนัก',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'score',
        headerName: 'คะแนน',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'days',
        headerName: 'จำนวนวัน',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }
    ]
  }, [])

  const scoreColumns2 = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'ค่าเฉลี่ย',
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        hide: false,
        sortable: false,
      }, {
        field: 'weight',
        headerName: 'น้ำหนัก',
        width: 75,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'option_name',
        headerName: 'ตัวเลือก',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'option',
        headerName: 'ตัวเลือก',
        width: 75,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'score',
        headerName: 'คะแนน',
        width: 75,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'score0',
        headerName: '0',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'score1',
        headerName: '1',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'score2',
        headerName: '2',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'score3',
        headerName: '3',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        hide: false,
        sortable: false,
      }, {
        field: 'note',
        headerName: 'หมายเหตุ',
        flex: 2,
        headerAlign: 'note',
        align: 'note',
        hide: false,
        sortable: false,
      }
    ]
  }, [])

  const [totalScore, setTotalScore] = useState(-1)
  const [scoreData2, setScoreData2] = useState([])

  const [ncb_data, setNCBData] = useState([])

  const scoreData = useMemo(() => {
    const data = []
    let sum_score = 0
    let average_balance_first = 0
    let average_balance_last = 0
    const latest = Object.entries(sorted).reverse()[0]
    const groups = []
    if (latest) {
      const [latestGroup] = latest
      // var startDate = moment([year, month - 1]);

      // Clone the value before .endOf()
      var endDate = moment(latest[0], "DD-MM-YYYY").add(1, 'months').date(1).subtract(1, 'days');;
      let check_date = moment(latest[1][latest[1].length-1][0]['date'], "DD-MM-YYYY")
      let adjust_month = 1
      if(check_date.isSame(endDate)){
        adjust_month = 0
      }
      for (let i = adjust_month; i < CONSIDER_MONTHS+adjust_month; i++) {
        const date = moment(latestGroup, DATE_FORMAT_USAGE).subtract(i, 'month').format(DATE_FORMAT_USAGE)
        groups.push(date)
      }
    }

    groups.map((group, index) => {
      if (index < CONSIDER_MONTHS) {
        const startDate = moment(group, DATE_FORMAT_USAGE)
        const endDate = getEndDate(group)
        const diffDate = endDate.diff(startDate, 'days')
        const rows = sorted[group] ?? [[{
          balance: 0,
          date: group,
          sumDeposit: 0,
          sumDepositDescription: ''
        }]]

        const summary = rows.reduce((arr, row, i) => {
          row.map((fileRow, j) => {
            if (!arr['averageBalance_' + j]) {
              arr['averageBalance_' + j] = 0
            }
            if (!arr['green_' + j]) {
              arr['green_' + j] = 0
            }
            if (!arr['yellow_' + j]) {
              arr['yellow_' + j] = 0
            }
            if (!arr['red_' + j]) {
              arr['red_' + j] = 0
            }

            arr['averageBalance_' + j] = numbro(fileRow.balance).divide(diffDate).add(arr['averageBalance_' + j]).value()
            arr['green_' + j] = arr['green_' + j] + (fileRow.balance > MAX_YELLOW_BALANCE ? 1 : 0)
            arr['yellow_' + j] = arr['yellow_' + j] + (MAX_YELLOW_BALANCE >= fileRow.balance && fileRow.balance > MAX_RED_BALANCE ? 1 : 0)
            arr['red_' + j] = arr['red_' + j] + (MAX_RED_BALANCE >= fileRow.balance ? 1 : 0)

            const startDate = moment(group, DATE_FORMAT_USAGE)
            const endDate = getEndDate(group)
            if (!arr['groupText_' + j]) {
              arr['groupText_' + j] = `${startDate.format(DATE_FORMAT_USAGE)} ถึง ${endDate.subtract(1, 'days').format(DATE_FORMAT_USAGE)}`
            }
          })
          return arr
        }, {})

        const green_0 = numbro(summary['green_0'] ?? 0).value()
        const green_score = numbro(green_0).multiply(WEIGHT_MATRIX[index][0]).value()
        const yellow_0 = numbro(summary['yellow_0'] ?? 0).value()
        const yellow_score = numbro(yellow_0).multiply(WEIGHT_MATRIX[index][1]).value()
        const red_0 = numbro(summary['red_0'] ?? 0).value()
        const red_score = numbro(red_0).multiply(WEIGHT_MATRIX[index][2]).value()
        let sum_weight = WEIGHT_ROUND_MATRIX[index]
        sum_score = numbro(sum_score).add(green_score).add(yellow_score).add(red_score).value()

        if (index === 0) {
          average_balance_first = numbro(summary['averageBalance_0']).value()
        }
        else {
          average_balance_last = numbro(summary['averageBalance_0']).divide(Math.min(rows.length, CONSIDER_MONTHS) - 1).add(average_balance_last).value()
        }

        const green = {
          id: (index * 3) + 0,
          sum_score: '',
          // month: `เดือน ${CONSIDER_MONTHS - index}`,
          month: summary['groupText_0'],
          sum_weight: sum_weight,
          color: 'สีเขียว',
          weight: WEIGHT_MATRIX[index][0],
          score: green_score,
          days: green_0,
        }
        const yellow = {
          id: (index * 3) + 1,
          sum_score: '',
          month: '',
          sum_weight: '',
          color: 'สีเหลือง',
          weight: WEIGHT_MATRIX[index][1],
          score: yellow_score,
          days: yellow_0,
        }
        const red = {
          id: (index * 3) + 2,
          sum_score: '',
          month: '',
          sum_weight: '',
          color: 'สีแดง',
          weight: WEIGHT_MATRIX[index][2],
          score: red_score,
          days: red_0,
        }

        data.push(green, yellow, red)
      }
    })

    if (data[0]) {
      data[0]['sum_score'] = sum_score
    }

    const option0 = average_balance_first <= MAX_SCORE_0 ? 0 : average_balance_first <= MAX_SCORE_1 ? 1 : average_balance_first <= MAX_SCORE_2 ? 2 : 3
    const option1 = average_balance_last <= MAX_SCORE_0 ? 0 : average_balance_last <= MAX_SCORE_1 ? 1 : average_balance_last <= MAX_SCORE_2 ? 2 : 3
    const option0_score = numbro(option0).multiply(OPTION_O_WEIGHT).value()
    const option1_score = numbro(option1).multiply(OPTION_1_WEIGHT).value()

    setScoreData2([
      {
        id: 2,
        name: 'ค่าเฉลี่ยปัจจุบัน',
        weight: 4,
        option_name: OPTIONS[option0],
        option: option0,
        score: numbro(option0_score).format('0,00.[00]'),
        score0: '< 1,000 บาท',
        score1: '1,000 - 2,500',
        score2: '2,501 - 5,000',
        score3: 'มากกว่า 5,000',
        note: 'การคำนวนให้คะแนนจากค่าเฉลี่ยเดือนล่าสุด',
      }, {
        id: 1,
        name: 'ค่าเฉลี่ยเดือนเก่า',
        weight: 4,
        option_name: OPTIONS[option1],
        option: option1,
        score: numbro(option1_score).format('0,00.[00]'),
        score0: '< 1,000 บาท',
        score1: '1,000 - 2,500',
        score2: '2,501 - 5,000',
        score3: 'มากกว่า 5,000',
        note: 'การคำนวนให้คะแนนจากค่าเฉลี่ย 5 เดือนเก่า นำมาเฉลี่ย',
      }
    ])
    const totalScore = numbro(sum_score).add(option0_score).add(option1_score).value()
    setTotalScore(totalScore)
    let body={
      contact_id:contact_id,
      user_id:localStorage.getItem('citizen_id'),
      how_long_salary_after_salary:numbro(sum_score).format('0,00.[00]'),
      mean_avg_30day_after_salary:numbro(option0_score).format('0,00.[00]'),
      low_balance:numbro(option1_score).format('0,00.[00]')
     }
          statementHttpServices.updateStatement(body);
    return data
  }, [sorted])

  const [additionals, setAdditionals] = useState([])
  const [selectedAdditional, setSelectedAdditional] = useState(null)

  const handleAdditionSelect = useCallback((row) => {
    const key = getOcrDateKey(row)
    const additional = { key, ...additionals?.[key] } ?? { key, note: ''}

    setSelectedAdditional(additional)
    // TODO TEST multiple render due to array dependency
  }, [additionals])

  const handleAdditionDialogClose = useCallback(() => {
    setSelectedAdditional(null)
  }, [])

  const handleAdditionSave = useCallback((additionalData) => {
    const newAdditionals = {
      ...additionals,
      [additionalData.key]: {
        note: additionalData.note
      }
    }

    statementHttpServices.editOcrAdditional({
      contactId: contact_id,
      additional: [{
        ...newAdditionals
      }]
    })
      .then((response) => {
          console.log("Statement note edited successfully:", response);
          // window.history.back();
          // successSaveData(true)
          setAdditionals(newAdditionals)
          setSelectedAdditional(null)
      })
      .catch((error) => {
          console.error("Error editing statement:", error);
          // successSaveData(false)
          setSelectedAdditional(null) // TODO alert if failed
      });
  }, [additionals, contact_id])

  return (
    <div>
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: '' }}>คุณภาพ Statement</Typography>
        </Grid>
      </Grid>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>คะแนนรวม {totalScore !== -1 ? totalScore : '-'} คะแนน</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={3} >
            <Box>
              <DataGrid
                experimentalFeatures={{ columnGrouping: true }}
                rows={scoreData}
                columns={scoreColumns}
                autoHeight
                // pagination={false}
                disableColumnFilter
                // disableRowSelectionOnClick
                // disableSelectionOnClick
                sx={{
                  '.MuiDataGrid-footerContainer': {
                    display: 'none'
                  },
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    // lineHeight: 1.5,
                  },
                  '& .red': {
                    backgroundColor: '#FFAAAA',
                  },
                  '& .yellow': {
                    backgroundColor: '#FFE59A',
                  },
                  '& .green': {
                    backgroundColor: '#B5D8A8',
                  },
                  '& .gray': {
                    backgroundColor: '#C5BFB9',
                  },
                  '& .purple': {
                    backgroundColor: '#E7AAFD',
                  },
                  '& .head-note': {
                    cursor: 'pointer',
                    color: 'black',
                    whiteSpace: 'normal !important',
                    height: 'fit-content',
                    minHeight: '32px !important',
                    padding: '4px 10px'
                  },
                  '& .not-head-note': {
                    whiteSpace: 'nowrap !important',
                    minHeight: '32px !important',
                  }
                }}
              />
            </Box>
          </Paper>

          <Paper elevation={3} >
            <Box>
              <DataGrid
                experimentalFeatures={{ columnGrouping: true }}
                rows={scoreData2}
                columns={scoreColumns2}
                autoHeight
                // pagination={false}
                disableColumnFilter
                // disableRowSelectionOnClick
                // disableSelectionOnClick
                sx={{
                  '.MuiDataGrid-footerContainer': {
                    display: 'none'
                  },
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    // lineHeight: 1.5,
                  },
                }}
              />
            </Box>
          </Paper>
        </AccordionDetails>
      </Accordion>

      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: '' }}>Statement วันที่ 25 - 3</Typography>
        </Grid>
      </Grid>
      {
        Object.entries(sorted255).map(([group, rows], index) => {
          const columnGroupingModel = rows[0]?.reduce((arr, d, i) => {
            const accountIndex = d.i

            const fileHeader = accountIndex === undefined ? undefined : fileHeaders[accountIndex]
            const accountOd = (fileHeader?.account_od ?? 0) > 0 ? `มียอด OD: ${numbro(fileHeader?.account_od).format(NUMBER_FORMAT)}` : ``
            return [
              ...arr,
              {
                groupId: accountIndex === undefined ? 'รวมทุกบัญชี' : `บัญชี ${accountIndex + 1}: ${fileHeader.bank} ${fileHeader.account_name} ${fileHeader.account_number} ${accountOd}`,
                children: [
                  { field: 'date_' + i },
                  { field: 'balance_' + i },
                  { field: 'sumDeposit_' + i },
                  { field: 'sumDepositDescription_' + i },
                ],
              },
            ]
          }, [])

          const columns = rows[0]?.reduce((arr, d, i) => {
            return [
              ...arr,
              {
                field: 'date_' + i,
                headerName: 'วันที่',
                width: 120,
                headerAlign: 'center',
                align: 'left',
                hide: false,
                sortable: false,
                cellClassName: cellClassNamePurpleGray
              }, {
                field: 'balance_' + i,
                headerName: 'ยอดคงเหลือสิ้นวัน',
                width: 120,
                headerAlign: 'center',
                align: 'right',
                hide: false,
                sortable: false,
                cellClassName: cellClassNamePurpleGray
              }, {
                field: 'sumDeposit_' + i,
                headerName: 'เงินเข้า',
                width: 120,
                headerAlign: 'center',
                align: 'right',
                hide: false,
                sortable: false,
                cellClassName: cellClassNamePurpleGray
              }, {
                field: 'sumDepositDescription_' + i,
                headerName: 'ข้อมูลหมายเหตุ',
                width: i === 0 ? 180 : 120,
                headerAlign: 'center',
                align: 'left',
                hide: false,
                sortable: false,
                cellClassName: (params) => {
                  const fieldIndex = params.field.split('_')?.[1]
                  const balance = numbro(params.row['balance_' + fieldIndex].toString().replaceAll(/,/g, '') || 0).value()

                  const sum_deposit = numbro(params.row['sumDeposit_' + fieldIndex].toString().replaceAll(/,/g, '') || 0).value()
                  let classNames = balance > MAX_YELLOW_BALANCE ? 'green' : balance > MAX_RED_BALANCE ? 'yellow' : 'red'
                  if (checkIsBlue(params.row['sumDepositDescription_' + fieldIndex], sum_deposit)) {
                    classNames += ' blue'
                  } else if (checkIsOrange(sum_deposit)) {
                    classNames += ' orange'
                  }

                  if (i === 0) {
                    classNames += ' head-note'
                  } else {
                    classNames += ' not-head-note text-transparent'
                  }

                  return classNames
                }
              },
            ]
          }, [])

          const data = rows.reduce((data, row, i) => {
            data.push(row.reduce((arr, fileRow, j) => {
              const dateKey = fileRow.date

              arr['date_' + j] = fileRow.date
              arr['balance_' + j] = numbro(fileRow.balance).format(NUMBER_FORMAT)
              arr['sumDeposit_' + j] = numbro(fileRow.sumDeposit).format(NUMBER_FORMAT)
              arr['sumDepositDescription_' + j] = j === 0 ? (additionals?.[dateKey]?.note ?? '') : fileRow.sumDepositDescription
              arr['sumIsSalary_' + j] = fileRow.sumIsSalary === true
              return arr
            }, { id: i }))

            return data
          }, [])

          // const startDate = moment(group, DATE_FORMAT_USAGE)
          // const endDate = getEndDate255(group)
          // const diffDate = endDate.diff(startDate, 'days') + 1

          let startDate = undefined
          let endDate = undefined

          const summary = rows.reduce((arr, row, i) => {
            row.map((fileRow, j) => {
              // if (!arr['groupText_' + j]) {
              //   arr['groupText_' + j] = `${startDate.format(DATE_FORMAT_USAGE)} ถึง ${moment(rows[rows.length-1][0].date, DATE_FORMAT_USAGE).format(DATE_FORMAT_USAGE)}`
              // }
              if (!arr['sumSumDeposit_' + j]) {
                arr['sumSumDeposit_' + j] = 0
              }
              if (!arr['averageSumDeposit_' + j]) {
                arr['averageSumDeposit_' + j] = 0
              }
              if (!arr['averageBalance_' + j]) {
                arr['averageBalance_' + j] = 0
              }
              if (!arr['green_' + j]) {
                arr['green_' + j] = 0
              }
              if (!arr['yellow_' + j]) {
                arr['yellow_' + j] = 0
              }
              if (!arr['red_' + j]) {
                arr['red_' + j] = 0
              }
              if (!arr['blue_' + j]) {
                arr['blue_' + j] = 0
              }
              if (!arr['orange_' + j]) {
                arr['orange_' + j] = 0
              }

              arr['sumSumDeposit_' + j] = numbro(fileRow.sumDeposit).add(arr['sumSumDeposit_' + j]).value()
              // arr['averageSumDeposit_' + j] = numbro(fileRow.sumDeposit).divide(diffDate).add(arr['averageSumDeposit_' + j]).value()
              arr['averageSumDeposit_' + j] = numbro(fileRow.sumDeposit).add(arr['averageSumDeposit_' + j]).value()

              // arr['averageBalance_' + j] = numbro(fileRow.balance).divide(diffDate).add(arr['averageBalance_' + j]).value()
              arr['averageBalance_' + j] = numbro(fileRow.balance).add(arr['averageBalance_' + j]).value()
              arr['green_' + j] = arr['green_' + j] + (fileRow.balance > MAX_YELLOW_BALANCE ? 1 : 0)
              arr['yellow_' + j] = arr['yellow_' + j] + (MAX_YELLOW_BALANCE >= fileRow.balance && fileRow.balance > MAX_RED_BALANCE ? 1 : 0)
              arr['red_' + j] = arr['red_' + j] + (MAX_RED_BALANCE >= fileRow.balance ? 1 : 0)
              arr['blue_' + j] = arr['blue_' + j] + checkIsBlue(fileRow.sumDepositDescription, fileRow.sumDeposit)
              arr['orange_' + j] = arr['orange_' + j] + checkIsOrange(fileRow.sumDeposit)

              const date = moment(fileRow.date, DATE_FORMAT_USAGE)
              if (startDate === undefined || date.isBefore(startDate)) {
                startDate = date
              }
              if (endDate === undefined || date.isAfter(endDate)) {
                endDate = date
              }
              if (i === rows.length - 1) {
                let diffDate = 0
                if (endDate && startDate) {
                  diffDate = endDate.diff(startDate, 'days') + 1
                }
                arr['groupText_' + j] = `ช่วงวันที่ ${startDate.format(DATE_FORMAT_USAGE)} ถึง ${endDate.format(DATE_FORMAT_USAGE)} (${row.length - 1} บัญชี, ${diffDate} วัน)`

                arr['averageSumDeposit_' + j] = numbro(arr['averageSumDeposit_' + j]).divide(diffDate).value()
                arr['averageBalance_' + j] = numbro(arr['averageBalance_' + j]).divide(diffDate).value()
              }
            })

            return arr
          }, {})

          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                  <Typography>{summary['groupText_0']}</Typography>
                  <Typography>เฉลี่ยคงเหลือ {numbro(summary['averageBalance_0'] ?? 0).format(NUMBER_FORMAT)}</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {
                      (summary['green_0'] ?? 0) > 0 && (
                        <Typography sx={{ background: '#B5D8A8', p: 0.5 }}>สีเขียว {summary['green_0']}</Typography>
                      )
                    }
                    {
                      (summary['yellow_0'] ?? 0) > 0 && (
                        <Typography sx={{ background: '#FFE59A', p: 0.5 }}>สีเหลือง {summary['yellow_0']}</Typography>
                      )
                    }
                    {
                      (summary['red_0'] ?? 0) > 0 && (
                        <Typography sx={{ background: '#FFAAAA', p: 0.5 }}>สีแดง {summary['red_0']}</Typography>
                      )
                    }
                    {
                      (summary['blue_0'] ?? 0) > 0 && (
                        <Typography sx={{ background: '#00bfff', p: 0.5 }}>สีฟ้า {summary['blue_0']}</Typography>
                      )
                    }
                    {
                      (summary['orange_0'] ?? 0) > 0 && (
                        <Typography sx={{ background: '#ff9800', p: 0.5 }}>สีส้ม {summary['orange_0']}</Typography>
                      )
                    }
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Paper elevation={3} >
                  <Box>
                    <DataGrid
                      onCellClick={(params) => {
                        if (params.field === 'sumDepositDescription_0') {
                          handleAdditionSelect(params.row)
                        }
                      }}
                      getRowHeight={() => 'auto'}
                      experimentalFeatures={{ columnGrouping: true }}
                      rows={data}
                      columns={columns}
                      columnGroupingModel={columnGroupingModel}
                      autoHeight
                      // pagination={false}
                      disableColumnFilter
                      // disableRowSelectionOnClick
                      // disableSelectionOnClick
                      sx={{
                        '.MuiDataGrid-footerContainer': {
                          display: 'none'
                        },
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                          color: 'primary.main',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                          textOverflow: "clip",
                          whiteSpace: "break-spaces",
                          // lineHeight: 1.5,
                        },
                        '& .red': {
                          backgroundColor: '#FFAAAA',
                        },
                        '& .yellow': {
                          backgroundColor: '#FFE59A',
                        },
                        '& .green': {
                          backgroundColor: '#B5D8A8',
                        },
                        '& .gray': {
                          backgroundColor: '#C5BFB9',
                        },
                        '& .purple': {
                          backgroundColor: '#E7AAFD',
                        },
                        '& .blue': {
                          border: 'solid #00bfff',
                        },
                        '& .orange': {
                          border: 'solid #ff9800',
                        },
                        '& .text-transparent': {
                          color: 'transparent',
                        },
                        '& .head-note': {
                          cursor: 'pointer',
                          color: 'black',
                          whiteSpace: 'normal !important',
                          height: 'fit-content',
                          minHeight: '32px !important',
                          padding: '4px 10px'
                        },
                        '& .not-head-note': {
                          whiteSpace: 'nowrap !important',
                          minHeight: '32px !important',
                        }

                      }}
                    />
                  </Box>
                </Paper>
              </AccordionDetails>
            </Accordion>
          )

        })
      }

      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: '' }}>Statement</Typography>
        </Grid>
      </Grid>
      {
        Object.entries(sorted).map(([group, rows], index) => {
          const columnGroupingModel = rows[0]?.reduce((arr, d, i) => {
            const accountIndex = d.i

            const fileHeader = accountIndex === undefined ? undefined : fileHeaders[accountIndex]
            const accountOd = (fileHeader?.account_od ?? 0) > 0 ? `มียอด OD: ${numbro(fileHeader?.account_od).format(NUMBER_FORMAT)}` : ``
            return [
              ...arr,
              {
                groupId: accountIndex === undefined ? 'รวมทุกบัญชี' : `บัญชี ${accountIndex + 1}: ${fileHeader.bank} ${fileHeader.account_name} ${fileHeader.account_number} ${accountOd}`,
                children: [
                  { field: 'date_' + i },
                  { field: 'balance_' + i },
                  { field: 'sumDeposit_' + i },
                  { field: 'sumDepositDescription_' + i },
                ],
              },
            ]
          }, [])

          const columns = rows[0]?.reduce((arr, d, i) => {
            return [
              ...arr,
              {
                field: 'date_' + i,
                headerName: 'วันที่',
                width: 120,
                headerAlign: 'center',
                align: 'left',
                hide: false,
                sortable: false,
                cellClassName: cellClassNamePurpleGray
              }, {
                field: 'balance_' + i,
                headerName: 'ยอดคงเหลือสิ้นวัน',
                width: 120,
                headerAlign: 'center',
                align: 'right',
                hide: false,
                sortable: false,
                cellClassName: cellClassNamePurpleGray
              }, {
                field: 'sumDeposit_' + i,
                headerName: 'เงินเข้า',
                width: 120,
                headerAlign: 'center',
                align: 'right',
                hide: false,
                sortable: false,
                cellClassName: cellClassNamePurpleGray
              }, {
                field: 'sumDepositDescription_' + i,
                headerName: 'ข้อมูลหมายเหตุ',
                width: i === 0 ? 180 : 120,
                headerAlign: 'center',
                align: 'left',
                hide: false,
                sortable: false,
                cellClassName: (params) => {
                  const fieldIndex = params.field.split('_')?.[1]
                  const balance = numbro(params.row['balance_' + fieldIndex].toString().replaceAll(/,/g, '') || 0).value()
                  const sum_deposit = numbro(params.row['sumDeposit_' + fieldIndex].toString().replaceAll(/,/g, '') || 0).value()
                  let classNames = balance > MAX_YELLOW_BALANCE ? 'green' : balance > MAX_RED_BALANCE ? 'yellow' : 'red'
                  if (checkIsBlue(params.row['sumDepositDescription_' + fieldIndex], sum_deposit)) {
                    classNames += ' blue'
                  } else if (checkIsOrange(sum_deposit)) {
                    classNames += ' orange'
                  }

                  if (i === 0) {
                    classNames += ' head-note'
                  } else {
                    classNames += ' not-head-note text-transparent'
                  }

                  return classNames
                },
              },
            ]
          }, [])
          const data = rows.reduce((data, row, i) => {
            data.push(row.reduce((arr, fileRow, j) => {
              const dateKey = fileRow.date

              arr['date_' + j] = fileRow.date
              arr['balance_' + j] = numbro(fileRow.balance).format(NUMBER_FORMAT)
              arr['sumDeposit_' + j] = numbro(fileRow.sumDeposit).format(NUMBER_FORMAT)
              arr['sumDepositDescription_' + j] = j === 0 ? (additionals?.[dateKey]?.note ?? '') : fileRow.sumDepositDescription
              arr['sumIsSalary_' + j] = fileRow.sumIsSalary === true
              return arr
            }, { id: i }))

            return data
          }, [])

          const startDate = moment(group, DATE_FORMAT_USAGE)
          const endDate = getEndDate(group)
          const endDateForCheck = getEndDate(group).subtract(1, 'days')
          // endDate = endDate.endOf('month')
          // endDate.add(1, 'months')
          // const diffDate = endDate.diff(startDate, 'days')
          const diffDate = rows.length
          let ncb_credit = 0
          let ncb_net_total = 0
          for (let ncb_item of ncb_data) {
            let dateStr = "";
            if(ncb_item.dateAccountOpened.length > 0){
              dateStr = ncb_item.dateAccountOpened[0]
            }else{
              dateStr = ncb_item.dateAccountClosed2[0]
            }
            if (moment(dateStr, "DD/MM/YYYY").isSameOrAfter(startDate) && moment(dateStr, "DD/MM/YYYY").isSameOrBefore(endDateForCheck)) {
              // ncb_details = ncb_item.
              ncb_net_total = ncb_item.outstanding_debt
              let credit_limit = 0
              for (let credit_limit_item of ncb_item.creditLimit) {
                credit_limit += credit_limit_item
              }
              ncb_credit = credit_limit
              break;
            }
          }
          let ncb_details = ''
          if (ncb_net_total < 0) {
            ncb_details = `เงินเข้าจากสินเชื่อในเดือน(${numbro(ncb_credit).format(NUMBER_FORMAT)}) บัญชีปิดมากกว่าเปิด(${numbro(ncb_net_total).format(NUMBER_FORMAT)})`
          } else {
            ncb_details = `เงินเข้าจากสินเชื่อในเดือน(${numbro(ncb_credit).format(NUMBER_FORMAT)}) บัญชีเปิดมากกว่าปิด(${numbro(ncb_net_total).format(NUMBER_FORMAT)})`
          }
          const summary = rows.reduce((arr, row, i) => {
            row.map((fileRow, j) => {
              if (!arr['groupNCBText_' + j]) {
                arr['groupNCBText_' + j] = `${ncb_details}`
              }
              if (!arr['groupRangeText_' + j]) {
                arr['groupRangeText_' + j] = `ช่วงวันที่ ${startDate.format(DATE_FORMAT_USAGE)} ถึง ${moment(rows[rows.length-1][0].date, "DD-MM-YYYY").format(DATE_FORMAT_USAGE)} `
              }
              if (!arr['sumSumDeposit_' + j]) {
                arr['sumSumDeposit_' + j] = 0
              }
              if (!arr['averageSumDeposit_' + j]) {
                arr['averageSumDeposit_' + j] = 0
              }
              if (!arr['averageBalance_' + j]) {
                arr['averageBalance_' + j] = 0
              }
              if (!arr['green_' + j]) {
                arr['green_' + j] = 0
              }
              if (!arr['yellow_' + j]) {
                arr['yellow_' + j] = 0
              }
              if (!arr['red_' + j]) {
                arr['red_' + j] = 0
              }
              if (!arr['blue_' + j]) {
                arr['blue_' + j] = 0
              }
              if (!arr['orange_' + j]) {
                arr['orange_' + j] = 0
              }

              arr['sumSumDeposit_' + j] = numbro(fileRow.sumDeposit).add(arr['sumSumDeposit_' + j]).value()
              arr['averageSumDeposit_' + j] = numbro(fileRow.sumDeposit).divide(diffDate).add(arr['averageSumDeposit_' + j]).value()

              arr['averageBalance_' + j] = numbro(fileRow.balance).divide(diffDate).add(arr['averageBalance_' + j]).value()
              arr['green_' + j] = arr['green_' + j] + (fileRow.balance > MAX_YELLOW_BALANCE ? 1 : 0)
              arr['yellow_' + j] = arr['yellow_' + j] + (MAX_YELLOW_BALANCE >= fileRow.balance && fileRow.balance > MAX_RED_BALANCE ? 1 : 0)
              arr['red_' + j] = arr['red_' + j] + (MAX_RED_BALANCE >= fileRow.balance ? 1 : 0)
              arr['blue_' + j] = arr['blue_' + j] + checkIsBlue(fileRow.sumDepositDescription, fileRow.sumDeposit)
              arr['orange_' + j] = arr['orange_' + j] + checkIsOrange(fileRow.sumDeposit)
            })
            return arr
          }, {})

          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                  {/* <div width="100%"> */}
                  <Typography>{summary['groupNCBText_0']}</Typography>
                  <Typography>{summary['groupRangeText_0']} </Typography>
                  <Typography>({rows[0].length - 1} บัญชี, {rows.length} วัน) เฉลี่ยคงเหลือ {numbro(summary['averageBalance_0'] ?? 0).format(NUMBER_FORMAT)}</Typography>
                  {/* </div>
                  <div> */}
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {
                      (summary['green_0'] ?? 0) > 0 && (
                        <Typography sx={{ background: '#B5D8A8', p: 0.5 }}>สีเขียว {summary['green_0']}</Typography>
                      )
                    }
                    {
                      (summary['yellow_0'] ?? 0) > 0 && (
                        <Typography sx={{ background: '#FFE59A', p: 0.5 }}>สีเหลือง {summary['yellow_0']}</Typography>
                      )
                    }
                    {
                      (summary['red_0'] ?? 0) > 0 && (
                        <Typography sx={{ background: '#FFAAAA', p: 0.5 }}>สีแดง {summary['red_0']}</Typography>
                      )
                    }
                    {
                      (summary['blue_0'] ?? 0) > 0 && (
                        <Typography sx={{ background: '#00bfff', p: 0.5 }}>สีฟ้า {summary['blue_0']}</Typography>
                      )
                    }
                    {
                      (summary['orange_0'] ?? 0) > 0 && (
                        <Typography sx={{ background: '#ff9800', p: 0.5 }}>สีส้ม {summary['orange_0']}</Typography>
                      )
                    }
                  </Box>

                  {/* </div> */}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Paper elevation={3} >
                  <Box>
                    <DataGrid
                      onCellClick={(params) => {
                        if (params.field === 'sumDepositDescription_0') {
                          handleAdditionSelect(params.row)
                        }
                      }}
                      getRowHeight={() => 'auto'}
                      experimentalFeatures={{ columnGrouping: true }}
                      rows={data}
                      columns={columns}
                      columnGroupingModel={columnGroupingModel}
                      autoHeight
                      // pagination={false}
                      disableColumnFilter
                      // disableRowSelectionOnClick
                      // disableSelectionOnClick
                      sx={{
                        '.MuiDataGrid-footerContainer': {
                          display: 'none'
                        },
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                          color: 'primary.main',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                          textOverflow: "clip",
                          whiteSpace: "break-spaces",
                          // lineHeight: 1.5,
                        },
                        '& .red': {
                          backgroundColor: '#FFAAAA',
                        },
                        '& .yellow': {
                          backgroundColor: '#FFE59A',
                        },
                        '& .green': {
                          backgroundColor: '#B5D8A8',
                        },
                        '& .gray': {
                          backgroundColor: '#C5BFB9',
                        },
                        '& .purple': {
                          backgroundColor: '#E7AAFD',
                        },
                        '& .blue': {
                          border: 'solid #00bfff',
                        },
                        '& .orange': {
                          border: 'solid #ff9800',
                        },
                        '& .text-transparent': {
                          color: 'transparent',
                        },
                        '& .head-note': {
                          cursor: 'pointer',
                          color: 'black',
                          whiteSpace: 'normal !important',
                          height: 'fit-content',
                          minHeight: '32px !important',
                          padding: '4px 10px'
                        },
                        '& .not-head-note': {
                          whiteSpace: 'nowrap !important',
                          minHeight: '32px !important',
                        }
                      }}
                    />
                  </Box>
                </Paper>
              </AccordionDetails>
            </Accordion>
          )

        })
      }

      <StatementNoteFooter />
      
      <StatementAdditionalDialog
        additional={selectedAdditional}
        onChange={handleAdditionSave}
        handleClose={handleAdditionDialogClose}
      />
    </div>
  );
}
