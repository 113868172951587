exports.ROOT_URL = "https://master-api.transfinmational.org"
exports.PROD_API_URL = "https://apiv2-uat.transfinmational.net" //"https://ocr-api.staging.batmaster.io:40443"
exports.ORC_URL = "https://ocr-api-mscw.tfm.in.th" //"http://localhost:4440"
exports.FILE_API_URL = "https://fliemng-api-mscw.tfm.in.th"
exports.MSCW_URL = "https://api-mscw.transfinmational.net" //"http://localhost:44447"
exports.PICO_URL = "https://api-uat.transfinmational.net"
exports.LOGIN_URL = "https://auth-api.tfm.in.th"
exports.DD_URL = "https://hackathon-horacle-app.manmuson.com"
exports.DC_URL = "https://hackathon-horacle-app.manmuson.com"
exports.DTA_API = "https://api-app.transfinmational.org"

exports.COMMITEE_URL = "https://committee-mscw.tfm.in.th"
// exports.FILE_API_URL2 = "http://localhost:4440"
// export const ROOT_URL = "https://master-api.transfinmational.org"
// export const PROD_API_URL = "http://localhost:8001"
// exports.DD_URL  = "http://localhost:1599"
// exports.DC_URL = "http://localhost:1599"
// exports.MSCW_URL = "http://localhost:3334"

exports.MKT_URL = "https://api-app.transfinmational.org"

// REACT_APP_AUTHENTICATION=false
// ROOT_URL="https://master-api.staging.batmaster.io:40443"
// PROD_API_URL="https://apiv2-uat.staging.batmaster.io:40443", # TODO CHECK
// ORC_URL="https://ocr-api.staging.batmaster.io:40443"
// FILE_API_URL="https://fliemng-api.staging.batmaster.io:40443"
// MSCW_URL="https://ncb-api.staging.batmaster.io:40443"
// PICO_URL="https://api-uat.transfinmational.net" # NOT USE NOW
// LOGIN_URL="https://auth-api.staging.batmaster.io:40443"
// exports.DD_URL="http://localhost:44446" 
// exports.DC_URL="http://localhost:44446"
// # DC_URL="https://payment-api.staging.batmaster.io:40443"
