import * as DirectDebit from '../../services/direct_debit.js';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { CSVLink } from 'react-csv';
import { confirmAlert } from "react-confirm-alert";

// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// components
import MyComponent from '../../components/filter.js';
import { react } from '@babel/types';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import { queryBalance, withdraw } from '../../services/gb_transfer.js'

export default function App() {
    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [open, setOpen] = React.useState(true);
    const [rows_tran, setRowsTran] = React.useState([]);
    const [start_report, setStartReport] = React.useState(new Date());
    const [end_report, setEndReport] = React.useState(new Date());
    const [export_data, setExportData] = React.useState([]);
    const location = useLocation();
    const has_permission = checkPermission(location.pathname, decoded);
    const [gb_balance, setGBBalance] = React.useState(0)
    React.useEffect(() => {
        if (!has_permission) {
            window.location.href = '/404';
        }
        queryBalance(token).then(res => {
            // console.log(res.data.data.netBalance);
            setGBBalance(res.data.data.netBalance)
        })
    }, [])
    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'No.',
            width: 100,
            headerAlign: 'center',
            align: 'center'
            ,
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
        },
        {
            field: 'col3',
            headerName: 'วันที่ชำระเงิน',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.transaction_date == null ? "-" : params.row.transaction_date.split("T")[0]}`,
        },
        {
            field: 'col4',
            headerName: 'เวลา',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.transaction_date == null ? "-" : new Date(params.row.transaction_date).toLocaleTimeString("th-TH", { timeZone: "UTC" })}`,
        },
        {
            field: 'col5',
            headerName: 'ชื่อผู้ชำระเงิน',
            width: 250,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.name || ''} ${params.row.lastname || ''}`,
        },
        {
            field: 'contract_no',
            headerName: 'รหัสอ้างอิง1',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.contract_no.replace(/[`~%^&*!@#$()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')}`,
        },
        {
            field: 'referenceNo',
            headerName: 'รหัสอ้างอิง2',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'GB_amount',
            headerName: 'จำนวนเงิน',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.amount - params.row.vat}`,

        },
        {
            field: 'col9',
            headerName: 'ช่องทาง',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${'Direct Debit'}`,
        },
        // {
        //     field: 'col10',
        //     headerName: 'เลขที่เช็ค',
        //     width: 150,
        //     editable: true,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        // {
        //     field: 'col11',
        //     headerName: 'สถานะของเช็ค',
        //     width: 150,
        //     editable: true,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        {
            field: 'bank_no',
            headerName: 'เลขที่บัญชี',
            width: 150,
            editable: true,
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'col13',
        //     headerName: 'สาขา',
        //     width: 150,
        //     editable: true,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        // {
        //     field: 'col14',
        //     headerName: 'ธนาคารของเช็ค',
        //     width: 150,
        //     editable: true,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        // {
        //     field: 'col15',
        //     headerName: 'สาขาของเช็ค',
        //     width: 150,
        //     editable: true,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        // {
        //     field: 'col16',
        //     headerName: 'วันที่บนหน้าเช็ค',
        //     width: 150,
        //     editable: true,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        // {
        //     field: 'col17',
        //     headerName: 'เหตุผลของเช็คคืน',
        //     width: 150,
        //     editable: true,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        // {
        //     field: 'col18',
        //     headerName: 'ค่าธรรมเนียมรายการ',
        //     width: 150,
        //     editable: true,
        //     headerAlign: 'center',
        //     align: 'center',
        //     valueGetter: (params) =>
        //         `${'10.70'}`,
        // },
        // {
        //     field: '',
        //     headerName: 'Action',
        //     width: 60,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderHeader: (params) => (
        //         <MdOpenInNew onClick={() => handleOpen()} fontSize={20} />
        //     ),
        // },

        // {
        // field: '',
        // headerName: '',
        // description: 'This column has a value getter and is not sortable.',
        // sortable: false,
        // type: 'number',
        // width: 160,
        // valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    // rows
    const onGetReport = () => {
        let obj = {
            start_report: start_report,
            end_report: end_report
        }
        DirectDebit.getTransactionExport(obj)
            .then((res) => {
                // console.log(res)
                setRowsTran(res.data.data)
                // console.log(res);
                let datas = res.data.data
                let export_data = []
                datas.map(data => {
                    let time = new Date(data.transaction_date).toLocaleTimeString("th-TH", { timeZone: "UTC" })
                    let contract_no  = data.contract_no ===null ?'' : data.contract_no.replace(/[`~%^&*!@#$()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
                    let row_data = [
                        'MSCW',
                        data.transaction_date.split("T")[0],
                        time,
                        data.name + ' ' + data.lastname,
                        contract_no,
                        data.referenceNo,
                        data.GB_amount - data.vat,
                        'Direct Debit',
                        '',
                        '',
                        data.bank_no,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '0'
                    ]
                    export_data.push(row_data);
                })
                console.log(export_data)
                setExportData(export_data);
            })
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob(["\uFEFF" + data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    let header = [
        'รหัสบริการ',
        'วันที่ชำระเงิน',
        'เวลา',
        'ชื่อผู้ชำระเงิน',
        'รหัสอ้างอิง1',
        'รหัสอ้างอิง2',
        'จำนวนเงิน',
        'ช่องทาง',
        'เลขที่เช็ค',
        'สถานะของเช็ค',
        'เลขที่บัญชี',
        'สาขา',
        'ธนาคารของเช็ค',
        'สาขาของเช็ค',
        'วันที่บนหน้าเช็ค',
        'เหตุผลของเช็คคืน',
        'ค่าธรรมเนียมรายการ'
    ]

    const handleExportButton = () => {

        if (start_report > end_report) {
            alert("คุณเลือกวันที่เริ่ม Export มากกว่าวันที่สิ้นสุดการ Export \n กรุณาเลือกใหม่อีกครั้ง");
        }
        else if (rows_tran.length <= 0) {
            alert("ไม่สามารถExportข้อมูลได้ เนื่องจากคุณยังไม่ได้กดค้นหา \n หรือไม่มีข้อมูลในวันดังกล่าว")
        } else {
            csvLink.current.link.click()
        }
    }
    const handleWithdrawButton = () => {
        console.log("eiei");
        confirmAlert({
            title: "ถอนเงินทั้งหมด "+gb_balance,
            message: "คุณต้องการถอนเงินทั้งหมดหรือไม่",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                    let data = {
                        amount: gb_balance
                    }
                    withdraw(data,token).then(res=>{
                        console.log(res);
                        if(res.data.data.resultCode === "00"){
                            alert("ถอนเงินสำเร็จ")
                        }else{
                            alert("เกิดข้อผิดพลาด "+res.data.resultCode)
                        }
                    })
                    
                }
              },
              {
                label: "No"
                // onClick: () => alert("Click No")
              }
            ]
          });
    }

    const csvLink = React.useRef()
    return (
        <>
            {has_permission && (
                <div className='customer-list' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: '' }}>Pay Success Export</Typography>
                        </Grid>

                    </Grid>
                    {
                        false ?
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            : ""
                    }

                    <Paper elevation={3} >
                        <Typography variant='h6' style={{ padding: '10px' }}><span>Export</span> <span>Success</span> payment list</Typography>
                        {/* <MyComponent /> */}
                        <Grid container spacing={2} style={{ padding: '1rem' }}>
                            <Grid item >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="วันที่เริ่มต้น"
                                        inputFormat="YYYY-MM-DD"
                                        value={start_report}
                                        onChange={(date) => setStartReport(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}

                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="วันที่สิ้นสุด"
                                        inputFormat="YYYY-MM-DD"
                                        value={end_report}
                                        onChange={(date) => setEndReport(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />

                                </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <Button style={{ float: 'right' }} variant="contained" onClick={() => onGetReport()}>ค้นหา</Button>
                            </Grid>

                            <Grid item>
                                <Button variant="outlined" color="success" style={{ width: '100px' }} onClick={handleExportButton}>
                                    Export
                                </Button>
                                <CSVLink
                                    headers={header}
                                    data={export_data}
                                    filename='export_data.csv'
                                    ref={csvLink}
                                    target='_blank'
                                >
                                </CSVLink>
                            </Grid>
                            <Grid item>
                            <Typography variant='h4' style={{ fontWeight: '' }}>ยอดเงินทั้งหมด {gb_balance}</Typography>
                            </Grid>
                            <Grid item>
                                <Button style={{ float: 'right' }} variant="contained" onClick={handleWithdrawButton}>ถอนเงินทั้งหมด</Button>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                                // bgcolor:'black'
                            }}
                        >
                            <DataGrid
                                rows={rows_tran}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}

                            />
                        </Box>
                    </Paper>
                </div>
            )}
        </>
    )

}