import * as React from 'react';
import * as tackingHttpServices from '../../services/tacking_service';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import { confirmAlert } from 'react-confirm-alert';
import { useConfirm } from "material-ui-confirm";
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Input } from '@mui/material';
import * as uploadHttpServices from '../../services/upload_service';

import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../App.css'
import Textarea from '@mui/joy/Textarea';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 550,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const style_note = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const columns = [
    { id: 'name', label: 'name', width: '200' },
    { id: 'date_doc', label: 'วันที่รับเอกสาร', width: '10%' },
    { id: 'password', label: 'password', width: '10%' },
    { id: 'status', label: 'สถานะเอกสาร', width: '20%' },
    { id: 'button3', width: '40%' },
    { id: '', width: 200, label: 'Download' },
];

const headCells = [

    {
        id: 'type',
        disablePadding: false,
        width: 250,
        label: 'ประเภทเอกสาร',
    },
    {
        id: 'date_receipt',
        disablePadding: false,
        width: 200,
        label: 'วันที่รับเอกสาร',
    },
    {
        id: 'status',
        width: 150,
        label: 'สถานะเอกสาร',
    },

    {
        id: 'note',
        width: 200,
        label: 'หมายเหตุ',
    },
    {
        id: 'edit',
        width: 200,
        label: '',
    },
    {
        id: '',
        width: 200,
        label: 'เพิ่มเติม',
    },


];


function MyApp() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [contact_id, setContactId] = React.useState(queryParams.get('contact_id'));
    const [user_id, setUserId] = React.useState(queryParams.get('user_id'));
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [rows, setRows] = React.useState([]);
    const [status, setStatus] = React.useState(false)
    const [file_type, setFileType] = React.useState([]);
    const [tag, setTag] = React.useState(false)
    const [type, setType] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [open_note, setOpenNote] = React.useState(false);
    const [note, setNote] = React.useState('');
    const [rows_data, setRowsData] = React.useState([]);
    const [selectedFile, setSelectedFile] = React.useState(null);

    const handleClose = () => setOpen(false);
    const handleCloseEdit = () => setOpenNote(false);
    let token = localStorage.getItem('token_org')
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm();
    //filter
    const [operator, setOperater] = React.useState([true, true, true, true, true, true, true])
    const [filter, setFilter] = React.useState(true)
    // console.clear();

    const [selected_bank, setSelectedBank] = React.useState(0)

    React.useEffect(() => {

        setRows([])
        setStatus(false)
        setSelected([])
        setTag(false)

        let test = []

        let object = {
            "pagination": {
                "page": 1,
                "rowsPerPage": 10000,
                "sortBy": "",
                "descending": true
            },
            "filters": test
        }
        tackingHttpServices.getFileStatuslist(token, contact_id)
            .then((res) => {
                setStatus(true)
                if (res.data.data != null) {
                    setRows(res.data.data)
                } else {
                    setRows([])

                }
            })
    }, [filter])

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align='left'
                            padding='normal'
                            width={headCell.width}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };
    const EnhancedTableToolbar = (props) => {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.text.primary, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
            </Toolbar>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => n.id);

            let withoutDuplicates = [...new Set(selected.concat(newSelecteds))];

            setSelected(withoutDuplicates);

            return;
        }

        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const display_status = (row) => {
        let str
        switch (row.file_type) {
            case "id_card_no":
                return display(row.id_card_status)
                break;
            case "statement":
                return display(row.statement_status)
                break;
            case "house_regis":
                return display(row.house_regis_status)
                break;
            case "other":
                return display(row.orther_file_status)
                break;
            case "salary":
                return display(row.salary_status)
                break;
            case "book_bank":
                return display(row.book_bank_status)
                break;
            case "auth":
                return display(row.auth_status)
                break;
            default:
        }
        function display(status) {
            switch (status) {
                case 0:
                    return <div>ยังไม่ได้รับ</div>
                case 1:
                    return <div>รอตรวจสอบ</div>
                case 2:
                    return <div>รอแก้ไข</div>
                case 3:
                    return <div>ผ่าน</div>
            }
        }
    }

    const getFile = (row) => {
        let obj = {
            user_id: user_id,
            contact_id: contact_id,
            file_type: row.file_type,
            name: row.file_name
        }
        tackingHttpServices.getFile(token, obj)
            .then((res) => {
                var myParam = res.data.data;
                window.open('/preview?param=' + encodeURIComponent(myParam), '_blank');

                // navigate("/test", {
                //     state: res.data.data,
                // });
            })
    }
    const getFileDownload = (row) => {
        let obj = {
            user_id: user_id,
            contact_id: contact_id,
            file_type: row.file_type,
            name: row.file_name
        }

        tackingHttpServices.getFile(token, obj)
            .then((res) => {
                var myParam = res.data.data;
                window.open(myParam);

                // navigate("/test", {
                //     state: res.data.data,
                // });
            })
    }

    const handleAnalysis = (row) => {

        window.open('/statement_analysis_quick')
    }

    const openPopup = (row) => {
        setType(row.file_type)
        let obj = {
            user_id: user_id,
            contact_id: contact_id,
            file_type: row.file_type,
        }
        tackingHttpServices.getFileType(token, obj)
            .then((res) => {
                if (res.data.data != null) {
                    setFileType(res.data.data)
                    setOpen(true)
                } else {
                    setFileType([])
                    setOpen(true)
                }
            })
    }
    const handleEdit = (row, status) => {

        if (row != 'note') {
            var obj = {
                user_id: row.user_id,
                contact_id: row.contact_id,
                file_id: row.file_id,
                status: status,
                file_type: row.file_type
            }
        } else {
            var obj = {
                user_id: rows_data.user_id,
                contact_id: rows_data.contact_id,
                file_type: rows_data.file_type,
                note: note,
                type_edit: 'note'
            }
        }

        confirmAlert({
            title: 'ยืนยันที่จะอัพเดทสถานะ?',
            message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        tackingHttpServices.updateFileStatus(token, obj)
                            .then((res) => {
                                window.alert('อัพเดทสถานะสำเร็จ')
                                setNote('')
                                setRowsData([])
                                window.location.reload();

                            })

                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })

    };
    const dateFomate = (row) => {
        var today = new Date(row.date)
        let date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
        return date
    }
    const nameFomate = (file_type) => {

        switch (file_type) {
            case "id_card_no":
                return 'บัตรประชาชน'
                break;
            case "statement":
                return 'statement'
                break;
            case "house_regis":
                return 'ทะเบียนบ้าน'
                break;
            case "other":
                return 'เอกสารอื่นๆ'
            case "salary":
                return 'สลีปเงินเดือน/หนังสือรับรองรายได้'
                break;
            case "book_bank":
                return 'หน้าสมุดบัญชีธนาคาร'
                break;
            case "auth":
                return 'รูปถ่ายคู่บัตรประชาชน'
                break;
            default:
        }
    }

    const editNote = (row) => {
        setRowsData(row)
        setNote(row.note)
        setOpenNote(true)
    }

    // const onClickUpdate = () => {

    //     let obj = {
    //         user_id: rows_data.user_id,
    //         contact_id: rows_data.contact_id,
    //         file_type: rows_data.file_type,
    //         note: note
    //     }
    //     // console.log(obj)
    //     // confirmAlert({
    //     //     title: 'ยืนยันที่จะอัพเดทสถานะ?',
    //     //     message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
    //     //     buttons: [
    //     //         {
    //     //             label: 'ตกลง',
    //     //             onClick: () => {
    //     //                 tackingHttpServices.updateFileStatus(token, obj)
    //     //                     .then((res) => {
    //     //                         console.log(res)
    //     //                         window.alert('อัพเดทสถานะสำเร็จ')
    //     //                         window.location.reload();

    //     //                     })

    //     //             }
    //     //         },
    //     //         {
    //     //             label: 'ยกเลิก',
    //     //             onClick: () => {
    //     //                 console.log("no")
    //     //             }
    //     //         }
    //     //     ],
    //     //     overlayClassName: "overlay-custom-class-name"
    //     // })
    // }

    const handleFileChange = async(event) => {
        setSelectedFile(event.target.files[0]);
        let formData1 = event.target.files[0]
        let formData2 = event.target.files[0].name
        const file = event.target.files[0];
        const typeFile = event.target.files[0].type.split('/')[1]
        const fileSize = file.size
        const maxFileSize = 10 * 1024 * 1024; // 10 MB
        let password = ''
     if (type === 'statement') {
            password = window.prompt("เอกสารนี้ได้รับการป้องกันด้วยรหัสผ่าน โปรดป้อนรหัสผ่าน:");
    }
        // ตรวจสอบขนาดไฟล์
        if (fileSize > maxFileSize) {
            alert('ไฟล์ขนาดใหญ่เกินกว่าที่รองรับ');
            event.target.value = null; // ล้างค่า input field
            return;
        }
         const formData = new FormData();
            formData.append(
                "files",
                formData1
            )
            formData.append(
                "file_name",
                formData2
            )
            formData.append(
                "password",
                password
            )
            formData.append(
                "type",
                type
            )
            formData.append(
                "id_card_no",
                user_id
            )
            formData.append(
                "contact_no",
                contact_id
            )
        const res = await uploadHttpServices.uploadfile(formData);

        if(res.data.status){
            alert("อัพโหลดสำเร็จ")
            let data ={
                file_type: type
            }
            openPopup(data)
        }
    };

    // const handleUpload = () => {
    //     // You can perform actions with the selected file here
    //     if (selectedFile) {
    //         console.log('File uploaded:', selectedFile);
    //         // You can upload the file to a server or perform other operations
    //     } else {
    //         console.log('Please select a file to upload');
    //     }
    // };
    return (
        <Box sx={{ width: '100%' }}>
            {
                rows.length === 0 && !status ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ""
            }

            <Paper sx={{ width: '100%', mb: 2 }}>

                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table
                        // sx={{ minWidth: 750 }}
                        // aria-labelledby="tableTitle"
                        // size='medium'
                        stickyHeader aria-label="sticky table"
                    >

                        {/* <Table stickyHeader aria-label="sticky table"> */}
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}

                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow>
                                            {/* <TableCell >{row.create_date  ==null ? "-" :row.create_date.split("T")[0]}</TableCell> */}
                                            <TableCell >{nameFomate(row.file_type)}</TableCell>
                                            <TableCell >{row.create_date == null || row.status == 0 ? "-" : row.create_date.split("T")[0]}</TableCell>
                                            <TableCell>{display_status(row)}</TableCell>
                                            <TableCell>
                                                <TextField id="outlined-basic" label="" variant="outlined" value={row.note} size="small" disabled /></TableCell>
                                            <TableCell > <Button variant="text" onClick={(e) => editNote(row)}><EditIcon />{'\u00a0'}</Button> </TableCell>
                                            <TableCell><IconButton onClick={(e) => openPopup(row)}><SearchIcon color="primary" /></IconButton></TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 300, 500,]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {/* รายการเอกสาร */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* {console.log(file_type)} */}
                    <h2 id="child-modal-title" className='test'>รายการเอกสาร :{nameFomate(type)}</h2>
                    <div>
                        <input
                            // accept="image/*" // Specify the accepted file types, e.g., image/* for images
                            id="contained-button-file"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" component="span" color="primary" >
                                อัพโหลดเอกสาร
                            </Button>
                        </label>
                    </div>
                    <TableContainer sx={{ maxHeight: 380 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ width: column.width }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    {/* {nameFomate(type) === "statement" ? 
                                    <TableCell>
                                        Bank
                                    </TableCell> : 
                                    null}
                                    {nameFomate(type) === "statement" ? 
                                    <TableCell>
                                        Analysis
                                    </TableCell> : 
                                    null} */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(file_type).map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <TableCell >{row.file_name}</TableCell>
                                            <TableCell >{dateFomate(row)}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }} >{row.password == 'undefined' ? "-" : row.password}</TableCell>
                                            <TableCell>
                                                <Select
                                                    value={row.status}
                                                    // onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    style={{ width: 150 }}
                                                >
                                                    <MenuItem value={1} onClick={() => handleEdit(row, 1)}>รอการตรวจสอบ</MenuItem>
                                                    <MenuItem value={2} onClick={() => handleEdit(row, 2)}>รอการแก้ไข</MenuItem>
                                                    <MenuItem value={3} onClick={() => handleEdit(row, 3)}>ได้รับครบถ้วน</MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell ><IconButton onClick={(e) => getFile(row)}><SearchIcon color="primary" /></IconButton> </TableCell>
                                            <TableCell ><IconButton onClick={(e) => getFileDownload(row)}><SaveAltIcon color="primary" /></IconButton> </TableCell>
                                            {/* {
                                                nameFomate(type) === "statement" && (
                                                    row.file_name.split('.')[1] === "PDF" 
                                                    ||row.file_name.split('.')[1] === "pdf"
                                                ) ? 
                                                <TableCell>
                                                    <Select
                                                        value={selected_bank}
                                                        // onChange={handleChange}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        style={{ width: 150 }}
                                                    >
                                                        <MenuItem 
                                                            value={0} 
                                                            onClick={() =>setSelectedBank(0)}
                                                        >
                                                            --Please Select Bank--
                                                        </MenuItem>
                                                        <MenuItem 
                                                            value={1} 
                                                            onClick={() =>setSelectedBank(1)}
                                                        >
                                                            K-Bank
                                                        </MenuItem>
                                                        <MenuItem 
                                                            value={2} 
                                                            onClick={() => setSelectedBank(2)}
                                                        >
                                                            BBL
                                                        </MenuItem>
                                                        <MenuItem 
                                                            value={3} 
                                                            onClick={() => setSelectedBank(3)}
                                                        >
                                                            SCB
                                                        </MenuItem>
                                                    </Select>
                                                </TableCell>
                                                : 
                                                    null
                                            }
                                            {
                                                nameFomate(type) === "statement" && (
                                                    row.file_name.split('.')[1] === "PDF" 
                                                    ||row.file_name.split('.')[1] === "pdf"
                                                ) ? 
                                                <TableCell>
                                                    <IconButton onClick={(e) => handleAnalysis(row)}>
                                                        <QueryStatsIcon color="primary" />
                                                    </IconButton> 
                                                </TableCell> : 
                                                null
                                            } */}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={file_type.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Modal>
            {/*Modal หมายเหตุ */}
            <Modal
                open={open_note}
                onClose={handleCloseEdit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_note}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenNote(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* {console.log(file_type)} */}
                    <h2 id="child-modal-title">เพิ่มหมายเหตุ</h2>
                    <Textarea minRows={5} id="outlined-basic" label="หมายเหตุ" variant="outlined" size="small" value={note} onChange={(e) => setNote(e.target.value)} />
                    <br></br>
                    <Button style={{ float: 'right' }} variant="contained" onClick={(e) => handleEdit('note')}>บันทึก</Button>
                </Box>
            </Modal>
        </Box>
    );
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={1}>

            <MyApp />
        </SnackbarProvider>
    );
}

