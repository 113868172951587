import React from 'react';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

export default function MarketingDashboardInputForm({
    header,
    is_open,
    handleCloseModal,
    value,
    setValue,
    handleSaveButton
}) {
    return (
        <>
            <Dialog
                open={is_open}
                onClose={() => handleCloseModal()}
                fullWidth
                aria-labelledby="AddUser-Dialog-title"
                aria-describedby="AddUser-Dialog-Content"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "400px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle
                    id="AddUser-Dialog-title"
                >
                    {header}
                </DialogTitle>
                <DialogContent
                    id="AddUser-Dialog-Content"
                >
                    <TextField
                        fullWidth
                        id="member_name"
                        // label="ชื่อ"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        required
                    />
                </DialogContent>
                <DialogActions style={{ padding: '1.5rem' }}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleSaveButton()}
                    >
                        บันทึก
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleCloseModal()
                        }}
                    >
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}