import React from 'react';
import CustomerForm from '../../components/form/customer-form';
import { useLocation } from 'react-router-dom';


const TestPage = () => {

    const location = useLocation();
    console.log(location.state)

    return (
        <div>
            <CustomerForm data={location.state.data} ncb= {location.state.ncb} />
        </div>
    );
};

export default TestPage;