import * as React from 'react';

import TableData from '../../components/table/table_contact_management.js';
import CollectorExportModal from '../../components/modal/CollectorExportModal.js';
import './../sum.css'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SideBar from './../../page/dashboard'
import jwt_decode from "jwt-decode";
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import { decode } from 'punycode';

export default function App() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let token = localStorage.getItem('token')
    let decoded
    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
    const location = useLocation();
    const hasPermission = checkPermission(location.pathname,decoded); 
    const handleOpenReport = (event) => {
        window.location.href = 'https://app.powerbi.com/view?r=eyJrIjoiMzgxNmEwZjYtYjMwNy00YWM3LWI2YzktZDQwZDY0YWI3NGQyIiwidCI6IjM5MzM4MmNhLTFkOTMtNDNiYy1hZDI5LWViNTc5MDNlN2RkYyIsImMiOjEwfQ%3D%3D'
    }
    React.useEffect(() => {
        if(!hasPermission){
            window.location.href = '/404';
        }
    },[])

    return (
        <>
        {/*hasPermission*/ true && (
        <div className='contact-management' style={{ margin: '2rem' }}>
            <SideBar />
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item ms>
                    <Typography variant='h4' style={{ fontWeight: 'bold' }}>{decoded.org_id === 1 ? 'PICO Contact Management' : 'MSCW Contact Management'}</Typography>
                </Grid>
                {/* <Grid item xs style={{ alignSelf: 'flex-end' , marginLeft : '10px' }}>
                    <Button onClick={handleOpen}>
                        Export
                    </Button>
                </Grid>
                <Grid item xs style={{ alignSelf: 'flex-end' }}>
                    <Button onClick={handleOpenReport}>
                        Report
                    </Button>
                </Grid> */}
            </Grid>
            <CollectorExportModal 
                is_open={open}
                handleClose={handleClose}
                token={token}
            />
            <TableData/>
        </div>
    )}
    </>
    )
}