// const libheif = require("libheif-js")

// const getImageData = async image => {
//   const width = image.get_width()
//   const height = image.get_height()

//   const imageData = await new Promise((resolve, reject) => {
//     const whiteImage = new ImageData(width, height)
//     image.display(whiteImage, displayData => {
//       if (!displayData) {
//         return reject(new Error("HEIF processing error"))
//       }
//       resolve(displayData)
//     })
//   })

//   return imageData
// }

// const imageDataToDataURL = (imageData, type = "image/jpeg", quality = 0.7) => {
//   const canvas = document.createElement("canvas")
//   canvas.width = imageData.width
//   canvas.height = imageData.height
//   const ctx = canvas.getContext("2d")

//   ctx.putImageData(imageData, 0, 0)
//   return canvas.toDataURL(type, quality)
// }

// const decodeFile = async (blob, multiple = false) => {
//   const decoder = new libheif.HeifDecoder()
//   let data

//   try {
//     data = decoder.decode(await blob.arrayBuffer())
//   } catch (error) {
//     console.log(error)
//   }

//   if (!data.length) {
//     throw new Error("HEIF image not found")
//   }

//   if (!multiple) {
//     return imageDataToDataURL(await getImageData(data[0]))
//   }

//   return data.map(async image => {
//     return imageDataToDataURL(await getImageData(image))
//   })
// }

// export { decodeFile }

export function debtStructure(status) {
    if (status === '01') {
        return "01 - ปรับปรุงโครงสร้างหนี้เชิงป้องกัน (Pre-emptive DR)"
    } else if (status === '02') {
        return "02 - ปรับปรุงโครงสร้างหนี้ที่มีปัญหา (TDR)"
    } else if (status === '03') {
        return "03 - ทำข้อตกลงเปลี่ยนแปลงเงื่อนไขสัญญาตามมาตราการ PD (Persistent Debt)"
    } else if (status === '00') {
        return "00 - ไม่มีข้อมูล (Null)"
    } else {
        return null
    }
}

export function DateOfDebtStructure(status, date) {
    if (['01', '02', '03'].includes(status)) {
        return date;
    } else {
        return null;
    }
}