import * as XLSX from 'xlsx';

const ExcelExport = (data, type) => {
        function formatDateToYMD(date) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
        }

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        const current_date = new Date();
        const formatted_date = formatDateToYMD(current_date)
        XLSX.utils.book_append_sheet(wb,ws,'sheet1');
        XLSX.writeFile(wb,`${type}_${formatted_date}.xlsx`);
}
export default ExcelExport;