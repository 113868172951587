import * as React from 'react';
import { useEffect } from 'react';
import TableData from '../../components/table/customer_pending.js';
import '../sum.css'
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import { ConfirmProvider } from "material-ui-confirm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { checkPermission } from '../../utils/permission_utils.js';
import Button from '@mui/material/Button';
export default function App() {
    const { state } = useLocation();
    const location = useLocation();
    const navigate = useNavigate();
    let token = localStorage.getItem('token')
    let decoded
    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    const has_permission = checkPermission(location.pathname, decoded);

    useEffect(() => {
        if (!has_permission) {
            window.location.href = '/404'
        }
    }, [has_permission])
    const handelRedirect = () => {
        window.open("/bank/change");
    }
    return (
        <>
            {has_permission && (
                <div className='customer_pending' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: 'bold' }}>ลูกค้ารอผลการพิจารณา</Typography>
                        </Grid>
                        <Grid item xs>
                            <Button variant="contained" style={{float :'right' }} onClick={() => handelRedirect()}>ไปหน้าธนาคาร</Button>
                        </Grid>
                    </Grid>
                    <ConfirmProvider>
                        <TableData />
                    </ConfirmProvider>
                </div>
            )}
        </>
    )
}