import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as ncbHttpServices from '../../services/ncb_services';
import Loading from './../../components/loading'
import moment from 'moment-timezone';

const NCBTable = () => {
    const { contact_id } = useParams()
    const [rows, setRows] = useState()

    const reNum = (value) => {
        const num = value || 0
        const result = num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        return result
    }

    const calDiffMonth = (date1, date2) => {
        let enquityDate = new Date(date1)
        let date = new Date(date2)

        let y = enquityDate.getFullYear() - date.getFullYear()
        let m = enquityDate.getMonth() - date.getMonth()

        return ((y * 12) + (m))
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const resData = await ncbHttpServices.ncbGetData(contact_id);
                console.log('resData', resData);
                const summaryData = resData.data;

                const open_account = summaryData.calculated_data?.open_account_in_1_year
                const close_account = summaryData.calculated_data?.close_account_in_1_year
                const cutDate = moment(summaryData.enquityDate).format('YYYY-MM-DD')

                let temp_list = []
                for (let i = 1; i <= 12; i++) {
                    // open_account_in_1_year
                    let creditLimit = []
                    let installmentAmount = []
                    let amountOwed = []
                    let dateAccountOpened = []
                    let accountType = []
                    let SumCreditLimit = 0
                    // lose_account_in_1_year
                    let creditLimit2 = []
                    let installmentAmount2 = []
                    let amountOwed2 = []
                    let dateAccountOpened2 = []
                    let dateAccountClosed2 = []
                    let accountType2 = []
                    let SumCreditLimit2 = 0

                    open_account && open_account.rows.forEach(element => {
                        const date = moment(element.dateAccountOpened).format('YYYY-MM-DD')
                        const diffMonth = calDiffMonth(cutDate, date)

                        if (diffMonth === i) {
                            accountType.push(element.accountType)
                            dateAccountOpened.push(moment(element.dateAccountOpened).format('DD/MM/YYYY'))
                            creditLimit.push(element.creditLimit)
                            installmentAmount.push(element.installmentAmount)
                            amountOwed.push(element.amountOwed)
                            SumCreditLimit += element.creditLimit
                        }
                    });

                    close_account && close_account.rows.forEach(element => {
                        const date = moment(element.dateAccountClosed).format('YYYY-MM-DD')
                        const diffMonth = calDiffMonth(cutDate, date)

                        if (diffMonth === i) {
                            accountType2.push(element.accountType)
                            dateAccountClosed2.push(moment(element.dateAccountClosed).format('DD/MM/YYYY'))
                            dateAccountOpened2.push(moment(element.dateAccountOpened).format('DD/MM/YYYY'))
                            creditLimit2.push(element.creditLimit)
                            installmentAmount2.push(element.installmentAmount)
                            amountOwed2.push(element.amountOwed)
                            SumCreditLimit2 += element.creditLimit

                        }
                    })

                    const rowSpan = Math.max(0, dateAccountOpened.length, dateAccountOpened2.length)

                    if (rowSpan !== 0) {
                        temp_list.push({
                            diffMonth: i,
                            accountType: accountType,
                            dateAccountOpened: dateAccountOpened,
                            creditLimit: creditLimit,
                            installmentAmount: installmentAmount,
                            amountOwed: amountOwed,

                            accountType2: accountType2,
                            dateAccountClosed2: dateAccountClosed2,
                            dateAccountOpened2: dateAccountOpened2,
                            creditLimit2: creditLimit2,
                            installmentAmount2: installmentAmount2,
                            amountOwed2: amountOwed2,

                            rowSpan: Math.max(1, dateAccountOpened.length, dateAccountOpened2.length),
                            outstanding_debt: SumCreditLimit - SumCreditLimit2,
                        })
                    }

                }

                const footer = {
                    sum_creditLimit: open_account.footer.creditLimt,
                    sum_installmentAmount: open_account.footer.installmentAmount,
                    sum_amountOwed: open_account.footer.amountOwed,

                    sum_creditLimit2: close_account.footer.creditLimt,
                    sum_installmentAmount2: close_account.footer.installmentAmount,
                    sum_amountOwed2: close_account.footer.amountOwed,
                }

                console.log('temp', temp_list)
                const sortedData = temp_list.sort((a, b) => new Date(b.diffMonth) - new Date(a.diffMonth));

                setRows({
                    months: sortedData,
                    footer: footer,
                    difference: summaryData.calculated_data.difference
                })
            } catch (error) {
                // Handle any errors here
                console.error('error', error);
                alert(error)
            }
        }

        fetchData();
    }, [contact_id]);

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '500px',
    };

    const thTdStyle = {
        border: '1px solid #dddddd',
        textAlign: 'center',
        padding: '8px',
    };

    const thTdNumberStyle = {
        border: '1px solid #dddddd',
        textAlign: 'right',
        padding: '8px',
        // minWidth: '50px'
    };

    if (rows === undefined) return <Loading />

    return (
        <div>
            {console.log('rows', rows)}
            <div style={{ overflow: 'auto' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thTdStyle} colSpan={13}>บัญชีที่เปิด-ปิดภายใน 1 ปี</th>
                        </tr>
                        <tr>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }} rowSpan={2}>ลำดับ</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }} colSpan={5}>บัญชีที่เปิด</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }} colSpan={6}>บัญชีที่ปิด</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }} rowSpan={2}>ภาระหนี้คงเหลือภายในเดือน</th>
                        </tr>
                        <tr>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>วันที่เปิดบัญชี</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>ประเภทบัญชี</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>วงเงิน</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>ผ่อนชำระ</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>ภาระหนี้คงเหลือ</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>วันที่ปิดบัญชี</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>วันที่เปิดบัญชี</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>ประเภทบัญชี</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>วงเงิน</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>ผ่อนชำระ</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}>ภาระหนี้คงเหลือ</th>
                        </tr>
                    </thead>

                    <tbody>
                        {rows.months && rows.months.map((element, index) => {
                            return [...Array(element.rowSpan)].map((_, i) => {
                                return (
                                    <tr key={index + i}>
                                        {i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>{index + 1}</td>}
                                        {
                                            element.dateAccountOpened.length === 0 ? (
                                                i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>-</td>
                                            ) : (
                                                element.dateAccountOpened.length === 1 ?
                                                    i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>{element.dateAccountOpened[i] || "-"}</td>
                                                    :
                                                    <td style={thTdStyle}>{element.dateAccountOpened[i] || "-"}</td>
                                            )
                                        }
                                        {
                                            element.accountType.length === 0 ? (
                                                i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>-</td>
                                            ) : (
                                                element.accountType.length === 1 ?
                                                    i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>{element.accountType[i] || "-"}</td>
                                                    :
                                                    <td style={thTdStyle}>{element.accountType[i] || "-"}</td>
                                            )
                                        }
                                        {
                                            element.creditLimit.length === 0 ? (
                                                i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(0)}</td>
                                            ) : (
                                                element.creditLimit.length === 1 ?
                                                    i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(element.creditLimit[i])}</td>
                                                    :
                                                    <td style={thTdNumberStyle}>{reNum(element.creditLimit[i])}</td>
                                            )
                                        }
                                        {
                                            element.installmentAmount.length === 0 ? (
                                                i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(0)}</td>
                                            ) : (
                                                element.installmentAmount.length === 1 ?
                                                    i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(element.installmentAmount[i])}</td>
                                                    :
                                                    <td style={thTdNumberStyle}>{reNum(element.installmentAmount[i])}</td>
                                            )
                                        }
                                        {
                                            element.amountOwed.length === 0 ? (
                                                i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(0)}</td>
                                            ) : (
                                                element.amountOwed.length === 1 ?
                                                    i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(element.amountOwed[i])}</td>
                                                    :
                                                    <td style={thTdNumberStyle}>{reNum(element.amountOwed[i])}</td>
                                            )
                                        }
                                        {
                                            element.dateAccountClosed2.length === 0 ? (
                                                i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>-</td>
                                            ) : (
                                                element.dateAccountClosed2.length === 1 ?
                                                    i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>{element.dateAccountClosed2[i] || "-"}</td>
                                                    :
                                                    <td style={thTdStyle}>{element.dateAccountClosed2[i] || "-"}</td>
                                            )
                                        }
                                        {
                                            element.dateAccountOpened2.length === 0 ? (
                                                i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>-</td>
                                            ) : (
                                                element.dateAccountOpened2.length === 1 ?
                                                    i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>{element.dateAccountOpened2[i] || "-"}</td>
                                                    :
                                                    <td style={thTdStyle}>{element.dateAccountOpened2[i] || "-"}</td>
                                            )
                                        }
                                        {
                                            element.accountType2.length === 0 ? (
                                                i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>-</td>
                                            ) : (
                                                element.accountType2.length === 1 ?
                                                    i === 0 && <td style={thTdStyle} rowSpan={element.rowSpan}>{element.accountType2[i] || "-"}</td>
                                                    :
                                                    <td style={thTdStyle}>{element.accountType2[i] || "-"}</td>
                                            )
                                        }
                                        {
                                            element.creditLimit2.length === 0 ? (
                                                i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(0)}</td>
                                            ) : (
                                                element.creditLimit2.length === 1 ?
                                                    i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(element.creditLimit2[i])}</td>
                                                    :
                                                    <td style={thTdNumberStyle}>{reNum(element.creditLimit2[i])}</td>
                                            )
                                        }
                                        {
                                            element.installmentAmount2.length === 0 ? (
                                                i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(0)}</td>
                                            ) : (
                                                element.installmentAmount2.length === 1 ?
                                                    i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(element.installmentAmount2[i])}</td>
                                                    :
                                                    <td style={thTdNumberStyle}>{reNum(element.installmentAmount2[i])}</td>
                                            )
                                        }
                                        {
                                            element.amountOwed2.length === 0 ? (
                                                i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(0)}</td>
                                            ) : (
                                                element.amountOwed2.length === 1 ?
                                                    i === 0 && <td style={thTdNumberStyle} rowSpan={element.rowSpan}>{reNum(element.amountOwed2[i])}</td>
                                                    :
                                                    <td style={thTdNumberStyle}>{reNum(element.amountOwed2[i])}</td>
                                            )
                                        }
                                        {i === 0 && <td style={{ ...thTdNumberStyle, width: '150px' }} rowSpan={element.rowSpan}>{reNum(element.outstanding_debt)}</td>}
                                    </tr>
                                )
                            })
                        })}
                    </tbody>

                    <tfoot>
                        <tr>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }} colSpan={3}>รวม</th>
                            <th style={{ ...thTdNumberStyle, backgroundColor: '#ffac7d' }}>{rows.footer.sum_creditLimit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
                            <th style={{ ...thTdNumberStyle, backgroundColor: '#ffac7d' }}>{rows.footer.sum_installmentAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
                            <th style={{ ...thTdNumberStyle, backgroundColor: '#ffac7d' }}>{rows.footer.sum_amountOwed.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }} colSpan={3}>รวม</th>
                            <th style={{ ...thTdNumberStyle, backgroundColor: '#ffac7d' }}>{rows.footer.sum_creditLimit2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
                            <th style={{ ...thTdNumberStyle, backgroundColor: '#ffac7d' }}>{rows.footer.sum_installmentAmount2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
                            <th style={{ ...thTdNumberStyle, backgroundColor: '#ffac7d' }}>{rows.footer.sum_amountOwed2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
                            <th style={{ ...thTdStyle, backgroundColor: '#ffac7d' }}></th>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
                <h3>ภาระหนี้ใหม่สุทธิ</h3>
                <h3>{rows.difference.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
            </div>
        </div >
    )
}

export default NCBTable

