
import React, { useState, useCallback } from 'react';
import { render } from 'react-dom';
import { useLocation } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';
import '../../App.css'
import { useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
export default function App() {
    const { state } = useLocation();
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [type, setType] = useState('img');
    const { userId } = useParams();
    const [numPages, setNumPages] = useState(null);
    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get('param');
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(url)[1];
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const handleContextMenu = (event) => {
        event.preventDefault(); // prevents the default context menu from showing up
    };
    const images = [url]
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    if (ext.toUpperCase() != 'PDF') {
        return (
            <div>
                {images.map((src, index) => (
                    <img
                        src={src}
                        onClick={() => openImageViewer(index)}
                        width="800"
                        key={index}
                        style={{ margin: '2px' }}
                        alt=""

                        class="center"
                    />
                ))}

                {isViewerOpen && (
                    <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                        class="center"
                        onContextMenu={handleContextMenu}
                    />
                )}

            </div>

        );
    } else if (ext.toUpperCase() == 'PDF') {

        return (
            <Document
                file={url}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
                {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map(page => <Page pageNumber={page} renderTextLayer={false} wrap={false} renderAnnotationLayer={false} />)}
            </Document>
        )
    }
}