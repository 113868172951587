import axios from "axios";

const config = require('../config.js')

const LOGIN_URL = config.LOGIN_URL
// const LOGIN_URL = 'http://localhost:9001'

export const TFMLogin = (body) => {
    return axios.post(`${LOGIN_URL}/authen/login`, body, {
    })
}
export const LoginOrg = (body) => {
    return axios.post(`https://apiv2-uat.transfinmational.net/generateToken`, body, {
    })
}