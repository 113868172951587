// import React from 'react';

// function Filter(props) {
//   return (
//     <div>
//       <h1>{props.title}</h1>
//       <p>{props.content}</p>
//     </div>
//   );
// }

// export default Filter;

import { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';

function Filter(props) {


  const [rows, setRows] = useState(1)
  const [open, setOpen] = useState(true)
  const [classify, setClassify] = useState([])
  // const [menu, setMenu] = useState([])
  const [operator, setOperater] = useState([true, true, true])
  const [inputValue, setInputValue] = useState('');


  useEffect(() => {
  }, [classify])

  const arr = []
  const [values, setValues] = useState([])

  const onClickFilter = () => {
    // const filter = {

    // }
    // console.log('filter', filter)

    console.log('classify', classify)
    console.log('value', values)
  }

  const addClassify = () => {
    if (rows < 3) {
      setRows(rows + 1)
    }
  }

  // const [filterActive,setFillerActive] = useState(1)

  const delFilter = (index) => {
    resetClassify(classify[index])

    delete classify[index]
    let arrFiltered = classify.filter(Boolean);
    setClassify(arrFiltered)
    if (rows > 1) {
      setRows(rows - 1)
    }
  }

  // const resetFilter = (index) => {
  //   resetClassify(classify[index])

  //   delete classify[index]
  //   let arrFiltered = classify.filter(Boolean);
  //   setClassify(arrFiltered)
  // }

  const resetClassify = (index) => {
    // // console.log('valueIndex',valueIndex)
    // // switch (valueIndex) {
    // //   case 1:
    // //     setValue1('')
    // //     break;
    // //   case 2:
    // //     setValue2('')
    // //     break;
    // //   case 3:
    // //     setValue3('')
    // //     break;
    // //   default:
    // //   // code block
    // // }
    // let newValue = []
    // newValue = [...classify]
    // newValue[index] = event.target.value
    // setValue(newValue)
    // console.log(classify)
    delete values[index]
    let arrFiltered = values.filter(Boolean);
    setValues(arrFiltered)
  }

  const valueX = (index) => (event) => {
    const filtered = classify.filter(obj => {
      return obj === event.target.value;
    });

    if (filtered.length === 0) {
      // resetFilter(value[index])

      let newValue = []
      newValue = [...classify]
      newValue[index] = event.target.value
      setClassify(newValue)
    }
  };

  // change operater
  const handleChangeOperator = (index) => (event) => {
    let newOperator = []
    newOperator = [...operator]
    newOperator[index] = event.target.value
    setOperater(newOperator)
  }

  const hasAppear = (value) => {
    const hasValue = classify.includes(value);
    return hasValue
  }

  const addValues = (index) => (event) => {
    console.log(index, event)
    const newVal = event.target.value;
    const newArr = [...values, newVal];
    setValues(newArr);
  }

  function handleChange(event) {
    setInputValue(event.target.value);
  }

  const test2 = () => {
    const test = [1, 2, 3]
    return test[0]
  }

  const filterClassify = (index) => {
    return (
      <>
        <Grid item >
          <FormControl style={{ width: 300 }} >
            <InputLabel id="demo-simple-select-label">Classify</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={classify[index] !== undefined ? classify[index] : ''}
              label="Classify"
              onChange={valueX(index)}
            >
              <MenuItem value={1} disabled={hasAppear(1)}>ทดสอบ1</MenuItem>
              <MenuItem value={2} disabled={hasAppear(2)}>ทดสอบ2</MenuItem>
              <MenuItem value={3} disabled={hasAppear(3)}>ทดสอบ3</MenuItem>

            </Select>
          </FormControl>
        </Grid>
        {filterMenu(classify[index])}

        {
          rows !== index + 1 ?
            <Grid item>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                <Select
                  style={{ width: 150 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={operator[index]}
                  label="Operator"
                  onChange={handleChangeOperator(index)}
                >
                  <MenuItem value={true}>และ</MenuItem>
                  <MenuItem value={false}>หรือ</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            :
            ''
        }

        {
          classify[index] !== undefined ?
            <Grid item>
              <Tooltip title="ล้างตัวกรอง">
                <IconButton onClick={() => resetClassify(index)}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            :
            ''
        }
        <Grid item marginRight={10}>
          <Tooltip title="ลบตัวกรองข้อมูล">
            {/* <IconButton onClick={() => delFilterAction(index)}> */}
            <IconButton onClick={() => delFilter(index)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </>
    )
  }

  // menu
  const filterMenu = (menu) => {
    console.log(menu)
    if (menu === 1) {
      return (
        <>
          <Grid item>
            <TextField id="outlined-basic" label="Menu1" variant="outlined" value={test2()} onChange={handleChange} />
          </Grid>
        </>
      )
    } else if (menu === 2) {
      return (
        <>
          <Grid item>
            <TextField id="outlined-basic" label="Menu2" variant="outlined" value={test2()} onChange={addValues()} />
          </Grid>
        </>
      )
    } else if (menu === 3) {
      return (
        <>
          <Grid item>
            <TextField id="outlined-basic" label="Menu3" variant="outlined" value={values} onChange={addValues()} />
          </Grid>
        </>
      )
    }
  }

  // return
  return (
    // <div style={{marginLeft:'-20px'}}>
    <div>
      <Grid display={!open ? 'none' : ''}>
        {
          rows > 4 ?
            <Grid container spacing={2} style={{ padding: '1rem' }}>
              {filterClassify(4)}
            </Grid>
            : null
        }
        {
          rows > 3 ?
            <Grid container spacing={2} style={{ padding: '1rem' }}>
              {filterClassify(3)}
            </Grid>
            : null
        }
        {
          rows > 2 ?
            <Grid container spacing={2} style={{ padding: '1rem' }}>
              {filterClassify(2)}
            </Grid>
            : null
        }
        {
          rows > 1 ?
            <Grid container spacing={2} style={{ padding: '1rem' }}>
              {filterClassify(1)}
            </Grid>
            : null
        }
        <Grid container spacing={2} style={{ padding: '1rem' }}>
          {filterClassify(0)}
          <Grid item >
            <Button variant="outlined" style={{ color: "#333", borderColor: "#333" }} onClick={() => addClassify()}>เพิ่มช่องการค้นหา</Button>
          </Grid>
          <Grid item >
            <Button variant="contained" onClick={() => onClickFilter()}>ค้นหา</Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Filter;