import React, { useEffect, useState } from 'react'
import { getGBLog, userGBRegiser } from '../services/direct_debit'
import Header from './dashboard.js'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
let token = localStorage.getItem('token')

function CountdownTimer({ initial }) {
    const [time_left, set_time_left] = useState(initial.time_left);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (time_left > 0) {
                set_time_left(time_left - 1);
            }
        }, 1000);

        return () => clearInterval(countdownInterval);
    }, [time_left]);

    const minutes = Math.floor(time_left / 60);
    const seconds = time_left % 60;

    let color;

    switch (true) {
        case time_left <= 180:
            color = 'red';
            break;
        default:
            color = '#000';
    }

    return (
        <span style={{ color: color }}>
            {minutes > 0 && `${minutes} นาที `}
            {seconds} วินาที
        </span>
    );
}

function CountdownStatus({ initial }) {
    const [time_left, set_time_left] = useState(initial.time_left);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (time_left > 0) {
                set_time_left(time_left - 1);
            }
        }, 1000);

        return () => clearInterval(countdownInterval);
    }, [time_left]);

    let label, color;

    switch (true) {
        case time_left === 0:
            label = "หมดอายุ";
            color = 'error';
            break;
        case time_left < 180:
            label = "ใกล้หมดอายุ";
            color = 'warning';
            break;
        default:
            label = "รอการสมัคร";
            color = 'success';
    }

    const handleClick = () => {
        // Handle the click event here, e.g., show an alert.
        if (window.confirm(`ส่ง Flex message ใหม่อึกครั้ง`,)) {
            const body = {
                user_id: initial.user_id,
                contact_id: initial.contract_id,
                ...initial
            }

            userGBRegiser(body,token)
            alert("ดำเนินการเสร็จสิ้น");
            window.location.reload()
        } else {
        }
    };

    return <Chip label={label} color={color} onClick={handleClick} />;
}

export default function Gateway() {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getGBLog();
                const response_data = response.data.data;

                const filteredData = response_data
                    .map((item, index) => {
                        const exp_date = new Date(item.create_date);
                        exp_date.setTime(exp_date.getTime() + 20 * 60 * 1000);
                        const now = new Date();

                        const time_left = Math.max(0, Math.floor((exp_date - now) / 1000));
                        const expires_time = exp_date.toLocaleTimeString();

                        return {
                            ...item,
                            time_left,
                            expires_time: expires_time,
                            index: index + 1
                        };
                    })
                    .filter((item) => item !== undefined);

                setRows(filteredData);
            } catch (error) {
                console.error("Error fetching and processing data:", error);
            }
        };

        fetchData();
    }, []);

    console.log('rows', rows)

    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 50,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'index',
            headerName: 'ลำดับ',
            width: 80,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'contract_id',
            headerName: 'เลขที่สัญญา',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'user_id',
            headerName: 'รหัสบัตรประชาชน',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'line_id',
            headerName: 'Line ID',
            width: 300,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'name',
            headerName: 'ชื่อ-นามสกุล',
            flex: 1,
            minWidth: 250,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => <p>{row.name} {row.surname}</p>
        },
        // {
        //     field: 'installment',
        //     headerName: 'ยอดผ่อน',
        //     width: 150,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        {
            field: 'bank_name',
            headerName: 'ธนาคาร',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'regis_link',
        //     headerName: 'regis_link',
        //     width: 250,
        //     headerAlign: 'center',
        //     align: 'center',
        //     hide: true
        // },
        {
            field: 'createdOn',
            headerName: 'วันที่ส่ง Link',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => row.create_date.split(" ")[0]
        },
        {
            field: 'expires',
            headerName: 'เวลาหมดอายุ',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => row.expires_time
        },
        {
            field: 'timeLeft',
            headerName: 'เวลาคงเหลือ',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => <CountdownTimer initial={row} />
        },
        {
            field: 'status',
            headerName: 'สถานะ',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => <CountdownStatus initial={row} />
        },
    ];


    return (
        <div className='customer-list' style={{ margin: '2rem' }}>
            <Header />
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h5'>รายการสถานะสมัครเข้าร่วม Direct Debit ของลูกค้า</Typography>
                </Grid>
            </Grid>

            <Paper elevation={3} >
                <Box>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1.5,
                            },
                        }}

                    />
                </Box>
            </Paper>

        </div>
    )
}
