import * as React from 'react';
import './../sum.css'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import SideBar from '../dashboard'
import * as CrmHttpServices from '../../services/crm_service';
import TableActivity from '../../components/table/table_activity';
import TableRevcard from '../../components/table/table_revcard';
import { useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import moment from "moment-timezone";
import TableFile from "../../components/table/table_crm_fileslist";
import EditIcon from '@mui/icons-material/Edit';
import { useLocation } from 'react-router-dom';
moment.tz.setDefault("Asia/Bangkok")
function financial(n) {
    if (n == 0) {
        return "0"
    } else if (n === null || n === undefined || isNaN(n)) {
        return "0"
    } else {
        return parseFloat(n).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
}
function cleanText(text) {
    if (text === null || text === undefined || text === "") {
        return "-"
    } else {
        return text.replace("null", "")
    }
}

function cleanDate(date) {
    if (date === null || date === undefined || date === "") {
        return "-"
    } else {
        return date.split(' ')[0]
    }
}
const handleCloseTab = () => {
    window.close();
}

const convertDate = (user_data) => {
    let birthYear = user_data?.user_detail?.formData1?.birthYear
    let birthMonth = user_data?.user_detail?.formData1?.birthMonth.length === 1 ? '0' + user_data?.user_detail?.formData1?.birthMonth : user_data?.user_detail?.formData1?.birthMonth
    let birthDate = user_data?.user_detail?.formData1?.birthDate.length === 1 ? '0' + user_data?.user_detail?.formData1?.birthDate : user_data?.user_detail?.formData1?.birthDate
    let birthday = birthYear + '-' + birthMonth + '-' + birthDate
    return birthday
}
export default function App() {
    const [data, setData] = React.useState()
    const [phone, setPhone] = React.useState()
    const [user_data, setUserData] = React.useState({})
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const contact_id = queryParams.get('contact_id');
    const user_id = queryParams.get('user_id');
    const contract_no = queryParams.get('contract_no');

    const handleEditUser = () => {
        console.log(contract_no)
        if (contract_no === '-' || contract_no === null || contract_no == '') {
            var email = phone
        } else {
            var email = contract_no
        }
        const parameterData = { contact_id: contact_id, user_id: user_id, phone: email };
        const queryParams = new URLSearchParams(parameterData).toString();
        window.open('/crm/customer/edit?' + queryParams, '_blank');
    }
    const Convertducational = (data) => {
        switch (data) {
            case "ปวช":
                return 'ปวช./ มัธยมปลายหรือต่ำกว่า'
            case 'ปวส':
                return 'ปวส./ วิชาเฉพาะ'
            case 'ปต':
                return 'ปริญญาตรี'
            case 'ปท':
                return 'สูงกว่าปริญญาตรี'
        }
    }
    let token = localStorage.getItem('token_org')
    React.useEffect(() => {

        let obj = {
            "user_id": user_id,
            "contact_id": Number(contact_id)
        }
        CrmHttpServices.getFormDetail(token, obj)
            .then((res) => {
                setUserData(res.data.data.user_data[0])
                setPhone(res.data.data.user_data[0]?.user_detail?.formData1?.phone)
            })
    }, [])
    return (
        <div className='contact-management' style={{ margin: '2rem' }}>
            <SideBar />
            <Button variant="contained" style={{ marginTop: '3rem' }} onClick={() => handleCloseTab()}><ArrowBackIcon></ArrowBackIcon>ย้อนกลับ</Button>
            <Button variant="contained" color="success" style={{ marginTop: '3rem', float: 'right' }} onClick={() => handleEditUser()}><EditIcon />แก้ไขข้อมูล ลูกค้า</Button>
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: 'bold' }}>รายละเอียด การสมัคร Digital App</Typography>
                </Grid>
            </Grid>
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>ข้อมูลทั่วไป</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>ประกอบด้วย ข้อมูลส่วนตามที่อยู่ปัจจุบัน</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'} >
                            <Card variant="outlined" style={{ padding: '20px', margin: '20px' }}>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>คำนำหน้าชื่อ : {user_data?.user_detail?.formData1?.gender??user_data?.user_detail?.formData1?.prefix}</Typography>
                                    <Typography sx={{ width: '18%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ชื่อ : {user_data?.user_detail?.formData1?.firstName}</Typography>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>นามสกุล : {user_data?.user_detail?.formData1?.lastName}</Typography>
                                    <Typography sx={{ width: '30%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>เลขบัตรประชาชน : {user_data?.user_detail?.formData1?.identity}</Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>วันเดือนปีเกิด : {convertDate(user_data)}</Typography>
                                    <Typography sx={{ width: '18%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>เบอร์มือถือ : {user_data?.user_detail?.formData1?.phone}</Typography>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ID Line : {user_data?.user_detail?.formData1?.line}</Typography>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>อีเมล : {user_data?.user_detail?.formData1?.email}</Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>Facebook : {user_data?.user_detail?.formData1?.facebook}</Typography>
                                    <Typography sx={{ width: '75%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ที่อยู่ตามบัตรประชาชน :  {user_data?.user_detail?.formData1?.address}  ตำบล: {user_data?.user_detail?.formData1?.district} อำเภอ: {user_data?.user_detail?.formData1?.county} จังหวัด: {user_data?.user_detail?.formData1?.province} รหัสไปรณีย์: {user_data?.user_detail?.formData1?.zipcode}</Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '40%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ที่อยู่ปัจจุบัน :  {user_data?.user_detail?.formData2?.currentAddress}  ตำบล: {user_data?.user_detail?.formData2?.currentDistrict} อำเภอ: {user_data?.user_detail?.formData2?.currentCounty} จังหวัด: {user_data?.user_detail?.formData2?.currentProvince} รหัสไปรณีย์: {user_data?.user_detail?.formData2?.currentZipcode}</Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '40%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ประวัติการศึกษาสูงสุด :  {Convertducational(user_data?.user_detail?.formData1?.educational)}</Typography>
                                </Stack>
                            </Card>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>ข้อมูลอาชีพ</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>ประกอบด้วย ข้อมูลอาชีพ ขณะที่สมัคร</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'}>
                            <Card variant="outlined" style={{ padding: '20px', margin: '20px' }}>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>อาชีพ : {user_data?.user_detail?.formData3?.career}</Typography>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ตำแหน่งงาน : {user_data?.user_detail?.formData3?.compPosition}</Typography>
                                    <Typography sx={{ width: '25%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ประเภทกิจการบริษัท/โรงงาน : {user_data?.user_detail?.formData3?.compType} </Typography>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ชื่อบริษัท : {user_data?.user_detail?.formData3?.compName}</Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '25%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>เบอร์โทรศัพท์ที่ทำงาน : {user_data?.user_detail?.formData3?.compPhone}</Typography>
                                    <Typography sx={{ width: '25%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>Email ที่ทำงาน: {user_data?.user_detail?.formData3?.compEmail}</Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '100%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ที่อยู่ที่ทำงาน :{user_data?.user_detail?.formData3?.compAddress} ตำบล : {user_data?.user_detail?.formData3?.compDistrict} อำเภอ : {user_data?.user_detail?.formData3?.compCounty} จังหวัด : {user_data?.user_detail?.formData3?.compProvince} รหัสไปรษณีย์ :{user_data?.user_detail?.formData3?.compZipcode} </Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '25%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>รายได้หลัก : {user_data?.user_detail?.formData3?.mainIncome}</Typography>
                                    <Typography sx={{ width: '25%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>รายได้พิเศษ : {user_data?.user_detail?.formData3?.extraIncome}</Typography>
                                    <Typography sx={{ width: '25%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>แหล่งที่มารายได้ : {user_data?.user_detail?.formData3?.incomeSource} </Typography>
                                </Stack>
                            </Card>
                        </Typography>
                        <div style={{ paddingTop: '2rem' }}>
                            {data === undefined ? "" : <TableRevcard data={data.Revcard} />}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>ข้อมูลสถานะภาพ</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>ประกอบด้วย ข้อมูลสถานะภาพ และบุคคลที่สามารถติดตามหนี้ได้</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'}>
                            <Card variant="outlined" style={{ padding: '20px', margin: '20px' }}>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>สถานะภาพ : {user_data?.user_detail?.formData4?.status} </Typography>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ชื่อคู่สมรส :  {user_data?.user_detail?.formData4?.spouseName}</Typography>
                                    <Typography sx={{ width: '18%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>นามสกุลคู่สมรส : {user_data?.user_detail?.formData4?.spouseSurname}</Typography>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>นามสกุลเดิมคู่สมรส : {user_data?.user_detail?.formData4?.spouseOldSurname}</Typography>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>เบอร์โทรศัพท์ : {user_data?.user_detail?.formData4?.spousePhone}</Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ชื่อสถานที่ทำงาน : {user_data?.user_detail?.formData4?.spouseComp} </Typography>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>เบอร์โทรศัพท์ที่ทำงาน : {user_data?.user_detail?.formData4?.spouseCompPhone} </Typography>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>รายได้คู่สมรส : {user_data?.user_detail?.formData4?.spouseIncome} </Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '100%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ที่อยู่คู่สมรส :  {user_data?.user_detail?.formData4?.spouseAddress} ตำบล :{user_data?.user_detail?.formData4?.spouseDistrict}อำเภอ :{user_data?.user_detail?.formData4?.spouseCounty} จังหวัด :{user_data?.user_detail?.formData4?.spouseProvince} รหัสไปรษณีย์ : {user_data?.user_detail?.formData4?.spouseZipcode}</Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '25%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ชื่อบุคคลที่สามารถติดต่อได้ : {user_data?.user_detail?.formData4?.collectName} </Typography>
                                    <Typography sx={{ width: '25%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>นามสกุลบุคคลที่สามารถติดต่อได้ : {user_data?.user_detail?.formData4?.collectSurname} </Typography>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ความสัมพันธ์ : {user_data?.user_detail?.formData4?.collectRelation} </Typography>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>เบอร์โทรศัพท์ : {user_data?.user_detail?.formData4?.collectPhone} </Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '30%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ชื่อสถานที่ทำงาน : {user_data?.user_detail?.formData4?.collectComp} </Typography>
                                </Stack>
                            </Card>
                        </Typography>
                        {/* {console.log(data)} */}
                        <div style={{ paddingTop: '2rem' }}>
                            {/* {console.log('data',data)}
                            {data === undefined ? "" : <ChildComponent childToParent={{ data }} />} */}
                            {data === undefined ? "" : <TableRevcard data={data.Revcard} />}
                            {/* <ChildComponent data={data}/> */}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>ข้อมูลการขอสินเชื่อ</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>ประกอบด้วย ข้อมูลของการขอสินเชื่อ</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'}>
                            <Card variant="outlined" style={{ padding: '20px', margin: '20px' }}>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ภาระหนี้รวมทั้งหมด : {user_data?.user_detail?.formData5?.totalDebt} </Typography>
                                    <Typography sx={{ width: '15%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ผ่อนชำระต่อเดือน :  {user_data?.user_detail?.formData5?.monthlyInstallments}</Typography>
                                    <Typography sx={{ width: '18%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>จำนวนเงินที่ขอกู้ : {user_data?.user_detail?.formData5?.amountReq}</Typography>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ยอดที่ต้องการผ่อนชำระต่อเดือน : {user_data?.user_detail?.formData5?.payAble}</Typography>
                                </Stack>
                                <Stack direction="row" style={{ marginLeft: '12%' }}>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>วัตถุประสงค์: {user_data?.user_detail?.formData5?.objective} </Typography>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>ธนาคาร :  {user_data?.user_detail?.formData5?.bank}</Typography>
                                    <Typography sx={{ width: '20%', flexShrink: 0, paddingRight: '1rem', paddingLeft: '1rem' }}>เลขบัญชี : {user_data?.user_detail?.formData5?.account}</Typography>
                                </Stack>
                            </Card>
                        </Typography>
                        <div style={{ paddingTop: '2rem' }}>
                            {data === undefined ? "" : <TableRevcard data={data.Revcard} />}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>ข้อมูลการพิจารณาสินเชื่อ</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>ประกอบด้วย ข้อมูลที่เกี่ยวกับการพิจารณา เช่น ผลการพิจารณาของกรรมการเป็นต้น</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'}>
                            {/* <Stack direction="row">
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="วันที่ทำสัญญา" variant="outlined" value={cleanDate(data?.Policy_date)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="วันสิ้นสุดสัญญา" variant="outlined" value={cleanDate(data?.End_date)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="เลขที่สัญญา" variant="outlined" value={cleanText(data?.Policy_no)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="เลขอ้างอิง" variant="outlined" value={cleanText(data?.Ref_no)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%' }}><TextField id="outlined-basic" fullWidth label="วงเงินกู้" variant="outlined" value={financial(data?.Amount)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                            </Stack>
                            <Stack direction="row" style={{ marginTop: '1rem' }}>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="อัตราดอกเบี้ย (% ต่อปี)" variant="outlined" value={data?.Interest_rate || ''} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="จำนวนงวด (งวดเดือน)" variant="outlined" value={data?.pay_no || ''} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="ค่าปรับ (% ต่อปี)" variant="outlined" value={financial(data?.plup_rate)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%', paddingRight: '1rem' }}><TextField id="outlined-basic" fullWidth label="ค่าทวงถาม (บาท)" variant="outlined" value={financial(data?.WFtie)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                                <div style={{ width: '20%' }}><TextField id="outlined-basic" fullWidth label="ค่างวด (บาท)" variant="outlined" value={financial(data?.PPP) || ''} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} /></div>
                            </Stack> */}

                        </Typography>
                        <div style={{ paddingTop: '2rem' }}>
                            {data === undefined ? "" : <TableRevcard data={data.Revcard} />}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>ไฟล์สำหรับการสมัครสินเชื่อ</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>ประกอบด้วย ไฟล์ที่ลูกค้า  Upload ผ่าน Digital App</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'}>
                            <TableFile />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div >
    )
}