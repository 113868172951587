
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import SideBar from '../../page/dashboard'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as CrmService from '../../services/crm_service';
// components
import Filter from '../../components/filter_2';
import { useNavigate } from "react-router-dom";
import { checkPermission } from '../../utils/permission_utils.js';
import { useLocation } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import Textarea from '@mui/joy/Textarea';
import EditIcon from '@mui/icons-material/Edit';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import ExcelExport from '../../reports/direct_debit_excel.js'

const style_note = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function App() {
    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [open, setOpen] = React.useState(true);
    const [rows_data, setRowsData] = React.useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const has_permission = checkPermission(location.pathname, decoded);
    const [filters, setFilters] = React.useState([])
    const [popup_note, setOpenNote] = React.useState(false)
    const [note, setNote] = React.useState([])
    const [rows, setRow] = React.useState([]);
    const [excel_data, setExcelData] = React.useState([])

    React.useEffect(() => {
        if (!has_permission) {
            window.location.href = '/404';
        }
        fetchData()

    }, [filters])
    const fetchData = () => {
        let object = {
            "pagination": {
                "page": 1,
                "rowsPerPage": 10000,
                "sortBy": "",
                "descending": true
            },
            "filters": filters
        }
        CrmService.crmGetUser(token, object)
            .then((res) => {
                setRowsData(res.data.data)
                let data = []
                res.data.data.map((item, index) => (
                    data.push(
                        {
                            "id": index + 1,
                            ...item
                        }
                    )
                ));
                setRowsData(data);
            })
    }
    const filter = [
        {
            property: 'brand',
            name: 'Brand',
            type: 'select',
            option: ['MSCW', 'CASHKRUB', 'MOU_GSC', 'Pico Tha']
        },
        {
            property: 'product',
            name: 'Product',
            type: 'select',
            option: ['PLOAN', 'PLOAN-NONCB', 'PLOAN-ALL', 'PLOAN-MOU', 'NANO'],
        },
        {
            property: 'contract_no',
            name: 'เลขที่สัญญา',
            type: "input",
        },
        {
            property: 'name',
            name: 'ชื่อ-นามสกุล',
            type: "input",
        },
        {
            property: 'citizen_id',
            name: 'เลขที่บัตรประชาชน',
            type: "input",
        },
        {
            property: 'phone_number',
            name: 'เบอร์โทรศัพท์',
            type: "input",
        },
        {
            property: 'dd_resister',
            name: 'สถานะเข้าระบบ DD ',
            type: "select",

            option: ['เข้าร่วม', 'ไม่เข้าร่วม(ไม่มีรหัสตัดเงิน)']
        },
        {
            property: 'bank_name',
            name: 'ธนาคารที่เข้าระบบ DD',
            type: "select",
            option: ['ธนาคารกสิกรไทย', 'ธนาคารกรุงเทพ', 'ธนาคารไทยพาณิชย์', 'ธนาคารกรุงไทย', 'ธนาคารกรุงศรี']
        },
        {
            property: 'debt_class',
            name: 'ชั้นหนี้',
            type: "select",
            option: ['เกิน 12 เดือน', 'เกิน 6 เดือน - 12 เดือน', 'เกิน 3 เดือน - 6 เดือน', 'เกิน 1 เดือน - 3 เดือน', 'ปกติ'],

        },
        {
            property: 'outstanding_principal',
            name: 'ต้นคงค้าง',
            type: "input",
        },
        {
            property: 'accrued_interest',
            name: 'ดอกเบี้ยคงค้าง',
            type: "input",
        },
        {
            property: 'last_pay',
            name: 'วันที่ชำระล่าสุด',
            type: "date",
        },
        {
            property: 'company_score',
            name: 'score บริษัท',
            type: "input",
        },
        {
            property: 'place_of_operation',
            name: 'สถานการดำเนินการ',
            type: "select",
            option: ['ติดต่อไม่ได้', 'ชำระแล้ว', 'ไม่นัด', 'ชำระบางส่วน', 'นัดชำระ', 'Welcome Call', 'ไม่รับสาย'],
        },
        {
            property: 'policy_group',
            name: 'กลุ่มสัญญา',
            type: "select",
            option: ['ลูกค้ารายย่อย', 'ฝากบันทึก - AMC', 'ฝากบันทึก - BYC', 'ฝากบันทึก - CLS', 'ฝากบันทึก - CLS1', 'ฝากบันทึก - CLS2', 'ฝากบันทึก - Cash Krub', 'ฝากบันทึก - ลูกหนี้ พิโกชลบุรี', 'ฝากบันทึก AMC รวมหนี้ MSCW - PICO', 'ฝากบันทึก DD PICO - AMC', 'ลูกค้ารายย่อย – MOU SCAN'],
        },
        {
            property: 'care_taker',
            name: 'ผู้ดูแล',
            type: "select",
            option: ['thuma.t',
                'piyapon.p',
                'chalisa.s',
                'aunchalee.p',
                'saranya.a',
                'kamonwan.t',
                'junpen.d',
                'suwaree.r',
                'patitta.c',
                'praew.p',
                'jariyaporn.k',
                'ponlasin.p',],
        },
        {
            property: 'debt_settlement_date',
            name: 'วันที่เจราหนี้',
            type: "date",

        },
    ];
    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'brand',
            headerName: 'Brand',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'product',
            headerName: 'Product',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'contract_no',
            headerName: 'เลขที่สัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'ชื่อ-นามสกุล',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.name || ''} ${params.row.lastname || ''}`,
        },
        {
            field: 'citizen_id',
            headerName: 'เลขที่บัตรประชาชน',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'phone_number',
            headerName: 'เบอร์โทรศัพท์',
            width: 200,
            editable: true,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'dd_resister',
            headerName: 'สถานะเข้าระบบ DD ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'bank_name',
            headerName: 'ธนาคารที่เข้าระบบ DD',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'debt_class',
            headerName: 'ชั้นหนี้',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'outstanding_principal',
            headerName: 'ต้นคงค้าง',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'accrued_interest',
            headerName: 'ดอกเบี้ยคงค้าง',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'last_pay',
            headerName: 'วันที่ชำระล่าสุด',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.last_pay === '1900-01-01') {
                    return (
                        <>
                            -
                        </>
                    );
                } else if (cellValues.row.last_pay === 'Invalid date') {
                    return (
                        <>
                            -
                        </>
                    );
                }
                else {
                    return (
                        <>
                            {cellValues.row.last_pay}
                        </>
                    );
                }

            }
        },
        {
            field: 'company_score',
            headerName: 'score บริษัท',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'place_of_operation',
            headerName: 'สถานการดำเนินการ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'policy_group',
            headerName: 'กลุ่มสัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },

        {
            field: 'care_taker',
            headerName: 'ผู้ดูแล',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'debt_settlement_date',
            headerName: 'วันที่เจราหนี้',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'note_popose',
            headerName: 'Note',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'note',
            headerName: '',
            width: 20,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton onClick={(event) => {
                            handleOpenEditNote(event, cellValues);
                        }} ><EditIcon color="primary" /></IconButton>
                    </>
                );
            }

        },
        {
            field: 'upload',
            headerName: 'เอกสารที่อัปโหลด ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton onClick={(event) => {
                            routeChangeEdit(event, cellValues);
                        }} ><SearchIcon color="primary" /></IconButton>
                    </>
                );
            }
        },
    ];
    const handleClose = () => {
        setOpen(false);
    };
    const routeChangeEdit = (e, value) => {
        const parameterData = { contact_id: value.row.contact_id, user_id: value.row.citizen_id, name: value.row.name };
        const queryParams = new URLSearchParams(parameterData).toString();
        window.open('/document_tracking_user?' + queryParams, '_blank');

    };
    const handleOpenEditNote = (e, value) => {
        setRow(value.row)
        setNote(value.row.note_popose)
        setOpenNote(true)
    }
    const handleEdit = (value) => {
        let body
        if (value === 'note') {
            body = {
                query: rows._id,
                note: note,
                note_type: "note_popose"
            }
        }
        CrmService.crmEdit(token, body)
            .then((res) => {
                if (res.data.success) {
                    alert('บันทึกสำเร็จ')
                    setOpenNote(false)
                    fetchData()
                }
            })
    }
    const handleExportExcelButton = () => {
        const list_datas = [];
        rows_data.map(item => {
            list_datas.push({
                "Brand": item.brand,
                "Product": item.product,
                "เลขที่สัญญา": item.contract_no,
                "ชื่อ-นามสกุล": item.name,
                "เลขที่บัตรประชาชน": item.citizen_id,
                "เบอร์โทรศัพท์": item.phone_number,
                "สถานะเข้าระบบ DD": item.dd_resister,
                "ธนาคารที่เข้าระบบ DD": item.bank_name,
                "ชั้นหนี้": item.debt_class,
                "ต้นคงค้าง": item.outstanding_principal,
                "ดอกเบี้ยคงค้าง": item.accrued_interest,
                "วันที่ชำระล่าสุด": item.last_pay ==='1900-01-01'||item.last_pay ==='Invalid date' ? '-':item.last_pay,
                "score บริษัท": item.company_score,
                "สถานะดำเนินการ": '-',
                "กลุ่มสัญญา": item.policy_group,
                "ผู้ดูแล": item.care_taker,
                "วันที่เจราจาหนี้": '-',
                "Note": item.note_popose,
            })
        });

        setExcelData(list_datas);
        ExcelExport(list_datas, "crm_propose");
    }
    return (
        <>

            {has_permission && (
                <div className='customer-list' style={{ marginRight: '2rem', marginLeft: '2rem' }}>
                    {
                        false ?
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            : ""
                    }

                    <Paper elevation={3} >
                        <Filter
                            list={filter}
                            handleSetFilters={setFilters}
                        />
                        <Grid item>
                            <Button variant="contained" color="success" style={{ margin: "10px" }}
                                onClick={() => handleExportExcelButton()}
                            > export</Button>
                        </Grid>
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                                // bgcolor:'black'
                            }}
                        >
                            <DataGrid
                                rows={rows_data}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}

                            />
                        </Box>
                    </Paper>
                </div>
            )}
            <Modal
                open={popup_note}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_note}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenNote(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h2 id="child-modal-title">เพิ่มหมายเหตุ</h2>
                    <Textarea minRows={5} id="outlined-basic" label="หมายเหตุ" variant="outlined" size="small" value={note} onChange={(e) => setNote(e.target.value)} />
                    <br></br>
                    <Button style={{ float: 'right' }} variant="contained" onClick={(e) => handleEdit('note')}>บันทึก</Button>
                </Box>
            </Modal>
        </>
    )

}