import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SideBar from '../dashboard.js';
import * as DirectCredit from '../../services/direct_credit.js';
import { Typography, Grid, Button, TextField, Paper, Select, MenuItem, InputLabel, FormControl, Chip } from '@mui/material'
import FormDialog from '../dev.js';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


export default function Detail() {
    let token_org = localStorage.getItem('token_org')
    let token = localStorage.getItem('token')
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const contact_id = queryParams.get('contact_id');
    const [missing_document, setMissingDocument] = useState([])
    const [select_date, setSelectDate] = useState(null)
    const [advance_paid_month1_num, setAdvancePaidMonth1Num] = useState(1)
    const [advance_paid_month2_num, setAdvancePaidMonth2Num] = useState(1)
    const [currentMonth, setCurrentMonth] = useState(0)
    const [formData, setFormData] = useState({
        contact_id: '',
        name: '',
        amount: '',
        duty: '',
        beauro: '',
        case_maker: '',
        missing_document: '',
        service_one: '',
        net_amount: '',
        advancepaid_month1: '',
        advancepaid_month2: '',
        estimate_installment_amount: '',
        installment_offered: '',
        prodname: '',
        bank_name: '',
        accountno: '',
        d_loan: null,
        interest_rate: '',
        policygroup: '',
        borrower_code: '',
        line_member: '',
        status: '',
        ncbsend: '',
        contract_no: '',
        citizen_id: '',
        accountno: '',
        bank_name: '',
        direct_debit: '',
        gb_fee: '',
        close_account: '',
        note: '',
        old_policy_group: ''
    });

    const handleDoc = (event) => {
        const {
            target: { value },
        } = event;
        setMissingDocument(
            typeof value === 'string' ? value.split(',') : value,
        );
    }
    // const [currentMonth] = useState(() => {
    //     const currentDate = new Date();
    //     return currentDate.getMonth();
    // });

    const getMonthName = (monthIndex) => {
        const monthNames = [
            'มกราคม', 'กุมภาพันธ', 'มีนาคม', 'เมษายน', 'พฤษภาคม ', 'มิถุนายน', 'กรกฎาคม',
            'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
        ];
        return monthNames[monthIndex];
    };

    useEffect(() => {
        let obj = {
            contact_id: contact_id
        }
        DirectCredit.getCreditOperationDetail(obj, token_org)
            .then((res) => {
                console.log('close',res.data.data)
                let approve_date = new Date(res.data.data.approve_date)
                console.log('get date ', approve_date.getDate());
                setCurrentMonth(approve_date.getMonth())

                if(approve_date.getDate() <= 15){
                    setAdvancePaidMonth1Num(parseInt((approve_date.getMonth())% 12))
                    setAdvancePaidMonth2Num(parseInt((approve_date.getMonth()+1)% 12))
                }else{
                    setAdvancePaidMonth1Num(parseInt((approve_date.getMonth()+1)% 12))
                    setAdvancePaidMonth2Num(parseInt((approve_date.getMonth()+2)% 12))

                }
                let net_amount =
                    parseFloat(res.data.data.amount)
                    - parseFloat(res.data.data.close_payment)
                    - parseFloat(res.data.data.duty)
                    - parseFloat(res.data.data.beauro)
                    - (res.data.data.advancepaid_month1 === '-' || res.data.data.advancepaid_month1 === 'no' ? 0 : parseFloat(res.data.data.estimate_installment_amount))
                    - (res.data.data.advancepaid_month2 === '-' || res.data.data.advancepaid_month1 === 'no' ? 0 : parseFloat(res.data.data.estimate_installment_amount))
                    - 4
                setFormData(res.data.data)
                console.log(res.data.data);
                setFormData((prevData) => ({
                    ...prevData,
                    gb_fee: 4, // Update the d_loan field with the new date
                    net_amount: net_amount,
                }));

            })
    }, [])
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleDateChange = (newDate) => {
        console.log(newDate);
        setFormData((prevData) => ({
            ...prevData,
            d_loan: newDate, // Update the d_loan field with the new date
        }));
    };
    const getNextMonth = (event) => {
        const d = new Date();
        let month = d.getMonth();
        console.log(month)
        return month + event
    }

    const handleDataChange = (event) => {
        let id = event.target.id
        let value = event.target.value
        if (id === "close_payment") {

            let value2 = value
            let net_amount =
                formData.amount
                - formData.gb_fee
                - Math.ceil(formData.amount / 2000)
                - formData.beauro
                - (formData.advancepaid_month1 === '-' || formData.advancepaid_month1 === 'no' ? 0 : parseFloat(formData.estimate_installment_amount))
                - (formData.advancepaid_month2 === '-' || formData.advancepaid_month1 === 'no' ? 0 : parseFloat(formData.estimate_installment_amount))
                - value2

            console.log(net_amount);
            if (net_amount < 0) {
                value2 = 0
                net_amount =
                    formData.amount
                    - formData.gb_fee
                    - Math.ceil(formData.amount / 2000)
                    - formData.beauro
                    - (formData.advancepaid_month1 === '-' || formData.advancepaid_month1 === 'no' ? 0 : parseFloat(formData.estimate_installment_amount))
                    - (formData.advancepaid_month2 === '-' || formData.advancepaid_month1 === 'no' ? 0 : parseFloat(formData.estimate_installment_amount))
                    - value2
                alert("ยอดปิดชำระมากกว่ายอดโอนสุทธิ กรุณาตรวจสอบอีกครั้ง")

            }
            setFormData({
                ...formData,
                [id]: value2,
                ['net_amount']: net_amount
            })
        }
        // setFormData({
        //     ...data,
        //     [id]: value
        // })
    }

    const handleSubmit = (e) => {
        console.log(formData)
        DirectCredit.sumbit_oper(formData, token)
            .then((res) => {

                if (res.data.success) {
                    alert("บันทึกสำเร็จ")
                    window.close()
                } else {
                    alert("บันทึกไม่สำเร็จ")
                }
            })
    }

    const generateOptions = () => {
        const options = [];
        for (let i = 0; i < 4; i++) {
            const monthIndex = (currentMonth + i) % 12;
            options.push({
                value: monthIndex,
                label: getMonthName(monthIndex),
            });
        }
        console.log(options)
        return options;
    };

    return (
        <div className='customer-list' style={{ margin: '2rem' }}>
            <SideBar  id="side-bar"/>
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: '' }}>Credit Operation Detail</Typography>
                </Grid>
            </Grid>
            <Paper elevation={3}>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} style={{ padding: '1rem' }}>
                        <Grid item xs={4}>
                            <TextField
                                label="ชื่อ-นามสกุล ลูกค้า"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={1.5}>
                            <TextField
                                label="ยอดอนุมัติ"
                                name="appv_amt"
                                value={formData.amount}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={1}>

                            <TextField
                                label="ค่าอากร"
                                name="duty_fee"
                                value={formData.duty}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                label="ค่าบูโร"
                                name="beauro"
                                value={formData.beauro}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={1.5}>
                            <TextField
                                label="ค่าธรรมเนียม 1"
                                name="gb_fee"
                                value={formData.gb_fee}
                                onChange={event => handleDataChange(event)}
                                fullWidth
                                margin="normal"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="ยอดปิดบัญชีเดิม"
                                id="close_payment"
                                name="close_payment"
                                value={formData.close_payment}
                                onChange={event => handleDataChange(event)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="ประเภทเคส"
                                id="note"
                                name="note"
                                value={formData.note}
                                fullWidth
                                margin="normal"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>กลุ่มสัญญาเดิม</InputLabel>
                                <Select
                                    name="old_policy_group"
                                    label="ค่างวดล่วงหน้า 1"
                                    value={formData.old_policy_group}
                                    // onChange={handleOldPocChange}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}></MenuItem>
                                    <MenuItem value={'ฝากบันทึก - BYC'}>ฝากบันทึก - BYC</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - CLS'}>ฝากบันทึก - CLS</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - CLS1'}>ฝากบันทึก - CLS1</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - CLS2'}>ฝากบันทึก - CLS2</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ผ่อนสินค้า'}>ฝากบันทึก - ผ่อนสินค้า</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ MSCW'}>ฝากบันทึก - ลูกหนี้ MSCW</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ NP PICO'}>ฝากบันทึก - ลูกหนี้ NP PICO</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ พัฒนาสินทรัพย์บุคคล'}>ฝากบันทึก - ลูกหนี้ พัฒนาสินทรัพย์บุคคล</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ พิโกชลบุรี<'}>ฝากบันทึก - ลูกหนี้ พิโกชลบุรี</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - AMC'}>ฝากบันทึก - AMC</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ปิดบัญชีหนี้สูญรับคืนแล้ว'}>ฝากบันทึก - ลูกหนี้ปิดบัญชีหนี้สูญรับคืนแล้ว</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ฝากบันทึกลูกหนี้ MSCW'}>พิโกชลบุรี - ฝากบันทึกลูกหนี้ MSCW</MenuItem>
                                    <MenuItem value={'ฝากบันทึก CLS'}>ฝากบันทึก CLS</MenuItem>
                                    <MenuItem value={'ลูกค้ารายย่อย'}>ลูกค้ารายย่อย</MenuItem>
                                    <MenuItem value={'ลูกค้ารายย่อย - MOU SCAN'}>ลูกค้ารายย่อย - MOU SCAN</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - Cash Krub'}>ฝากบันทึก - Cash Krub</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - Nova Capital'}>ฝากบันทึก - Nova Capital</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - Easy Cap'}>ฝากบันทึก - Easy Cap</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>

                                    <TextField
                                        label="ค่างวดล่วงหน้า 1"
                                        id="note"
                                        name="note"
                                        value={formData.advancepaid_month1 ==='-' ? '' :getMonthName(advance_paid_month1_num)}
                                        fullWidth
                                        margin="normal"
                                        disabled={true}
                                    />
                                    {/* <FormControl fullWidth margin="normal">
                                        <InputLabel>ค่างวดล่วงหน้า 1</InputLabel>
                                        <Select
                                            name="advancepaid_month1"
                                            label="ค่างวดล่วงหน้า 1"
                                            value={formData.advancepaid_month1 ==='-' ? '' :getNextMonth(advance_paid_month1_num)}
                                            onChange={handleChange}
                                            disabled={true}
                                        >
                                            {generateOptions().map((option) => (
                                                <MenuItem value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> */}
                                </Grid>
                                {formData.advancepaid_month1 === '/' ? (
                                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant='h6'>{formData.estimate_installment_amount ?? 0}</Typography>
                                    </Grid>)
                                    : (<Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                                    </Grid>)

                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>

                                <TextField
                                        label="ค่างวดล่วงหน้า 2"
                                        id="note"
                                        name="note"
                                        value={formData.advancepaid_month2 ==='-' ? '' :getMonthName(advance_paid_month2_num)}
                                        fullWidth
                                        margin="normal"
                                        disabled={true}
                                    />
                                    {/* <FormControl fullWidth margin="normal">
                                        <InputLabel>ค่างวดล่วงหน้า 2</InputLabel>
                                        <Select
                                            name="advancepaid_month2"
                                            value={formData.advancepaid_month2 ==='-' ? '' :getNextMonth(advance_paid_month2_num)}
                                            onChange={handleChange}
                                            label="ค่างวดล่วงหน้า 2"
                                            disabled={formData.direct_debit === 1 ? true : false}
                                            disabled={true}
                                        >
                                            <MenuItem value=""></MenuItem>
                                            {generateOptions().map((option) => (
                                                <MenuItem value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> */}
                                </Grid>
                                {formData.advancepaid_month2 === '/' ? (
                                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant='h6'>{formData.estimate_installment_amount ?? 0}</Typography>
                                    </Grid>)
                                    : (<Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                                    </Grid>)

                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="ยอดโอนสุทธิ"
                                name="net_transfer"
                                value={formData.net_amount}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="สินเชื่อผู้จัดทำเคส"
                                name="case_maker"
                                value={formData.case_maker}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>ชื่อธนาคาร</InputLabel>
                                <Select
                                    name="bank_name"
                                    label="ชื่อธนาคาร"
                                    value={formData.bank_name}
                                    onChange={handleChange}
                                    disabled={true}
                                >
                                    <MenuItem></MenuItem>
                                    <MenuItem value="ธนาคารกรุงเทพ (BBL)">ธนาคารกรุงเทพ</MenuItem>
                                    <MenuItem value="ธนาคารกรุงไทย (KTB)">ธนาคารกรุงไทย</MenuItem>
                                    <MenuItem value="ธนาคารกรุงศรีอยุธยา (BAY)">ธนาคารกรุงศรีอยุธยา</MenuItem>
                                    <MenuItem value="ธนาคารกสิกรไทย (KBANK)">ธนาคารกสิกรไทย</MenuItem>
                                    <MenuItem>ธนาคารเกียรตินาคิน</MenuItem>
                                    <MenuItem>ธนาคารซีไอเอ็มบีไทย</MenuItem>
                                    <MenuItem>ธนาคารทหารไทยธนชาต</MenuItem>
                                    <MenuItem>ธนาคารทิสโก้</MenuItem>
                                    <MenuItem value="ธนาคารไทยพาณิชย์ (SCB)">ธนาคารไทยพาณิชย์</MenuItem>
                                    <MenuItem>ธนาคารยูโอบี</MenuItem>
                                    <MenuItem>ธนาคารไอซีบีซีไทย</MenuItem>
                                    <MenuItem>ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร</MenuItem>
                                    <MenuItem value="ธนาคารออมสิน (GSB)">ธนาคารออมสิน</MenuItem>
                                    <MenuItem>ธนาคารไทยเครดิต เพื่อรายย่อย จำกัด (มหาชน)</MenuItem>
                                    <MenuItem>ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)</MenuItem>
                                    <MenuItem>ธนาคารอิสลามแห่งประเทศไทย</MenuItem>
                                    <MenuItem>ธนาคารธนชาต</MenuItem>
                                    <MenuItem value="ธนาคารทหารไทยธนชาต (TTB)">ธนาคารทหารไทยธนชาต </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="เลขบัญชี"
                                name="accountno"
                                value={formData.accountno}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>ชื่อสินค้า</InputLabel>
                                <Select
                                    name="prodname"
                                    label="ชื่อสินค้า"
                                    value={formData.prodname}
                                    onChange={handleChange}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value="PLOAN1 PLR for other">PLOAN1 PLR for other</MenuItem>
                                    <MenuItem value="PLOAN PLR for Occcupation">PLOAN PLR for Occcupation</MenuItem>
                                    <MenuItem value="Nano Finance">Nano Finance</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Policy Group</InputLabel>
                                <Select
                                    name="policygroup"
                                    label="Policy Group"
                                    value={formData.policygroup}
                                    onChange={handleChange}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value="ลูกค้ารายย่อย">MSCW PLOAN</MenuItem>
                                    <MenuItem value="ลูกค้ารายย่อย - NANO">MSCW NANO</MenuItem>
                                    <MenuItem value="ฝากบันทึก - CLS">ฝากบันทึก - CLS</MenuItem>
                                    <MenuItem value="ฝากบันทึก - BYC">ฝากบันทึก - BYC</MenuItem>
                                    <MenuItem value="ฝากบันทึก - PicoTH">ฝากบันทึก - PicoTH</MenuItem>
                                    <MenuItem value="ฝากบันทึก - Cash Krub">ฝากบันทึก - Cash Krub</MenuItem>
                                    <MenuItem value="ฝากบันทึก - Nova Capital">ฝากบันทึก - Nova Capital</MenuItem>
                                    <MenuItem value="ฝากบันทึก - Easy Cap">ฝากบันทึก - Easy Cap</MenuItem>
                                    <MenuItem value="ฝากบันทึก - AMC">ฝากบันทึก - AMC</MenuItem>
                                    <MenuItem value="ฝากบันทึก - อื่นๆ">ฝากบันทึก - อื่นๆ</MenuItem>
                                    {/* <MenuItem value="ลูกค้ารายกลาง">ลูกค้ารายกลาง</MenuItem>
                                    <MenuItem value="ลูกค้ารายใหญ่">ลูกค้ารายใหญ่</MenuItem>
                                    <MenuItem value="หนี้สูญทางบัญชี">หนี้สูญทางบัญชี</MenuItem>
                                    <MenuItem value="ฝากบันทึก-ลูกหนี้ PICO">ฝากบันทึก-ลูกหนี้ PICO</MenuItem>
                                    <MenuItem value="ฝากบันทึก-ลูกหนี้ MSCW">ฝากบันทึก-ลูกหนี้ MSCW</MenuItem>
                                    <MenuItem value="ฝากบันทึก-ลูกหนี้ พิโก้ชลบุรี">ฝากบันทึก-ลูกหนี้ พิโก้ชลบุรี</MenuItem>
                                    <MenuItem value="ฝากบันทึก-ผ่อนสินค้า">ฝากบันทึก-ผ่อนสินค้า</MenuItem>
                                    <MenuItem value="ฝากบันทึก-ลูกหนี้ปิดบัญชีหนี้สูญรับคืนแล้ว">ฝากบันทึก-ลูกหนี้ปิดบัญชีหนี้สูญรับคืนแล้ว</MenuItem>
                                    <MenuItem value="ลูกค้ารายกลาง-นิติบุคคล">ลูกค้ารายกลาง-นิติบุคคล</MenuItem>
                                    <MenuItem value="ลูกค้ารายใหญ่-นิติบุคคล">ลูกค้ารายใหญ่-นิติบุคคล</MenuItem>
                                    <MenuItem value="ลูกค้ารายย่อย-บุคคลรับประกัน">ลูกค้ารายย่อย-บุคคลรับประกัน</MenuItem>
                                    <MenuItem value="ฝากบันทึก-ลูกหนี้ พัฒนาสินทรัพย์บุคคล">ฝากบันทึก-ลูกหนี้ พัฒนาสินทรัพย์บุคคล</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <InputLabel>เอกสารที่ยังขาด</InputLabel>
                            <Select
                                multiple
                                name="missing_document"
                                value={missing_document}
                                onChange={handleDoc}
                                label="เอกสารที่ยังขาด"
                                fullWidth
                                inputProps={{
                                    id: 'document-select',
                                }}
                                renderValue={(selected) => (
                                    <div>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </div>
                                )}
                            >
                                <MenuItem value="เอกสารครบ">เอกสารครบ</MenuItem>
                                <MenuItem value="แบบฟอร์มคำขอสินเชื่อ 3 ใบ">แบบฟอร์มคำขอสินเชื่อ 3 ใบ</MenuItem>
                                <MenuItem value="สัญญาเงินกู้ 3 ใบ">สัญญาเงินกู้ 3 ใบ</MenuItem>
                                <MenuItem value="หนังสือให้ความยินยอมในการเปิดเผยข้อมูล(NCB)">หนังสือให้ความยินยอมในการเปิดเผยข้อมูล(NCB)</MenuItem>
                                <MenuItem value="หนังสือสัญญาหลักประกันทางธุรกิจ 2 ใบ">หนังสือสัญญาหลักประกันทางธุรกิจ 2 ใบ</MenuItem>
                                <MenuItem value="หนังสือนำส่งข้อมูลเครดิตครั้งแรก(ทางอิเล็กทรอนิกส์)">หนังสือนำส่งข้อมูลเครดิตครั้งแรก(ทางอิเล็กทรอนิกส์)</MenuItem>
                                <MenuItem value="หนังสือแจ้งความยินยอมในการปฎิเสธสินเชื่อ (ทางอิเล็กทรอนิกส์)">หนังสือแจ้งความยินยอมในการปฎิเสธสินเชื่อ (ทางอิเล็กทรอนิกส์)</MenuItem>
                                <MenuItem value="สำเนาบัตรประชาชน 2 ฉบับ">สำเนาบัตรประชาชน 2 ฉบับ</MenuItem>
                            </Select>
                        </Grid> */}
                        {/* <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>หลักทรัพย์ค้ำประกัน</InputLabel>
                                <Select
                                    name="service_one"
                                    label="หลักทรัพย์ค้ำประกัน"
                                    value={formData.service_one}
                                    onChange={handleChange}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value="1">วางที่ดินไว้</MenuItem>
                                    <MenuItem value="2">ที่ดินจดจำนอง</MenuItem>
                                    <MenuItem value="3">จดหลักประกันทางธุรกิจ</MenuItem>
                                    <MenuItem value="4">ไม่มีหลักประกัน (Clean Loan)</MenuItem>
                                    <MenuItem value="5">วางเล่มทะเบียนรถยนต์</MenuItem>
                                    <MenuItem value="6">วางเล่มทะเบียนรถจักรยานยนต์</MenuItem>
                                    <MenuItem value="7">วางเล่มรถอื่นๆ</MenuItem>
                                    <MenuItem value="8">บุคคลค้ำ</MenuItem>
                                    <MenuItem value="9">รถยนต์เกษตร</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid> */}

                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DesktopDatePicker
                                    label="วันที่ปล่อยสินเชื่อ"
                                    inputFormat="YYYY-MM-DD"
                                    name="d_loan"
                                    value={formData.d_loan}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField sx={{ width: '100%', mt: 2 }} {...params} />}

                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="อัตราดอกเบี้ย"
                                name="interest_rate"
                                disabled={true}
                                value={formData.interest_rate}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>NCB Send</InputLabel>
                                <Select
                                    name="ncbsend"
                                    label="NCB Send"
                                    value={formData.ncbsend}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="TRUE">TRUE</MenuItem>
                                    <MenuItem value="FALSE">FALSE</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={6}>
                            <TextField
                                label="รหัสกู้"
                                name="borrower_code"
                                value={formData.borrower_code}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid> */}
                        {/* <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Line Member</InputLabel>
                                <Select
                                    name="line_member"
                                    label="Line Member"
                                    value={formData.line_member}
                                    onChange={handleChange}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value="Yes">Yes</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={6}>
                            <TextField
                                label="contract_no"
                                name="contract_no"
                                value={formData.contract_no}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid> */}
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>สถานะ</InputLabel>
                                <Select
                                    name="status"
                                    label="สถานะ"
                                    value={formData.status}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="0">รอ Add Line</MenuItem>
                                    <MenuItem value="7">ไม่รับสาย</MenuItem>
                                    <MenuItem value="9">ปฏิเสธ</MenuItem>
                                    <MenuItem value="2">พร้อมโอนเงิน</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <Button onClick={handleSubmit} size='large' variant="contained" color="primary">
                                บันทึก
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>
    );
}
