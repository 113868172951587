import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit';
// const useStyles = makeStyles({
//     customTableContainer: {
//       overflowX: "initial"
//     }
//   })
export default function MarketingDashboardTable({
    mkt_data,
    selected_month,
    handleEditClick
}) {
    return (
        <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader={true} aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="center"
                            colSpan={4}
                            sx={{
                                backgroundColor: "#134F5C",
                                color: "#FFFFFF",
                            }}
                        >
                            {selected_month}
                        </TableCell>
                        <TableCell
                            align="center"
                            colSpan={13}
                            className='table-section'
                            sx={{
                                backgroundColor: "#385391",
                                color: "#FFFFFF",
                            }}
                        >
                            Contact
                        </TableCell>
                        <TableCell
                            align="center"
                            colSpan={9}
                            className='table-section'
                            sx={{
                                backgroundColor: "#5E813F",
                                color: "#FFFFFF"
                            }}
                        >
                            Loan
                        </TableCell>
                        <TableCell
                            align="center"
                            colSpan={5}
                            className='table-section'
                            sx={{
                                backgroundColor: "#7B7B7B",
                                color: "#FFFFFF"
                            }}
                        >
                            Cost
                        </TableCell>
                        <TableCell
                            align="center"
                            // rowSpan={2}
                            className='table-section'
                            sx={{
                                backgroundColor: "#FF0000",
                                color: "#FFFFFF"
                            }}
                        >
                            {/* หมายเหตุ */}
                        </TableCell>
                    </TableRow>
                    <TableRow
                        className='table-row'
                    >
                        {/* General section */}
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Date
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell table-row'
                        >
                            Agency
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell table-row'
                        >
                            Brand
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell table-row'
                        >
                            Channel
                        </TableCell>
                        {/* Contract section */}
                        <TableCell
                            align="center"
                            className='table-section table-row'
                        >
                            Amount Spent
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Reach
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Impressions
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Result(Link Click)
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Contact
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            No Test
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            % No Test
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Pass
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            No Pass
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            % Pass
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            เข้าร่วม digital app
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            ไม่เข้าร่วม digital app
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            % ไม่เข้าร่วม digital app
                        </TableCell>
                        {/* Loan section */}
                        <TableCell
                            align="center"
                            className='table-section'
                        >
                            Document Received
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            % Document Received
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Approved
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Reject
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            % Approved(Loan)
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            % Reject
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            ยอดอนุมัติ (สินเชื่อ)
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            ยอดปล่อย (Oper)
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            วงเงินเฉลี่ย
                        </TableCell>
                        {/* Cost Section */}
                        <TableCell
                            align="center"
                            className='table-section'
                        >
                            Cost per Result
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Cost per Contact
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Cost per pass
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Cost per approved
                        </TableCell>
                        <TableCell
                            align="center"
                            className='table-cell'
                        >
                            Cost per 1M Approved
                        </TableCell>
                        <TableCell
                            align="center"
                            // rowSpan={2}
                            className='table-section'
                            sx={{
                                backgroundColor: "#FF0000",
                                color: "#FFFFFF"
                            }}
                        >
                            หมายเหตุ
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        mkt_data.map((mkt_row, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    style={{ backgroundColor: mkt_row.agency === "Total" ? '#FBE7A3' : mkt_row.agency === "Total (Month)" ? '#50C878' : mkt_row.agency === "Total (Week)" ? '#E9B28A' : '#FFFFFF' }}
                                >
                                    <TableCell
                                        align="center"
                                        className='table-cell'
                                    >
                                        {mkt_row.date}
                                    </TableCell>
                                    <TableCell
                                        className='table-cell'
                                    >
                                        {mkt_row.agency}
                                    </TableCell>
                                    <TableCell
                                        className='table-cell'
                                    >
                                        {mkt_row.brand}
                                    </TableCell>
                                    <TableCell
                                        className='table-cell'
                                    >
                                        {mkt_row.mkt_channel}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-section'
                                    // style={{width: 300 }}
                                    >
                                        {
                                            mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ?
                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="amount_spent"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                                    value={mkt_row.amount_spent}
                                                    disabled
                                                />
                                                :
                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="amount_spent"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                                    value={mkt_row.amount_spent}
                                                    disabled
                                                    InputProps={{
                                                        endAdornment:
                                                            <IconButton
                                                                onClick={() => handleEditClick('amount_spent', mkt_row._id, mkt_row.tx_data_index, mkt_row.amount_spent)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                    }}
                                                />
                                        }
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    // style={{width: 300 }}
                                    >
                                        {
                                            mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ?

                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="reach"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                                    value={mkt_row.reach}
                                                    disabled
                                                />
                                                :
                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="reach"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                                    value={mkt_row.reach}
                                                    disabled
                                                    InputProps={{
                                                        endAdornment:
                                                            <IconButton
                                                                disabled={mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ? true : false}
                                                                onClick={() => handleEditClick('reach', mkt_row._id, mkt_row.tx_data_index, mkt_row.reach)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                    }}
                                                />

                                        }
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    // style={{width: 300 }}
                                    >

                                        {
                                            mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ?
                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="impressions"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                                    value={mkt_row.impressions}
                                                    disabled
                                                />
                                                :
                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="impressions"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                                    value={mkt_row.impressions}
                                                    disabled
                                                    InputProps={{
                                                        endAdornment:
                                                            <IconButton
                                                                disabled={mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ? true : false}
                                                                onClick={() => handleEditClick('impressions', mkt_row._id, mkt_row.tx_data_index, mkt_row.impressions)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                    }}
                                                />
                                        }
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {/* {mkt_row.link_click} */}
                                        {
                                            mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ?

                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="link_click"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={mkt_row.link_click}
                                                    disabled
                                                />
                                                :
                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="link_click"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={mkt_row.link_click}
                                                    disabled
                                                    InputProps={{
                                                        endAdornment:
                                                            <IconButton
                                                                disabled={mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ? true : false}
                                                                onClick={() => handleEditClick('link_click', mkt_row._id, mkt_row.tx_data_index, mkt_row.link_click)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                    }}
                                                />
                                        }
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    // style={{width: 300 }}
                                    >
                                        {
                                            mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ?
                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="contact"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                                    value={mkt_row.contact}
                                                    disabled
                                                />
                                                :
                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="contact"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                                    value={mkt_row.contact}
                                                    disabled
                                                    InputProps={{
                                                        endAdornment:
                                                            <IconButton
                                                                disabled={mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ? true : false}
                                                                onClick={() => handleEditClick('contact', mkt_row._id, mkt_row.tx_data_index, mkt_row.contact)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                    }}
                                                />
                                        }
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.no_test}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.percent_no_pass_micro_site}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.pass_micro_site}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.no_pass_micor_site}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.percent_pass_micro_site}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.digital_app}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.no_ditgital_app}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.percent_no_ditgital_app}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-section'
                                    >
                                        {/* {mkt_row.document_received} */}
                                        {
                                            mkt_row.agency !== "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ?
                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="document_received"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                                    value={mkt_row.document_received}
                                                    disabled
                                                />
                                                :
                                                <TextField
                                                    style={{ width: 125, textAlign: 'right' }}
                                                    id="document_received"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                                    value={mkt_row.document_received}
                                                    disabled
                                                    InputProps={{
                                                        endAdornment:
                                                            <IconButton
                                                                onClick={() => handleEditClick('document_received', mkt_row._id, mkt_row.tx_data_index, mkt_row.document_received)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                    }}
                                                />
                                        }
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.percent_document_received}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.approved}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.reject}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.percent_approved}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.percent_reject}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.amount}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.offer_limit}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.amount_avg}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-section'
                                    >
                                        {mkt_row.cost_per_click}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.cost_per_contact}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.cost_per_pass_micro_site}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.cost_per_approved}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-cell'
                                    >
                                        {mkt_row.cost_per_million}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className='table-section'
                                    >
                                        {
                                            mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ?
                                                <TextField
                                                    style={{ width: 200 }}
                                                    id="note"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={mkt_row.note}
                                                    disabled

                                                />
                                                :
                                                <TextField
                                                    style={{ width: 200 }}
                                                    id="note"
                                                    size="small"
                                                    type="text"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={mkt_row.note}
                                                    disabled
                                                    InputProps={{
                                                        endAdornment:
                                                            <IconButton
                                                                disabled={mkt_row.agency === "Total" || mkt_row.agency === "Total (Month)" || mkt_row.agency === "Total (Week)" ? true : false}
                                                                onClick={() => handleEditClick('note', mkt_row._id, mkt_row.tx_data_index, mkt_row.note)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                    }}
                                                />
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>

    )
}