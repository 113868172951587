import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {
  callOCRStatement
} from '../../services/statement_service'
import { useParams } from 'react-router-dom';
import { PDFDocument } from 'pdf-lib';

export default function AlertDialog({ open, handleClose, handleOpen }) {
  const { contact_id } = useParams()
  const [bank, setBank] = useState(null);
  const [pdfFile, setPDFfile] = useState(null)
  const [password, setPassword] = useState(null);
  const fileInput = useRef(null);

  const PDFJS = window.pdfjsLib;

  async function loadPDF(file, password) {
    const uri = URL.createObjectURL(file);

    return PDFJS.getDocument({
      url: uri,
      password: password
    })
      .promise
      .then(function (pdfDoc) {
        console.log("PDF loaded successfully:", pdfDoc);

        setPDFfile(file)
        setPassword(password)

        return pdfDoc;
      })
      .catch(function (error) {
        console.log("Error loading PDF:", error);
        return error;
      });
  }

  async function checkIsEncrypt(file) {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer, { ignoreEncryption: true });
    return pdfDoc.isEncrypted
  }

  async function handleFileChange(event) {
    const file = event.target.files[0];
    const isPasswordProtected = await checkIsEncrypt(file);

    if (isPasswordProtected) {
      const password = window.prompt("เอกสารนี้ได้รับการป้องกันด้วยรหัสผ่าน โปรดป้อนรหัสผ่าน");
      const PDFAuth = await loadPDF(file, password)

      // PasswordException
      if (PDFAuth.name === 'PasswordException') {
        window.alert(PDFAuth.message);

        // Return file
        event.target.value = '';
        return;
      }
    } else {
      await loadPDF(file, password)
    }
  }

  const handleSave = () => {
    const formData = new FormData();
    formData.append(
      "files",
      pdfFile,
      pdfFile.name
    );
    formData.append(
      "bank",
      bank.en_name
    );
    formData.append(
      "password",
      password
    );
    formData.append(
      "callback",
      // "https://api-uat.transfinmational.com/statement?id=" + contact_id
      // "http://node-api:3000/test/200?id=12345"
      "https://ocr-api-mscw.tfm.in.th/test/200?id=" + contact_id
    );
    formData.append(
      "contact_id",
      contact_id
    )

    console.log('formData', ...formData)

    callOCRStatement(formData).then(res => {
      console.log(res);
      window.location.reload();
    }).catch(err => {
      console.log(err);
      alert(err.response.data.status)
    })
  }

  const handleCancel = () => {
    setBank(null)
    setPassword(null)
    setPDFfile(null)
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogContent style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
          <Autocomplete
            value={bank}
            onChange={(event, newValue) => {
              setBank(newValue);
            }}
            id="autocomplete-bank"
            options={[
              { th_name: 'ธ.กสิกร', en_name: 'kbank' },
              { th_name: 'ธ.กรุงเทพ', en_name: 'bbl' },
              { th_name: 'ธ.ไทยพาณิชย์', en_name: 'scb' },
              { th_name: 'ธ.กรุงศรีอยุธยา', en_name: 'bay' },
              { th_name: 'ธ.กรุงไทย', en_name: 'ktb' }
            ]}
            getOptionLabel={(option) => option.th_name}
            isOptionEqualToValue={(option, value) => option.en_name === value.en_name}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="ธนาคาร" />}
          />

          <TextField
            id="outlined-basic"
            label="ไฟล์ Statement"
            onChange={event => handleFileChange(event)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              backgroundColor: "#FFFFFF",
              width: '300px'
            }}
            type="file"
            ref={fileInput}
            inputProps={{ accept: 'application/pdf' }}
            disabled={bank === null}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} disabled={pdfFile === null}>SAVE</Button>
          <Button onClick={handleCancel}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
