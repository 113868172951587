import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import BayImage from '../../../src/assets/images/icon_bank_bay.png'
import BblImage from '../../../src/assets/images/icon_bank_bbl.png'
import KbankImage from '../../../src/assets/images/icon_bank_kbank.png'
import KtbImage from '../../../src/assets/images/icon_bank_ktb.png'
import ScbImage from '../../../src/assets/images/icon_bank_scb.png'
import { userGBRegiser } from '../../services/direct_debit';
let token = localStorage.getItem('token')

const banksData = {
    KBANK: {
        name: 'ธนาคารกสิกรไทย (KBANK)',
        imageSrc: KbankImage
    },
    KTB: {
        name: 'ธนาคารกรุงไทย (KTB)',
        imageSrc: KtbImage,
    },
    SCB: {
        name: 'ธนาคารไทยพาณิชย์ (SCB)',
        imageSrc: ScbImage
    },
    BBL: {
        name: 'ธนาคารกรุงเทพ (BBL)',
        imageSrc: BblImage,
    },
    BAY: {
        name: 'ธนาคารกรุงศรีอยุธยา (BAY)',
        imageSrc: BayImage,
    },
};

function BankSelection({ bank, handleChange }) {
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ธนาคาร</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bank}
                    label="ธนาคาร"
                    onChange={handleChange}
                >
                    {Object.keys(banksData).map((bankKey) => (
                        <MenuItem key={bankKey} value={bankKey}>
                            {banksData[bankKey].name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br />
            <br />
            {bank && (
                <div style={{ border: '1px solid #c4c4c4', borderRadius: '5px', padding: '10px', display: 'flex', alignItems: 'center' }}>
                    <img src={banksData[bank].imageSrc} alt='' width={'50px'} style={{ marginRight: '10px', borderRadius: '10px' }} />
                    <span>{banksData[bank].name}</span>
                </div>
            )}
        </div>
    );
}

export default function AlertDialog({ open, close, data }) {
    console.log('data', data)
    const [bank, setBank] = useState('');

    const handleChange = (e) => {
        setBank(e.target.value)
    }

    const handleSubmit = () => {
        userGBRegiser({ ...data, bank_name: banksData[bank].name },token)
            .then((res) => {
                if (res.data.success) {
                    alert('ส่งLinkเปลี่ยนธนาคารสำเร็จ')
                } else {
                    alert('ส่งLinkเปลี่ยนธนาคารไม่สำเร็จ')
                }
                setBank('')
                close()
            })

    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    เลือกหรือเปลี่ยนธนาคาร
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        เลือกธนาคารด้านล่างเพื่อเลือกหรือเปลี่ยนธนาคารในการทำ Direct Debit
                        <br />
                        <br />
                        <BankSelection bank={bank} handleChange={handleChange} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>ยกเลิก</Button>
                    <Button onClick={handleSubmit} autoFocus>
                        ต่อไป
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}