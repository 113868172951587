/* eslint-disable no-unused-vars */
import * as React from 'react';
import Box from '@mui/material/Box';
import { confirmAlert } from 'react-confirm-alert';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import PreviewIcon from '@mui/icons-material/Preview'
import EditIcon from '@mui/icons-material/Edit'
import * as DirectDebit from '../../services/direct_debit.js';
// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
// components
import MyComponent from '../../components/filter.js';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Grid, Button, TextField, Paper, Select, MenuItem, InputLabel, FormControl, Chip } from '@mui/material'
import { green, red } from '@mui/material/colors';
export default function App() {
    let token = localStorage.getItem('token')
    let decoded
    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
    };
    // backdrop
    const [open, setOpen] = React.useState(true);
    const [rows_user, setRowsUser] = React.useState(true);
    const [rows_detail, setRowsDetail] = React.useState(true);
    const [open_popup, setOpenPopup] = React.useState(false);
    const [PIN, setPIN] = React.useState('');
    const location = useLocation();
    const hasPermission = checkPermission(location.pathname, decoded);
    const [selected_row, setSelectedRow] = React.useState([]);
    const [open_popup_edit, setOpenPopUpEdit] = React.useState(false);
    const [open_popup_detail, setOpenPopUpDetail] = React.useState(false);
    const [installments, setInstallments] = React.useState();
    const [formData, setFormData] = React.useState({})
    const handleClose = () => setOpen(false);
    const moment = require("moment-timezone")
    moment.tz.setDefault("Asia/Bangkok")
    React.useEffect(() => {
        if (!hasPermission) {
            window.location.href = '/404';
        }
        DirectDebit.getDeductionAutoList()
            .then((res) => {
                const list_data = res.data.data;
                const detail = [];
                list_data.map((item, index) => {
                    detail.push(
                        {
                            "id": index + 1,
                            "create_date": `${item.create_date}`,
                            "contract_no": `${item.contract_no}`,
                            "name": `${item.name} ${item.lastname}`,
                            "estimate_installment_amount": `${item.estimate_installment_amount}`,
                            "partial_amount": item.partial_amount,
                            "remaining_money_installment" : item.remaining_money_installment === null ? 0 : item.remaining_money_installment,
                            "all_round":item.all_round,
                            "successful_round":item.successful_round,
                            "status":item.status,
                            "detail":item,
                            "edit":item,
                        }
                    )
                });
                setRowsUser(detail)
                // setRowsUser(res.data.data)
            })
    }, [])
    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'No.',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,

        },
        {
            field: 'create_date',
            headerName: 'วันที่',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>

                `${params.row.create_date == null ? "-" : params.row.create_date.split("T")[0]}`,
        },
        {
            field: 'contract_no',
            headerName: 'เลขที่สัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'ชื่อ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.name || ''} ${params.row.lastname || ''}`,
        },
        {
            field: 'estimate_installment_amount',
            headerName: 'ค่างวด',
            width: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'partial_amount',
            headerName: 'จำนวนตัดเงินต่อรอบ',
            width: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'remaining_money_installment',
            headerName: 'ค่างวดคงเหลือ',
            width: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.remaining_money_installment === null ? 0 : params.row.remaining_money_installment}`,
        },
        {
            field: 'all_round',
            headerName: 'จำนวนครั้งที่ตัด',
            width: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'successful_round',
            headerName: 'จำนวนครั้งที่ตัดสำเร็จ',
            width: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center'

        },
        {
            field: 'status',
            headerName: 'status',
            width: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                switch (cellValues.row.status) {
                    case 1:
                        return <div class="green">Active</div>
                    case 0:
                        return <div class="red">InActive</div>
                }
            }
        },
        {
            field: 'detail',
            headerName: 'รายละเอียด',
            width: 200,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <IconButton onClick={(event) => {
                        handleClickDetail(event, cellValues);
                    }}><PreviewIcon color="primary" /></IconButton>
                );
            }
        },
        // {
        //     field: 'edit',
        //     headerName: 'แก้ไข',
        //     width: 200,
        //     editable: true,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: (cellValues) => {
        //         return (
        //             <IconButton onClick={(event) => {
        //                 handleClick(event, cellValues);
        //             }}><EditIcon color="primary" /></IconButton>
        //         );
        //     }
        // },
    ];
    const columns_detail = [
        {
            field: 'id',
            headerName: 'id',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'No.',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,

        },
        {
            field: 'transaction_date',
            headerName: 'วันที่',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>

                `${params.row.transaction_date == null ? "-" : params.row.transaction_date.split("T")[0]}`,
        },
        {
            field: 'col3',
            headerName: 'เวลา',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.transaction_date == null ? "-" :  new Date(params.row.transaction_date).toLocaleTimeString("th-TH", { timeZone: "UTC" })}`,
        },
        {
            field: 'amount',
            headerName: 'จำนวนเงิน',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>

                `${params.row.amount === null ? 0 : params.row.amount}`,
        },
        {
            field: 'resultMessage',
            headerName: 'Status',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },

    ];
    const handleClick = (e, value) => {
        setInstallments(value.row.remaining_money_installment)
        setFormData((prevData) => ({ ...prevData, user_id: value.row.user_id, contract_id: value.row.contract_id, type: value.row.type, amount: value.row.amount, remaining_money_installment: value.row.remaining_money_installment }))
        setOpenPopUpEdit(true)
    }
    const handleClickDetail = (e, value) => {
        console.log('click')
        console.log(value)
        let obj = {
            user_id: value.row.detail.user_id,
            deduction_id: value.row.detail.deduction_id
        }
        DirectDebit.DeductionAutoDetail(obj)
            .then((res) => {
                console.log('test')
                console.log(res.data.data)
                const list_data = res.data.data;
                const detail = [];
                list_data.map((item, index) => {
                    detail.push(
                        {
                            "id": index + 1,
                            "transaction_date": `${item.transaction_date}`,
                            "amount": (item.amount - item.vat).toFixed(2),
                            "resultMessage": item.resultMessage,
                        }
                    )
                });
                setRowsDetail(detail)
            })
        setOpenPopUpDetail(true)
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'amount' && value > installments) {
            alert('คุณกรอกจำนวนเงินมากกว่าค่างวด กรุณาตรวจสอบอีกคร้ัง')
        } else {
            if (name === 'type' && value === '1') {
                setFormData((prevData) => ({ ...prevData, amount: installments, type: '1' }));

            } else {
                setFormData((prevData) => ({ ...prevData, [name]: value, }));
            }
        }
    };
    const handleSaveOption = (e) => {
        if (formData.type !== '1' && (formData.amount === 0 || formData.amount === '')) {
            alert('คุณยังไม่ได้ทำการกรอกจำนวนเงิน กรุณากรอกจำนวนเงิน')
        } else {
            setOpenPopUpEdit(false)
            handleUpdate()
        }
    }
    const handleUpdate = (e) => {
        let obj = {
            user_id: formData.user_id,
            contract_id: formData.contract_id,
            amount: formData.amount,
            type: formData.type
        }
        console.log('obj', obj)
        DirectDebit.updateManualPayment(obj,token)
            .then((res) => {
                if (res.data.success) {
                    alert("บันทึกสำเร็จ")
                    window.location.reload();
                } else {
                    alert("บันทึกไม่สำเร็จ")
                    window.location.reload();
                }
            })
    }
    const handlePayment = (e) => {
        const selectedData = selected_row.map((selectedIndex) =>
            rows_user.find((row) => row.id === selectedIndex)
        );
        setOpenPopup(false)
        let obj = {
            all_user: selectedData,
            user_action: decoded.user_id,
            pin: PIN
        }
        confirmAlert({
            title: 'ยืนยันที่จะสั่งตัดเงิน?',
            message: 'คุณแน่ใจหรือที่จะสั่งตัดสิน',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        DirectDebit.manualPayment(obj)
                            .then((res) => {
                                if (res.data.success) {
                                    alert("ตัดเงินสำเร็จ")
                                    window.location.reload();
                                } else {
                                    if (res.data.code = "01") {
                                        alert("PIN ไม่ถูกต้อง")
                                    } else {
                                        alert("ตัดเงินไม่สำเร็จ")
                                    }
                                    window.location.reload();
                                }
                            })
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("cancel")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
    }
    // TODO CARE DATA
    return (
        <>{
            hasPermission && (
                <div className='customer-list' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: '' }}>รายการลูกค้าตัดเงินบางส่วนตามรอบอัตโนมัติ</Typography>
                        </Grid>
                    </Grid>
                    {
                        false ?
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            : ""
                    }

                    <Paper elevation={3} >
                        <Typography variant='h6' style={{ padding: '10px' }}>Auto Partial Transaction Customer List</Typography>
                        {/* <MyComponent /> */}
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                                // bgcolor:'black'
                            }}
                        >
                            <DataGrid
                                rows={rows_user}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}

                            />
                            <Modal
                                open={open_popup}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{ zIndex: 900 }}
                            >
                                <Box sx={style}>
                                    <IconButton
                                        style={{ position: "absolute", top: "0", right: "0" }}
                                        onClick={() => setOpenPopup(false)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <h2 id="app">กรุณากรอกรหัส</h2>
                                    <TextField style={{ marginBottom: '2rem', width: 230 }} id="outlined-basic2" label="PIN" variant="outlined" size="small" type='password' onChange={(e) => setPIN(e.target.value)} />
                                    <br></br>
                                    <Button style={{ float: 'right', marginTop: 10 }} variant="contained" onClick={() => handlePayment()}>ยืนยัน</Button>
                                </Box>

                            </Modal>
                        </Box>
                    </Paper>
                    <Dialog open={open_popup_edit} onClose={() => setOpenPopUpEdit(false)} fullWidth
                        maxWidth="sm">
                        <DialogTitle>แก้ไขจำนวนเงินในการตัดต่อรอบ</DialogTitle>
                        <DialogContent>
                            <Box sx={{ height: 300, width: '100%' }}  >
                                <Grid item xs={6}>
                                    <TextField
                                        label="จำนวนเงิน"
                                        name="amount"
                                        value={formData.type === 1 ? installments : formData.amount}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        disabled={formData.type === 1 ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <h3>จำนวนค่างวดที่เหลือ :  {formData.remaining_money_installment === null ? 0 : formData.remaining_money_installment}  บาท</h3>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenPopUpEdit(false)} variant="contained" color="error">
                                ยกเลิก
                            </Button>
                            <Button onClick={() => handleSaveOption()} variant="contained" color="primary">
                                บันทึก
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={open_popup_detail} onClose={() => setOpenPopUpDetail(false)} fullWidth
                        maxWidth="md">
                        <DialogTitle>รายละเอียดการตัดเงิน</DialogTitle>
                        <DialogContent>
                            <Box
                                sx={{
                                    height: 630,
                                    width: '100%',
                                    // bgcolor:'black'
                                }}
                            >
                                <DataGrid
                                    rows={rows_detail}
                                    columns={columns_detail}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    sx={{
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1.5,
                                        },
                                    }}

                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenPopUpDetail(false)} variant="contained" color="error">
                                ปิด
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </>
    )
}