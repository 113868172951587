import axios from "axios";
const config = require('../config.js')

const ROOT_URL = config.ROOT_URL
// const ROOT_URL = "http://localhost:44446"
export const queryBalance = (token) => {
    return axios.get(`${ROOT_URL}/gb_transfer_out/query_balance`, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}
export const withdraw =  (obj,token) => {
    return axios.post(`${ROOT_URL}/gb_transfer_out/withdraw`, obj, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}